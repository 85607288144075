import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { Chevron, Direction } from "../Chevron/Chevron";

interface IAccordianState {
    isHidden: boolean;
    setIsHidden: (isHidden: boolean) => void;
    onToggle?: (isCollapsed: boolean) => void;
}

const AccordianContext = createContext<IAccordianState>({ isHidden: true } as IAccordianState);

interface IAccordianProps {
    id?: string;
    isCollapsed?: boolean;
    className?: string;
    children:  ReactNode;
    onToggle?: (isCollapsed: boolean) => void;
}

export const Accordion = ({ children, isCollapsed = true, id, className="", onToggle } : IAccordianProps) => {
    const [isHidden, setIsHidden] = useState(isCollapsed);

    // This should only be set if onToggle is defined, otherwise this won't work as intended
    if (onToggle) {
        useEffect(() => {
            setIsHidden(isCollapsed);
        }, [isCollapsed]);
    }

    return (
      <AccordianContext.Provider value={{ isHidden, setIsHidden, onToggle }}>
        <div id={id} className={`is-clickable ${className}`}>{children}</div>
      </AccordianContext.Provider>
    );
};

interface IChevronOptions {
    show?: boolean;
    className?: string;
    directionOnCollapsed?: Direction;
    directionOnExpanded?: Direction;
}

interface IAccordianHeaderProps {
    className?: string;
    children: ReactNode;
    chevronOptions?: IChevronOptions
}

Accordion.Header = ({ children, className = "", chevronOptions } : IAccordianHeaderProps) => {
    const { isHidden, setIsHidden, onToggle } = useContext(AccordianContext);

    const {
        show = true,
        directionOnCollapsed = "Down",
        directionOnExpanded = "Up",
    } = chevronOptions || {};

    const toggle = () => {
        if (setIsHidden) {
            setIsHidden(!isHidden);
            if (onToggle) {
                onToggle(!isHidden);
            }
        }
    };
    
    return (
        <div onClick={() => toggle()} className={`is-flex is-flex-direction-row ${className}`}>
            <div className="is-flex is-flex-grow-1 is-align-items-center">
                {children}
            </div>
            {show && <Chevron className="px-3 is-align-self-center" direction={isHidden ? directionOnCollapsed : directionOnExpanded}/>}
        </div>
    );
};

interface IAccordianContentProps {
    className?: string;
    children: ReactNode;
}

Accordion.Content = ({ children, className="" } : IAccordianContentProps) => {
const { isHidden } = useContext(AccordianContext);
    return (
        <div className={`pt-2 ${className}${isHidden ? " is-hidden" : ""}`}>
            <span>{children}</span>
        </div>
    );
};
