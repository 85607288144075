import { Columns, Section } from "react-bulma-components";
import { Chat } from "../../../../../../../components/Chat/Chat"
import { StartOver } from "../../../../../../../components/StartOver/StartOver";
import { RiskToleranceScript } from "./RiskToleranceScript"
import "./RiskTolerance.scss";
import { useEffect, useState } from "react";
import { useLoader } from "../../../../../../../hoc/withLoader";
import { CalculateRiskTolerance_Request, CalculateRiskTolerance_Request_Answer, CalculateRiskTolerance_Request_Question, CalculateRiskTolerance_Response } from "../../../../../services/generated/PropertyClient";
import { useNavigate } from "react-router-dom";
import { PropertyClientFactory } from "../../../../../services/PropertyClientFactory";
import { Helmet } from "react-helmet-async";

export const PageTitle = () => "Risk tolerance (1/2)";

export const RiskTolerance = () => {

    const [iteration, setIteration] = useState(1);

    const onStartOver = () => {
        setIteration(iteration + 1);
    }

    return (
        <div className="risk-tolerance is-flex is-flex-grow-1 is-flex-direction-column">
            <Helmet>
                <title>Risk Tolerance</title>
            </Helmet>
            <div className="start-over-nav">
                <div className="is-relative">
                    <div className="is-overlay has-background">
                    </div>
                </div>
                <Columns className="is-flex-grow-1" breakpoint="desktop">
                    <Columns.Column>
                        <div className="is-hidden-touch is-flex is-justify-content-center pt-3">
                            <StartOver onStartOver={onStartOver}/>
                        </div>
                        <div className="px-5">
                            <div className="is-hidden-desktop is-flex is-justify-content-space-between is-align-items-center py-3">
                                <h6 className="title is-5 mb-0 has-text-navy">{PageTitle()}</h6>
                                <StartOver onStartOver={onStartOver}/>
                            </div>
                        </div>
                    </Columns.Column>
                    <Columns.Column size={9}></Columns.Column>
                </Columns>  
            </div>
            <Columns className="is-flex-grow-1 mb-0" breakpoint="desktop">
                <Columns.Column size={3}>
                </Columns.Column>
                <Columns.Column className="glass-desktop">
                    <Section>
                        <div className="py-4">
                            <RiskToleranceCalculation key={iteration}/>
                        </div>
                    </Section>
                </Columns.Column>
                <Columns.Column size={3}>
                </Columns.Column>
            </Columns>
        </div>
    );
}

const RiskToleranceCalculation = () => {

    const client = PropertyClientFactory();
    const [loaderState, { load, defaultLoaderDisplay, defaultErrorDisplay }] = useLoader<CalculateRiskTolerance_Response>();
    const navigate = useNavigate();

    let answers = new Array<({question: string, reply: string, score: number})>();

    useEffect(() => {
        if (loaderState.loadingState === "Loaded") {
            navigate("/invest/tools/risk/tolerance-results");
        }
    }, [loaderState.loadingState]);

    const onAnswer = (pathId: number, label: string, value: string) => {
        let path = RiskToleranceScript().paths.find(x => x.id === pathId);
        if (path) {
            let question = path.botMessages[path.botMessages.length - 1] ?? ""; // Assume the question is always the last bot message
            let reply = label;
            let score = parseInt(value);
            answers.push({ question, reply, score });
        }
    }

    const onFinished = () => {
        // Map the answers to the API request and call the loader
        let request = new CalculateRiskTolerance_Request();
        request.answers = answers.map((x, i) => {
            let answer = new CalculateRiskTolerance_Request_Answer();
            answer.question = new CalculateRiskTolerance_Request_Question();
            answer.question.id = i + 1;
            answer.question.text = x.question;
            answer.reply = x.reply;
            answer.score = x.score;

            return answer;
        });

        load(client.calculateRiskTolerance(request));
    }

    return (
        <> 
            {
                loaderState.loadingState === "NotStarted" &&
                <div className="risk-tolerance-calculation">
                    <Chat script={RiskToleranceScript()} onAnswerReceived={onAnswer} onFinished={onFinished}/>
                </div>
            }
            {
                (loaderState.loadingState === "Loading" || loaderState.loadingState == "Loaded") &&
                <div className="pt-8 is-flex is-flex-grow-1 is-flex-direction-column is-justify-items-center">
                    <h3 className="title is-3 has-text-centered has-text-navy">Calculating your risk tolerance</h3>
                    { defaultLoaderDisplay() }
                </div>
            }
            {
                loaderState.loadingState === "Failed" &&
                <div className="pt-8 is-flex is-flex-grow-1 is-flex-direction-column is-justify-items-center">
                    { defaultErrorDisplay() }
                </div>
            }
        </>
    )
}