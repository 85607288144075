import { useEffect, useState } from "react";
import { InvestmentsDashboard } from "../../../../../components/InvestmentsDashboard/InvestmentsDashboard";
import { formatCurrency } from "../../../../../../../helpers/currency";
import { Table } from "../../../../../../../components/Table/Table";
import { Loader } from "../../../../../../../components/Loader/Loader";
import { Info } from "../../../../../../../components/Info/Info";
import { formatPercentage } from "../../../../../../../helpers/percentage";
import { PortfoliosClientFactory } from "../../../../../services/PorfoliosClientFactory";
import { useLoader } from "../../../../../../../hoc/withLoader";
import { GetPortfolioTransactions_Response, GetPortfolioTransactions_TransactionType } from "../../../../../services/generated/PortfoliosClient";
import dayjs from "dayjs";
import { ContributionOverlayDetails } from "./ContributionOverlayDetails";
import { WithdrawalOverlayDetails } from "./WithdrawalOverlayDetailals";
import { FundSwitchOverlayDetails } from "./FundSwitchOverlayDetails";
import { ContractNoteOverlayDetails } from "./ContractNoteOverlayDetails";

interface FundsDashboardProps {
    currentValue?: number;
    simpleReturn?: number;
    portfolioId?: string;
}

export const FundsDashboard = ({ currentValue, simpleReturn, portfolioId } : FundsDashboardProps) => {

    const client = PortfoliosClientFactory();
    const [loaderState, loader] = useLoader<GetPortfolioTransactions_Response>();
    const [overlayTransactionId, setOverlayTransactionId] = useState<string | undefined>();
    const [overlayOrder, setOverlayOrder] = useState<{ orderId: string | undefined, type: GetPortfolioTransactions_TransactionType}>();

    useEffect(() => {
        if (portfolioId) {
            loader.load(client.getPortfolioTransactions(portfolioId));
        }
    }, [portfolioId]);

    const getTypeString = (type?: GetPortfolioTransactions_TransactionType) => {
        switch(type) {
            case GetPortfolioTransactions_TransactionType.Deposit:
                return "Contribution";
            case GetPortfolioTransactions_TransactionType.Withdrawal:
                return "Withdrawal";
            case GetPortfolioTransactions_TransactionType.Rebalance:
                return "Fund switch";
            default:
                return "Unknown";
        }
    }

    const transactions = loaderState.response?.transactions?.map(x => {
        return {
            id: x.transactionId,
            dateRequested: x.dateRequested,
            amount: x.amount,
            type: x.type,
            status: x.status
        };
    });

    let selectedOverlayTransaction = undefined;

    if (transactions !== undefined) {
        selectedOverlayTransaction = overlayTransactionId ? transactions.find(x => x.id === overlayTransactionId): undefined;
    }

    const onOrderDetailsClick = (orderId: string, type: GetPortfolioTransactions_TransactionType) => {
        setOverlayOrder({ orderId, type });
    }

    const getTransactionOverlay = (selectedOverlayTransaction : any) => {
        switch(selectedOverlayTransaction?.type) {
            case GetPortfolioTransactions_TransactionType.Deposit:
                return <ContributionOverlayDetails showOrderDetails={onOrderDetailsClick} transaction={selectedOverlayTransaction} />;
            case GetPortfolioTransactions_TransactionType.Withdrawal:
                return <WithdrawalOverlayDetails showOrderDetails={onOrderDetailsClick} transaction={selectedOverlayTransaction} />;
            case GetPortfolioTransactions_TransactionType.Rebalance:
                return <FundSwitchOverlayDetails showOrderDetails={onOrderDetailsClick} transaction={selectedOverlayTransaction} />;
            default:
                return null;
        }
    }

    const onOrderDetailsHide = (currentTransactionId : string) => {
        setOverlayOrder(undefined);
        setOverlayTransactionId(currentTransactionId);
    }
    
    return (
        <InvestmentsDashboard>
            <div className="is-flex is-justify-content-space-between">
                {
                    currentValue === undefined 
                        ? <Loader />
                        : <div className="has-text-purple-blue-500">
                            <p className="is-size-h4">{formatCurrency(currentValue)}</p>
                            <p className="is-size-h6">Invested value <Info notificationClassName={"has-text-left is-size-6"} notificationStyle={{lineHeight:1.2}} positioning={["bottom"]}><span>This is the total value of your investment. This is calculated in real time and adjusted based on the price of the units in the fund you have invested in. This value can go up and down and volatility is normal.
                            </span></Info></p>
                        </div>
                }
                {
                    simpleReturn === undefined 
                        ? <Loader />
                        : <div style={{ color: simpleReturn > 0 ? '#189715' : '#1B0777'}} className="has-text-right mb-4">
                            <p className="is-size-h4">{formatPercentage(simpleReturn)}</p>
                            <p className="is-size-h6">Simple Return <Info notificationClassName={"has-text-left is-size-6"} notificationStyle={{lineHeight:1.2}} positioning={["bottom", "left"]}><span>This value shows you how much your money has grown. It takes into account the money you have contributed and withdrawn. Sometimes, when you withdraw a lot of money at once, the return may show as negative.</span></Info></p>
                        </div>
                }
            </div>
           
           <InvestmentsDashboard.Footer linkText="View transaction history" expandedlinkText="Close transaction history">
                <Table>
                    <Table.Header>
                        <Table.Header.Heading><p className="has-text-purple-blue-500 has-text-weight-medium">Date Requested</p></Table.Header.Heading>
                        <Table.Header.Heading><p className="has-text-purple-blue-500 has-text-weight-medium">Amount</p></Table.Header.Heading>
                        <Table.Header.Heading><p className="has-text-purple-blue-500 has-text-weight-medium">Status</p></Table.Header.Heading>
                        <Table.Header.Heading><p className="has-text-purple-blue-500 has-text-weight-medium">Type</p></Table.Header.Heading>
                    </Table.Header>
                    { transactions !== undefined &&
                        <Table.Data data={
                            transactions!.map(x => {
                                return {
                                    onClick: () => setOverlayTransactionId(x.id),
                                    values: [
                                        <>{ dayjs(x.dateRequested).format("DD/MM/YYYY") }</>,
                                        <>{ x.amount ? formatCurrency(x.amount) : "-"}</>,
                                        <>{x.status}</>,
                                        <>{getTypeString(x.type)}</>
                                    ]
                                }
                            })
                        } />
                    }
                </Table>
            </InvestmentsDashboard.Footer>

            <InvestmentsDashboard.Overlay isVisible={overlayTransactionId !== undefined} onClose={() => setOverlayTransactionId(undefined)}>
                { getTransactionOverlay(selectedOverlayTransaction) }
                <InvestmentsDashboard.Overlay isVisible={overlayOrder?.orderId != undefined} onClose={() => setOverlayOrder(undefined)}>
                    <ContractNoteOverlayDetails hideOrderDetails={onOrderDetailsHide} transactionId={overlayTransactionId} orderId={overlayOrder?.orderId} transactionType={overlayOrder?.type} />
                </InvestmentsDashboard.Overlay>
            </InvestmentsDashboard.Overlay>
        </InvestmentsDashboard>
    );
}
