import { ReactNode } from "react";

export interface IBasicTileProps {
    children?: ReactNode,
    color?: "White",
    className?: string,
    paddingSize?: "Default" | "Small",
}


export const BasicTile = ({children, color = "White", paddingSize = "Default", className = ""} : IBasicTileProps) => {

    let paddingClasses = "p-4";
    switch (paddingSize) {
        case "Small":
            paddingClasses += " px-6-desktop";
            break;
        default:
            paddingClasses += " p-6-desktop";
            break;
    }

    let colorClass = "";
    switch (color) {
        case "White":
            colorClass = "has-background-grey-100";
            break;
    }

    return (
        <div className={`is-rounded-2 ${colorClass} ${className} ${paddingClasses}`}>
            {children}
        </div>
    )
}