import { Link } from "react-router-dom";
import { ContentTitle } from "../Layout/Content/ContentTitle";
import { Chevron } from "../Chevron/Chevron";
import { useWpLoader } from "../../hoc/withLoader";
import { useEffect } from "react";
import { FaqParams, WpPostTypeRequest } from "../Auth/AuthenticatedCmsClient";
import { LoaderProps } from "../Layout/Wrappers/ILoaderProps";

interface IFAQMiniProps {
    questions: { question: string; id: string;}[];
    sectionSlug: string;
}

export const FAQMini = ({ questions, sectionSlug } : IFAQMiniProps) => {
    return (
        <>
            <ContentTitle text="FAQs" size="Small"/>
            <div className="faqs border-top">
                {
                    questions.length > 0
                        ? questions.map((x : any) => {
                            return (
                                <div key={x.id} className="is-flex is-flex-direction-row pb-1 is-justify-content-space-between border-bottom py-2 is-size-5">
                                    <Link to={`/faqs/#${x.id}`} className="has-text-grey-500">{ x.question }</Link>
                                    <Chevron className="is-align-self-center" direction={"Right"} color="purple"/>
                                </div>
                            )
                        })
                        : <p className="py-2">No FAQs found for this section.</p>
                }
            </div>
            {
                sectionSlug !== "" && 
                    <Link id={sectionSlug} to={`/faqs/#${sectionSlug}`} className="is-fullwidth mt-5 is-block has-text-centered is-underlined">See more</Link>
            }
        </>
    );
}

interface IFAQMiniContainerProps extends LoaderProps{
    platformArea: string;
}

export const FAQMiniContainer = ({platformArea, wrap}: IFAQMiniContainerProps) => {
    const [loaderState, { load, defaultLoaderDisplay, defaultErrorDisplay }] = useWpLoader();

    useEffect(() => {
        if (loaderState.loadingState === "NotStarted") {
            load(new WpPostTypeRequest({
                url: "faqs",
                params: new FaqParams({
                    per_page: 1,
                    platform_area: platformArea,
                })
            }));
        }
        
    }, [loaderState.loadingState]);

    const inner = () => {
        if (loaderState.loadingState === "Loaded") {

            if (loaderState.response.length === 0) {
                return <></>;
            }

            let questions = loaderState.response[0].acf.faqs.filter((x: any) => {
                return x.featured;
            }).map((x: any) => {
                return {
                    question: x.question,
                    id: x.unique_id
                }
            });

            return <FAQMini questions={questions} sectionSlug={loaderState.response[0].slug}/>;
        }

        if (loaderState.loadingState === "Failed") {
            return defaultErrorDisplay();
        }

        return defaultLoaderDisplay();
    }

    return wrap
    ? wrap(inner())
    : inner();
}