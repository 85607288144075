import { Card, CardWithLink } from "../../../../components/Cards/Card";
import { MembershipProps } from "../../../../components/Membership/MembershipProps";
import { defaultLoaderDisplay } from "../../../../hoc/withLoader";
import { Excerpt } from "../../../../components/Excerpt/Excerpt";
import { ImageSize, getImageUrl } from "../../../../helpers/cms";

interface LevelCardProps extends MembershipProps {
    url: string;
    totalChildren: number;
    childrenCompleted?: number;
    thumbnail: string;
    name: string;
    duration: string;
    description?: string;
    className?: string;
}

// TODO: If clicked ad is membership, need to open modal instead
export const LevelCard = ({url, totalChildren, childrenCompleted, thumbnail, name, duration, description, membership, className = ""} : LevelCardProps) => {
    return (
        <CardWithLink
            heading={name}
            imageUrl={getImageUrl(thumbnail, ImageSize.MediumLarge)}
            url={url}
            membership={membership}
            className={className}
        >
            <div className="is-flex is-fullwidth is-justify-content-space-between is-6 mt-1">
                {
                    childrenCompleted === undefined 
                        ? defaultLoaderDisplay()
                        : <div className="mr-1">
                            <i className="is-size-smaller-tablet-only is-size-smaller-desktop-only">
                                {childrenCompleted}/{totalChildren} complete
                            </i>
                        </div>
                }
                <div className="has-text-right m-0">
                    <i className="is-size-smaller-tablet-only is-size-smaller-desktop-only has-text-right">
                        Time {duration}
                    </i>
                </div>
            </div>
            {
                description &&
                    <div className="has-text-black mb-0 mt-1 pb-0">
                        <Excerpt text={description} maxLines={5}/>
                    </div>
            }
        </CardWithLink>
    )
}