import { X } from "react-feather"
import { OutsideAlerter } from "../OutsideAlerter/OutsideAlerter"

import * as classes from "./Explainer.module.scss";

export const Explainer = ({ 
    children = undefined as undefined | any,
    onDismiss = () => {}
}) => {
    return (
        <OutsideAlerter className="is-position-relative has-background-grey-100 has-border-grey-200-1" onClickOutside={onDismiss}>
            <div className="is-position-absolute is-fullwidth">
                <div className="is-flex is-flex-direction-row is-justify-content-end">
                    <figure className={classes.icon}>
                        <X strokeWidth={"2px"} size={20} className="is-clickable" onClick={onDismiss}/>
                    </figure>
                </div>
            </div>
            <div className="p-3">
                <div className={classes.explainer}>
                    {children}
                </div>
            </div>
        </OutsideAlerter>
    )
}