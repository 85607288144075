import { FC, useContext, useState } from "react";
import { Columns, Navbar as BulmaNavbar } from "react-bulma-components";
import { useAuth } from "react-oidc-context";
import { Link } from "react-router-dom";
import logo from "../../assets/propelle-logo-navy.svg";
import "./Navbar.scss";
import { getUserData } from "../../helpers/auth";
import { ScreenSize, WindowContext } from "../App/WindowContext";
import { Membership } from "./Membership";

interface INavbarProps {
    pageTitle?: string;
    showMenu?: boolean;
}

export const Navbar = ({ showMenu = true, pageTitle }: INavbarProps) => {
    const [isBurgerActive, setIsBurgerActive] = useState<Boolean>(false);
    const auth = useAuth();
    const userInfo = getUserData(auth);
    const { screenSize } = useContext(WindowContext);

    return (
        <BulmaNavbar fixed="top" className="propelle-navbar">
            <Columns className="is-flex-grow-1 mb-0" breakpoint="desktop">
                <Columns.Column className="pb-0">
                    <BulmaNavbar.Brand>
                        <BulmaNavbar.Item href="https://propelle.io">
                            <img src={logo} alt="Propelle Logo" />
                        </BulmaNavbar.Item>
                        {
                            showMenu && <BulmaNavbar.Burger
                                className={isBurgerActive ? "is-active" : undefined}
                                id="navbar-burger"
                                data-testid="navbar-burger"
                                aria-label="menu"
                                aria-expanded="false"
                                data-target="navbar-menu"
                                onClick={() => setIsBurgerActive(!isBurgerActive)} />
                        }
                    </BulmaNavbar.Brand>
                </Columns.Column>
                {
                    pageTitle &&
                    <Columns.Column className="is-flex is-flex-direction-column is-justify-content-center is-hidden-touch">
                        <div className="has-text-centered"><h3 className="title is-3 has-text-navy">{pageTitle}</h3></div>
                    </Columns.Column>
                }
                {
                    showMenu
                        ?
                        <Columns.Column className="py-0">
                            <BulmaNavbar.Menu id="navbar-menu" className={isBurgerActive ? "is-active" : undefined}>
                                <>
                                    <NavbarStart />
                                    <NavbarEnd>
                                        {
                                            (screenSize > ScreenSize.Tablet) && <Membership.Badge />
                                        }
                                        <BulmaNavbar.Item className="is-hoverable is-desktop">
                                            <NavbarLink className="is-arrowless">Menu<MenuIcon />
                                            </NavbarLink>
                                            <BulmaNavbar.Dropdown>
                                                <Link className="navbar-item" to={"/my-account/"}>
                                                    My Account
                                                </Link>
                                                <a className="navbar-item" href="https://propelle.io/privacy-policy/" target="_blank">Privacy Policy</a>
                                                <Link className="navbar-item" to={"/faqs/"}>
                                                    FAQs
                                                </Link>
                                                <Link className="navbar-item" to={"/contact-us/"}>
                                                    Contact us
                                                </Link>
                                                <BulmaNavbar.Item onClick={() => auth.signoutRedirect()}>Log out</BulmaNavbar.Item>
                                                {
                                                    userInfo.role === "staff" &&
                                                        <>
                                                            <BulmaNavbar.Divider />
                                                            <div className="navbar-item">
                                                                {"Propelle Staff ✨"}
                                                            </div>
                                                        </>
                                                }
                                            </BulmaNavbar.Dropdown>
                                        </BulmaNavbar.Item>
                                        <Link className="is-mobile navbar-item" to={"/my-account/"}>
                                            My Account
                                        </Link>
                                        <a className="is-mobile navbar-item" href="https://propelle.io/privacy-policy/" target="_blank">Privacy Policy</a>
                                        <Link className="is-mobile navbar-item" to={"/faqs/"}>
                                            FAQs
                                        </Link>
                                        <Link className="is-mobile navbar-item" to={"/contact-us/"}>
                                            Contact us
                                        </Link>
                                        <BulmaNavbar.Item className="is-mobile" onClick={() => auth.signoutRedirect()}>Log out</BulmaNavbar.Item>
                                        {
                                            <div className="is-mobile navbar-item">
                                                <Membership.BurgerMenuItem/>
                                            </div>
                                        }
                                        {
                                            userInfo.role === "staff" &&
                                                <>
                                                    <BulmaNavbar.Divider />
                                                    <div className="is-mobile navbar-item">
                                                        {"Propelle Staff ✨"}
                                                    </div>
                                                </>
                                        }
                                    </NavbarEnd>
                                </>
                            </BulmaNavbar.Menu>
                        </Columns.Column>
                        : <Columns.Column className="py-0"></Columns.Column>
                }
            </Columns>
        </BulmaNavbar>
    );
};

const menuIconStyle: any = {
    marginLeft: "0.5rem",
};

interface NavbarLinkProps {
    className?: string;
    children?: React.ReactNode;
}

interface NavbarStartProps {
    children?: React.ReactNode;
}

interface NavbarEndProps {
    children?: React.ReactNode;
}

const NavbarLink: FC<NavbarLinkProps> = (props) => {
    return <a className={"navbar-link pt-4" + (props.className ? ` ${props.className}` : "")}>{props.children}</a>;
};

const NavbarStart: FC<NavbarStartProps> = (props) => {
    return <div className="navbar-start">{props.children}</div>;
};

const NavbarEnd: FC<NavbarEndProps> = (props) => {
    return <div className="navbar-end">{props.children}</div>;
};

const MenuIcon = () => {
    return <span style={menuIconStyle}>☰</span>;
};

