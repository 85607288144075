import dayjs from "dayjs"
import { InvestmentsDashboard } from "../../../../../components/InvestmentsDashboard/InvestmentsDashboard"
import { formatCurrency } from "../../../../../../../helpers/currency"
import { TradingClientFactory } from "../../../../../services/TradingClientFactory";
import { GetWithdrawal_Response } from "../../../../../services/generated/TradingClient";
import { useLoader } from "../../../../../../../hoc/withLoader";
import { useEffect } from "react";
import { GetPortfolioTransactions_TransactionType } from "../../../../../services/generated/PortfoliosClient";

interface WithdrawalOverlayDetailsProps {
    transaction: {
        id: string;
        dateRequested?: Date;
        amount?: number;
        type?: string;
        status?: string;
    };
    showOrderDetails: (orderId: string, type: GetPortfolioTransactions_TransactionType) => void;
}

export const WithdrawalOverlayDetails = ({transaction, showOrderDetails} : WithdrawalOverlayDetailsProps) => {

    const client = TradingClientFactory();
    const [loaderState, loader] = useLoader<GetWithdrawal_Response>();

    useEffect(() => {
        loader.load(client.getWithdrawal(transaction.id));
    }, []);

    const onOrderDetailsClick = (orderId: string) => {
        showOrderDetails(orderId, GetPortfolioTransactions_TransactionType.Withdrawal);
    }

    const getOrderReceipts = () => {
        if (loaderState.response?.deInvestedOn === undefined) {
            return "Pending";
        }

        return <>
            <span onClick={() => onOrderDetailsClick(loaderState.response?.sellIds![0]!)} className="is-underlined is-link is-clickable has-text-purple-blue-400">Sell Order - Contract Note</span>
        </>
    }

    const inner = () => {
        return (
            <>
                <InvestmentsDashboard.Overlay.OverlaySection>
                    <p className="has-text-purple-blue-500 has-text-weight-semibold" style={{fontSize: "20px"}}>Withdrawal details</p>
                </InvestmentsDashboard.Overlay.OverlaySection>
                <InvestmentsDashboard.Overlay.OverlaySection>
                    <p className="has-text-purple-blue-500 has-text-weight-medium">Date requested</p>
                    <p>{dayjs(transaction.dateRequested).format("DD/MM/YYYY")}</p>
                </InvestmentsDashboard.Overlay.OverlaySection>
                <InvestmentsDashboard.Overlay.OverlaySection>
                    <p className="has-text-purple-blue-500 has-text-weight-medium">Date money disinvested</p>
                    <p>{loaderState.response?.deInvestedOn !== undefined ? dayjs(loaderState.response?.deInvestedOn).format("DD/MM/YYYY") : "Pending"}</p>
                </InvestmentsDashboard.Overlay.OverlaySection>
                <InvestmentsDashboard.Overlay.OverlaySection>
                    <p className="has-text-purple-blue-500 has-text-weight-medium">Date money sent to you</p>
                    <p>{loaderState.response?.settledOn !== undefined ? dayjs(loaderState.response?.settledOn).format("DD/MM/YYYY") : "Pending"}</p>
                </InvestmentsDashboard.Overlay.OverlaySection>
                <InvestmentsDashboard.Overlay.OverlaySection>
                    <p className="has-text-purple-blue-500 has-text-weight-medium">Transaction receipt</p>
                    <p>{ getOrderReceipts() }</p>
                </InvestmentsDashboard.Overlay.OverlaySection>
                <InvestmentsDashboard.Overlay.OverlaySection background="Purple" className="py-4 is-rounded-bottom">
                    <div className="columns is-mobile">
                        <div className="column">
                            <p className="has-text-purple-blue-500 has-text-weight-medium">Amount</p>
                        </div>
                        <div className="column">
                            <div className="is-fullheight is-flex is-flex-direction-row is-align-items-center is-justify-content-end">
                                <p className="has-text-purple-blue-500 has-text-weight-medium">{loaderState.response?.finalisedAmount !== undefined ? formatCurrency(loaderState.response?.finalisedAmount) : "Final amount pending"}</p>
                            </div>
                        </div>
                    </div>
                </InvestmentsDashboard.Overlay.OverlaySection>
            </>
        )
    }

    return loader.defaultDisplayUntilLoaded(inner(), "p-4")

}