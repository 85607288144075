import { Container } from "react-bulma-components"

import './PropelleMobileMenu.scss';
import ConnectIcon from "./connect-icon.svg";
import ConnectInactiveIcon from "./connect-inactive-icon.svg";
import HomeIcon from "./home-icon.svg";
import HomeInactiveIcon from "./home-inactive-icon.svg";
import InvestIcon from "./invest-icon.svg";
import InvestInactiveIcon from "./invest-inactive-icon.svg";
import LearnIcon from "./learn-icon.tsx.svg";
import LearnInactiveIcon from "./learn-inactive-icon.tsx.svg";
import { Link, useLocation, useNavigate, redirect } from "react-router-dom";

export const PropelleMobileMenu = () => {
   
    return (
        <Container>
            <div className="mobile-menu py-1 is-flex is-flex-direction-row is-align-items-end is-justify-content-space-evenly is-hidden-desktop">
                <MenuItem name="Home" route="/" 
                    icon={<HomeIcon />} 
                    inactiveIcon={<HomeInactiveIcon />} />
                <MenuItem name="Invest" route="/invest/tools" 
                    icon={<InvestIcon />} 
                    inactiveIcon={<InvestInactiveIcon />}/>
                <MenuItem name="Learn" route="/learn" 
                    icon={<LearnIcon style={{marginBottom: "3px"}}/>} 
                    inactiveIcon={<LearnInactiveIcon style={{marginBottom: "3px"}}/>}/>
                <MenuItem name="Connect" route="/connect" 
                    icon={<ConnectIcon />} 
                    inactiveIcon={<ConnectInactiveIcon />} />
            </div>
        </Container>
    )
}

export const PropelleMobileMenuSpacer = () => {
    return (
        <div className="mobile-menu-spacer"></div>
    )
}

interface MenuItemProps {
    name: string,
    route: string,
    icon: any,
    inactiveIcon: any,
    linkStyle?: React.CSSProperties
}

const MenuItem = (props: MenuItemProps) => {

    const location = useLocation();
    const navigate = useNavigate();

    let isActive = location?.pathname === props.route;

    if (props.route === "/") {
        // Unique case for the home route
        isActive = props.route == location?.pathname;
    } else {
        isActive = location?.pathname.startsWith(props.route);
    }

    let icon = isActive ? props.icon : props.inactiveIcon;

    const onClick = () => {
        navigate(props.route);
    }

    return (
        <>
            <div className={ "has-text-centered mobile-menu-item is-flex is-flex-grow-1 is-align-items-center is-justify-content-space-between is-flex-direction-column is-clickable " +  (isActive ? "is-active" : "") } onClick={onClick}>
                { icon }
                <Link style={props.linkStyle} to={props.route}>{props.name}</Link>
            </div>
        </>
    )
}