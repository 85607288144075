@use "../../../node_modules/bulma/sass/utilities/_all.sass";

.options-container {
    overflow-x: scroll;
    scroll-behavior: smooth;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.options {

    @include all.until(all.$desktop) {
        gap: 5px;
    }

    @include all.from(all.$desktop) {
        gap: 7.5px;
    }
}

.bar {
    height: 2px;
    background: linear-gradient(326deg, #E9897D 0%, #A594F9 99.95%);
}

.scale {
    .label {
        margin: 0 !important;

        &-start {
            color: #A594F9;
        }
        
        &-end {
            color: #E9897D;
        }
    }
}