
@use "../../../../../../styles/colors";

$border-color: map-get(map-get(colors.$color-pallete, "grey"), "200") solid 1px;

.with-border-top {
    border-top: $border-color;
}

.with-border-left {
    border-left: $border-color;
}

.rag-table {
    overflow-x: auto;
}

.dimension-limits {
    min-width: 550px;
    max-width: 850px;
    margin: 0 auto;
}

.y-axis-label {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
}