import React, { useEffect } from "react";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { AuthHelper } from "./AuthHelper";
import { AccountManagementClientFactory } from "../../areas/invest/services/AccountManagementClientFactory";
import { PortfoliosClientFactory } from "../../areas/invest/services/PorfoliosClientFactory";
import { TradingClientFactory } from "../../areas/invest/services/TradingClientFactory";

interface AuthorizationContextProps {
    children?: React.ReactNode;
}

export interface OidcState {
    id: string;
    path: string;
}

export const AuthorizationContext = (props: AuthorizationContextProps) => {

    const oidcConfig = AuthHelper.getOidcConfig() as AuthProviderProps;

    if (process.env.NODE_ENV !== 'production') {
        const user = AuthHelper.getUserData();
        console.log("UserId:\n" + user?.id);
    }

    return (
        <AuthProvider {...oidcConfig}>
            { props.children }
        </AuthProvider>
    )
}