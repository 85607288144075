import { CssClasses } from "../Helpers/CssClasses";
import chevronNavy from "./chevron.svg";
import chevronPurple from "./chevron-purple.svg";
import { useEffect, useRef, useState } from "react";
import * as classes from "./Chevron.module.scss";

export interface ChevronProps {
    direction: Direction;
    size?: Size;
    className?: string;
    color?: "navy" | "purple"
}

export type Direction =
   | "Up"
   | "Right"
   | "Down"
   | "Left";

export type Size = "Small" | "Medium";

const Rotations = { // From -> To
    "Up": {
        "Down": 180,
        "Left": -90,
        "Right": 90,
        "Up": 0
    },
    "Down": {
        "Up": -180,
        "Left": 90,
        "Right": -90,
        "Down": 0
    },
    "Left": {
        "Right": -180,
        "Up": 90,
        "Down": -90,
        "Left": 0
    },
    "Right": {
        "Left": 180,
        "Up": -90,
        "Down": 90,
        "Right": 0
    }
}

export const Chevron = ({ direction, size = "Medium", className = "", color = "navy" } : ChevronProps) => {
    const [prevDirection, setPrevDirection] = useState<Direction>();
    const elementRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (prevDirection !== direction) {
            setPrevDirection(direction);

            // This isn't the nicest but the chevrons were ticking me off when they
            // spun the wrong way and also that they spin in on load at the start
            const element = elementRef.current;
            if (element) {
                let rotate = 0;
                if (element.style.transform && prevDirection) {
                    const rotateRegex = /rotate\((-?\d*\.?\d+)deg\)/;
                    const match = element.style.transform.match(rotateRegex);

                    rotate = parseFloat(match![1]!)+Rotations[prevDirection][direction];
                    element.style.transform = `rotate(${rotate}deg)`;
                    element.style.transition = "transform 0.3s"
                } else {
                    rotate = direction === "Up" ? 180 : direction === "Down" ? 0 : direction === "Left" ? 90 : 270;
                    element.style.transform = `rotate(${rotate}deg)`;
                }              
            }
        }   
    }, [direction]);

    let cssClasses = new CssClasses(["chevron"]);
    cssClasses.add(className);

    if (classes.chevron) {
        cssClasses.add(classes.chevron);
    }

    const styles = {
        "Small": { height: "6px", width: "12px" },
        "Medium": { height: "8px", width: "16px" }
    }

    let chevron = color === "purple" ? chevronPurple : chevronNavy;

    return (
        <span className={cssClasses.aggregated()} is-unselectable="true" ref={elementRef}>
            <img src={chevron} alt="expander" style={styles[size]} is-unselectable="true"/>
        </span>
    )
}