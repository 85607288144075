import { useState } from "react";
import { IOptionProps } from "./BasicOption";
import { Accordion } from "../../../../../../components/Accordian/Accordian";
import { Radio } from "../../../../../../components/Forms/Radio/Radio";

interface SelectedOptions {
    restricted?: {
        last12Months?: boolean;
        next12Months?: boolean;
    }
}

export const ExtraSelectOption = ({ option, children, value, onChange, showExtraOptions = false } : IOptionProps & { showExtraOptions : boolean } ) => {
    
    const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({});
    
    // Allow control over accordian as well as the default click
    const [isCollapsed, setIsCollapsed] = useState(true);
    const onToggle = (isToggleCollapsed: boolean) => {
        setIsCollapsed(isToggleCollapsed);
    }

    const onRestrictedChecked = (value: string) => {
        if (onChange) {
            onChange(selectedOptions.restricted !== undefined
                && selectedOptions.restricted.last12Months !== undefined
                && selectedOptions.restricted.next12Months !== undefined, value);
        }
        setIsCollapsed(false);
    }

    let onExtraInfoChanged = (value: string, target: HTMLInputElement) => {
        const updatedValue = {...selectedOptions, restricted: {...selectedOptions.restricted, [target.name]: value}};
        setSelectedOptions(updatedValue);

        if (onChange) {
            onChange(updatedValue.restricted !== undefined
                && updatedValue.restricted.last12Months !== undefined
                && updatedValue.restricted.next12Months !== undefined);
        }

    }

    return (
        <Accordion className="is-rounded-2 has-background-purple-blue-100 p-3 mb-2" isCollapsed={isCollapsed} onToggle={onToggle}>
            <Accordion.Header className={"is-size-5"} chevronOptions={{directionOnCollapsed: "Down", directionOnExpanded: "Right"}}>
                <Radio name="investorType" value={value.toString()} onChange={onRestrictedChecked}/>
                {option}
            </Accordion.Header>
            <Accordion.Content className="content ml-6">
                {children}
                {
                    // isChecked() && 
                    <div className={!showExtraOptions ? "is-hidden" : ""}>
                        <div className="field mt-3">
                            <p>In the last 12 months, have you invested more than 10% of your total net assets in peer-to-peer loans, investment crowdfunding or unlisted debt and equity? Note, net assets don’t include: your home (primary residence), your pension or any rights under qualifying contracts of insurance.</p>
                            <Radio labelText="Yes" name="last12Months" value="true" className="mb-1" onChange={onExtraInfoChanged}/>
                            <Radio labelText="No" name="last12Months" value="false" className="mb-1" onChange={onExtraInfoChanged}/>
                        </div>
                        <div className="field">
                            <p>Will you be investing more than 10% of your net assets in the investments described above in the next 12 months?</p>
                            <Radio labelText="Yes" name="next12Months" value="true" className="mb-1" onChange={onExtraInfoChanged}/>
                            <Radio labelText="No" name="next12Months" value="false" className="mb-1" onChange={onExtraInfoChanged}/>
                        </div>
                    </div>
                }
            </Accordion.Content>
        </Accordion>
    );
}