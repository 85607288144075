import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../Header/Header"
import { Layout as DefaultLayout } from '../../../../components/Layout/_Layout';
import { AuthorizedLayout } from "../../../../components/Auth/AuthorizedLayout";


export const Layout = () => {

    let location = useLocation();
    let navigate = useNavigate();

    // We should default to property if the current route is "/invest"
    useEffect(() => {
        if (location?.pathname == "/invest") {
            navigate("/invest/property");
        }
    });

    return (
        <AuthorizedLayout>
            <DefaultLayout>
                <Header />
            </DefaultLayout>
        </AuthorizedLayout>
    )
}