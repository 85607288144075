import { useEffect } from "react";
import { useWpLoader } from "../../../../../../hoc/withLoader";
import { EventParams, WpPostTypeRequest } from "../../../../../../components/Auth/AuthenticatedCmsClient";
import { Content } from "../../../../../../components/Layout/Content/Content";
import { Glass } from "../../../../../../components/Layout/Glass/Glass";
import { EventsCarousel } from "../../../../../../components/Carousel/EventsCarousel";

export const PropertyEvents = () => {
    const [loaderState, { load, defaultLoaderDisplay }] = useWpLoader();

    useEffect(() => {
        if (loaderState.loadingState === "NotStarted") {
            load(new WpPostTypeRequest({
                url: "event",
                params: new EventParams({
                    per_page: 6,
                    platform_area: "property"
                })
            }));  
        }
    }, [loaderState.loadingState]);

    let inner = defaultLoaderDisplay();

    if (loaderState.response) {
        inner = <EventsCarousel wpEvents={(loaderState.response as any[])} title={"Property Events"} />
    }
    
    return (
        <Glass opacity={100}>
            <Content isMain={true}>
                { inner }
            </Content>
        </Glass>
    );
}