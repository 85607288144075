@use "../../../../../../styles/colors";
@use "../../../../../../../node_modules/bulma/sass/utilities/mixins.sass";
@use "../../../../../../styles/spacing";

.tools-tile {
    width: 18.75rem;
    min-height: 9.375rem;

    @include mixins.until(mixins.$desktop) {
        width: 100%;
    }
}

.peach-gradient {
    background: colors.$peach-gradient-lighter;
}

$icon-size: map-get(spacing.$spacing-values, "6");
.icon {
    height: $icon-size;
    max-height: $icon-size;
    width: $icon-size;
    min-width: $icon-size;

    svg {
        width: 100%;
    }
}