import { Outlet } from "react-router-dom";
import { Navbar } from "../Nav/Navbar";
import './_ChatLayout.scss';
import { LocationManager } from "../Location/LocationContext";

export interface ChatLayoutProps {
    pageTitle?: string;
    children?: React.ReactElement[] | React.ReactElement;
}

export const ChatLayout = (props: ChatLayoutProps) => {
    return (
        <div className="chat-layout">
            <LocationManager>
                <Navbar showMenu={false} pageTitle={props.pageTitle}/>
                <Background />
                <main className="chat-main">
                    { props.children }
                    <Outlet />
                </main>
            </LocationManager>
        </div>
    );
}

const Background = () => {
    return <div className="background"></div>;
}