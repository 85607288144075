@use '../../../styles/colors';

.radio {
    cursor: pointer;
    display: flex;
    align-items: center;

    input[type="radio"] {
        height: 20px;
        width: 20px;
        accent-color: map-get(map-get(colors.$color-pallete, "purple-blue"), "200");
    }

    &.square {
        input[type="radio"] {
            appearance: none;
            -webkit-appearance: none;
            border: 2px solid map-get(map-get(colors.$color-pallete, "purple-blue"), "500");
            border-radius: 2px;
            position: relative;
            cursor: pointer;

            &:checked {
                background-color: map-get(map-get(colors.$color-pallete, "purple-blue"), "500");

                &:before {
                    // content: "\2713";
                    content: "✓";
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    font-weight: 700;
                    color: map-get(map-get(colors.$color-pallete, "grey"), "100");
                }
            }
        }
    }
}