import { Helmet } from "react-helmet-async"
import { Main } from "../../../../../components/Layout/Main/Main"
import { Container } from "react-bulma-components"
import { Glass } from "../../../../../components/Layout/Glass/Glass"
import { Content } from "../../../../../components/Layout/Content/Content"
import { Section } from "../../../../../components/Layout/Section/Section"
import { BackButton } from "../../../../../components/BackButton/BackButton"
import { SelfCertificationForm } from "./components/SelfCertificationForm"
import { useContext, useState } from "react"
import { PropertyContext } from "../Property/PropertyContext"
import { defaultErrorDisplay, defaultLoaderDisplay } from "../../../../../hoc/withLoader"
import { Unqualified } from "./components/Unqualified"
import { useNavigate } from "react-router-dom"

export const SelfCertification = () => {

    const context = useContext(PropertyContext);
    const [showUnqualified, setShowUnqualified] = useState(false);
    const navigate = useNavigate();

    let inner = <></>;

    const afterFormSubmit = (isEligible : boolean) => {
        context.refetchData();
        if (isEligible) {
            navigate("/invest/property");
        } else {
            setShowUnqualified(true);
        }
    }

    if (context.loadingState === "NotStarted" || context.loadingState === "Loading") {
        inner = defaultLoaderDisplay();
    } else if (context.loadingState === "Failed") {
        inner = defaultErrorDisplay();
    }

    if (context.loadingState === "Loaded") {

        if (showUnqualified) {
            inner = <Unqualified />
        } else if (context.canSubmitSelfCertification) {
            inner = <SelfCertificationForm afterSubmit={afterFormSubmit}/>
        } else {
            inner = <p>You cannot access this form at this time.</p>
        }
    }

    return (
        <>
            <Helmet>
                <title>Self Certification</title>
            </Helmet>
            <Main>
                <Container>
                    <div className="columns">
                            <div className="column is-8">
                                <Section>
                                    <BackButton className="mb-2 mb-4-desktop"/>
                                    <Glass opacity={100}>
                                        <Content isMain={true}>
                                            { inner }
                                        </Content>
                                    </Glass>
                                </Section>
                            </div>
                         </div>
                </Container>
            </Main>
        </>
    
    )
}

