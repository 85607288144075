import { X } from "react-feather";
import * as classes from "./Banner.module.scss";
import { useState } from "react";
import { CssClasses } from "../Helpers/CssClasses";

export interface BannerProps {
    isPermanent?: boolean;
    type?: "Default" | "Info";
    onClose?: () => void;
    children?: any;
};

export const Banner = ({isPermanent = false, type = "Default", onClose = undefined, ...props }: BannerProps) => {

    const [isClosed, setIsClosed] = useState(false);

    const bannerCss = new CssClasses(["is-relative is-rounded-2"])

    if (type == "Default" && classes.standard) {
        bannerCss.add(classes.standard);
    }

    if (type === "Info" && classes.info) {
        bannerCss.add(classes.info);
    }

    const onBannerClose = () => {
        setIsClosed(true);
        if (onClose) {
            onClose();
        }
    }

    return (
        <>
        {
            !isClosed &&
                <div className={bannerCss.aggregated()}>
                    {
                        !isPermanent &&
                        <div className="is-position-absolute is-top is-right">
                            <div className="p-1 is-clickable" onClick={onBannerClose}>
                                <X color="#1B0777"/>
                            </div>
                        </div>
                    }
                    <div className="p-4 py-3-desktop px-6-desktop">
                        { props.children }
                    </div>
                </div>
        }
        </>
    )
}