import * as classes from "./Main.module.scss";
import "./Main.overrides.scss";

export const Main = (props: { children?: any, className?: string }) => {

    return (
        <div className={`${classes.main} px-8-desktop px-4 py-6 has-background-yellow-100-touch ${props.className ?? ""}`} style={{minWidth: "300px"}}>
            { props.children }
        </div>
    )
}