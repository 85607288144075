import "./Faces.scss";

export function LeftFaces() {
    return (
        <div className="faces-container">
            <div className="faces left">
                <div className="face-container">
                    <div className="face top"></div>
                    <div className="face bottom"></div>
                </div>
            </div>
        </div>
    );
}

export function RightFaces() {
    return (
        <div className="faces-container">
            <div className="faces right">
                <div className="face-container">
                    <div className="face top"></div>
                    <div className="face bottom"></div>
                </div>
            </div>
        </div>
    );
}