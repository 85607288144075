import { Container } from 'react-bulma-components';
import './AccountSetupOverview.scss';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { ApiException, GetOnboardingStage_OnboardingStage, GetOnboardingStage_Response_Root } from '../../../../services/generated/AccountManagementClient';
import { AccountManagementClientFactory } from '../../../../services/AccountManagementClientFactory';
import { useContext, useEffect, useState } from 'react';
import { EquityContext } from '../OnboardingContext';
import { Main } from '../../../../../../components/Layout/Main/Main';
import { Glass } from '../../../../../../components/Layout/Glass/Glass';
import { BackButton } from '../../../../../../components/BackButton/BackButton';
import { useLoader } from '../../../../../../hoc/withLoader';

const steps = [
    <p className='is-size-5-tablet is-size-6-mobile'>Create an account <span className='has-text-weight-semibold'>(you will need your national insurance number for this)</span></p>,
    <p className='is-size-5-tablet is-size-6-mobile'>Select your investment account type (either a Stocks and Shares ISA or a General Investment account) and your fund</p>,
    <p className='is-size-5-tablet is-size-6-mobile'>We check all your details and completing Know Your Customer checks. As soon are they are complete, you can move on to the next step.</p>,
    <p className='is-size-5-tablet is-size-6-mobile'>Make a bank transfer to deposit into your account <span className='has-text-weight-semibold'>(you will need your bank details for this).</span></p>
]

type StageState = "NotStarted" | "InProgress" | "Completed";

interface StageProps {
    state: StageState,
    stepNumber: number,
    children: React.ReactElement;
}

const Stage = ({ state, stepNumber, children }: StageProps) => {
    const stageCss = {
        "NotStarted": "",
        "InProgress": "step-in-progress",
        "Completed": "step-completed"
    }

    return (
        <li className={"step is-flex mb-2 " + stageCss[state]}>
            <div className='bullet-point has-text-weight-bold'>{state === "Completed" || stepNumber}</div>
            <div className='ml-4'>{children}</div>
        </li>)
}

const MainButton = (props: { onboardingStage: GetOnboardingStage_OnboardingStage }) => {
    const userData = useContext(EquityContext);
    const loaderStagesDict: { [id: string]: { page: string, text: string }; } = {
        "NotStarted": { page: "eligibility", text: "Create account" },
        "InitialDetails": { page: "eligibility", text: "Create account" },
        "PersonalDetails": { page: "user-details", text: "Enter your details" },
        "AccountSelection": { page: "account-selection", text: "Select account preference" },
        "InvestmentSelection": { page: "investment-selection", text: "Select investment preference" },
        "KYCChecks": { page: "account-setup", text: "Refresh the screen" },
        "BankDetails": { page: "make-a-deposit", text: "Make a deposit" },
    };

    let buttonText = loaderStagesDict[props.onboardingStage]?.text;

    const client = AccountManagementClientFactory();
    let navigate = useNavigate();

    const handleClick = () => {
        if (props.onboardingStage !== "NotStarted") {
            client.getAccountHolder()
                .then(() => {
                    navigate(`/invest/funds/${loaderStagesDict[props.onboardingStage]?.page}`)
                })
                .catch(err => {
                    let apiException = err as ApiException;
                    if (apiException.result === 404) {
                        //navigate to error page 

                    }
                })
        }
        else if (props.onboardingStage === "NotStarted") {
            client.addAccountHolder()
                .then(() => {
                    userData?.reload();
                    navigate(`/invest/funds/eligibility`)
                })
                .catch(err => {
                    let apiException = err as ApiException;
                    if (apiException.result === 404) {
                        //navigate to error page 
                    }
                })
        }

    }

    return <button className="button is-primary is-rounded is-normal" onClick={handleClick}>{buttonText}</button>
}

export const AccountSetupOverview = () => {
    const client = AccountManagementClientFactory();

    const [loaderState, loader] = useLoader<GetOnboardingStage_Response_Root>();
    const [retryCount, setRetryCount] = useState(0);

    const navigate = useNavigate();

    let userProgress: StageState[] = [];
    let backNavigation: string = "";

    if (loaderState.response) {    
        switch (loaderState.response.stage) {
            case GetOnboardingStage_OnboardingStage.NotStarted:
            case GetOnboardingStage_OnboardingStage.InitialDetails:
            case GetOnboardingStage_OnboardingStage.PersonalDetails:
                userProgress = ["InProgress", "NotStarted", "NotStarted", "NotStarted"];
                backNavigation = "/invest/funds";
                break;
            case GetOnboardingStage_OnboardingStage.AccountSelection:
                userProgress = ["Completed", "InProgress", "NotStarted", "NotStarted"];
                backNavigation = "/invest/funds/user-details";
                break;
            case GetOnboardingStage_OnboardingStage.KYCChecks:
                userProgress = ["Completed", "Completed", "InProgress", "NotStarted"];
                steps[2] = <p className='is-size-5-tablet is-size-6-mobile'>We’re running some background checks on you called Know Your Customer checks (KYC). Once that's done, you'll be all set to proceed. If you're waiting for 10 mins or more, please get in touch at <a href='mailto:support@propelle.io'><b>support@propelle.io</b></a>.</p>
                backNavigation = "";
                break;
            case GetOnboardingStage_OnboardingStage.BankDetails:
                userProgress = ["Completed", "Completed", "Completed", "InProgress"];
                break;
            case GetOnboardingStage_OnboardingStage.Complete:
                userProgress = ["Completed", "Completed", "Completed", "Completed"];
                break;
        }
    }

    useEffect(() => {
        loader.load(client.getOnboardingStage());
    }, [retryCount]);

    useEffect(() => {
            if (loaderState.response?.stage === GetOnboardingStage_OnboardingStage.Complete ) {
            navigate("/invest/funds");
        }
    }, [loaderState?.loadingState]);

    if (loaderState.response?.stage === GetOnboardingStage_OnboardingStage.KYCChecks) {
        const retryInterval = 10000; // Retry every 10 seconds
        const maxRetries = 5 * 60 / 10; // Maximum number of retries in 5 minutes

        if (retryCount < maxRetries) {
            setTimeout(() => {
                setRetryCount(retryCount + 1);
            }, retryInterval);
        }
    }

    return (
        <Main>
             <Helmet>
                <title>Setup Overview</title>
            </Helmet>
            <Container>
                {
                    backNavigation !== "" &&
                        <BackButton to={backNavigation} text="Back" className="mb-3"/>
                }
                <div className='columns'>
                    <div className="column is-10-widescreen is-8-fullhd">
                        <Glass className='account-setup-overview'>
                            <div className="container">
                                <h1 className="pb-4 is-size-2-tablet is-size-4-mobile has-text-navy">Steps to get you setup</h1>
                                {
                                    loader.defaultDisplayUntilLoaded(
                                        <>
                                            <ul>
                                                {
                                                    steps.map((children, index) =>
                                                        <Stage
                                                            stepNumber={index + 1}
                                                            state={userProgress[index] || "NotStarted"}
                                                            key={index}
                                                        >
                                                            {children}
                                                        </Stage>
                                                    )
                                                }
                                            </ul>
                                            <MainButton onboardingStage={loaderState.response?.stage!} />
                                        </>
                                    )
                                } 
                            </div>
                        </Glass>
                    </div>
                </div>
            </Container>
        </Main>
    )
};


