import { Topic } from "./Topic";
import { Course } from "./Course";
import dayjs from "dayjs";

export class Level {
    protected _status: string;
    protected _parser = new DOMParser();
    id: number;
    slug: string;
    description: string;
    name: string;
    level: string;
    course: Course;
    topics: Topic[] = [];
    
    constructor(level: any) { // Expects a WP REST API response
        this.id = parseInt(level.id);
        this.slug = level.slug;
        this.description = level.description;
        this.name = level.name;
        this.level = level.level ?? level.acf.level;
        this._status = level.level_ready_to_publish ?? level.acf.level_ready_to_publish;
        if (level.parent_term) {
            const parentTerm = level.parent_term;
            parentTerm.name = this._parser.parseFromString(parentTerm.name, "text/html").body.textContent;

            this.course = new Course(parentTerm);
        }
        if (level.children) {
            level.children.forEach((topic: any) => {
                topic.name = this._parser.parseFromString(topic.name, "text/html").body.textContent;
                this.topics.push(new Topic(topic));
            });
        }
    }

    public getCompletedTopics() : number | undefined {
        // Make sure data has been loaded before calculating
        const loadedTopics = this.topics.filter(topic => topic.isComplete !== undefined);
        return loadedTopics.length === this.topics.length
            ? loadedTopics.filter(topic => topic.isComplete).length
            : undefined;
    }

    public getTotalFormattedDuration() : string {
        const mins = this.topics.reduce((total : number, topic) => {
            return total + topic.duration
        }, 0);
        return dayjs.duration(mins, "minutes").format(mins < 60 ? "m[ mins]" : "H[h] m[mins]");
    }

    public getTopicIds() : number[] {
        return this.topics.map(topic => topic.id);
    }

    public get status() : string {
        const completedTopics = this.getCompletedTopics();
        return this.topics.length === completedTopics && this._status === "levelReadyToPublish"
            ? "complete"
            : this._status === "levelContentComingSoon" || completedTopics === undefined
                ? "coming-soon"
                : "active";
    }
}