import { Link } from "react-router-dom";
import { Chevron } from "../Chevron/Chevron";
import * as classes from "./Back.module.scss";

export interface BackProps {
    navigate: {
        to?: string,
        action?: () => void
    };
    children?: any;
}

export const Back = (props: BackProps) => {

    if (props.navigate.to) {
        return (
            <Link to={props.navigate.to} className={`${classes.link} back is-flex is-flex-direction-row is-flex-wrap-wrap is-flex-gap-1`}>
                <Chevron direction="Left" />
                { props.children }
            </Link>
        )
    }

    if (props.navigate.action) {
        return (
            <a onClick={props.navigate.action} className={`${classes.link} back is-flex is-flex-direction-row is-flex-wrap-wrap is-flex-gap-1`}>
                <Chevron direction="Left" />
                { props.children }
            </a>
        )
    }

    return (
        <Link to="#" className={`${classes.link} back is-flex is-flex-direction-row is-flex-wrap-wrap is-flex-gap-1`}>
            <Chevron direction="Left" />
            { props.children }
        </Link>
    )
}