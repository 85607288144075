import { useState, useEffect, ComponentType, ReactElement, ReactNode } from 'react';
import { PropertyClient } from '../areas/invest/services/generated/PropertyClient';
import { PropertyClientFactory } from '../areas/invest/services/PropertyClientFactory';
import { Loader } from '../components/Loader/Loader';
import { IWpRequest, useAuthenticatedCmsClient } from '../components/Auth/AuthenticatedCmsClient';


export interface ILoaderProps<T> {
    response?: T | null;
    onRefresh?: () => void;
}

export const withLoader = <P extends object, T>(
    WrappedComponent: ComponentType<P & ILoaderProps<T>>,
    apiFunction: (client: PropertyClient) => Promise<T>,
  ): ((props: P) => ReactElement) => (props: P): ReactElement => {
      const [isLoading, setIsLoading] = useState<boolean>(true);
      const [response, setResponse] = useState<T | null>(null);
      const [error, setError] = useState<Error | null>(null);
      const [refresh, setRefresh] = useState<boolean>(false);

      const client = PropertyClientFactory();

      useEffect(() => {
            const fetchData = async () => {
            try {
                const result = await apiFunction(client);
                setResponse(result);
                setIsLoading(false);
            } catch (error) {
                setError(error);
            }
            };
    
            fetchData();
        }, [refresh]);
  
        if (isLoading) {
            return <Loader />
        }
        
        if (error) {
            return <p className="has-text-centered">Loading failed</p>
        }

        return (<WrappedComponent response = {response} onRefresh={() => setRefresh(!refresh)}  { ...props as P }/>);
  };

  export type LoadingState =
    | "NotStarted"
    | "Loading"
    | "Loaded"
    | "Failed";

  export interface UseLoaderState<TResponse>  {
    loadingState: LoadingState;
    response?: TResponse;
    error?: any;
  }
  
  export const useLoader = <TResponse,> () => {

    const [state, setState] = useState({loadingState: "NotStarted"} as UseLoaderState<TResponse>);
    const [request, setRequest] = useState({} as Promise<TResponse> | undefined);

    useEffect(() => {
        if (state.loadingState === "Loading") {
            try {
                if (request) {
                    request
                        .then(resp => {
                            onLoaded(resp);
                        })
                        .catch(err => {
                            onFailed(err)
                        });
                }
            }
            catch (error) {
                onFailed(error);
            }
        }
    }, [state?.loadingState]);

    const onLoaded = (response: TResponse) => {
        setState({ loadingState: "Loaded", response: response });
    }

    const onFailed = (error: any) => {
        setState({ loadingState: "Failed", error: error });
    }

    const load = (promise: Promise<TResponse>)  => {
        setRequest(promise);
        setState({ loadingState: "Loading", error: undefined, response: undefined });
    }

    const defaultDisplayUntilLoaded = (children: ReactNode, defaultLoaderClassName?: string) => {
        if (state.loadingState === "NotStarted" || state.loadingState === "Loading") {
            return defaultLoaderDisplay(defaultLoaderClassName);
        }

        if (state.loadingState === "Failed") {
            return defaultErrorDisplay();
        }

        return children;
    }

    let result: [UseLoaderState<TResponse>, { load: (promise: Promise<TResponse>) => void, defaultLoaderDisplay: () => JSX.Element, defaultErrorDisplay: () => JSX.Element, defaultDisplayUntilLoaded: (children: ReactNode, defaultLoaderClassName?: string) => ReactNode }];
    result = [state, { load, defaultLoaderDisplay, defaultErrorDisplay, defaultDisplayUntilLoaded }];

    return result;
}

export const loadingStateAggregator = (loadingStates: LoadingState[]): LoadingState => {

    const ifAny = (loadingStates: LoadingState[], find: LoadingState) => {
        for (let i = 0; i < loadingStates.length; i++) {
            if (loadingStates[i] === find) {
                return true;
            }
        }

        return false;
    }

    const ifAll = (loadingStates: LoadingState[], find: LoadingState) => {
        for (let i = 0; i < loadingStates.length; i++) {
            if (loadingStates[i] !== find) {
                return false;
            }
        }

        return true;
    }

    if (ifAny(loadingStates, "Failed")) {
        return "Failed";
    }

    if (ifAll(loadingStates, "Loaded")) {
        return "Loaded";
    }

    if (ifAll(loadingStates, "NotStarted")) {
        return "NotStarted";
    }

    return "Loading";
}

export const useWpLoader = () => {
    const [state, setState] = useState({loadingState: "NotStarted"} as UseLoaderState<any>);
    const [request, setRequest] = useState({} as Promise<any> | undefined);

    let client = useAuthenticatedCmsClient();

    useEffect(() => {
        if (state.loadingState === "Loading") {
            try {
                if (request) {
                    request
                        .then(resp => {
                            onLoaded(resp);
                        })
                        .catch(err => {
                            onFailed(err)
                        });
                }
            }
            catch (error) {
                onFailed(error);
            }
        }
    }, [state?.loadingState]);

    const onLoaded = (response: any) => {
        setState({ loadingState: "Loaded", response: response.data });
    }

    const onFailed = (error: any) => {
        setState({ loadingState: "Failed", error: error });
    }

    const load = (request: IWpRequest) => {
        let promise = client!.get(request);
        setRequest(promise);
        setState({ loadingState: "Loading", error: undefined, response: undefined });
    }

    const defaultLoaderDisplay = (className? : string) => {
        return <Loader className={className} />
    }

    const defaultErrorDisplay = () => {
        return <p className="is-flex is-flex-grow-1 is-flex-direction-row is-justify-content-center is-align-items-center has-text-centered">Loading failed</p>
    }

    const defaultDisplayUntilLoaded = (children: ReactNode, defaultLoaderClassName?: string) => {
        if (state.loadingState === "NotStarted" || state.loadingState === "Loading") {
            return defaultLoaderDisplay(defaultLoaderClassName);
        }

        if (state.loadingState === "Failed") {
            return defaultErrorDisplay();
        }

        return children;
    }

    let result: [UseLoaderState<any>, { load: (request: IWpRequest) => void, defaultLoaderDisplay: (className? : string) => JSX.Element, defaultErrorDisplay: () => JSX.Element, defaultDisplayUntilLoaded: (children: ReactNode, defaultLoaderClassName?: string) => ReactNode }];
    result = [state, { load, defaultLoaderDisplay, defaultErrorDisplay, defaultDisplayUntilLoaded }];

    return result;
}

export const defaultLoaderDisplay = (className? : string) => {
    return <Loader className={className} />
}

export const defaultErrorDisplay = () => {
    return <p className="is-flex is-flex-grow-1 is-flex-direction-row is-justify-content-center is-align-items-center has-text-centered">Loading failed</p>
}
