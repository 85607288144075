import { createContext } from "react";

export interface PropertyContextState {
    loadingState: "NotStarted" | "Loading" | "Loaded" | "Failed";
    isEligibleForInvestment: boolean;
    canSubmitSelfCertification: boolean;
    reSubmissionAllowedOn?: Date;
    refetchData: () => void;
}

export const PropertyContext = createContext<PropertyContextState>({ loadingState: "NotStarted" } as PropertyContextState);