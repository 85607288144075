import { CssClasses } from "../../../../../../components/Helpers/CssClasses";
import { formatCurrency } from "../../../../../../helpers/currency";

import * as classes from "./RagCell.module.scss";

export const RagCell = ({ val = undefined as number | undefined, formatAs = "percentage" as "percentage" | "currency", className = undefined as string | undefined }) => {
    if (!val) {
        return <></>;
    }

    const css = new CssClasses(["is-fullwidth is-fullheight is-flex is-justify-content-center is-align-items-center"]);
    const cssColor = getCssColor(val);
    
    if (formatAs === "percentage") {
        css.add(cssColor);
    }
    css.add(className);

    if (formatAs === "currency") {
        return (
            <div className={css.aggregated()}>{ formatCurrency(val ?? 0)}</div>
        );
    }

    return <div className={css.aggregated()}> { `${Math.round((val ?? 0) * 100)}%` }</div>;
}

const getCssColor = (val: number | undefined) => {

    if (val === undefined) {
        return "";
    }

    const colorings = [
        { lowerBoundary: -Number.MAX_VALUE, upperBoundary: 0, css: classes["is-red"] },
        { lowerBoundary: 0, upperBoundary: 0.15, css: classes["is-amber"] },
        { lowerBoundary: 0.15, upperBoundary: Number.MAX_VALUE, css: classes["is-green"] },
    ]

    return colorings.find(x => {
        return val >= x.lowerBoundary && val < x.upperBoundary;
    })?.css;
}