import { Notification } from "react-bulma-components";
import "./Info.scss";
import { useEffect, useRef, useState } from "react";
import { Edit, Info as InfoIcon, X } from "react-feather";
import { CssClasses } from "../Helpers/CssClasses";

type Position = "top" | "bottom" | "right" | "left" | "top-desktop" | "bottom-desktop" | "top-touch" | "bottom-touch" | "left-desktop" | "right-desktop" | "left-touch" | "right-touch";

export const Info = (props: {
    children: React.ReactElement[] | React.ReactElement | undefined | null,
    positioning?: Array<Position>;
    notificationClassName?: string;
    notificationStyle?: React.CSSProperties;
    icon?: "info" | "edit";
}) => {
    const [show, setShow] = useState(false);

    const onDismiss = (e: any) => {
        e.preventDefault();
        setShow(false);
    }

    const positioning = props.positioning ? props.positioning : ["top"];

    const useOutsideAlerter = (ref: React.RefObject<HTMLElement>) => {
        useEffect(() => {
            const handleClickOutside = (e: Event) => {
                if (ref.current && !ref.current.contains(e.target as Node)) {
                    e.preventDefault();
                    setShow(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const infoIcon = () => {
        return (
            <span
                className="info-icon icon is-right has-text is-clickable"
                onClick={() => setShow(true)}>
                    {
                        props.icon === "info" || props.icon === undefined && 
                            <InfoIcon strokeWidth={"2px"} size={20} color="#1B0777" onClick={(e) => {e.preventDefault()}}/>
                    }
                    {
                        props.icon === "edit" && 
                            <Edit strokeWidth={"2px"} size={16} color="#0c0cbd" onClick={(e) => {e.preventDefault()}}/>
                    }
            </span>
        )
    }

    const positionCss = () => {
        let cssClasses = new CssClasses();

        positioning.forEach(position => {
            cssClasses.add(`is-positioned-${position}`);
        });

        return cssClasses.aggregated();
    }

    return (
        <>
            {infoIcon()}
            {show &&
                <div className={"is-relative " + props.notificationClassName } ref={wrapperRef} style={props.notificationStyle} onClick={onDismiss}>
                    <div className={"info-explainer " + positionCss() }>
                        <Notification>
                            <span className="is-position-absolute is-top is-right pt-2 pr-2">
                                <X strokeWidth={"2px"} size={20} className="is-clickable"></X>
                            </span>
                            {props.children}
                        </Notification>
                    </div>
                </div>
            }
        </>
    );
}