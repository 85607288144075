import { Container } from "react-bulma-components";
import { Glass } from "../../../components/Layout/Glass/Glass"
import { Main } from "../../../components/Layout/Main/Main"
import { Section } from "../../../components/Layout/Section/Section"
import { PageTitleWithinGlass } from "../../../components/Layout/PageTitle/PageTitle";
import { SectionWithSeparator } from "../../../components/Layout/Section/SectionWithSeparator";
import { useWpLoader } from "../../../hoc/withLoader";
import { EventParams, Membership, WpPostTypeRequest } from "../../../components/Auth/AuthenticatedCmsClient";
import { useEffect } from "react";
import { EventsCarousel } from "../../../components/Carousel/EventsCarousel";
import { AuthHelper } from "../../../components/Auth/AuthHelper";

export const Events = () => {
    const [loaderState, { load, defaultDisplayUntilLoaded }] = useWpLoader();

    useEffect(() => {
        load(new WpPostTypeRequest({
            url: "event",
            params: new EventParams({
                per_page: 100,
            })
        }));
    }, []);

    const hasAccess = AuthHelper.isPropelleMember();

    let groupedEvents : {
        [key: string]: any[];
    } = {};

    if (loaderState.loadingState === "Loaded") {
        groupedEvents = loaderState.response.reduce((acc : any, event: any) => {
            event["event-category"].forEach((category : any) => {
                if (!acc[category.slug]) {
                    acc[category.slug] = [];
                }

                // Lock in-person workshops if not a member
                if (category.slug === "in-person-workshops") {
                    event.membership = new Membership({
                        hasAccess: hasAccess,
                        requiresMembership: true
                    });
                }
            
                acc[category.slug].push(event);
            });
            return acc;
          }, {} as any);
    }

    return (
        <Main>
            <Container>
                <Section>
                    <Glass>
                        <PageTitleWithinGlass text="Events at Propelle 🤩" />
                        {
                            defaultDisplayUntilLoaded(
                                <>
                                    {
                                        groupedEvents["webinars"] !== undefined && groupedEvents["webinars"]!.length > 0 &&
                                            <SectionWithSeparator title="Webinars">
                                                <EventsCarousel wpEvents={groupedEvents['webinars'] ?? []} />
                                            </SectionWithSeparator>
                                    }
                                    {
                                        groupedEvents["in-person-networking"] !== undefined && groupedEvents["in-person-networking"]!.length > 0 &&
                                            <SectionWithSeparator title="In-person Networking" separatorHeaderOverride="Discount for pro members">
                                                <EventsCarousel wpEvents={groupedEvents["in-person-networking"] ?? []} />
                                            </SectionWithSeparator>
                                    }
                                    {
                                        groupedEvents["in-person-workshops"] !== undefined && groupedEvents["in-person-workshops"]!.length > 0 &&
                                            <SectionWithSeparator title="Workshops" membership={{ requiresMembership: true, hasAccess: hasAccess }}>
                                                <EventsCarousel wpEvents={groupedEvents["in-person-workshops"] ?? []} />
                                            </SectionWithSeparator>
                                    }
                                </>
                            )
                        }
                    </Glass>
                </Section>
            </Container>
        </Main>
    );
}