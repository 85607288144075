import { useState } from "react";
import { Chevron } from "../Chevron/Chevron";

import * as classes from "./DropDownExplainer.module.scss";
import { CssClasses } from "../Helpers/CssClasses";

export interface DropDownExplainerProps {
    linkText?: string;
    expandedlinkText?: string;
    children?: any;
}

export const DropDownExplainer = ({
    linkText = undefined as undefined | string,
    expandedlinkText = undefined as undefined | string,
    children = undefined as undefined | any }: DropDownExplainerProps) => {

    const [isExpanded, toggleExpanded] = useState(false);

    return (
        <div className="py-4-mobile py-4-tablet py-6-desktop">
            {
                isExpanded &&
                    <>{children}</>
            }
            <div className={new CssClasses([isExpanded ? "pt-4-mobile pt-4-tablet pt-6-desktop" : undefined]).aggregated()}>
                <div className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center is-clickable" onClick={() => toggleExpanded(!isExpanded)}>

                    <p className="has-text-purple-blue-400 has-text-centered is-underlined pr-2">{isExpanded ? expandedlinkText ?? linkText : linkText}</p>
                    <div className={new CssClasses([classes.chevron, !isExpanded ? "mt--1" : "mt-1", "is-flex is-flex-direction-row is-justify-content-center is-align-items-center"]).aggregated()}>
                        <Chevron direction={isExpanded ? "Up" : "Down"} size="Medium" color="navy" />
                    </div>
                </div>
            </div>
        </div>
    );
}