import { Link } from "react-router-dom"
import { Content } from "../../../../../components/Layout/Content/Content"

interface IDashboardTileProps {
    icon?: any;
    title: string | JSX.Element;
    description: string | JSX.Element;
    button: { text: string, to: string, isSolid?: boolean, disabled?: boolean };
    className?: string;
}

export const DashboardTile = ( {icon, title, description, button, className} : IDashboardTileProps) => {

    return (
        <div className={`p-4 py-8-desktop px-7-desktop is-rounded-2 ${className ?? ""}`}>
            <div className="is-flex is-flex-direction-column is-justify-content-space-between is-flex-gap-3 is-fullheight">
                <div>
                    {
                        icon &&
                            <Content>
                                { icon }
                            </Content>
                    }
                    <Content>
                        {
                            typeof title === "string"
                            ? <h5 className="has-text-purple-blue-500 is-size-4">{title}</h5>
                            : title
                        }
                    </Content>
                    <Content>
                        {
                            typeof description === "string"
                            ? <p className="has-text-grey-600">{description}</p>
                            : description
                        }
                    </Content>
                </div>
                <div>
                    <Link to={button.to}>
                        <button
                            className={`button is-rounded is-primary ${!button.isSolid && "is-outlined"}`}
                            disabled={button.disabled}>
                                {button.text}
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}