import { createContext, ReactNode, useEffect, useState } from "react";
import { CalculateFutureValue_Request, CalculateFutureValue_Response, CreateWealthPicture_Request, CreateWealthPicture_Response } from "./services/generated/WealthMappingClient";
import { cloneDeep } from 'lodash';
import { useNavigate } from "react-router-dom";
import * as uuid from "uuid";
import { IWealthMappingApiCache, useCache, WealthMappingApiCacheKey } from "./Caching";
import { defaultFutureValueCalculatorTimeframe } from "./services/Utils";
import { AuthHelper } from "../../components/Auth/AuthHelper";

export interface IWealthMappingContext {
    wealthMappingData: IWealthMappingApiCache | undefined;
    setWealthPictureRequest: (request: CreateWealthPicture_Request) => void;
    setWealthPictureResponse: (request: CreateWealthPicture_Response) => void;
    setFutureValueRequest: (request: CalculateFutureValue_Request) => void;
    setFutureValueResponse: (request: CalculateFutureValue_Response) => void;
    setFutureValueData: (futureValueRequest: CalculateFutureValue_Request, futureValueResponse: CalculateFutureValue_Response) => void;
};

interface IWealthMappingProviderProps {
    children: ReactNode
}

export const WealthMappingContext = createContext<IWealthMappingContext>({ } as IWealthMappingContext);

export const WealthMappingProvider = ({ children } : IWealthMappingProviderProps) => {    

    const [wealthMappingRequests, setWealthMappingRequests] = useState({
        prospectiveUserId: uuid.v4()
    } as IWealthMappingApiCache);
    const [cacheManager, { set }] = useCacheManager({ process: x => setWealthMappingRequests(x) });
    const navigate = useNavigate();

    useEffect(() => {
        if (cacheManager === "Processed") {
            if (!wealthMappingRequests.wealthPictureRequest || !wealthMappingRequests.wealthPictureResponse) {
                if (!AuthHelper.hasAccessToken() && window.location.pathname !== "/wealth-mapping") {
                    navigate("/wealth-mapping/chat");
                    return;
                }
            }
        }
    }, [cacheManager, wealthMappingRequests]);

    const setWealthPictureRequest = (wealthPictureRequest: CreateWealthPicture_Request) => {
        let newState = cloneDeep(wealthMappingRequests);
        newState.wealthPictureRequest = wealthPictureRequest;

        // Wipe out any existing requests and responses if they exist
        (newState.wealthPictureResponse as any) = undefined;
        (newState.futureValueRequest as any) = undefined;

        set(newState);
        setWealthMappingRequests(newState);
    }
    
    const setWealthPictureResponse = (response: CreateWealthPicture_Response) => {

        let newState = cloneDeep(wealthMappingRequests);
        newState.wealthPictureResponse = response;
        newState.futureValueRequest = {
            years: defaultFutureValueCalculatorTimeframe(wealthMappingRequests.wealthPictureRequest)
        } as CalculateFutureValue_Request;

        set(newState);
        setWealthMappingRequests(newState);
    }

    const setFutureValueRequest = (futureValueRequest: CalculateFutureValue_Request) => {

        let newState = cloneDeep(wealthMappingRequests);
        newState.futureValueRequest = futureValueRequest;

        setWealthMappingRequests(newState);

        set(newState);
        return newState;
    }

    const setFutureValueResponse = (futureValueResponse: CalculateFutureValue_Response) => {

        let newState = cloneDeep(wealthMappingRequests);
        newState.futureValueResponse = futureValueResponse;

        set(newState);
        setWealthMappingRequests(newState);
    }

    const setFutureValueData = (futureValueRequest: CalculateFutureValue_Request, futureValueResponse: CalculateFutureValue_Response) => {
        let newState = cloneDeep(wealthMappingRequests);
        newState.futureValueRequest = futureValueRequest;
        newState.futureValueResponse = futureValueResponse;

        set(newState);
        setWealthMappingRequests(newState);
    }

    return (
        <WealthMappingContext.Provider value={{ wealthMappingData: wealthMappingRequests, setWealthPictureRequest, setWealthPictureResponse, setFutureValueRequest, setFutureValueResponse, setFutureValueData }}>
            {children}
        </WealthMappingContext.Provider>
    );
}

type CacheManagerProcessingState = 
    | "NotProcessed"
    | "Processed";

interface CacheManagerProps {
    process: (cacheEntry: IWealthMappingApiCache) => void;
}

const useCacheManager = (props: CacheManagerProps) => {

    const [state, setState] = useState<CacheManagerProcessingState>("NotProcessed");
    const { get, set } = useCache<IWealthMappingApiCache>(WealthMappingApiCacheKey());

    useEffect(() => {
        if ("NotProcessed") {
            let cachedData = get();

            if (cachedData) {
                props.process?.(cachedData);
            }

            setState("Processed");
        }
    }, []);

    let result: [CacheManagerProcessingState, { set: (item: IWealthMappingApiCache) => void }] = [state, { set }];
    return result;
}