@use "../../styles/colors";
@use "../../styles/spacing";

$row-height: 40px;
$min-col-width: 80px;

.table-container {
    -webkit-overflow-scrolling: touch;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

.table {
    display: table;
    width: 100%;
    border-collapse: collapse;

    > * {
        display: table-row;
    }

    &.with-border-top {
        border-top: map-get(map-get(colors.$color-pallete, "grey"), "200") solid 1px;
    }

    .header {
        border-bottom: map-get(map-get(colors.$color-pallete, "grey"), "200") solid 1px;
    }

    .with-border-bottom {
        border-bottom: map-get(map-get(colors.$color-pallete, "grey"), "200") solid 1px;
    }

    .cell {
        display: table-cell;
        min-width: $min-col-width;
    }

    .cell-contents {
        white-space: nowrap;
    }
}