import { Navbar } from "react-bulma-components";
import logo from "../../../../assets/propelle-logo-navy.svg";
import "./WealthMappingNavbar.scss";

export function WealthMappingNavbar () {
    return (
        <div className="wealth-mapping-nav">
            <div className="is-hidden-touch">
                <Navbar className="is-flex is-justify-content-space-between">
                    <Navbar.Item  href="https://propelle.io">
                        <img src={logo} alt="Propelle Logo" />
                    </Navbar.Item>
                    <Navbar.Item>
                        <h2 className="title is-3">Goal Mapper</h2>
                    </Navbar.Item>
                    <Navbar.Item></Navbar.Item>
                </Navbar>
            </div> 
            <div className="is-hidden-desktop">
                <Navbar className="is-flex is-justify-content-center">
                    <Navbar.Item href="https://propelle.io">
                        <img src={logo} alt="Propelle Logo" />
                    </Navbar.Item>
                </Navbar>
            </div>
        </div>
    );
}