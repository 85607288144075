import { Card } from "react-bulma-components";
import { Fund } from "../../../areas/invest/equities/dataComponents/AvailableFundsContainer"
import * as classes from "./FundCard.module.scss";
import { Info } from "../../Info/Info";

interface FundCardProps {
    fund : Fund;
    selectable: boolean;
    selected?: boolean;
    hasBackground?: boolean;
}

export const FundCard = ({ fund, selectable, hasBackground, selected = false } : FundCardProps) => {

    const displayRisk = (risk: number) => {
        let riskRockets = [];

        for (let index = 0; index < 7; index++) {
            if (index < risk) {
                riskRockets.push(<span key={index}>⚡</span>);
            } else {
                riskRockets.push(<span key={index} style={{opacity: 0.25}}>⚡</span>);
            }
        }

        return riskRockets;
    }

    const buttons = () => {
        if (selectable) {

            if (selected) {
                return (
                    <div className="is-flex pt-3 is-justify-content-center">
                        <p className="has-text-weight-semibold" style={{color: "#29AE29"}}>Your current fund</p>
                    </div>
                );
            }

            return (
                <div className="is-flex">
                    <button className="button is-primary is-rounded mr-2 is-flex-grow-1 is-justify-content-center" data-fundisin={fund.isin}>Select Fund</button>
                    <a href={fund.fundInfoPdfUrl} target="_blank" className="button is-primary is-rounded is-outlined is-flex-grow-1 is-justify-content-center">View Fund</a>
                </div>
            )
        }
        
        return (
            <div className="is-flex">
                <a href={fund.fundInfoPdfUrl} target="_blank" className="button is-primary is-rounded mr-2 is-flex-grow-1 is-justify-content-center">View Fund</a>
                <a href={fund.kiidPdfUrl} target="_blank" className="button is-primary is-rounded is-outlined is-flex-grow-1 is-justify-content-center">View KIID</a>
            </div>
        )
        
    }

    return (
        <div className="card-wrapper">
            <Card className={`p-2 ${hasBackground ? 'has-background-white' : ''}`} style={{ backgroundColor: selected ? "#eef6ec" : "#ffffff" }}>
                <Card.Image src={fund.imageUrl} size={'5by2'}/>
                <div>
                    <Card.Header className={`match-height is-flex-direction-column has-text-weight-medium my-3 pb-2 ${classes['has-border-bottom']}`}>
                        <p className="has-text-purple-blue-400">{fund.name}</p>
                    </Card.Header>
                    <Card.Content className='px-1 pb-3 pt-0'>
                        <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center my-2">
                            <div className="has-text-purple-blue-500 has-text-weight-medium px-0">
                                <span className="pr-1">Risk</span>
                                <Info notificationClassName="is-fullwidth">
                                    <p>Investments carry different risk levels based on the assets they are made of. A fund with more investments in stocks and shares will be considered of higher risk than a fund with more bonds. The risk displayed is given to us by Vanguard, using the Oxford band 5 methodology of assessing risk, which shows a risk on a scale of 1-5.</p>
                                </Info>
                            </div>
                            <div>
                                { displayRisk(fund.risk) }
                            </div>
                        </div>
                        <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center my-2">
                            <div className="has-text-purple-blue-500 has-text-weight-medium px-0">
                                <span className="pr-1">Equity</span>
                                <Info notificationClassName="is-fullwidth">
                                    <p>Equity is used to describe stocks and shares of companies. When holding a share of a company, you own a piece of that company. The purpose of investing in a share of s company is to try and sell it for a higher price later.</p>
                                </Info>
                            </div>
                            <span className="has-text-right px-0 has-text-black">{fund.equity}%</span>
                        </div>
                        <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center my-2">
                            <div className="has-text-purple-blue-500 has-text-weight-medium px-0">
                                <span className="pr-1">Bonds</span>
                                <Info notificationClassName="is-fullwidth">
                                    <p>Bonds are securities issued by companies and governments in exchange for money. When a government needs to borrow money, the issue bonds. Lenders buy those bonds and give the government the money. The terms of the bond determine when and under what conditions the lender will receive their money back.</p>
                                </Info>
                            </div>
                            <span className="has-text-right px-0 has-text-black">{fund.bonds}%</span>
                        </div>
                        <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center my-2">
                            <div className="has-text-purple-blue-500 has-text-weight-medium px-0">
                                <span className="pr-1">Cost</span>
                                <Info notificationClassName="is-fullwidth">
                                    <p>The cost of a fund is a yearly cost that is deducted from your fund daily. For example, a 0.7% fee means that 0.0019% is deducted every day. This money is used to pay the fund manager, who invests the money in the fund and the platform, who administers the money and interacts with the customer.
The 0.7% fee is made up of 0.22% for Vanguard, the fund manager, and 0.48% for Propelle.</p>
                                </Info>
                            </div>
                            <span className="has-text-right px-0 has-text-black">{fund.cost}%</span>
                        </div>
                        <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center my-2">
                            <div className="has-text-purple-blue-500 has-text-weight-medium px-0">
                                <span className="pr-1">Provider</span>
                                <Info notificationClassName="is-fullwidth">
                                    <p>The provider of a fund is the company that invests the money. They chose how to invest it and what strategy to follow, in order to try to achieve good returns.</p>
                                </Info>
                            </div>
                            <span className="has-text-right px-0 has-text-black">{fund.provider}</span>
                        </div>
                        { buttons() }
                    </Card.Content>
                </div>
            </Card>
        </div>
    )
}