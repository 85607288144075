import { Helmet } from "react-helmet-async"
import { AccountSelectionSectionProps } from "./AccountSelection"
import { Container } from "react-bulma-components"
import { BackButton } from "../../../../../../components/BackButton/BackButton"
import { AvailableFundsContainer } from "../../../dataComponents/AvailableFundsContainer"
import { AvailableFundsCarousel } from "../../../components/AvailableFundsCarousel"
import { useContext, useEffect } from "react"
import { EquityContext } from "../OnboardingContext"
import { PortfoliosClientFactory } from "../../../../services/PorfoliosClientFactory"
import { useLoader } from "../../../../../../hoc/withLoader"
import { AddPortfolio_Request_Root, GetPortfolioRequestsForAccount_Response_Root, UpdatePortfolioFundSelection_Request_FundAllocation, UpdatePortfolioFundSelection_Request_Root } from "../../../../services/generated/PortfoliosClient"
import { Main } from "../../../../../../components/Layout/Main/Main"
import { Glass } from "../../../../../../components/Layout/Glass/Glass"

interface ChooseYourFundProps extends AccountSelectionSectionProps {}

export const ChooseYourFund = ({ moveForward, moveBackward } : ChooseYourFundProps) => {
    const context = useContext(EquityContext);
    const client = PortfoliosClientFactory();
    const [loaderState, loader] = useLoader<GetPortfolioRequestsForAccount_Response_Root>();

    const accountId = context?.accountData?.accountId;
    
    useEffect(() => {
        if (accountId) {
            loader.load(client.getPortfolioRequestsForAccount(accountId));
        }
    }, [context?.accountData]);

    const handleFundSelected = async (fundIsin: string, fundName: string) => {

        let portfolioId = undefined;

        const fundAllocations = [
            new UpdatePortfolioFundSelection_Request_FundAllocation({
                allocationPercentage: 1,
                fundIsin: fundIsin
            })
        ];

        if (loaderState.response?.portfolios !== undefined && loaderState.response?.portfolios.length > 0 ) {

            let portfolio = loaderState.response?.portfolios[0]; // Should only be one at this point

            if (portfolio?.submitted) {
                throw new Error("Portfolio has already been submitted");
            }

            client.updatePortfolioFundSelection(new UpdatePortfolioFundSelection_Request_Root({
                fundAllocations: fundAllocations
            }), portfolio!.id!);

        } else {
            // create portfolio
            let portfolio = await client.addPortfolio(new AddPortfolio_Request_Root({
                accountId: context?.accountData?.accountId!,
                fundAllocations: fundAllocations
            }))

            portfolioId = portfolio.portfolioId!;
        }
        
        context?.setAccountData({ ...context.accountData!, initialFundIsin: fundIsin, initialFundName: fundName, portfolioId: portfolioId });

        moveForward();
    }

    return (
        <>
            <Main>
                <Helmet>Choose your account</Helmet>
                <Container>
                    <BackButton action={moveBackward} text="Back" className="mb-3"/>
                    <div className="columns">
                        <div className="column is-12">
                            <Glass>
                                <div className="container" id="available-funds">
                                    <h1 className="is-size-2-tablet is-size-4-mobile has-text-navy has-text-centered-mobile mb-3">Choose your fund</h1>
                                    <p className="mb-3">Select one of 3 funds that best suits your investment needs.</p>
                                    {
                                        loader.defaultDisplayUntilLoaded(
                                            <AvailableFundsContainer>
                                                <AvailableFundsCarousel availableFunds={[]} onFundSelected={handleFundSelected} />
                                            </AvailableFundsContainer>)
                                    }
                                </div>
                            </Glass>
                        </div>
                    </div>
                </Container>
            </Main>
        </>
    )
}