import { CssClasses } from "../Helpers/CssClasses";
import "./Loader.scss";

export const Loader = (props: { className?: string}) => {

    let cssClasses = new CssClasses(["propelle-loader is-flex is-flex-direction-row is-justify-content-center is-align-items-center"]);

    if (props.className) {
        cssClasses.add(props.className);
    }

    return (
        <p className={cssClasses.aggregated()}>
            <span></span>
            <span></span>
            <span></span>
        </p>
    )
}