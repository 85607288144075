import { Layout as DefaultLayout } from '../../../../components/Layout/_Layout';
import { AuthorizedLayout } from "../../../../components/Auth/AuthorizedLayout";
import { withHeader } from '../../hocs/withHeader';
import { LoaderFunction } from 'react-router-dom';
import { categories } from '../Categories/Categories';

export interface LayoutProps {
    children?: React.ReactElement[] | React.ReactElement;
}

export const Layout = ({ children } : LayoutProps) => {

    return (
        <AuthorizedLayout>
            <DefaultLayout>
                { children }
            </DefaultLayout>
        </AuthorizedLayout>
    )
}
    
export const LayoutWithHeader = withHeader(Layout);

export const loader: LoaderFunction = async () => {

    let result = await categories();
    return { categories: result.categories };
}

export interface LoaderData {
    categories : any[],
}