import { SigninRedirectArgs, User, UserManager, UserManagerSettings } from "oidc-client-ts";
import { v4 as uuidv4 } from 'uuid';
import { OidcState } from "./AuthorizationContext";
import JwtDecode, { JwtPayload } from "jwt-decode";
import { UserData } from "../../helpers/auth";

// These functions don't use any hooks and so can use them where hooks are not available
// It does rely on the jwt token being available in session storage
export class AuthHelper {
    static getAccessToken() {
      const oidcStorage = sessionStorage.getItem(
        `oidc.user:${process.env.OIDC_AUTHORITY}:${process.env.OIDC_CLIENT_ID}`
      );
  
      if (!oidcStorage) {
        return null;
      }
  
      return User.fromStorageString(oidcStorage).access_token;
    }

    static hasAccessToken() {
        return AuthHelper.getAccessToken() !== null;
    }

    static generateRedirectState(navigateBackTo: string = window.location.pathname) {
      return {
        id: uuidv4(),
        path: navigateBackTo
      } as OidcState;
    }

	static getOidcConfig() {
		const oidcConfig = {
			authority: process.env.OIDC_AUTHORITY as string,
			client_id: process.env.OIDC_CLIENT_ID as string,
			redirect_uri: process.env.OIDC_REDIRECT_URI as string,
			response_type: process.env.OIDC_RESPONSE_TYPE as string,
			grant_type: process.env.OIDC_GRANT_TYPE as string,
			scope: "api offline_access",
			post_logout_redirect_uri: process.env.OIDC_POST_LOGOUT_URI as string,
			onSigninCallback: (user: User) => {
				if (user.state as OidcState) {
					const state = user.state as OidcState;
	
					window.location.href = state.path;
				}
			}
		} as UserManagerSettings;

		return oidcConfig;
	}

    static hasUserInStorageOrTryRedirect() {

		const oidcStorage = sessionStorage.getItem(
			`oidc.user:${process.env.OIDC_AUTHORITY}:${process.env.OIDC_CLIENT_ID}`
		);

		if (oidcStorage) {
			return true;
		}

		this.redirect();	

      	return false;
    }

	static async redirect() {
		let oidcConfig = this.getOidcConfig();

		let userManager = new UserManager(oidcConfig);

		const state = this.generateRedirectState();

		const args = {
			...oidcConfig,
			state: state,
		} as SigninRedirectArgs;

		userManager.signinRedirect(args);
	}

	static getUserData() : UserData {
		var accessToken = AuthHelper.getAccessToken();

		if (accessToken) {
			const token = JwtDecode<JwtPayload>(accessToken);
			return {
				id: (token as any)["propelle_user_id"],
				name: (token as any)["propelle_name"],
				email: (token as any)["propelle_email"],
				role: (token as any)["propelle_role"],
				membership: (token as any)["propelle_membership"],
				hasBetaAccess: (token as any)["propelle_beta_access"]
			}
		}

		return {} as UserData;
	}

	static isPropelleMember() : boolean {

		var accessToken = AuthHelper.getAccessToken();

		if (accessToken) {
			const token = JwtDecode<JwtPayload>(accessToken);
			let role = (token as any)["propelle_membership"];
			return role === "pro";
		}

		return false;
	}

	static isBetaUser() : boolean {

		var accessToken = AuthHelper.getAccessToken();

		if (accessToken) {
			const token = JwtDecode<JwtPayload>(accessToken);
			let access = (token as any)["propelle_beta_access"];
			return access === "true";
		}

		return false;
	}
}