import { Helmet } from "react-helmet-async"
import { ObjectSchema, number } from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { FundsContext } from "../components/FundsContext"
import { PortfoliosClientFactory } from "../../services/PorfoliosClientFactory"
import { GetPortfolioAvailableActions_Action, RequestDepositToPortfolio_Request } from "../../services/generated/PortfoliosClient"
import { Main } from "../../../../components/Layout/Main/Main"
import { BackButton } from "../../../../components/BackButton/BackButton"
import { Glass } from "../../../../components/Layout/Glass/Glass"
import { Section } from "../../../../components/Layout/Section/Section"
import { Content } from "../../../../components/Layout/Content/Content"
import { Form } from "../../../../components/Forms/Form"
import { Loader } from "../../../../components/Loader/Loader"

export const TopUp = () => {
    const context = useContext(FundsContext);

    useEffect(() => {
        context.fetchPortfolioActions();
        context.fetchPortfolioBalance();
        context.fetchPortfolioAllowance();
    }, []);

    let inner = () => {
    
        if (context.actions) {

            var deposit = context.actions.actions?.find(x => x.action === GetPortfolioAvailableActions_Action.Deposit);

            if (!deposit?.allowed) {
                return (
                    <Content>
                        <p>Sorry, you can't deposit into your account at this time for the following reason: <b>{deposit?.reason}</b></p>
                    </Content>
                )
            }
        }

        return context.portfolio?.id === undefined || context.allowance === undefined
            ? <Loader className="py-6" />
            : <>
                <Content>
                    <p className="is-size-5 has-text-weight-semibold has-text-purple-blue-500">How much would you like to contribute?</p>
                </Content>
                <TopUpForm maximumContribution={context.allowance!.maximumContribution} portfolioId={context.portfolio.id}/>
            </>
    }

    return (
        <>
            <Helmet>
                <title>Funds - Top up</title>
            </Helmet>
            <Main>
                <div className="container">
                    <BackButton to={"/invest/funds"} text="Back" className="mb-3"/>
                    <div className="columns">
                        <div className="column is-12 is-7-widescreen">
                            <Glass opacity={100}>
                                <Section>
                                    <Content>
                                        <h1 className="is-size-4 has-text-centered has-text-left-desktop is-size-2-desktop has-text-purple-blue-500">Make a contribution</h1>
                                    </Content>
                                    {
                                        inner()
                                    }
                                </Section>
                            </Glass>
                        </div>
                    </div>
                </div>
            </Main>
        </>
    )
}

interface DepositForm {
    amount?: number
}

interface TopUpFormProps {
    maximumContribution?: number,
    portfolioId: string
}

const TopUpForm = ({ maximumContribution, portfolioId } : TopUpFormProps) => {
    const context = useContext(FundsContext);
    const navigate = useNavigate();
    const [submissionState, toggleSubmissionStatus] = useState({ isSubmitting: false, isSubmitted: false });
    const [depositId, setDepositId] = useState<string|undefined>(undefined);

    const validationAmount = number()
        .typeError('Please enter a valid amount')
        .required('Please enter an amount')
        .moreThan(4, 'Amount must be more than £5')
        .test(
            "amountToNearestPenny",
            "Amount must be to the nearest penny",
            x => {
                const split = x.toString().split('.');
                if (split.length <= 1) {
                    return true;
                }

                if ((split[1]?.length ?? 0) <= 2) {
                    return true;
                }

                return false;
            } 
    );

    if (maximumContribution) {
        validationAmount.lessThan(maximumContribution, `Amount must be less than your maximum contribution limit: £${maximumContribution.toString()}`);
    }

    const validation = new ObjectSchema<DepositForm>({
        amount: validationAmount
    });
    
    const { register, formState: { isValid, errors }, getValues, handleSubmit } = useForm<DepositForm>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(validation)
    });

    const submit = async () => {
        try {
            const response = await PortfoliosClientFactory().requestDepositToPortfolio(new RequestDepositToPortfolio_Request({
                amount: getValues("amount")
            }), (portfolioId).toString());

            setDepositId(response.depositId);

            toggleSubmissionStatus({ isSubmitting: false, isSubmitted: true});
        } catch (e) {
            toggleSubmissionStatus({ isSubmitting: false, isSubmitted: false});
        }        
    }

    const onFormCompleted = () => {
        toggleSubmissionStatus({ isSubmitting: true, isSubmitted: false});
    }

    useEffect(() => {
        if (submissionState.isSubmitting) {
            submit()
        } else if (submissionState.isSubmitted && depositId !== undefined) {
            // Bit hacky here but need to refresh the cache to reflect the new fund
            context.refreshPortfolioData();
            navigate(`/invest/funds/transfer-details/${depositId}`);
        }
    }, [submissionState.isSubmitting, depositId])

    return (
        <Form className="mx-0" onSubmit={handleSubmit(onFormCompleted)}>
            <Form.Inputs>
                <Form.FormInput>
                    <Form.FormInput.Label text="Amount" infoExplainer="The amount you want to contribute. Heads up - this deposit will go into the fund you have chosen. As with all investments, your capital is at risk." />
                    <Form.FormInput.PrefixedInput prefix={"£"}>
                        <input type="text" className="input" {...register("amount", { valueAsNumber: true })} />
                    </Form.FormInput.PrefixedInput>
                    <Form.FormInput.ValidationMessage text={errors.amount?.message} />
                </Form.FormInput>
            </Form.Inputs>
            <Form.Submit disabled={!isValid || submissionState.isSubmitting} loading={submissionState.isSubmitting}>Confirm amount</Form.Submit>
        </Form>
    )
}