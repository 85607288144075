import { Container } from "react-bulma-components"
import { Helmet } from "react-helmet-async"
import { Main } from "../../components/Layout/Main/Main"
import { Section } from "../../components/Layout/Section/Section"
import { Content } from "../../components/Layout/Content/Content"
import { ScreenSize, WindowContext } from "../../components/App/WindowContext"
import { useContext } from "react"
import { PricingTable } from "./components/PricingTable"
import { FAQMiniContainer } from "../../components/FAQs/FAQMini"
import { BasicTile } from "../../components/Layout/Wrappers/BasicTile"
import * as classes from "./PropellePro.module.scss";

export const PropellePro = () => {
    const { screenSize } = useContext(WindowContext);

    return (
        <>
            <Helmet>
                <title>Propelle Pro</title>
            </Helmet>
            <Main>
                <Container>
                    <Section>
                        <Content>
                            <h1 className={`title ${screenSize > ScreenSize.Tablet ? "is-1" : "is-2"} has-text-centered has-text-weight-bold has-text-purple-blue-500`}>Propelle Pro</h1>
                        </Content>
                        <Content>
                            <div className="columns">
                                <div className="column is-6 is-offset-3">
                                    <p className="is-size-5 has-text-centered">Upgrade your membership and elevate your investing experience with exclusive access to in-depth courses, events and investment opportunities.</p>
                                </div>
                            </div>
                        </Content>
                    </Section>
                    <Section>    
                        <Content>
                            <PricingTable />
                        </Content>        
                        <Content>
                            <PoweredByStripe />
                        </Content>
                    </Section>
                    <Section>
                        <FAQMiniContainer platformArea="membership" wrap={(children: JSX.Element) => <BasicTile>{children}</BasicTile>} />
                    </Section>
                </Container>
            </Main>
        </>
    )
}

const PoweredByStripe = () => {
    const { screenSize } = useContext(WindowContext);

    const logo = () => {
        return (
            <div className={classes["has-stripe-logo"]}></div>
        )
    }

    const text = () => {
        return (
            <p className="is-size-7 has-text-weight-semibold has-text-centered has-text-purple-blue-500">PAYMENT POWERED BY</p>
        )
    }

    if (screenSize > ScreenSize.Tablet) {
        return (
            <div className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center is-flex-gap-2">
                { text() }
                { logo() }
            </div>
        )
    }

    return (
        <div className="is-flex is-flex-direction-column is-align-items-center">
            { text() }
            { logo() }
        </div>
    )
}