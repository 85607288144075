import { useAuth } from "react-oidc-context";
import { getUserData } from "../../helpers/auth";
import { isFeatureEnabled, FeatureFlag } from "../../helpers/feature-flags";
import { Lock } from "react-feather";
import PropellePro from "./propelle-pro.tsx.svg";
import { Link } from "react-router-dom";

const Nav = {
    Membership: "/membership"
}

const Badge = () => {

    const auth = useAuth();
    const userInfo = getUserData();
    
    if (!isFeatureEnabled(FeatureFlag.Membership)) {
        return <></>
    }

    if (!auth.isAuthenticated) {
        // If not logged in, don't show a membership status
        return <></>;
    }

    if (userInfo?.membership == "pro") {
        return (
            <div className="is-flex is-flex-direction-column is-justify-content-center mr-3 mt-2">
                <ProMember />
            </div>
        ) 
    }

    return (
        <div className="is-flex is-flex-direction-row is-align-items-center mr-3 mt-2">
            <Link to={Nav.Membership} className="button is-rounded has-background-purple-blue-300 is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
                <Lock color="#FFFFFF"/>
                <span className="has-text-grey-100 has-text-weight-bold is-family-montserrat">Upgrade</span>
            </Link>
        </div>
    )
}

const ProMember = () => {
    return (
        <div className="is-flex is-flex-direction-row is-align-items-center is-flex-gap-1">
            <PropellePro />
            <p className="has-text-purple-blue-300 has-text-weight-bold is-family-montserrat">Pro Member</p>
        </div>
    )
}

const BurgerMenuItem = () => {
    
    const auth = useAuth();
    const userInfo = getUserData();

    if (!isFeatureEnabled(FeatureFlag.Membership)) {
        return <></>
    }

    if (!auth.isAuthenticated) {
        // If not logged in, don't show a membership status
        return <></>;
    }

    if (userInfo?.membership == "pro") {
        return <ProMember />;
    }

    return (
        <Link to={Nav.Membership} className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
            <Lock color="#A594f9"/>
            <span className="has-text-purple-blue-300 has-text-weight-bold is-family-montserrat">Upgrade</span>
        </Link>
    );
}

export const Membership = () => { return <></> }
Membership.Badge = Badge;
Membership.BurgerMenuItem = BurgerMenuItem;