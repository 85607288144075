import { Container, Navbar } from "react-bulma-components"
import { Link, useLocation } from "react-router-dom"
import "./SecondaryNav.scss";

interface SecondaryNavProps {
    links: NavItemProps[]
    urlRoot?: string
}

export interface NavItemProps {
    to: string,
    name: string
}

export const SecondaryNav = ({ links, urlRoot = '/' }: SecondaryNavProps) => {

    let location = useLocation();

    const isRouteActive = (target: string) => {
        const pathlessStartTarget = target.startsWith('/') ? target.substring(1) : target;
        
        const matchesStart = location?.pathname.startsWith(urlRoot + pathlessStartTarget);
        const routeIsNotHome = urlRoot + target !== urlRoot;
        const routeIsHome = target === '' && location?.pathname.replace(/\/$/, '') === urlRoot.replace(/\/$/, '');

        return (matchesStart && routeIsNotHome) || routeIsHome;
    }

    return (
        <>
            <div className="header-container secondary-nav">
                <div className="header px-8-desktop">
                    <Container>
                        <Navbar>
                            <Navbar.Brand>
                                {links.map((x, i) => {
                                    return <Link key={i} className={`navbar-item has-text-navy has-text-weight-bold is-justify-content-flex-start mr-4 ml-5-mobile ml-8-tablet ml-0-desktop ${isRouteActive(x.to) ? 'is-active' : ''}`} to={x.to}>{x.name}</Link>
                                })}
                            </Navbar.Brand>
                        </Navbar>
                    </Container>
                </div>
            </div>
            <Spacer />
        </>
    );
}

export const SecondarySubNav = ({ links, urlRoot = '/' }: SecondaryNavProps) => {

    let location = useLocation();

    return (
        <>
            <div className="header-container secondary-sub-nav">
                <div className="header px-8-desktop">
                    <Container>
                        <Navbar>
                            <Navbar.Brand>
                                {links.map((x, i) => {
                                    let isActive = (location?.pathname.startsWith(x.to)
                                        && x.to !== urlRoot)
                                        || location?.pathname === x.to;
                                    return <Link key={i} className={`button is-light is-rounded ${isActive ? 'is-active' : ''}`} to={x.to}>{x.name}</Link>
                                })}
                            </Navbar.Brand>
                        </Navbar>
                    </Container>
                </div>
            </div>
            <Spacer />
        </>
    );
}

const Spacer = () => {
    return <div className="secondary-nav-spacer"></div>
}
