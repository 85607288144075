import { Link } from "react-router-dom"
import { Edit2 } from "react-feather"
import * as classes from "./Summary.module.scss";

export const Summary = ({ 
    children = undefined as any,
    className = undefined as string | undefined
}) => {
    return (
        <div className={`${classes["summary-width"]}${className ? ` ${className}` : ""}`}>
            { children}
        </div>
    )
}

const Title = ({ children = undefined as any}) => {
    return (
        <>
            { children }
            <hr className="has-background-grey-200 my-1"/>
        </>
    )
}

const Item = ({ 
    description = undefined as string | undefined,
    value = undefined as string | undefined,
    editLocation = undefined as string | undefined
}) => {
    return (
        <>
            <div className="is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between">
                {
                    description &&
                    <p className="has-text-purple-blue-500">{description}</p>
                }
                <div className="is-flex is-flex-direction-row is-flex-gap-2">
                    {
                        value &&
                        <p>{value}</p>
                    }
                    {
                        editLocation &&
                        <Link to={editLocation}>
                            <Edit2 color="#1B0777" size={16}></Edit2>
                        </Link>
                    }
                </div>
            </div>
            <hr className="has-background-grey-200 my-1"/>
        </>
    )
}

Summary.Title = Title;
Summary.Item = Item;