import { useEffect, useState } from "react";
import { useLoader } from "../../../../../../../hoc/withLoader";
import { PortfoliosClientFactory } from "../../../../../services/PorfoliosClientFactory";
import { GetDeposit_Response_Root } from "../../../../../services/generated/PortfoliosClient";
import { Helmet } from "react-helmet-async";
import { Main } from "../../../../../../../components/Layout/Main/Main";
import { Container } from "react-bulma-components";
import { Glass } from "../../../../../../../components/Layout/Glass/Glass";
import { Section } from "../../../../../../../components/Layout/Section/Section";
import { Content } from "../../../../../../../components/Layout/Content/Content";
import { Loader } from "../../../../../../../components/Loader/Loader";
import { useParams } from "react-router-dom";
import { Table } from "../../../../../../../components/Table/Table";
import { Copy } from "../../../../../../../components/Copy/Copy";
import * as classes from "./TransferDetails.module.scss";
import { Link } from "react-router-dom";


export const TransferDetails = () => {

    let { depositId } = useParams();
    const [loaderState, { load }] = useLoader<GetDeposit_Response_Root>();
    let client = PortfoliosClientFactory();
    const [retryCount, setRetryCount] = useState(0);

    useEffect(() => {
        if (depositId) {
            load(client.getDeposit(depositId));
        }
    }, [retryCount]);

    if (loaderState.response?.status === "Created") {
        const retryInterval = 10000; // Retry every 10 seconds
        const maxRetries = 5 * 60 / 10; // Maximum number of retries in 5 minutes

        if (retryCount < maxRetries) {
            setTimeout(() => {
                setRetryCount(retryCount + 1);
            }, retryInterval);
        }
    }

    const inner = () => {
        if (loaderState.response?.status === "Pending" || loaderState.response?.status === "Created") {
            return <BankDetails {...loaderState.response} />;
        } else if (loaderState.loadingState === "NotStarted" || loaderState.loadingState === "Loading") {
            return <TransferHolding />;
        } else {
            return <TransferAlreadyComplete />;
        }
    }

    return (
        <>
            <Helmet>
                <title>Funds - Transfer details</title>
            </Helmet>
            <Main>
                <Container>
                    <div className="columns">
                        <div className="column is-12 is-7-widescreen">
                            <Glass opacity={100}>
                                {inner()}
                            </Glass>
                        </div>
                    </div>
                    
                </Container>
            </Main>
        </>
    )
}

const TransferHolding = () => {
    return (
        <Section>
            <Content>
                <h1 className="is-size-4 has-text-centered has-text-left-desktop is-size-2-desktop has-text-purple-blue-500">Loading</h1>
            </Content>
            <Content>
                <Loader />
            </Content>
            <Content>
                <p>We're busy processing your information. If you've been waiting longer than 5 minutes, please get in touch at <b><a href="mailto:support@propelle.io">support@propelle.io</a></b></p>
            </Content>
        </Section>
    )
}

const TransferAlreadyComplete = () => {
    return (
        <Section>
            <Content>
                <h1 className="is-size-4 has-text-centered has-text-left-desktop is-size-2-desktop has-text-purple-blue-500">Transfer complete</h1>
            </Content>
            <Content>
                <p>Your transfer for this deposit has been completed.</p>
            </Content>
        </Section>
    )
}

const BankDetails = ( { reference, amount } : {
    reference?: string | undefined;
    amount?: number | undefined;
}) => {
    // Function implementation
    return (
        <>
            <Section>
                <Content>
                    <h1 className="is-size-4 has-text-centered has-text-left-desktop is-size-2-desktop has-text-purple-blue-500">Make a contribution</h1>
                </Content>
                <Content>
                    <h3 className="is-size-h6 has-text-purple-blue-500 mb-2">Transfer details</h3>
                    <p>Use the bank details below to make your deposit. Remember to use the bank account you've told us about previously.</p>
                </Content>
                <Content>
                    <Table className={`with-border-top ${classes.table}`}>
                        <Table.Data data={
                            [
                                { values: [ <div className="has-text-purple-blue-500 has-text-weight-semibold">Bank account name</div>, <Copy fixedWidth={200}>WealthKernel Clients</Copy> ]},
                                { values: [ <div className="has-text-purple-blue-500 has-text-weight-semibold">Sort code</div>, <Copy fixedWidth={200}>04-13-90</Copy> ]},
                                { values: [ <div className="has-text-purple-blue-500 has-text-weight-semibold">Account number</div>, <Copy fixedWidth={200}>11009712</Copy> ]},
                                { values: [ <div className="has-text-purple-blue-500 has-text-weight-semibold">Reference</div>, <Copy fixedWidth={200}>{reference}</Copy> ]},
                                { values: [ <div className="has-text-purple-blue-500 has-text-weight-semibold">Amount</div>, <Copy fixedWidth={200} toCopy={amount}>{`£${amount}`}</Copy> ]},
                            ]
                        } />
                    </Table>
                </Content>
            </Section>
            <Link to={'/invest/funds'} className="button is-primary is-rounded mt-4">I'm done!</Link>
        </>
    )
}
