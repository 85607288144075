
import { useEffect } from "react";
import { FundCard } from "../../../../components/Cards/FundCard/FundCard"
import { Carousel, CarouselItem } from "../../../../components/Carousel/Carousel"
import { AvailableFundProps, Fund } from "../dataComponents/AvailableFundsContainer"

interface AvailableFundsCarouselProps extends AvailableFundProps {
    selectedFundIsin?: string;
    availableFunds: Fund[];
    onFundSelected?: (fundIsin: string, fundName: string) => void;
}

export const AvailableFundsCarousel = ({ selectedFundIsin, availableFunds, onFundSelected } : AvailableFundsCarouselProps) => {

    useEffect(() => {
     const fundsWrapper = document.getElementById('available-funds'); // Ensure this is a wrapper before any loading

        // Setup event listener for selecting a fund - has to be done outside of carousel due to the cloning of slides
        const handleClick = (event: Event) => {
            const { target } = event;


            event.stopPropagation();
            event.preventDefault();

            if (target instanceof HTMLElement && target.nodeName === 'BUTTON' && target.textContent === 'Select Fund') {

                const fundIsin = target.getAttribute('data-fundisin');

                if (fundIsin !== null) {
                    const fund = availableFunds.find((fund) => fund.isin === fundIsin);

                    onFundSelected?.(fundIsin, fund!.name);
                }
            } else if (target instanceof HTMLElement && target.nodeName === 'A' && target.textContent === 'View Fund') {
      
                const href = target.getAttribute('href');

                window.open(href!, '_blank');
                
            }
        };

        if (fundsWrapper) {
            fundsWrapper.addEventListener('click', handleClick);
        }
        
        return () => {
            if (fundsWrapper) {
                fundsWrapper.removeEventListener('click', handleClick);
            }
        };

    }, []);

    return (
        <Carousel itemSize="Large" title="Which fund is right for me?">
            {
                availableFunds.map((fund) => (
                    <CarouselItem key={`carousel-available-funds-${fund.isin}`}>
                        <FundCard fund={fund} selectable={true} selected={selectedFundIsin === fund.isin} key={fund.isin}/>
                    </CarouselItem>
                ))
            }
        </Carousel>
    )
}