import { Container } from "react-bulma-components";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Main } from "../../../../../../components/Layout/Main/Main";
import { BackButton } from "../../../../../../components/BackButton/BackButton";
import { Glass } from "../../../../../../components/Layout/Glass/Glass";

export const NotEligible = () => {

    return (
        <Main>
            <Helmet>
                Create account
                </Helmet>
            <Container>
                    <BackButton to={"/invest/funds/eligibility"} text="Back" className="mb-3"/>
                    <div className="columns">
                        <div className="column is-10-widescreen is-8-fullhd">
                            <Glass>
                                <h1 className="is-size-2 is-size-4-mobile has-text-navy has-text-centered-mobile">Add your details</h1>
                                <div className="has-text-centered mt-4">
                                    <h2 className="title is-size-1">😔</h2><br />
                                    <h2 className="title is-size-4 has-text-navy pb-4">Sadly we are not currently able to have US citizens invest in equities through the platform. </h2>
                                    <p className="is-5 has-text-black pb-8">Please click on the button below to return to the app.</p>
                                    <div className="is-flex is-justify-content-center"><Link className="button is-primary is-rounded" to="/">Return to platform</Link></div>
                                </div>
                            </Glass>
                        </div>
                    </div>
            </Container>
        </Main>
    );
};