import { CalculateFutureValue_Request, CalculateFutureValue_Response, CreateWealthPicture_Request, CreateWealthPicture_Response } from "./services/generated/WealthMappingClient";


export const WealthMappingApiCacheKey = () => "wm:data";

export interface IWealthMappingApiCache {
    prospectiveUserId: string;
    wealthPictureRequest: CreateWealthPicture_Request;
    wealthPictureResponse: CreateWealthPicture_Response;
    futureValueRequest: CalculateFutureValue_Request;
    futureValueResponse: CalculateFutureValue_Response;
}

export const useCache = <TCacheItem,> (cacheKey: string) => {
    const get = () => {
        let item = localStorage.getItem(cacheKey);
        if (item) {
            return JSON.parse(item ?? "{}") as TCacheItem;
        } 
            
        return undefined;
    }

    const set = (item: TCacheItem) => {
        localStorage.setItem(cacheKey, JSON.stringify(item));
    }

    const remove = () => {
        localStorage.removeItem(cacheKey);
    }

    return { get, set, remove };
}