import { ImageSize, getImageUrl } from "../../helpers/cms";
import { EventCardProps, EventCardWithLink } from "../Cards/EventCard/EventCard";
import { Carousel, CarouselItem } from "./Carousel";
import "./EventsCarousel.scss";

interface EventCarouselProps {
    wpEvents: any[];
    title?: string;
}

export const EventsCarousel = ({ wpEvents, title } : EventCarouselProps ) => {

    let events = wpEvents
            .map(x => {
                return {
                    id: parseInt(x.id ? x.id : -1),
                    image: getImageUrl(x.acf.thumbnail, ImageSize.MediumLarge),
                    date: x.acf.date_time ? new Date(x.acf.date_time) : undefined,
                    header: x.title ?? "",
                    location: x.acf.is_virtual?.toString() === "true" ? "Virtual event" : x.acf.location?.toString() ?? "",
                    url: x.acf.link ? x.acf.link?.toString() : undefined,
                    matchHeight: true,
                    membership: x.membership
                } as EventCardProps
            });

    return (
        <Carousel className="events-carousel mx--3-desktop" title={title}>
            {
                events.map((x, i) => {
                    return (
                        <CarouselItem key={(x as any).id ?? i}>
                            <EventCardWithLink {...x} />
                        </CarouselItem>
                    )
                })
            }
        </Carousel>
    )

}