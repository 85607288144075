export class CssClasses {
    _classes: Array<string>;

    constructor(classes?: Array<string | undefined>) {
        this._classes = new Array<string>();

        classes?.forEach(val => {
            this.add(val);
        });
    }

    add(cssClass: string | undefined) {
        if (cssClass) {
            this._classes.push(cssClass);
        }
    }

    aggregated() {
        let aggregated = "";

        this._classes.map(e => {
            aggregated += " " + e;
        });

        return aggregated.trim();
    }
}