import { useEffect, useRef, useState } from "react";
import { PropertyContext, PropertyContextState } from "../Property/PropertyContext";
import { PropertyClientFactory } from "../../../services/PropertyClientFactory";
import { useLoader } from "../../../../../hoc/withLoader";
import { InvestorCertificationGetSelfCertification_Response_Root } from "../../../services/generated/PropertyClient";
import { Outlet } from "react-router-dom";

export const Layout = () => {
    const [refetchData, setRefetchData] = useState(false);
    const refetchRef = useRef(refetchData);
    const refetch = () => {
        setRefetchData(!refetchData!);
    }

    const [loaderState, { load }] = useLoader<InvestorCertificationGetSelfCertification_Response_Root>();
    const [contextState, setContextState] = useState<PropertyContextState>({ loadingState: "NotStarted", refetchData: refetch } as PropertyContextState);
    const client = PropertyClientFactory();

    useEffect(() => {
        if (loaderState.loadingState === "NotStarted" || refetchRef.current !== refetchData) {
            refetchRef.current = refetchData;
            load(client.investorCertificationGetSelfCertification());
        } else if (loaderState.loadingState === "Loaded") {
            setContextState({
                loadingState: loaderState.loadingState,
                isEligibleForInvestment: loaderState.response!.isEligibleForPropertyInvestment ?? false,
                canSubmitSelfCertification: loaderState.response!.canSubmit ?? false,
                reSubmissionAllowedOn: loaderState.response!.expiresOn,
                refetchData: refetch
            });
        }
    }, [loaderState.loadingState, refetchData])

    return (<PropertyContext.Provider value={contextState}>
        <Outlet />
    </PropertyContext.Provider>);
}