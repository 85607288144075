export class TaxYear {
    start: number;
    end: number;

    constructor(start: number, end: number) {
        this.start = start;
        this.end = end;
    }
}

export const currentTaxYear = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();

    if (month < 3 || (month === 3 && day < 6)) {
        return new TaxYear(year - 1, year);
    }
    return new TaxYear(year, year + 1);
}