import { useEffect, useState } from "react";
import { UserInfo } from "../../components/Auth/AuthApiClient";
import { Loader } from "../../components/Loader/Loader";
import { Container, Form, Heading, Notification } from "react-bulma-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import "./MyAccount.scss";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useMyAccountLoader } from "./loader";
import { GetUserMembership_Response, GetUserMembership_Response_MembershipResponse_RenewalPeriodResponse, StartCancellation_Request, StartCancellation_Response } from "../membership/services/generated/MembershipClient";
import { MembershipClientFactory } from "../membership/services/MembershipClientFactory";
import { useLoader } from "../../hoc/withLoader";
import { getUserData } from "../../helpers/auth";
import dayjs from "dayjs";
import { Info } from "../../components/Info/Info";
import { GetAccountHolder_Response_Root } from "../invest/services/generated/AccountManagementClient";
import { Glass } from "../../components/Layout/Glass/Glass";
import { Main } from "../../components/Layout/Main/Main";
import { Section } from "../../components/Layout/Section/Section";
import { FeatureFlag, isFeatureEnabled } from "../../helpers/feature-flags";

export const MyAccount = () => {
    const myAccountLoad = useMyAccountLoader();
    const [activeExplainer, setActiveExplainer] = useState<string | undefined>(undefined);

    if (myAccountLoad.loadingState !== "Loaded" || !myAccountLoad.user || !myAccountLoad.membership) {
        return <Loader />
    }

    return (
        <>
            <Helmet>
                <title>My Account</title>
            </Helmet>
            <Main>
                <Container>
                    <Section>
                        <Glass>
                            <div className="px-4">
                                <Heading size={2} className="has-text-purple-blue-500">Account Overview</Heading>
                                <div className="columns">
                                    <div className="column is-7-desktop is-12-tablet is-12-mobile">
                                        <UserInfoForm userInfo={myAccountLoad.user} accountHolder={myAccountLoad.accountHolder} membership={myAccountLoad.membership} showExplainers={activeExplainer === "user_info"} onShowExplainer={() => setActiveExplainer("user_info")} />
                                        <DeleteAccount className="pt-8" showExplainers={activeExplainer === "delete_account"} onShowExplainer={() => setActiveExplainer("delete_account")} />
                                    </div>
                                </div>
                            </div>
                        </Glass>
                    </Section>
                </Container>
            </Main>
        </>
    )
}

const UserInfoForm = (props: {
    userInfo: UserInfo,
    membership: GetUserMembership_Response,
    showExplainers: boolean, onShowExplainer: () => void,
    accountHolder: GetAccountHolder_Response_Root
}) => {

    const [activeExplainer, setActiveExplainer] = useState<string | undefined>(undefined);

    const onExplainerShown = (explainer: string) => {
        setActiveExplainer(explainer);
        props.onShowExplainer();
    }

    const changeInfoExplainer = () => {
        return <p>To change any of your personal information, please email us at <a href="mailto:support@propelle.io">support@propelle.io</a>. If you have any questions, you can get in touch with us and we'll be happy to assist you.</p>
    }

    const passwordExplainer = () => {
        return <p>If you want to change you password, please log out and click on “Forgot Password” and follow the link to reset your password.</p>;
    }

    const membershipCta = () => {
        const isMember = props.membership.hasMembership;

        return (
            <div className="pt-3-desktop pt-3-tablet pt-1-mobile">
                {
                    !isMember 
                        ? <UpgradeMembership />
                        : <CancelMembership membership={props.membership} />
                }
            </div>
        );
    }

    const membershipDescription = () => {
        if (!props.membership.hasMembership) {
            return "Free"
        }

        const membership = props.membership.membership;

        if (!membership) {
            return "Propelle Pro"
        }

        var renewalPeriodDescription = GetUserMembership_Response_MembershipResponse_RenewalPeriodResponse[membership.renewalPeriod ?? GetUserMembership_Response_MembershipResponse_RenewalPeriodResponse.Monthly];

        return `Propelle Pro (${renewalPeriodDescription.toLocaleLowerCase()})`;
    }

    return (
        <form>
            <FormInput label="First name" value={props.userInfo.firstName} edit={changeInfoExplainer()}/>
            <FormInput label="Last name" value={props.userInfo.surname}  edit={changeInfoExplainer()}/>
            <FormInput label="Email" value={props.userInfo.email}  edit={changeInfoExplainer()}/>
            <FormInput label="Password" value="************" edit={passwordExplainer()} />
            {
                isFeatureEnabled(FeatureFlag.Membership) &&
                <>
                    <UserInfoFormInput label="Membership" value={membershipDescription()} showExplainers={false} extraAction={membershipCta()} />
                    <div className="mb-6"></div>
                </>
            }
            {
                props.accountHolder && 
                <>
                    <FormInput label="Account name" value={"Account Name"} edit={changeInfoExplainer()} />

                    <FormInput label="Date of birth" value={dayjs(props.accountHolder.dateOfBirth).format('DD/MM/YYYY')} edit={changeInfoExplainer()} />
                    <FormInput label="Phone" value={props.accountHolder.phoneNumber!} edit={changeInfoExplainer()} />
                    <FormInput label="Email" value={props.accountHolder.email!} edit={changeInfoExplainer()} />
                    <div className="field is-horizontal">
                        <h2 className="is-size-h4 mt-4 has-text-purple-blue-500">Address</h2>
                    </div>
                    <FormInput label="Address" value={props.accountHolder.currentAddress?.line1!} edit={changeInfoExplainer()} />
                    <FormInput label="Town/City" value={props.accountHolder.currentAddress?.city!} edit={changeInfoExplainer()} />
                    <FormInput label="Postcode" value={props.accountHolder.currentAddress?.postcode!} edit={changeInfoExplainer()} />
                    <div className="field is-horizontal">
                        <h2 className="is-size-h4 mt-4 has-text-purple-blue-500">Bank Details</h2>
                    </div>
                    <FormInput label="Account name" value={props.accountHolder.currentBankDetails?.accountName!} edit={changeInfoExplainer()} />
                    <FormInput label="Account number" value={props.accountHolder.currentBankDetails?.accountNumber!} edit={changeInfoExplainer()} />
                    <FormInput label="Sort code" value={props.accountHolder.currentBankDetails?.sortCode!} edit={changeInfoExplainer()} />
                </>
            }
            
            
        </form>
    )
}

interface FormInputProps {
    label: string;
    value: string;
    edit?: JSX.Element;
}

const FormInput = ({label, value, edit} : FormInputProps) => {
    return (
        <div className="field is-horizontal">
            <div className="field-label is-normal">
                <label className="label has-text-weight-medium">{label}</label>
            </div>
            <div className="field-body">
                <div className="field">
                    <div className={`control ${ edit !== undefined ? "has-icons-right" : ""}`}>
                        <input className="input" type="text" value={value} readOnly />
                        {
                            edit &&
                                <span className="icon is-right has-text-primary is-clickable">
                                    <Info icon="edit" positioning={["right"]}>
                                        {edit}
                                    </Info>
                                </span>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


const UserInfoFormInput = (props: { 
    label: string, 
    value: string, 
    fieldExplainer?: React.ReactElement, 
    infoExplainer?: React.ReactElement, 
    showExplainers: boolean, 
    extraAction?: React.ReactElement,
    onExplainerShown?: () => void }) => {

    interface State {
        showExplainer?: boolean;
        showInfoExplainer?: boolean;
    }

    const [explainers, setExplainers] = useState<State>();

    const onShowExplainer = () => {
        setExplainers({ showExplainer: true, showInfoExplainer: false });
        props.onExplainerShown?.();
    }
    
    const onShowInfoExplainer = () => {
        setExplainers({ showExplainer: false, showInfoExplainer: true});
        props.onExplainerShown?.();
    }

    const onDismiss = () => {
        setExplainers({ showExplainer: false, showInfoExplainer: false });
    }

    return (
        <>
            <Form.Field horizontal={true}>
                <Form.Field.Label className="py-2-desktop py-2-tablet">
                    <Form.Label>
                        <span className="has-text-weight-medium">{ props.label }</span>
                        { props.infoExplainer && <span className="pl-3 is-clickable" onClick={onShowInfoExplainer}><FontAwesomeIcon icon={faCircleInfo} /></span> }
                    </Form.Label>
                </Form.Field.Label>
                <Form.Field.Body>
                    <Form.Field>
                        { props.showExplainers && explainers?.showExplainer && props.fieldExplainer && <Explainer onDismiss={onDismiss}>{ props.fieldExplainer }</Explainer> }
                        { props.showExplainers && explainers?.showInfoExplainer ? <Explainer onDismiss={onDismiss}>{ props.infoExplainer }</Explainer> : <></> }
                        <Form.Control className={props.onExplainerShown ? "has-icons-right" : ""} onClick={onShowExplainer}>
                            <Form.Input value={props.value} readOnly />
                            {
                                props.onExplainerShown &&
                                    <span className="icon is-right has-text-primary is-clickable"><FontAwesomeIcon icon={faEdit} /></span>
                            }
                        </Form.Control>
                        { props.extraAction }
                    </Form.Field>
                </Form.Field.Body>
            </Form.Field>
        </>
    )
}

const DeleteAccount = (props: { showExplainers: boolean, onShowExplainer: () => void, className?: string }) => {

    const [showExplainer, setShowExplainer] = useState<boolean>(false);

    const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setShowExplainer(true);
        props.onShowExplainer();
    }

    const onDismiss = () => {
        setShowExplainer(false);
    }

    return (
        <div className={props.className}>                           
            { 
                props.showExplainers && showExplainer && 
                <Explainer onDismiss={onDismiss} position="bottom">
                    <p>We're sorry to see you go! To delete your account, please email us at <a href="mailto:support@propelle.io">support@propelle.io</a>. If you have any questions, you can get in touch with us and we'll be happy to assist you..</p>
                </Explainer> 
            }
            <p className="has-text-weight-medium">Do you wish to delete your account? <a onClick={onClick} className="is-underlined">Click here</a>.</p>
        </div>
    )
}

type ExplainerPosition = "top" | "bottom";

const Explainer = (props: { children: React.ReactElement[] | React.ReactElement | undefined, onDismiss: () => void, position?: ExplainerPosition }) => {

    const onDismiss = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        props.onDismiss();
    }

    const position = props.position ? props.position : "top";

    return (
        <div className="is-relative">
            <div className={ "explainer is-" + position }>
                <Notification>
                    <button className="delete" onClick={onDismiss}></button>
                    { props.children }
                </Notification>
            </div>         
        </div>
    );
}

const UpgradeMembership = () => {
    return (
        <Link to="/membership" className="has-text-purple-blue-400 has-text-weight-medium is-underlined">Upgrade membership</Link>
    );
}

const CancelMembership = ({ membership = undefined as GetUserMembership_Response | undefined }) => {

    const membershipClient = MembershipClientFactory();
    const [state, { load }] = useLoader<StartCancellation_Response>();
    const user = getUserData();
    
    const hasPendingCancellation = membership?.hasMembership 
        && membership.hasMembership == true
        && membership.membership?.cancellation?.willEndOn;

    const onCancelMembershipClicked = (e: any) => {
        e.preventDefault();

        const returnUrl = `${window.location.origin}/membership/cancelled`;

        load(membershipClient.startCancellation(
            new StartCancellation_Request({ 
                returnUrl: returnUrl
            }), 
            user.id ?? ''));
    }

    useEffect(() => {
        if (state.loadingState === "Loaded" && state.response?.stripeDashboardUrl) {
            window.location.href=state.response.stripeDashboardUrl;
        }
    }, [state.loadingState])

    if (hasPendingCancellation) {
        return <p className="has-grey-500">Your membership will end on {dayjs(membership.membership?.cancellation?.willEndOn).format('DD/MM/YYYY')}</p>
    }

    return (
        <Link to="#" 
            className={`has-text-purple-blue-400 has-text-weight-medium is-underlined`}
            onClick={onCancelMembershipClicked}>
                Cancel membership
        </Link>
    );
}