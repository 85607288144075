$red: #FE9AA9;
$amber: #FFF1A9;
$green: #C1FFA1;

.is-red {
    background-color: $red;
}

.is-amber {
    background-color: $amber;
}

.is-green {
    background-color: $green;
}