import { Helmet } from "react-helmet-async"
import { Main } from "../../../../../components/Layout/Main/Main"
import { Section } from "../../../../../components/Layout/Section/Section"
import { Back } from "../../../../../components/Back/Back"
import { Box } from "../components/Box/Box"
import { Form } from "../../../../../components/Forms/Form"
import { Content } from "../../../../../components/Layout/Content/Content"
import { useForm } from "react-hook-form"
import { ObjectSchema, number, ref } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react"
import { RagTable } from "../components/RagTable/RagTable"
import { smallestToLargest, unique } from "../../../../../helpers/arrays"
import { DropDownExplainer } from "../../../../../components/Explainer/DropDownExplainer"
import { ScrollIntoView } from "../../../../../components/ScrollIntoView/ScrollIntoView"
import { ScreenSize } from "../../../../../components/App/WindowContext"

export const CrowdfundingTool = () => {

    const [calculation, setCalculation] = useState<Calculation | undefined>(undefined);

    return <>
        <Helmet>
            <title>Crowdfunding Tool</title>
        </Helmet>
        <Main>
            <div className="container">
                <Back navigate={{to: "/invest/tools"}}>Back</Back>
                <Section>
                    <div className="pb-2 pb-1-touch">
                        <div className="pt-1-touch">
                            <h1 className="title is-size-2-desktop is-size-4-touch has-text-purple-blue-500 has-text-centered">Crowdfunding Tool</h1>
                        </div>
                    </div>
                    <div className="mb-5-desktop mb-4-touch">
                        <Box>
                            <Content className="mb-6">
                                <p className="has-text-centered has-text-purple-blue-500 has-text-weight-medium is-size-5 mb-1">PART 1</p>
                                <p className="has-text-centered has-text-purple-blue-500 mb-1">Enter your inputs to calculate the potential percentage return your could make on a crowdfunding deal.</p>
                            </Content>
                            <Content>
                                <CrowdfundingToolForm onCalculated={setCalculation} />
                            </Content>
                        </Box>
                    </div>
                    <div className="mb-5-desktop mb-4-touch">
                        <Box>
                            <CrowdfundingToolResults calculation={calculation} />
                        </Box>
                        <Content className="pt-4">
                            <p className="is-italic">*This tool is designed to assist you in assessing the sensitivity of returns on a particular real estate crowdfunding opportunity to changes in costs and sale price, taking into account the effects of leverage, and is for information purposes only. It should not be used in other circumstances and should not be relied upon to assess potential returns. You are responsible for inputting the information required from the offering memorandum accurately. Please be aware that as with all investing your capital is at risk and you are advised to read the risk warnings of any investment opportunity carefully before proceeding.</p>
                        </Content>
                    </div>
                </Section>
            </div>
        </Main>
    </>
}

interface Calculation {
    inputs: {
        projectCost: number
        gdv: number
        salesCostPercentage: number
        amountInvested: number
        investorPercentage: number
    },
    outputs: {
        variations: Array<{
            gdv: number,
            gdvPercentageChange: number,
            costs: number,
            costPercentageChange: number,
            returnPercentage: number
        }>
    }
}

const CrowdfundingToolForm = ({ onCalculated = (_: Calculation) => { } }) => {

    interface CrowdfundingToolForm {
        projectCost?: number,
        gdv?: number,
        salesCostPercentage?: number
        crowdfundingAmount?: number,
        profitShare?: number
    }

    const validation = new ObjectSchema<CrowdfundingToolForm>({
        projectCost: number()
            .typeError('Total costs is required')
            .required('Total costs is required')
            .lessThan(ref('gdv'), 'Total costs must be less than the GDV')
            .moreThan(0, 'Total costs must be more than 0'),
        gdv: number()
            .typeError('The GDV is required')
            .required('The GDV is required')
            .moreThan(0, 'The GDV must be more than 0'),
        salesCostPercentage: number()
            .typeError('A valid Sales Cost Percentage is required')
            .required('The Sales Cost Percentage is required')
            .max(20, 'Cannot be more than 20%')
            .min(0, 'Sales costs must be a positive or zero percentage'),
        crowdfundingAmount: number()
            .typeError('The Crowdfunding amount is required')
            .required('The Crowdfunding amount is required')
            .lessThan(ref('projectCost'), 'The Crowdfunding amount must be less than the Total costs')
            .moreThan(0, 'The Crowdfunding amount must be more than 0'),
        profitShare: number()
            .typeError('The Profit share is required')
            .required('The Profit share is required')
            .max(100, 'Cannot be more than 100%')
            .min(2, 'Must be at least 2%'),
    });

    const { register, formState: { isValid, errors }, handleSubmit } = useForm<CrowdfundingToolForm>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(validation)
    });

    const onCalculate = (form: CrowdfundingToolForm) => {

        const gdvVariations = [0, -0.05, -0.10, -0.15, -0.20];
        const costVariations = [0, 0.05, 0.10, 0.15, 0.20];

        const variations = gdvVariations
            .map(gdvVariation => {
                return costVariations.map(costVariation => {

                    const gdv = (form.gdv ?? 0) * (1 + gdvVariation)
                    const gdvDeductingSales = gdv * (1 - ((form.salesCostPercentage ?? 0) / 100));
                    const costs = (form.projectCost ?? 0) * (1 + costVariation);
                    const returnAmount = (gdvDeductingSales - costs) * ((form.profitShare ?? 0) / 100);
                    const returnPercentage = returnAmount / (form.crowdfundingAmount ?? 1);

                    return {
                        gdv: gdv,
                        costs: costs,
                        gdvPercentageChange: gdvVariation,
                        costPercentageChange: costVariation,
                        returnPercentage: returnPercentage
                    }
                })
            })
            .reduce((acc, val) => acc.concat(val), []);

        if (onCalculated) {
            onCalculated({
                inputs: {
                    projectCost: form.projectCost ?? 0,
                    gdv: form.gdv ?? 0,
                    salesCostPercentage: ((form.salesCostPercentage ?? 0) / 100),
                    amountInvested: form.crowdfundingAmount ?? 0,
                    investorPercentage: ((form.profitShare ?? 0) / 100)
                },
                outputs: {
                    variations: variations
                }
            })
        }
    }

    return (
        <Form onSubmit={handleSubmit(onCalculate)}>
            <Form.Inputs>
                <Form.FormInput>
                    <Form.FormInput.Label text="Total costs (Incl. Finance)" infoExplainer="This is the total cost of the project, including the cost of finance. In the documentation, the developer may refer to this as two separate line items, or combine them as the total cost (incl. finance)" />
                    <Form.FormInput.PrefixedInput prefix={"£"}>
                        <input type="number" className="input" {...register("projectCost", { valueAsNumber: true, deps: ["gdv", "amountInvested"] })} tabIndex={1} />
                    </Form.FormInput.PrefixedInput>
                    <Form.FormInput.ValidationMessage text={errors.projectCost?.message} />
                </Form.FormInput>
                <Form.FormInput>
                    <Form.FormInput.Label text="GDV(selling price)" infoExplainer="This stands for Gross Development Value. This is essentially the price at which the project is expected to be sold. It may be referred to as Sales price." />
                    <Form.FormInput.PrefixedInput prefix={"£"}>
                        <input type="number" className="input" {...register("gdv", { valueAsNumber: true, deps: ["projectCost", "amountInvested"] })} tabIndex={2} />
                    </Form.FormInput.PrefixedInput>
                    <Form.FormInput.ValidationMessage text={errors.gdv?.message} />
                </Form.FormInput>
                <Form.FormInput>
                    <Form.FormInput.Label text="Sales costs (%)" infoExplainer="This is the cost associated with selling the project. Usually, an agent or a property company is hired to fulfill the sell and they take a cut of the selling price as a %. Sometimes in the documentation, this cost will be noted as a number. You'll have to divide it by the GDV to get the Sales Cost as a percentage. I.e. if the GDV is 1,000,000 and the Sales cost is 30,000, then the Sales cost as a percentage is 3%" />
                    <input type="text" className="input" {...register("salesCostPercentage", { valueAsNumber: true })} tabIndex={3} />
                    <Form.FormInput.ValidationMessage text={errors.salesCostPercentage?.message} />
                </Form.FormInput>
                <Form.FormInput>
                    <Form.FormInput.Label text="Crowdfunding amount" infoExplainer="That's the amount that the developer is asking from investors to finish the project." />
                    <Form.FormInput.PrefixedInput prefix={"£"}>
                        <input type="number" className="input" {...register("crowdfundingAmount", { valueAsNumber: true, deps: ["projectCost", "gdv"] })} tabIndex={4} />
                    </Form.FormInput.PrefixedInput>
                    <Form.FormInput.ValidationMessage text={errors.crowdfundingAmount?.message} />
                </Form.FormInput>
                <Form.FormInput>
                    <Form.FormInput.Label text="Profit share" infoExplainer="This is the share of the profit that the investors will get." />
                    <input type="number" className="input" {...register("profitShare", { valueAsNumber: true })} tabIndex={5} />
                    <Form.FormInput.ValidationMessage text={errors.profitShare?.message} />
                </Form.FormInput>
            </Form.Inputs>
            <Form.Submit disabled={!isValid} tabIndex={6}>Calculate</Form.Submit>
        </Form>
    )
}

const CrowdfundingToolResults = ({ calculation = undefined as undefined | Calculation }) => {

    const PartTwo = () => <p className="has-text-centered has-text-purple-blue-500 has-text-weight-medium is-size-5 mb-1">PART 2</p>;

    if (!calculation) {
        return (
            <>
                <PartTwo />
                <p className="has-text-purple-blue-500 has-text-centered">Once you inputed all your inputs and clicked calculate, we will show you how much return you could get from you investment, depending on how costs and selling price fluctuate.</p>
            </>
        )
    }

    const gdvHeadings = calculation.outputs.variations
        .map(x => x.gdv)
        .filter(unique)
        .sort(smallestToLargest);

    const costHeadings = calculation.outputs.variations
        .map(x => x.costs)
        .filter(unique)
        .sort(smallestToLargest);

    let data = new Array<Array<number | undefined>>();

    // First row is the GDV variations (plus an empty space at the start)
    let firstRow = new Array<number | undefined>();
    firstRow.push(undefined);
    firstRow = firstRow.concat(gdvHeadings);

    // Remaining rows start with cost variations and their relevant values for each GDV
    const remainingRows = costHeadings.map(costHeading => {
        let row = new Array<number | undefined>();
        row.push(costHeading);
        row = row.concat(
            calculation.outputs.variations
                .filter(x => x.costs === costHeading)
                .sort((a, b) => smallestToLargest(a.gdv, b.gdv))
                .map(x => x.returnPercentage)
        );
        return row;
    });

    data.push(firstRow);
    data = data.concat(remainingRows);

    return (
        <>
            <Content>
                <PartTwo />
                <p className="has-text-purple-blue-500 has-text-centered">Below shows the Projected Investor Return on Investment with varying GDVs and cost values.</p>
            </Content>
            <Content>
                <ScrollIntoView dependency={calculation?.outputs} upToSize={ScreenSize.Tablet}>
                    <RagTable data={data.map((x, i) => x.map((y, j) => { return { val: y, formatAs: (i === 0 || j === 0) ? "currency" : "percentage" } }))} />
                </ScrollIntoView>
            </Content>
            <Content>
                <div className="mb--7">
                    <DropDownExplainer linkText="Want to understand how the return is calculated?" expandedlinkText="See less">
                        <Content>
                            <p className="has-text-purple-blue-500">This tool should be used as a guide and is suitable for development deals only.</p>
                        </Content>
                        <Content>
                            <p className="has-text-purple-blue-500">The returns presented show projected returns under different scenarios. These returns are not annualised and do not take account of the time taken to realise your investment. All figures are nominal and do not consider external factors such as the effects of inflation.</p>
                        </Content>
                        <Content>
                            <p className="has-text-purple-blue-500">Where negative returns are shown, customers may get back less than the amount invested.</p>
                        </Content>
                    </DropDownExplainer>
                </div>
            </Content>
        </>
    )
}
