import React, { forwardRef } from "react";
import * as classes from "./Radio.module.scss";

interface IRadioProps {
    labelText?: string;
    name: string;
    value: string;
    className?: string;
    tabIndex?: number;
    onChange?: (value: string, target: EventTarget & HTMLInputElement) => void;
}

export const Radio = forwardRef<HTMLInputElement, IRadioProps>(({ labelText, name, value, className = "", onChange, tabIndex} : IRadioProps, ref) => {

    const onChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        if (onChange) {
            onChange(value, event.target);
        }
    }

    return (
        <label className={`${classes["radio"]} ${className} ${classes["square"]}`}>
            <input ref={ref} className="mr-3" type="radio" name={name} value={value} tabIndex={tabIndex} onChange={onChanged} onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => { event.stopPropagation() }} />
            {labelText && labelText}
        </label>
    );
});