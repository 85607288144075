import { useEffect, useRef, useState } from "react";
import * as classes from "./SelectScale.module.scss";
import { useOverflowDetector } from "../../customHooks/useOverflowDetector";
import { ChevronRight, ChevronLeft } from "react-feather";
import { useSwipeable } from "react-swipeable";

export const SelectScale = (props: {
    options: Array<{ label: string, value: number }>,
    labels: { start: string, end: string },
    onSelected?: (value: number) => void,
    initialValue?: number,
    overflow?: { top?: number, right?: number, bottom?: number, left?: number, display?: "mobile" | "touch" | "desktop" }
}) => {

    const [selected, setSelected] = useState<number | undefined>(props.initialValue);
    const carouselContainerRef = useRef<HTMLDivElement>(null);
    const carouselRef = useRef<HTMLDivElement>(null);
    const isOverflow = useOverflowDetector(carouselContainerRef);
    const [barWidth, setBarWidth] = useState<number | undefined>();

    const overflowDisplayModifier = props.overflow?.display ? `-${props.overflow.display}` : "";
    const overflowCss = {
        top: props.overflow?.top ? `pt-${props.overflow.top}${overflowDisplayModifier} mt--${props.overflow.top}${overflowDisplayModifier}` : "",
        right: props.overflow?.right ? `pr-${props.overflow.right}${overflowDisplayModifier} mr--${props.overflow.right}${overflowDisplayModifier}` : "",
        bottom: props.overflow?.bottom ? `pb-${props.overflow.bottom}${overflowDisplayModifier} mb--${props.overflow.bottom}${overflowDisplayModifier}` : "",
        left: props.overflow?.left ? `pl-${props.overflow.left}${overflowDisplayModifier} ml--${props.overflow.left}${overflowDisplayModifier}` : ""
    };

    const swipeable = useSwipeable({
        delta: { up: 1000, down: 1000 },
        onSwiped: (data) => move(data.deltaX > 0 ? "Left" : "Right"),
        trackMouse: true
    });

    const onSelected = (value: number) => {
        props.onSelected?.(value);
        setSelected(value);
    }

    const move = (direction: "Left" | "Right") => {
        if (carouselContainerRef.current) {
            if (direction === "Left") {
                carouselContainerRef.current.scrollLeft = carouselContainerRef.current.scrollLeft - 100;
            } else {
                carouselContainerRef.current.scrollLeft = carouselContainerRef.current.scrollLeft + 100;
            }
        }
    }

    useEffect(() => {
        if (carouselRef.current) {
            setBarWidth(carouselRef.current.scrollWidth);
        }
    })

    return (
        <div>
            <div className="is-position-relative mb-1">
                <div className="is-position-absolute is-fullwidth">
                    <div className={`${classes["options-container"]} ${overflowCss.top} ${overflowCss.right} ${overflowCss.bottom} ${overflowCss.left}`} {...swipeable} ref={carouselContainerRef}>
                        <div className="is-flex is-flex-direction-column is-flex-gap-1">
                            <div className={`${classes.options} is-flex is-flex-direction-row is-align-items-center`} ref={carouselRef}>
                                {
                                    props.options.map(x => {

                                        const commonCssClasses = "is-rounded-4 is-flex-grow-1 p-1 px-3 is-clickable";

                                        if (selected === x.value) {
                                            return (
                                                <div className={`${commonCssClasses} has-background-grey-500 has-border-grey-500-1`} key={`select-scale-option-${x.value}`}>
                                                    <p className="has-text-weight-medium has-text-grey-100 has-text-centered is-size-7">{x.label}</p>
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div className={`${commonCssClasses} has-border-grey-300-1`} key={`select-scale-option-${x.value}`} onClick={() => onSelected(x.value)}>
                                                    <p className="has-text-weight-medium has-text-grey-300 has-text-centered is-size-7">{x.label}</p>
                                                </div>
                                            );
                                        }
                                    })
                                }
                            </div>
                            <div className={`${classes.bar}`} style={barWidth ? {width: barWidth} : {}}></div>
                        </div>
                    </div>
                </div>
                <div className={`${classes.scale} pt-7`}>
                    <div className="is-flex is-flex-direction-row is-justify-content-space-between">
                        <div className={`is-flex is-flex-direction-row is-align-items-center ${isOverflow ? "is-clickable" : ""}`} onClick={() => move("Left")}>
                            {
                                isOverflow &&
                                <ChevronLeft color="#A594F9" />
                            }
                            <p className={`${classes.label} ${classes["label-start"]} has-text-weight-medium`}>{props.labels.start}</p>
                        </div>
                        <div className={`is-flex is-flex-direction-row is-align-items-center ${isOverflow ? "is-clickable" : ""}`} onClick={() => move("Right")}>
                            <p className={`${classes.label} ${classes["label-end"]} has-text-weight-medium`}>{props.labels.end}</p>
                            {
                                isOverflow &&
                                <ChevronRight color="#E9897D" />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}