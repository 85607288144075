import mapPin from "./map-pin.svg";
import dayjs from "dayjs";
import { MembershipProps } from "../../Membership/MembershipProps";
import { Card } from "../Card";
import { Level } from "react-bulma-components";
import { withLink } from "../../../hoc/withLink";

export interface EventCardProps extends MembershipProps {
    image: any;
    header: string;
    location: string;
    date?: Date;
    matchHeight?: boolean;
    className?: string;
}

export const EventCard = ({ image, header, date, location, membership, matchHeight = false, className = "" }: EventCardProps) => {
    return (
        <Card heading={header} imageUrl={image} className={className} membership={membership} matchHeight={matchHeight}>
            <p className="py-2 has-text-primary has-text-weight-medium is-size-smaller">{ date ? dayjs(date).format('ddd, MMM D, HH:mm') : "Coming soon" } </p>
            <LocationLabel className="is-size-smaller" location={location}/>
        </Card>
    );
}

interface LocationLabelProps {
    className?: string;
    location: string;
}

const LocationLabel = ({ location, className = ""}: LocationLabelProps) => {
    return (
        <Level breakpoint="mobile" className={`event-location-label is-align-content-center ${className}`}>
            <Level.Item className="is-flex-grow-0 is-justify-content-start mr-1">
                <img src={mapPin} alt="map pin" />
            </Level.Item>
            <Level.Item className="is-justify-content-start has-text-grey-500">
                <p>{ location }</p>
            </Level.Item>
        </Level>
    )
}

export const EventCardWithLink = withLink(EventCard, "event-card card-wrapper");