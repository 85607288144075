import { CssClasses } from "../../Helpers/CssClasses";
import "./Content.scss";

interface IContentProps {
    children?: any;
    isMain?: boolean;
    isMarginless?: boolean;
    className?: string;
}

export const Content = ({ children, isMain = false, isMarginless = false, className } : IContentProps) => {

    const css = new CssClasses(["content-rv9AIY"]);

    if (!isMarginless) {
        if (isMain) {
            css.add("mb-5");
        } else {
            css.add("mb-3")
        }
    }
    
    if (className) {
        css.add(className);
    }

    return (
        <div className={css.aggregated()}>
            { children }
        </div>
    )
}