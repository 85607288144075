import { useEffect, useRef } from "react";
import "./Meter.scss";

interface MeterProps {
    segments: number,
    filledSegments: number,
    size?: 50 | 60 | 70 | 80 | 90 | 100 | 110 | 120 | 130 | 140 | 150 | 160 | 170 | 180 | 190 | 200;
    children?: any;
}

export const Meter = (props: MeterProps) => {

    const canvas = useRef<HTMLCanvasElement>(null);
    const defaultSize = 200;
    const size = props.size ?? defaultSize;

    useEffect(() => {
        if (canvas.current) {

            canvas.current.height = size;
            canvas.current.width = size;

            drawMeter(canvas.current, props.segments, props.filledSegments);
        }
    });

    const drawMeter = (canvas: HTMLCanvasElement, segments: number, filledSegments: number) => {

        const ctx = canvas.getContext("2d");

        if (!ctx) {
            return;
        }
        
        const segmentAngle = (Math.PI * 2 / segments) * 0.8; // Angle of each segment
        const radius = canvas.width * 0.5; // Radius of the meter
        const thickness = size / 20 + 7; // Thickness of the meter ring
        const gapAngle = Math.PI / 8; // Angle of the gaps between the segments
        const gapThickness = 5; // Thickness of the gaps between the segments
        const centerX = canvas.width / 2; // X-coordinate of the center
        const centerY = canvas.height / 2; // Y-coordinate of the center
        const startAngle = Math.PI * 0.7; // Starting angle of the meter arc
        const endAngle = Math.PI * 2; // Ending angle of the meter arc

        const gradient = ctx.createLinearGradient(
            centerX + Math.cos(startAngle) * radius,
            centerY + Math.sin(startAngle) * radius,
            centerX + Math.cos(endAngle) * radius,
            centerY + Math.sin(endAngle) * radius
        );
        gradient.addColorStop(0, "#A594F9");
        gradient.addColorStop(1, "#E9897D");

        // Draw each segment and gap
        for (let i = 0; i < segments; i++) {
            const segmentStartAngle = startAngle + segmentAngle * i + gapAngle / 2;
            const segmentEndAngle = startAngle + segmentAngle * (i + 1) - gapAngle / 2;

            ctx.beginPath();
            ctx.arc(centerX, centerY, radius - thickness / 2, segmentStartAngle, segmentEndAngle);
            ctx.lineWidth = thickness - gapThickness;
            ctx.lineCap = "round"; // Set the line cap to round
            ctx.strokeStyle = i < filledSegments ? gradient : "#CDCDCD";
            ctx.stroke();
        }
    };

    return (
        <div className={`meter-${size} is-relative is-flex is-align-items-center is-justify-content-center`}>
            <canvas ref={canvas}>
            </canvas>
            <div className="meter-label is-overlay is-flex is-align-items-center is-justify-content-center mt--3">
                {props.children}
            </div>
        </div>
    )
}