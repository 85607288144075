import React from "react";
import { Columns } from "react-bulma-components";
import { Breakpoint } from "react-bulma-components/src/components";
import "./CenteredContent.scss";

interface CenteredContentProps {
    children?: React.ReactNode;
}

export function CenteredContent (props: CenteredContentProps) {
    return (
        <div className="centered-column-6">
            <Columns breakpoint={'desktop' as Breakpoint} centered={true}>
                <Columns.Column size={3}></Columns.Column>
                <Columns.Column>
                    <div className="padded-content">
                        { props.children }
                    </div>
                </Columns.Column>
                <Columns.Column size={3}></Columns.Column>
            </Columns>
        </div>
    )
}