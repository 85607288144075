import { ChevronRight } from "react-feather";
import * as classes from "./Table.module.scss";
import { createContext, useContext } from "react";
import { CssClasses } from "../Helpers/CssClasses";
import { convertToSassModule } from "../../helpers/sass";
import { extend } from "lodash";

const TableContext = createContext({ 
    hasBorderedRows: true,
    columnGap: 1,
    rowHeight: 7
});

export const Table = ({
    hasBorderedRows = true,
    columnGap = 1,
    rowHeight = 7,
    children = undefined as any,
    className = undefined as string | undefined
}) => {

    className?.split(",").forEach(x => {
        if (classes[x]) {
            className = className?.replace(x, classes[x]!);
        }
    });

    className = convertToSassModule(classes, className);

    return (
        <div className={classes["table-container"]}>
            <div className={classes.table + (className ? ` ${className}` : "") }>
                <TableContext.Provider value={{hasBorderedRows, columnGap, rowHeight}}>
                    {
                        children
                    }
                </TableContext.Provider>
            </div>
        </div>
    )
}

const Header = ({ children = undefined as any }) => {
    return (
        <header className={classes.header}>
            {
                children
            }
        </header>
    )
}

const Heading = ({ children = undefined as any }) => {

    const tableContext = useContext(TableContext);

    return (
        <div className={new CssClasses([classes.cell ?? "", `is-height-${tableContext.rowHeight}`]).aggregated()}>
            <div className={classes["cell-contents"] + " px-1 is-flex is-flex-direction-row is-align-items-center"}>
                {
                    children
                }
            </div>
        </div>
    )
}

const Data = ({ data = new Array<{values: Array<React.ReactElement>, onClick?:() => void }> }) => {

    const tableContext = useContext(TableContext);
    const borderCss = tableContext.hasBorderedRows ? ` ${classes["with-border-bottom"]}` : ""
    const columnGapCss = `px-${tableContext.columnGap}`;

    return (
        <>
            { 
                data.map((row, row_index) => {
                    return (
                        <div key={`row_${row_index}`} className={borderCss + ` is-height-${tableContext.rowHeight} is-min-height-${tableContext.rowHeight}` + " is-position-relative" + (row.onClick ? " is-clickable" : "")} onClick={() => { if (row.onClick) { row.onClick(); } }}>
                            {
                                row.values.map((cell, cell_index) => {
                                    return (
                                        <div key={`cell_${cell_index}`} className={new CssClasses([classes.cell ?? "", `is-max-height-${tableContext.rowHeight}`, 'cell']).aggregated()}>
                                            <div className={`${classes["cell-contents"]} is-height-${tableContext.rowHeight} is-min-height-${tableContext.rowHeight} ${columnGapCss} is-flex is-flex-direction-row is-align-items-center cell-contents`}>
                                                {cell}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                row.onClick && 
                                <div className="is-position-absolute is-right is-fullwidth is-fullheight">
                                    <div className="is-flex is-flex-direction-row-reverse is-align-items-center is-fullheight">
                                        <ChevronRight color="#A594F9" />
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })
            }
        </>
    )
};

Header.Heading = Heading;

Table.Header = Header;
Table.Data = Data;

