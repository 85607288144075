import { Check } from "react-feather"
import * as classes from "./Checklist.module.scss";

export const Checklist = ({ children = undefined as undefined | any }) => {
    return (
        <div className="is-flex is-flex-direction-column is-align-items-start is-flex-gap-2">
            { children }
        </div>
    )
}

const ChecklistItem = ({ children = undefined as undefined | any}) => {
    return (
        <div className="is-flex is-flex-direction-row is-align-items-center is-flex-gap-4">
            <div className={classes.check}>
                <Check color="#A594F9" size={30}/>
            </div>
            { children }
        </div>
    )
}

Checklist.Item = ChecklistItem;