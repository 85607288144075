import { Helmet } from "react-helmet-async";
import { Content } from "../../../../../components/Layout/Content/Content";
import { Glass } from "../../../../../components/Layout/Glass/Glass";
import { Main } from "../../../../../components/Layout/Main/Main";
import { Container } from "react-bulma-components";
import { PageTitle } from "../../../../../components/Layout/PageTitle/PageTitle";
import { ContentTitle } from "../../../../../components/Layout/Content/ContentTitle";
import { DashboardTile } from "../../../../home/Dashboard/components/DashboardTile/DashboardTile";
import Crowdfunding from "./crowdfunding.tsx.svg";
import PeerToPeer from "./peer-to-peer.tsx.svg";
import { FAQMiniContainer } from "../../../../../components/FAQs/FAQMini";
import { BasicTile } from "../../../../../components/Layout/Wrappers/BasicTile";
import { SelfCertificationTile } from "./components/SelfCertificationTile";
import { useContext, useState } from "react";
import { PropertyContext } from "../Property/PropertyContext";
import { Section } from "../../../../../components/Layout/Section/Section";
import { Link } from "react-router-dom";
import { PropertyOpportunitiesContainer } from "../../../../../dataComponents/cms/PropertyOpportunitiesContainer";
import { PropertyOpportunityCarousel } from "./components/PropertyOpportunityCarousel";
import { PropertyEvents } from "./components/PropertyEvents";
import { Banner } from "../../../../../components/Banner/Banner";
import { Info } from "react-feather";
import { ExternalLink } from "../../../../../components/Links/ExternalLink";
import { PropertyDashboard } from "./components/PropertyDashboard";
import { TagContentCarousel } from "../../../../../components/ContentCarousel/TagContentCarousel";

export const Property = () => {
    const context = useContext(PropertyContext);

    return (
        <>
            <Helmet>
                <title>Property</title>
            </Helmet>
            <Main>
                <Container>
                    <PageTitle text="Your property dashboard" className="has-text-centered-touch" />
                    <Section>
                        <div className="columns">
                            <div className="column is-8">
                                {
                                    context.isEligibleForInvestment
                                        ? <PropertyDashboard />
                                        : <HowItWorks />
                                }
                            </div>
                            <div className="column is-4">
                                <FAQMiniContainer platformArea="property" wrap={(children: JSX.Element) => <BasicTile>{children}</BasicTile>} />
                            </div>
                        </div>
                    </Section>
                    <Section>
                        <Banner isPermanent={true}>
                            <div className="is-flex is-flex-direction-row is-align-items-center is-flex-gap-4">
                                <Info color="#1B0777" height="24px" width="24px" style={{minHeight: "24px", minWidth: "24px" }}/>
                                <p className="is-italic has-text-purple-blue-500 has-text-weight-medium">Don't invest unless you're prepared to lose money. This is a high-risk investment. You may not be able to access your money easily and are unlikely to be protected if something goes wrong. <ExternalLink href="https://www.simplecrowdfunding.co.uk/ps22-risk-summary-default" className="has-text-purple-blue-500 is-underlined" text="Take 2 mins to learn more."/></p>
                            </div>
                        </Banner>
                    </Section>
                    <Section>
                        <Glass equalPadding={true}>
                            <Content>
                                <div className="carousel-title mb-2 p-0-touch">
                                    <h2 className="is-size-4 has-text-purple-blue-500 mb-1">Investment opportunities from our partners</h2>
                                        {
                                            !context.isEligibleForInvestment &&
                                                <>
                                                    <p>Please complete our investor certification questionnaire to access property investment opportunities.</p>
                                                    <Link to={"/invest/property/self-certification"}>
                                                        <button
                                                            className="button is-rounded is-primary my-2"
                                                            disabled={!context.canSubmitSelfCertification}>
                                                                Complete investor certification
                                                        </button>
                                                    </Link>
                                                </>
                                        }
                                </div>
                                <PropertyOpportunitiesContainer isEligibleForInvestment={context.isEligibleForInvestment}>
                                    <PropertyOpportunityCarousel propertyOpportunities={[]} isEligibleForInvestment={context.isEligibleForInvestment}/>
                                </PropertyOpportunitiesContainer>
                            </Content>
                        </Glass>
                    </Section>
                    <Section>
                        <TagContentCarousel tag="property" title="Property Content" />
                    </Section>
                    <Section>
                        <PropertyEvents />
                    </Section>
                </Container>
            </Main>
        </>
    );
}

const HowItWorks = () => {

    const context = useContext(PropertyContext);

    return (
        <Glass opacity={100}>
            <Content isMain={true}>
                <div className="columns is-multiline">
                    <div className="column is-12">
                        <div className="has-background-grey-100-100-touch p-4-touch is-rounded-2">
                            <ContentTitle text="How it works" />
                            <p>We've partnered with property platforms to bring investment opportunities to you. You'll be able to invest in what we call property debt (property peer to peer lending) or property equity (property crowdfunding).</p>
                        </div>
                    </div>
                    {
                        !context.isEligibleForInvestment &&
                        <div className="column is-12">
                            <SelfCertificationTile />
                        </div>
                    }
                    <div className="column is-6">
                        <DashboardTile
                            icon={<PeerToPeer />}
                            className="has-background-purple-blue-100 is-fullheight"
                            title={<h5 className="is-family-primary has-text-purple-blue-500 is-size-5 has-text-weight-semibold">Peer to Peer Lending</h5>}
                            description='Property Peer to Peer Lending is what we call "Property Debt".'
                            button={{ text: "Learn more", to: "/learn/article/property-peer-2-peer" }}
                        />
                    </div>
                    <div className="column is-6">
                        <DashboardTile
                            icon={<Crowdfunding />}
                            className="has-background-pink-100 is-fullheight"
                            title={<h5 className="is-family-primary has-text-purple-blue-500 is-size-5 has-text-weight-semibold">Crowdfunding</h5>}
                            description='Property Crowdfunding is what we call "Property Equity".'
                            button={{ text: "Learn more", to: "/learn/article/crowdfunding" }}
                        />
                    </div>
                </div>
            </Content>
        </Glass>
    )
}

