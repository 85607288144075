import { useEffect } from "react";
import { Columns, Container } from "react-bulma-components";
import { useWpLoader } from "../../../../../../hoc/withLoader";
import { ArticleCard, ArticleCardWithLink } from "../../../../../../components/Cards/ArticleCard";
import './LatestArticles.scss';
import { Link } from "react-router-dom";
import { ArticleParams, Embed, Order, OrderBy, WpPostTypeRequest } from "../../../../../../components/Auth/AuthenticatedCmsClient";
import { ImageSize, getImageUrl } from "../../../../../../helpers/cms";
import { ContentTitle } from "../../../../../../components/Layout/Content/ContentTitle";
import { Glass } from "../../../../../../components/Layout/Glass/Glass";

export const LatestArticles = () => {
    const [loaderState, { load, defaultLoaderDisplay }] = useWpLoader();

    useEffect(() => {
        if (loaderState.loadingState === "NotStarted") {
            load(new WpPostTypeRequest({
                url: "posts",
                params: new ArticleParams({
                    category_slug: "blog",
                    per_page: 4,
                    orderby: OrderBy.Date,
                    order: Order.Descending,
                    _embed: [Embed.Term]
                })
            }));
        }
        
    }, [loaderState.loadingState]);

    let inner = defaultLoaderDisplay();
    if (loaderState.loadingState === "Loaded") {

        let latestArticles = loaderState.response;

        if (latestArticles.length === 0) {
            return <></>;
        }

        let firstArticle = latestArticles[0];

        let getArticleCategory = (article: any) : any => {
            for (let i = 0; i < article._embedded["wp:term"].length; i++) {
                const term = article._embedded["wp:term"][i];
                if (term.some((t: any) => t.taxonomy === "category")) {
                    return term.find((t: any) => t.taxonomy === "category" && t.slug !== "blog");
                }
            }
            return undefined;
        }

        let firstArticleCategory = getArticleCategory(firstArticle);

        inner = (
            <Columns className="latest-articles">
                <Columns.Column 
                    tablet={{
                        size:5
                    }}
                    desktop={{
                        size:6
                    }}
                    className="article-main p-0">
                    <ArticleCardWithLink
                        key={`latest-${firstArticle.id}`}
                        id={firstArticle.id}
                        header={firstArticle.title}
                        duration={firstArticle.acf.duration}
                        url={`/learn/article/${firstArticle.slug}`}
                        imageUrl={getImageUrl(firstArticle.acf.thumbnail, ImageSize.Large)}
                        category={firstArticleCategory.name}
                        size="large"/>

                </Columns.Column>
                <Columns.Column
                    tablet={{
                        size:7
                    }}
                    desktop={{
                        size:6
                    }}
                    className="article-list is-flex is-flex-direction-column p-0">
                    { latestArticles?.slice(1).map((x : any, i : any) => {                    
                        return (
                            <ArticleCardWithLink
                                key={`latest-${x.id}`}
                                id={x.id}
                                header={x.title}
                                duration={x.acf.duration}
                                url={`/learn/article/${x.slug}`}
                                imageUrl={getImageUrl(x.acf.thumbnail, ImageSize.Medium)}
                                category={getArticleCategory(x).name}
                                size="small"
                                classes="is-flex-grow-1"/>
                        );
                    }) }
                </Columns.Column>
            </Columns>
        );
    }

    return (
        <Container>
            <Glass>
                <ContentTitle text="Latest blog releases"/>
                { inner }
                <Link to={'/learn/blog'} className="button is-rounded is-outlined is-primary is-fullwidth-mobile mt-1">See all</Link>
            </Glass>
        </Container>
    );
}