import { useEffect, useState } from "react";
import { Button, Card, Columns, Form } from "react-bulma-components";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { CalculateFutureValue_Request, CalculateFutureValue_Response } from "../../../../services/generated/WealthMappingClient";
import { WealthMappingClientFactory } from "../../../../services/WealthMappingClientFactory";
import { CalculatorResult } from "../CalculationResult/CalculationResult";
import "./Calculation.scss";
import { Info } from "../../../../../../components/Info/Info";
import { cloneDeep } from "lodash";
import { CssClasses } from "../../../../../../components/Helpers/CssClasses";
import { Label } from "../../../../../../components/Forms/Label";
import { SelectScale } from "../../../../../../components/Slider/SelectScale";

export interface CalculationProps {
    prospectiveUserId?: string,
    lumpSumRequired?: number;
    futureValueRequest?: CalculateFutureValue_Request;
    futureValueResponse?: CalculateFutureValue_Response;
    onFutureValueCalculated?: (request: CalculateFutureValue_Request, response: CalculateFutureValue_Response) => void;
}

interface Form {
    initialDeposit?: number | undefined;
    monthlyDeposit?: number | undefined;
    years?: number | undefined;
    desiredReturn?: number;
}

export const Calculation = (props: CalculationProps) => {

    const { register, clearErrors, reset, setValue, formState: { errors, isValid, isDirty }, handleSubmit } = useForm<Form>({
        mode: 'all',
        reValidateMode: 'onBlur',
        defaultValues: {
            years: props.futureValueRequest?.years ?? undefined,
            initialDeposit: props.futureValueRequest?.initialDeposit ?? undefined,
            monthlyDeposit: props.futureValueRequest?.monthlyDeposit ?? undefined,
            desiredReturn: props.futureValueRequest?.desiredReturn ?? 0.08
        }
    });
    const client = WealthMappingClientFactory();

    const [state, setState] = useState({ isFutureValueRequestLoading: false, calculateFutureValueRequest: undefined as CalculateFutureValue_Request | undefined });

    const onCalculate: SubmitHandler<Form> = data => {
        let request = {
            initialDeposit: data.initialDeposit?.toString() != "" ? data.initialDeposit : undefined,
            monthlyDeposit: data.monthlyDeposit?.toString() != "" ? data.monthlyDeposit : undefined,
            years: data.years,
            prospectiveUserId: props.prospectiveUserId,
            desiredReturn: data.desiredReturn
        } as CalculateFutureValue_Request;

        setState({ isFutureValueRequestLoading: true, calculateFutureValueRequest: request });
        reset({}, { keepValues: true });
    }

    const validateMonthlyDeposit = (val: number | undefined, formValues: Form) => {
        if (!val) {
            if (!formValues.initialDeposit) {
                clearErrors('initialDeposit');
                return false;
            }
        } else {
            let isValid = val >= 100 && val <= 100000;

            if (isValid && !formValues.initialDeposit) {
                clearErrors('initialDeposit');
            };

            return isValid;
        }
    }

    const validateInitialDeposit = (val: number | undefined, formValues: Form) => {
        if (!val) {
            if (!formValues.monthlyDeposit) {
                clearErrors('monthlyDeposit');
                return false;
            }
        } else {
            let isValid = val >= 1 && val <= 10000000;

            if (isValid && !formValues.monthlyDeposit) {
                clearErrors('monthlyDeposit');
            };

            return isValid;
        }
    };

    const onError: SubmitErrorHandler<Form> = (errors, e) => {
    }

    useEffect(() => {
        if (state.isFutureValueRequestLoading) {
            if (state.calculateFutureValueRequest) {
                if (client.isLoggedIn()) {
                    client.calculateOwnedFutureValue(state.calculateFutureValueRequest)
                        .then(resp => {
                            if (state.calculateFutureValueRequest) {
                                setState(s => {
                                    const state = cloneDeep(s);
                                    state.isFutureValueRequestLoading = false;
                                    return state;
                                })
                                props.onFutureValueCalculated?.(state.calculateFutureValueRequest, resp);
                            }
                        })
                        .catch(_ => { });
                } else {
                    client.calculateFutureValue(state.calculateFutureValueRequest)
                        .then(resp => {
                            if (state.calculateFutureValueRequest) {
                                setState(s => {
                                    const state = cloneDeep(s);
                                    state.isFutureValueRequestLoading = false;
                                    return state;
                                })
                                props.onFutureValueCalculated?.(state.calculateFutureValueRequest, resp);
                            }
                        })
                        .catch(_ => { });
                }
            }
        }
    }, [state.calculateFutureValueRequest]);

    const buttonCssClasses = new CssClasses(["button is-primary is-rounded calculate-button is-justify-content-center"]);

    if (state.isFutureValueRequestLoading) {
        buttonCssClasses.add("is-loading");
    }

    return (
        <div className="projection is-rounded">
            <Card className="projection-content is-rounded-top goal-planning-padding-x pb-3">
                <Card.Content>
                    <p className="has-text-centered has-text-navy has-text-weight-medium mb-1">PART 2</p>
                    <h4 className="title is-4 has-text-centered has-text-navy mb-2">Calculator</h4>
                    <p className="has-text-navy has-text-centered pb-5 mt-0">By using the calculator you can play with different parameters to see how the money you're ready to invest can grow and whether you're projected to be meeting, exceeding or not reaching your goal. This will help you assess whether your goal is achievable and realistic.</p>
                    <form onSubmit={handleSubmit(onCalculate, onError)}>
                        <Columns breakpoint={"desktop"}>
                            <Columns.Column size={"half"}>
                                <Form.Field className={errors.initialDeposit ? "has-errors" : ""}>
                                    <Form.Control>
                                        <Form.Label className="has-text-navy is-flex is-align-items-center is-flex-wrap-wrap">
                                            <Label text="Initial investment" className="pr-1"/>
                                            <Info notificationClassName="is-fullwidth">
                                                <p>This is the amount of money you currently have ready to start investing. </p>
                                            </Info>
                                        </Form.Label>
                                        <Form.Field horizontal={true} className="is-flex is-align-items-center is-flex-direction-row ">
                                            <Form.Field.Label className="is-flex-grow-0 m-0 pr-2 has-text-weight-medium">£</Form.Field.Label>
                                            <Form.Field.Body>
                                                <input className="input input-big" type="number" placeholder=" " {...register("initialDeposit", { validate: validateInitialDeposit })} />
                                            </Form.Field.Body>
                                        </Form.Field>
                                    </Form.Control>
                                    <p className="help is-danger">Initial deposit should be between {currencyFormatter.format(1)} and {currencyFormatter.format(10000000)}</p>
                                </Form.Field>
                            </Columns.Column>
                        </Columns>
                        <Columns breakpoint="desktop">
                            <Columns.Column size={"half"}>
                                <Form.Field className={errors.monthlyDeposit ? "has-errors" : ""}>
                                    <Form.Control>
                                            <Form.Label className="has-text-navy is-flex is-align-items-center is-flex-wrap-wrap">
                                                <Label text="Monthly investment" className="pr-1"/>
                                                <Info notificationClassName="is-fullwidth">
                                                    <p>This is the monthly amount you want to add to your investments.</p>
                                                </Info>
                                            </Form.Label>
                                            <Form.Field horizontal={true} className="is-flex is-align-items-center is-flex-direction-row">
                                                <Form.Field.Label className="is-flex-grow-0 m-0 pr-2 has-text-weight-medium">£</Form.Field.Label>
                                                <Form.Field.Body>
                                                    <input className="input input-big" type="number" placeholder=" " {...register("monthlyDeposit", { validate: validateMonthlyDeposit })} />
                                                </Form.Field.Body>
                                            </Form.Field>
                                        </Form.Control>
                                        <p className="help is-danger">Monthly deposit should be between {currencyFormatter.format(100)} and {currencyFormatter.format(100000)}</p>
                                    </Form.Field>
                            </Columns.Column>
                            <Columns.Column size={"half"}>
                                <Form.Field className={errors.years ? "has-errors" : ""}>
                                    <Form.Control>
                                        <Form.Label className="has-text-navy is-flex is-align-items-center is-flex-wrap-wrap">
                                            <Label text="Time period" className="pr-1"/>
                                            <Info notificationClassName="is-fullwidth">
                                                <p>This is the timeframe you plan to leave your money invested.</p>
                                            </Info>
                                        </Form.Label>
                                        <Form.Field horizontal={true} className="is-flex is-align-items-center is-flex-direction-row">
                                            <Form.Field.Body className="is-flex-grow-0">
                                                <input className="input input-small" type="number" placeholder=" " {...register("years", { min: 3, max: 50, required: true })} />
                                            </Form.Field.Body>
                                            <Form.Field.Label className="is-flex-grow-0 m-0 pl-2 has-text-weight-medium">Years</Form.Field.Label>
                                        </Form.Field>
                                    </Form.Control>
                                    <p className="help is-danger">Number of years should be between 3 and 50</p>
                                </Form.Field>
                            </Columns.Column>
                        </Columns>
                        <div className="mb-4">
                            <Form.Label className="has-text-navy is-flex is-align-items-center is-flex-wrap-wrap">
                                <Label text="Desired return" className="pr-1"/>
                                <Info notificationClassName="is-fullwidth" positioning={["bottom-desktop", "top-touch"]}>
                                    <p>Choose different return levels to see how the expected return of your investment impacts your projected value. A higher risk level can produce higher returns but also carries a greater chance of losing money. Likewise a lower risk level carries a lower chance to lose money, but can produce low returns.</p>
                                </Info>
                                </Form.Label>
                            <Form.Control>
                                <SelectScale
                                    options={[
                                        { label: "2%", value: 0.02 },
                                        { label: "4%", value: 0.04 },
                                        { label: "6%", value: 0.06 },
                                        { label: "8%", value: 0.08 },
                                        { label: "10%", value: 0.1 },
                                        { label: "12%", value: 0.12 },
                                        { label: "14%", value: 0.14 },
                                        { label: "16%", value: 0.16 },
                                        { label: "18%", value: 0.18 },
                                        { label: "20%", value: 0.20 }                                      
                                    ]}
                                    labels={{ start: "Low risk", end: "High risk" }}
                                    initialValue={props.futureValueRequest?.desiredReturn ?? 0.08}
                                    onSelected={x => setValue("desiredReturn", x, { shouldDirty: true })}
                                    overflow={{ left: 7, right: 7, display: "touch" }}/>
                            </Form.Control>
                        </div>
                        <Form.Control>
                            <Button className={buttonCssClasses.aggregated()} type="submit" disabled={!isValid || !isDirty}>Calculate</Button>
                        </Form.Control>
                    </form>
                </Card.Content>
            </Card>
            <CalculatorResult futureValueResponse={props.futureValueResponse} isFutureValueResponseLoading={state.isFutureValueRequestLoading} lumpSumRequired={props.lumpSumRequired} />
        </div>
    )
}

const currencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    maximumFractionDigits: 0
});