import { Card } from "react-bulma-components";
import './CalculationSummary.scss';
import { Link } from "react-router-dom";

export interface CalculationSummaryProps {
    lumpSumRequired?: number;
}

export const CalculationSummary = (props: CalculationSummaryProps) => {
    return (
        <Card className="calculation-summary is-rounded">
            <Card.Content className="py-5">
                <p className="has-text-centered has-text-navy has-text-weight-medium mb-2">PART 1</p>
                <h3 className="title is-3 has-text-centered has-text-navy is-hidden-touch">To achieve your goal, you'll need</h3>
                <h5 className="title is-5 has-text-centered has-text-navy is-hidden-desktop mb-0">To achieve your goal, you'll need</h5>
                <h4 className="title is-4 is-hidden-desktop has-text-centered has-text-navy">{currencyFormatter.format(props.lumpSumRequired ?? 0)}</h4>
                <h2 className="title is-2 is-hidden-touch has-text-centered has-text-navy">{currencyFormatter.format(props.lumpSumRequired ?? 0)}</h2>
            </Card.Content>
        </Card>
    );
}

const currencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    maximumFractionDigits: 0
});