import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { Navigate } from "react-router-dom";

export const PostLogout = () => {
    let auth = useAuth();

    useEffect(() => {
        auth.removeUser();
    });

    return (
        <Navigate replace to="/" />
    )
}