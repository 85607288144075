import { Container } from "react-bulma-components";
import { Carousel, CarouselItem } from "../Carousel/Carousel";
import './CarouselWithPartsOverlay.scss';
import { OverlayCardColor, OverlayCardProps, OverlayCardWithLink } from "../Cards/OverlayCard";
import { Link } from "react-router-dom";
import { Glass } from "../Layout/Glass/Glass";

interface CarouselWithPartsOverlayProps {
    title: string;
    color?: OverlayCardColor;
    items: OverlayCardProps[];
    linkTo: string;
}

export const CarouselWithPartsOverlay = ({ title, items, color, linkTo } : CarouselWithPartsOverlayProps) => {
    return (
        <Container className="carousel-with-parts-overlay">
            <Glass>
                <Carousel className={"mx--3-desktop"} title={title}>
                    {
                        items.map((x : any, _) => {
                            return (
                                <CarouselItem key={`sub-cat-${x.id}`}>
                                    <OverlayCardWithLink {...x} color={color} className="is-fullheight"/>
                                </CarouselItem>
                            )
                        })
                    }
                </Carousel>
                <Link to={linkTo} className="button is-rounded is-outlined is-primary is-fullwidth-mobile mt-3">See all</Link> 
            </Glass>
        </Container>
    );
}