import { useRef, useEffect, Ref } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideAlerter = (ref: any, onClickOutside: () => void) => {

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    const handleClickOutside = (event: any) => {
        if (ref?.current && !ref?.current.contains(event.target)) {
            onClickOutside();
        }
    }
}

export const OutsideAlerter = ({ onClickOutside = () => {}, className = undefined as undefined | string, children = undefined as any }) => {

    const wrapperRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(wrapperRef, onClickOutside);

    return (
        <div ref={wrapperRef} className={className}>{children}</div>
    );
}