@use "../../../styles/spacing";
@use "../../../../node_modules/bulma/sass/utilities/_all.sass";

.stack {
    display: flex;
    flex-direction: row;

    &.below-size {

        &-mobile {
            @include all.until(all.$tablet) {
                flex-direction: column !important;
            }
    
            @each $spacing-name, $spacing-value in spacing.$spacing-values {
                &.gap-on-stack-#{$spacing-name} {
                    @include all.until(all.$tablet) {
                        gap: $spacing-value !important;
                    }
                }
            }
        }

        &-tablet {
            @include all.until(all.$desktop) {
                flex-direction: column !important;
            }
            
            @each $spacing-name, $spacing-value in spacing.$spacing-values {
                &.gap-on-stack-#{$spacing-name} {
                    @include all.until(all.$desktop) {
                        gap: $spacing-value !important;
                    }
                }
            }
        }
    }

    @each $spacing-name, $spacing-value in spacing.$spacing-values {
        &.gap-#{$spacing-name} {
            gap: $spacing-value;
        }
    }
}