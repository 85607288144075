import Blob1 from './blob-1.svg';
import Blob2 from './blob-2.svg';
import { Link } from 'react-router-dom';
import { Content } from '../../../../../components/Layout/Content/Content';
import { getQualitativeGoalFromResponse } from '../../../../wealth-mapping/pages/Calculator/components/QualitativeGoal/QualitativeGoal';
import { useGoalPlanningLoader } from '../../../../invest/pages/Tools/GoalPlanning/GoalPlanning';
import { CssClasses } from '../../../../../components/Helpers/CssClasses';
import { useContext } from 'react';
import { ScreenSize, WindowContext } from '../../../../../components/App/WindowContext';

export const GoalDashboard = ({ className = undefined as undefined | string }) => {

    const [loaderState, loaderFunctions] = useGoalPlanningLoader();
    const windowContext = useContext(WindowContext);

    if (loaderState.loadingState === "Loading") {
        return (
            <Box className={className}>
                <div className="p-8">
                    { loaderFunctions.defaultLoaderDisplay()}
                </div>
            </Box>
        );
    }

    const lumpSumRequired = loaderState.overview?.wealthPicture?.outputs?.lumpSumRequired;
    const goal = loaderState.overview?.wealthPicture?.outputs?.goal;
    const timeframe = loaderState.overview?.wealthPicture?.outputs?.years;

    const currencyFormatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        maximumFractionDigits: 0
    });

    return (
        <Box className={new CssClasses([className]).aggregated()}>
            {
                (lumpSumRequired && windowContext.screenSize >= ScreenSize.FullHD) &&
                    <>
                        <BlobOverlay image={<Blob1 />} x="right" y="top" className="is-hidden-touch" />
                        <BlobOverlay image={<Blob2 />} x="right" y="bottom" className="is-hidden-touch"/>
                    </>
            }
            
            <div className="p-6">
                <Content>
                    <h4 className="title is-size-4 is-size-3-desktop has-text-purple-blue-500">
                        {
                            lumpSumRequired 
                                ? <>Your goal</>
                                : <>You haven't set your financial goal <span className="is-white-space-nowrap">yet 🎯</span></>
                        }
                    </h4>
                </Content>
                <Content>
                    {
                        lumpSumRequired &&
                            <div className="mb-0">
                                <h4 className="title is-size-4 is-size-3-fullhd has-text-purple-blue-300">{getQualitativeGoalFromResponse(goal)}</h4>
                            </div>
                    }
                    <p className="has-text-grey-500">
                        {
                            lumpSumRequired 
                                ? <>{ currencyFormatter.format(lumpSumRequired) } in {timeframe ?? 0} years</>
                                : <>To set your financial goal, click on the button below.</>
                        }
                    </p>
                </Content>
                <Content>
                    <Link className="button is-rounded is-primary is-outlined" to={"/wealth-mapping/chat"}>
                        {
                            lumpSumRequired
                                ? <>Reset goal</>
                                : <>Set your goal</>
                        }
                    </Link>
                </Content>
            </div>
        </Box>
    );
}

const Box = (props: { children?: any, className?: string }) => {
    return (
        <div className={`has-background-grey-100 is-rounded-2 is-position-relative ${props.className ?? ""}`}>
            {props.children}
        </div>
    );
}

const BlobOverlay = (props: { image: any, x: "left" | "right", y: "top" | "bottom", className?: string; }) => {
    return (
        <div className={`is-position-absolute is-${props.x} is-${props.y} is-rounded-2 is-clipped ${props.className ?? ""}`}>
            <div className={props.y === "top" ? "mb-2" : "mb--2"}>
                { props.image }
            </div>
        </div>
    )
}