import { Link } from "react-router-dom";
import "./Footer.scss";
import logo from "./logo-white.svg";
import youtube from "./youtube-logo.svg";
import insta from "./insta-logo.svg";
import linkedin from "./linkedin-logo.svg";
import tiktok from "./tiktok-logo.svg";
import { getUserData } from "../../../helpers/auth";


export const Footer = (props: { className?: string }) => {

    const isLoggedIn = getUserData().id !== undefined;

    var today = new Date();

    return (
        <footer className={`${props.className ?? ""} section has-background-purple-blue-500 has-text-white`}>
            <div className="container is-fullhd">
                <div className="columns is-multiline">
                    <div className="column is-12-mobile is-4-tablet propelle-logo">
                        <img src={logo} alt="Propelle logo" />
                        <p className="is-size-small has-text-centered-mobile">Copyright @ Propelle <span className="is-hidden-mobile">(Network) Ltd </span>{today.getFullYear()}</p>
                    </div>
                    <div className="column is-12-touch is-8-desktop is-size-smaller">
                        <div className="columns is-multiline footer-nav is-mobile">
                            <div className="column is-6-mobile is-4-desktop mb-2 mb-5-desktop">
                                <h5 className="mb-4 is-uppercase is-family-montserrat has-text-grey-100-60">Useful links</h5>
                                <ul>
                                    <li className="mb-4"><a href="https://propelle.io/how-it-works" target="_blank">How it works</a></li>
                                    <li className="mb-4"><a href="https://propelle.io/about-us" target="_blank">About us</a></li>
                                    <li className="mb-4">
                                        { isLoggedIn 
                                            ? <Link to="/learn/blog">Blog</Link>
                                            : <a href="https://propelle.io/blog" target="_blank">Blog</a>
                                        
                                        }
                                    </li>
                                </ul>
                            </div>
                            <div className="column is-12-touch is-4-desktop mb-5-desktop legal">
                                <h5 className="mb-4 is-uppercase is-family-montserrat has-text-grey-100-60">Legal</h5>
                                <ul>
                                    <li className="mb-4"><a href="https://propelle.io/privacy-policy" target="_blank">Privacy policy</a></li>
                                    <li className="mb-4"><a href="https://propelle.io/community-guidelines/" target="_blank">Community Guidelines</a></li>
                                    <li className="mb-4"><a href="https://propelle.io/terms-and-conditions" target="_blank">Terms and conditions</a></li>
                                </ul>
                            </div>
                            <div className="column is-6-touch is-4-desktop mb-2 mb-5-desktop">
                                <h5 className="mb-4 is-uppercase is-family-montserrat has-text-grey-100-60">Get in touch</h5>
                                <div className="mb-4">
                                    {
                                        isLoggedIn
                                            ? <Link to="/faqs">FAQs</Link>
                                            : <a href="https://propelle.io/faqs" target="_blank">FAQs</a>
                                    }
                                </div>
                                <div className="mb-4">
                                    <a href="mailto:support@propelle.io">support@propelle.io</a>
                                </div>
                                <div className="social-media">
                                    <a className="mr-2" href="https://www.linkedin.com/company/propelle-network/" target="_blank"><img src={linkedin} alt="linkedin logo" /></a>
                                    <a className="mr-2" href="https://www.instagram.com/propelle/?hl=en" target="_blank"><img src={insta} alt="instagram logo" /></a>
                                    <a className="mr-2" href="https://www.youtube.com/@propelle" target="_blank"><img src={youtube} alt="youtube logo" /></a>
                                    <a href="https://www.tiktok.com/@withpropelle" target="_blank"><img src={tiktok} alt="tiktok logo" /></a>
                                </div>
                            </div>
                        </div>
                        <p>Propelle (Network) Ltd (company no.12106229) is registered in England and Wales with registered offices at 36 Scotts Road, Bromley, England, BR1 3QD, United Kingdom. Propelle is an appointed representative of RiskSave Technologies Limited, which is authorised and regulated by the Financial Conduct Authority (FRN: 775330).</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};
