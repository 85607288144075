import { AuthHelper } from "../components/Auth/AuthHelper";

export enum FeatureFlag {
    Equities = "equities",
    Property = "property",
    Membership = "membership"
}

// Fetch and store process.env results
// Note, if you change these, please amend the tests :) 
const FeatureFlags = {
    [FeatureFlag.Property]: process.env["ENABLE_PROPERTY"],
    [FeatureFlag.Equities]: process.env["ENABLE_EQUITIES"],
    [FeatureFlag.Membership]: process.env["ENABLE_MEMBERSHIP"],
};

export const isFeatureEnabled = (feature: FeatureFlag) => {
    var user = AuthHelper.getUserData();

    var betaFlags = [
        FeatureFlag.Equities
    ];

    const featureFlagIsEnabled = FeatureFlags[feature] === "true" || FeatureFlags[feature] === "1";
    const userIsStaff = user.role === "staff";
    const isEnabled = featureFlagIsEnabled || userIsStaff || (user.hasBetaAccess === "true" && betaFlags.includes(feature));
    return isEnabled;
}