import { Container } from "react-bulma-components"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import { Chevron } from "../../../../../../components/Chevron/Chevron"
import { AccountManagementClientFactory } from "../../../../services/AccountManagementClientFactory"
import { useContext, useEffect } from "react"
import { EquityContext } from "../OnboardingContext"
import { loadingStateAggregator, useLoader } from "../../../../../../hoc/withLoader"
import {AccountType, AddAccountToAccountHolder_Request_Root, GetAccountsByAccountHolder_Response_Root, GetOpenableAccountsForAccountHolder_Response_Root } from "../../../../services/generated/AccountManagementClient"
import { AccountSelectionSectionProps } from "./AccountSelection"
import { BackButton } from "../../../../../../components/BackButton/BackButton"
import { Glass } from "../../../../../../components/Layout/Glass/Glass"
import { Main } from "../../../../../../components/Layout/Main/Main"
import { ExternalLink, File } from "react-feather"
// import { ProgressBar } from "../../../components/ProgressBar/ProgressBar"

interface SelectInvestmentAccountProps extends AccountSelectionSectionProps {}

export const SelectInvestmentAccount = ({ moveForward, moveBackward } : SelectInvestmentAccountProps) => {
    const client = AccountManagementClientFactory();
    const [getAvailableAccountsLoaderState, getAvailableAccountsLoader] = useLoader<GetOpenableAccountsForAccountHolder_Response_Root>();
    const [getAccountsLoaderState, getAccountsLoader] = useLoader<GetAccountsByAccountHolder_Response_Root>();
    const aggregatedLoadingState = loadingStateAggregator([getAvailableAccountsLoaderState.loadingState, getAccountsLoaderState.loadingState]);

    const context = useContext(EquityContext);
    const accountHolderId = context?.userData?.accountHolderId;

    useEffect(() => {
        if (accountHolderId) {
            getAvailableAccountsLoader.load(client.getOpenableAccountsForAccountHolder(accountHolderId!));
            getAccountsLoader.load(client.getAccountsByAccountHolder(accountHolderId!));
        }
    }, [context?.userData]);

    const handleSelection = async (accountType : AccountType, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        if (event.target instanceof HTMLAnchorElement) {
            return;
        }

        let accountGlobalId = getAccountsLoaderState.response?.accounts?.find(x => x.accountType === accountType)?.id;

        if (!accountGlobalId) {
            var response = await client.addAccountToAccountHolder(new AddAccountToAccountHolder_Request_Root({
                accountType: accountType,
            }), accountHolderId!);

            accountGlobalId = response.accountId!
            
            // Reload
            getAccountsLoader.load(client.getAccountsByAccountHolder(accountHolderId!));
        }
            
        context?.setAccountData({ accountId: accountGlobalId!, accountType: accountType });

        moveForward();
    }

    let availableAccounts = getAvailableAccountsLoaderState.response?.accounts || [];

    return (
        <>
            <Helmet>
                Choose your account
                </Helmet>
            <Main>
                <Container>
                    <BackButton action={moveBackward} text="Back" className="mb-3" />
                    <div className="columns">
                        <div className="column is-10-widescreen is-8-fullhd">
                            <Glass>
                                <div className="container eligibility">
                                    <h1 className="is-size-2-tablet is-size-4-mobile has-text-navy has-text-centered-mobile mb-3">Choose your account</h1>
                                    <h4 className="title is-size-5 has-text-navy is-family-primary mb-3">Select your investment account</h4>
                                    <div className="block">
                                        {
                                            aggregatedLoadingState === ("Loading" || "NotStarted")
                                                ? getAvailableAccountsLoader.defaultLoaderDisplay()
                                                : aggregatedLoadingState === "Failed"
                                                    ? getAvailableAccountsLoader.defaultErrorDisplay()
                                                    :
                                                        <>
                                                            {
                                                                availableAccounts.includes(AccountType.Isa) &&
                                                                    <div className={"p-5 is-rounded has-background-gradient-peach is-clickable mb-4"} onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleSelection(AccountType.Isa, event)}>
                                                                        <div className="is-flex is-justify-content-space-between mb-2">
                                                                            <p className="has-text-weight-semibold is-size-5 has-text-purple-blue-500">Stocks & Shares ISA</p>
                                                                            <Chevron direction={"Right"}></Chevron>
                                                                        </div>
                                                                        <p className="is-size-6 has-text-purple-blue-500 mb-2 pr-3">Invest up to £20,000 each year in a tax-efficient investment account. Careful - you can’t open or invest in more than one Stocks & Shares ISA per tax year, so if you’ve already done that, you need to opt for the GIA.</p>
                                                                        <Link to={"/learn/article/what-is-an-isa"} target={"_blank"} className="is-flex is-align-items-center is-underlined has-text-purple-blue-500 mb-2">Read More on ISA’s <ExternalLink className="ml-2" size={20}/></Link>
                                                                        <a href="https://propelle.io/isa-key-feature-document" target={"_blank"} className="is-flex is-align-items-center is-underlined has-text-purple-blue-500">View key features document <File className="ml-2" size={20}/></a>
                                                                    </div>
                                                            }
                                                            {
                                                                availableAccounts.includes(AccountType.Gia) &&
                                                                    <div className="p-5 is-rounded has-background-gradient-horizontal-from-purple-blue-300-to-purple-blue-200 is-clickable" onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleSelection(AccountType.Gia, event)}>
                                                                        <div className="is-flex is-justify-content-space-between mb-2">
                                                                            <p className="has-text-weight-semibold is-size-5 has-text-purple-blue-500">General Investment Account</p>
                                                                            <Chevron direction={"Right"}></Chevron>
                                                                        </div>
                                                                        <p className="is-size-6 has-text-purple-blue-500 mb-2 pr-3">Already opened a Stocks and Shares ISA with another provider this year? Maxed out your ISA for the tax year? You can open a GIA with no upper limit on how much you can invest.</p>
                                                                        <Link to={"/learn/article/what-is-a-gia"} target={"_blank"} className="is-flex is-align-items-center is-underlined has-text-purple-blue-500">Read More on GIA’s <ExternalLink className="ml-2" size={20}/></Link>
                                                                        <a href="https://propelle.io/gia-key-feature-document" target="_blank" className="is-flex is-align-items-center is-underlined has-text-purple-blue-500">View key features document <File className="ml-2" size={20}/></a>
                                                                    </div>
                                                            }
                                                        </>
                                        }
                                    </div>
                                    <div className="block">
                                        <p>As with all investing, your capital is at risk. The value of your portfolio can go down as well as up and you may get back less than you invest.</p>
                                        <p>Tax is subject to personal circumstances and subject to change in the future. If you are unsure about how this will affect you speak to a financial adviser.</p>
                                    </div>
                                </div>
                            </Glass>
                        </div>
                    </div>
                </Container>
        </Main>
    </>)
}