import { Helmet } from "react-helmet-async"
import { Main } from "../../components/Layout/Main/Main"
import { Container } from "react-bulma-components"
import { Content } from "../../components/Layout/Content/Content"
import { Section } from "../../components/Layout/Section/Section"
import { useContext } from "react"
import { ScreenSize, WindowContext } from "../../components/App/WindowContext"
import { Link } from "react-router-dom"
import { getUserData } from "../../helpers/auth"
import { Checklist } from "./components/Checklist"

export const WelcomeToPropellePro = () => {
    const { screenSize } = useContext(WindowContext);
    const user = getUserData();

    if (user.membership === "free") {
        return <MembershipPending />
    }

    return (
        <>
            <Helmet>
                <title>Propelle Pro - Welcome</title>
            </Helmet>
            <Main>
                <Container>
                    <Section>
                        <Content>
                            <p className="has-text-purple-blue-500 has-text-weight-semibold is-size-5-desktop">Welcome to Propelle Pro</p>
                        </Content>
                        <Content>
                            <h1 className={`title ${screenSize > ScreenSize.Tablet ? "is-2" : "is-3"} has-text-weight-bold has-text-purple-blue-500`}>We hope you are excited, because we certainly are! ✨</h1>
                        </Content>
                        <Content>
                            <p>We’re delighted to welcome to you as a Propelle Pro Member. 🚀</p>
                        </Content>
                    </Section>
                    <Section>
                        <Content>
                            <p>Here's what your membership has to offer:</p>
                        </Content>
                    </Section>
                    <Section>
                        <Content>
                            <div className="columns">
                                <div className="column">
                                    <Checklist>
                                        {
                                            ProMemberBenefits.slice(0, (ProMemberBenefits.length / 2) + 1).map((benefit, index) => {
                                                return (
                                                    <Checklist.Item key={index}>
                                                        <p className="is-size-6 has-text-weight-semibold">{benefit}</p>
                                                    </Checklist.Item>
                                                )
                                            })
                                        }
                                    </Checklist>
                                </div>
                                <div className="column pt-0-mobile">
                                    <Checklist>
                                        {
                                            ProMemberBenefits.slice((ProMemberBenefits.length / 2) + 1).map((benefit, index) => {
                                                return (
                                                    <Checklist.Item key={index}>
                                                        <p className="is-size-6 has-text-weight-semibold">{benefit}</p>
                                                    </Checklist.Item>
                                                )
                                            })
                                        }
                                    </Checklist>
                                </div>
                            </div>
                        </Content>
                    </Section>
                    <Section>
                        <Content>
                            <p>Whether you have questions, suggestions, or just want to say hello, our virtual doors are always open. Just drop us a note at <a href="mailto:support@propelle.io">support@propelle.io</a>, our friendly support team are on hand to help.</p>
                        </Content>
                    </Section>
                    <Section>
                        <Content>
                            <p>Once again, welcome to the Pro Membership! 🌟</p>
                        </Content>
                    </Section>
                    <Section>
                        <Content>
                            <Link to="/" className="button is-primary is-rounded">Return to dashboard</Link>
                        </Content>
                    </Section>
                </Container>
            </Main>
        </>
    )
}

const MembershipPending = () => {
    const { screenSize } = useContext(WindowContext);

    return (
        <>
            <Helmet>
                <title>Propelle Pro Pending</title>
            </Helmet>
            <Main>
                <Container>
                    <Section>
                        <Content>
                            <p className="has-text-purple-blue-500 has-text-weight-semibold is-size-5-desktop">Membership incoming</p>
                        </Content>
                        <Content>
                            <h1 className={`title ${screenSize > ScreenSize.Tablet ? "is-2" : "is-3"} has-text-weight-bold has-text-purple-blue-500`}>Your membership is being activated</h1>
                        </Content>
                        <Content>
                            <p>Thank you for upgrading to Propelle Pro.</p>
                        </Content>
                        <Content>
                            <p>Sometimes this can take a few minutes on our end. Please click on the button below to check your membership status.</p>
                        </Content>
                    </Section>
                    <Section>
                        <Content>
                            <Link to="/membership/checkout-complete" className="button is-primary is-rounded">Check membership status</Link>
                        </Content>
                    </Section>
                </Container>
            </Main>
        </>
    )
}

const ProMemberBenefits = [
    "Investment workshops",
    "Level 2 courses",
    "Re-watch past webinars on demand",
    "Crowdfunding investment tools",
    "Discount on in person events",
    "Property glossary"
]

