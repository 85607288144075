import { createBrowserRouter, createRoutesFromElements, Link, Navigate, Outlet, Route, RouterProvider, useRouteError } from "react-router-dom";
import { Start } from "./areas/wealth-mapping/pages/Start/Start";
import { Layout as WealthMappingLayout } from "./areas/wealth-mapping/pages/Layout/_Layout";
import { Layout } from "./components/Layout/_Layout";
import { PostLogout } from "./components/Auth/PostLogout";
import { AuthorizedLayout } from "./components/Auth/AuthorizedLayout";
import { Chat } from "./areas/wealth-mapping/pages/Chat/Chat";
import { Result } from "./areas/wealth-mapping/pages/Result/Result";
import { Calculator } from "./areas/wealth-mapping/pages/Calculator/Calculator";
import { Dashboard } from "./areas/home/Dashboard/Dashboard";
import { Learn } from "./areas/learn/pages/Learn/Learn";
import { Feed } from "./areas/connect/pages/Feed/Feed";
import { Tools } from "./areas/invest/pages/Tools/Tools";
import { GetStarted } from "./areas/invest/pages/property/GetStarted/GetStarted";
import { InvestmentChecklist } from "./areas/invest/pages/property/InvestmentChecklist";
import { Layout as InvestmentLayout } from "./areas/invest/components/Layout/_Layout";
import { Layout as ConnectLayout } from "./areas/connect/components/Layout/_Layout";
import { Risk } from "./areas/invest/pages/Tools/Risk/Risk";
import { PageTitle as RiskTolerancePageTitle, RiskTolerance } from "./areas/invest/pages/Tools/Risk/pages/RiskTolerance/RiskTolerance";
import { PageTitle as RiskCapacityPageTitle, RiskCapacity } from "./areas/invest/pages/Tools/Risk/pages/RiskCapacity/RiskCapacity";
import { ChatLayout } from "./components/Layout/_ChatLayout";
import { GoalPlanning } from "./areas/invest/pages/Tools/GoalPlanning/GoalPlanning";
import { Layout as LearnLayout, LayoutWithHeader as LearnLayoutWithHeader, loader } from './areas/learn/components/Layout/Layout';
import { Article, loader as articleLoader } from "./areas/learn/pages/Article/Article";
import { TopicPage, loader as topicLoader } from "./areas/learn/courses/pages/TopicPage/TopicPage";
import { ArticleCategory, loader as articleCategoryLoader } from "./areas/learn/pages/ArticleCategory";
import { ScrollToTop } from "./components/ScrollToTop/ScrollToTop";
import { ContactUs } from "./areas/contact-us/ContactUs";
import { MyAccount } from "./areas/my-account/MyAccount";
import { Faqs } from "./areas/faqs/Faqs";
import { RiskCapacityResultsPage } from "./areas/invest/pages/Tools/Risk/pages/RiskCapacity/RiskCapacityResultsPage";
import { RiskToleranceResultsPage } from "./areas/invest/pages/Tools/Risk/pages/RiskTolerance/RiskToleranceResultsPage";
import { Eligibility } from "./areas/invest/equities/pages/onboarding/create-user-account/Eligibility";
import { UserDetails } from "./areas/invest/equities/pages/onboarding/create-user-account/UserDetails";
import { NotEligible } from "./areas/invest/equities/pages/onboarding/create-user-account/NotEligible";
import { Success } from "./areas/invest/equities/pages/onboarding/create-user-account/Success";
import { CreateAccountLayout } from "./areas/invest/equities/pages/onboarding/create-user-account/CreateAccountLayout";
import { FeatureFlag, isFeatureEnabled } from "./helpers/feature-flags";
import { Property } from "./areas/invest/pages/property/PropertyDashboard/Property";
import { SelfCertification } from "./areas/invest/pages/property/SelfCertification/SelfCertification";
import { Layout as PropertyLayout } from "./areas/invest/pages/property/components/Layout";
import { Main } from "./components/Layout/Main/Main";
import { CourseOverview, loader as courseLoader } from "./areas/learn/courses/pages/CourseOverview";
import { LevelOverview, loader as levelLoader } from "./areas/learn/courses/pages/LevelOverview";
import { AllCourses } from "./areas/learn/courses/pages/AllCourses";
import { PropellePro } from "./areas/membership/PropellePro";
import { WelcomeToPropellePro } from "./areas/membership/WelcomeToPropellePro";
import { CheckoutComplete } from "./areas/membership/CheckoutComplete";
import { Events } from "./areas/connect/pages/Events";
import { CrowdfundingTool } from "./areas/invest/pages/Tools/Crowdfunding/CrowdfundingTool";
import { Cancelled } from "./areas/membership/Cancelled";
import { MembersOnly } from "./components/Membership/MembersOnly";
import { AccountSelection } from "./areas/invest/equities/pages/onboarding/account-setup/AccountSelection";
import { Deposit } from "./areas/invest/equities/pages/onboarding/account-setup/Deposit";
import { BankDetails } from "./areas/invest/equities/pages/onboarding/account-setup/BankDetails";
import { TransferDetails } from "./areas/invest/equities/pages/onboarding/account-setup/TransferDetails/TransferDetails";
import { Dashboard as FundsDashboard } from "./areas/invest/equities/pages/Dashboard/Dashboard";
import { AccountSetupOverview } from "./areas/invest/equities/pages/onboarding/AccountSetupOverview/AccountSetupOverview";
import { Layout as FundsLayout } from "./areas/invest/equities/components/Layout";
import { TopUp } from "./areas/invest/equities/pages/TopUp";
import { Withdraw } from "./areas/invest/equities/pages/Withdraw";
import { ChangeFund } from "./areas/invest/equities/pages/ChangeFund/ChangeFund";

export const RouteConfig = () => {

	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route path="/" element={<RouteLayout />} errorElement={<Layout><Error /></Layout>}>
				<Route index element={<AuthorizedLayout><Layout><Dashboard /></Layout></AuthorizedLayout>} />
				<Route path="/invest/" element={<InvestmentLayout />}>
					{
						isFeatureEnabled(FeatureFlag.Equities) &&
							<Route path="funds" element={<FundsLayout/>}>
								<Route index element={<FundsDashboard />} />
								<Route path="deposit" element={<TopUp />} />
								<Route path="withdraw" element={<Withdraw />} />
								<Route path="change-fund" element={<ChangeFund />} />
								<Route element={<CreateAccountLayout />}>
									<Route path="account-setup" element={<AccountSetupOverview />}/>
									<Route path="eligibility" element={<Eligibility />} />
									<Route path="user-details" element={<UserDetails />} />
									<Route path="not-eligible" element={<NotEligible />}/> // shouldn't be a page
									<Route path="success" element={<Success />}/> // shouldn't be a page
									<Route path="account-selection" element={<AccountSelection/>}/>
									<Route path="make-a-deposit" element = {<Deposit />} />
									<Route path="bank-details" element = {<BankDetails />} />
									<Route path="transfer-details/:depositId" element={<TransferDetails />}/>
								</Route>
							</Route>
					}
					{
						isFeatureEnabled(FeatureFlag.Property) &&
							<Route path="property/" element={<PropertyLayout/>}>
								<Route index element={<Property />} />
								<Route path="self-certification" element={<SelfCertification />} />
								<Route path="get-started" element={<GetStarted />} />
								<Route path="investment-checklist" element={<InvestmentChecklist />} />
							</Route>
					}
                    <Route path="tools">
                        <Route index element={<Tools />} />
                        <Route path="goal-planning" element={<GoalPlanning />} />
                        <Route path="risk" element={<Risk />}/>
						{
							isFeatureEnabled(FeatureFlag.Membership) &&
								<Route path="crowdfunding" element={<MembersOnly><CrowdfundingTool /></MembersOnly>} />
						}
                        <Route path="risk/capacity-results" element={<AuthorizedLayout><RiskCapacityResultsPage /></AuthorizedLayout>} />
                        <Route path="risk/tolerance-results" element={<AuthorizedLayout><RiskToleranceResultsPage /></AuthorizedLayout>} />
                    </Route>
				</Route>    
				{/* <Route path="/invest/property/disclaimer" element={<AuthorizedLayout><Disclaimer /></AuthorizedLayout>} /> */}
				<Route path="/invest/tools/risk/tolerance" element={<AuthorizedLayout><ChatLayout pageTitle={RiskTolerancePageTitle()}><RiskTolerance /></ChatLayout></AuthorizedLayout>} />
				<Route path="/invest/tools/risk/capacity" element={<AuthorizedLayout><ChatLayout pageTitle={RiskCapacityPageTitle()}><RiskCapacity /></ChatLayout></AuthorizedLayout>} />
				<Route path="/learn/" element={<LearnLayoutWithHeader />} loader={loader} id="learn-root">
					<Route index element={<Learn />} />
					<Route path=":categorySlug" element={<ArticleCategory />} loader={articleCategoryLoader} />
					<Route path=":categorySlug/:categoryChildSlug" element={<ArticleCategory />} loader={articleCategoryLoader} />
					<Route path="courses" element={<AllCourses />} />
				</Route>
				{
					isFeatureEnabled(FeatureFlag.Membership) &&
					<Route path = "/membership/" element={<AuthorizedLayout><Layout /></AuthorizedLayout>}>
						<Route index element={<PropellePro />} />
						<Route path="welcome" element={<WelcomeToPropellePro />} />
						<Route path="checkout-complete" element={<CheckoutComplete />}/>
						<Route path="cancelled" element={<Cancelled />} />
					</Route>
				}
				<Route path="/learn/" element={<LearnLayout />}>
					<Route path="article/:articleSlug" element={<Article />} loader={articleLoader} />
					<Route path="courses/:courseSlug" element={<CourseOverview />} loader={courseLoader} />
					<Route path="courses/:courseSlug/:levelSlug" element={<LevelOverview />} loader={levelLoader} />
					<Route path="courses/:courseSlug/:levelSlug/:topicSlug" element={<TopicPage />} loader={topicLoader} />
				</Route>
				{
					isFeatureEnabled(FeatureFlag.Membership) 
						? <Route path="/connect" element={<ConnectLayout/>}>
							<Route index element={<Events />} />
							{/* <Route path="/connect/feed" element={<Feed/>}></Route> */}
							<Route path="/connect/events" element={<Events/>}></Route>
						</Route>
						: <Route path="/connect" element={<AuthorizedLayout><Layout /></AuthorizedLayout>}>
								<Route index element={<Events />} />
							</Route>
				}
				<Route path="/wealth-mapping/" element={<WealthMappingLayout />}>
					<Route index element={<Start />} />
					<Route path="chat" element={<Chat />} />
					<Route path="result" element={<Result />} />
					<Route path="calculator" element={<Calculator />} />
				</Route>
				<Route path="faqs" element={<AuthorizedLayout><Layout><Faqs /></Layout></AuthorizedLayout>} />
				<Route path="contact-us" element={<AuthorizedLayout><Layout><ContactUs /></Layout></AuthorizedLayout>} />
				<Route path="my-account" element={<AuthorizedLayout><Layout><MyAccount /></Layout></AuthorizedLayout>} />
				<Route path="/auth/redirect" element={<AuthorizedLayout><Navigate replace to="/" /></AuthorizedLayout>} />
				<Route path="/auth/logout" element={<AuthorizedLayout><PostLogout /></AuthorizedLayout>} />
				<Route path="*" element={<Layout><NoMatch /></Layout>} /> {/* Catch all */}
			</Route>
		));

	return (
		<RouterProvider router={router} />
	);
}

const RouteLayout = () => {
	return (
		<>
			<ScrollToTop />
			<Outlet />
		</>
	);
}

const NoMatch = () => {
	return (
		<Main>
			<h2>Nothing to see here!</h2>
			<p><Link to="/">Go to the home page</Link></p>
		</Main>
	);
}

const Error = () => {
	let error : any = useRouteError();

	let message = error.status !== undefined ? error.data : "Something went wrong! Please refresh the page.";

  	console.error(error);
	return (
		<Main>
			<h2>{ message }</h2>
			<p><Link to="/">Go to the home page</Link></p>
		</Main>
	);
}
