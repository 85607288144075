import { Columns, Container } from "react-bulma-components";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { UpdateAccountHolder_Request_Address, UpdateAccountHolder_Request_Root } from "../../../../services/generated/AccountManagementClient";
import { AccountManagementClientFactory } from "../../../../services/AccountManagementClientFactory";
import { useContext, useEffect, useState } from "react";
import * as iso from 'iso-3166-1'
import { EquityContext } from "../OnboardingContext";
import dayjs from 'dayjs';
import Gpb from "./gbp.tsx.svg";
import Select from 'react-select'
import { Main } from "../../../../../../components/Layout/Main/Main";
import { Glass } from "../../../../../../components/Layout/Glass/Glass";
import { BackButton } from "../../../../../../components/BackButton/BackButton";
// import { ProgressBar } from "../../components/ProgressBar/ProgressBar";

type FormInputs = {
    accountHolderId: number;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    phoneNumber: string;
    nino: string,
    nationality: string;
    countryOfBirth: string;
    currentAddress: {
        line1: string;
        line2: string;
        line3: string;
        city: string;
        region: string;
        postcode: string;
        country: string;
    },
    employmentStatus: string;
    industry: string;
    sourcesOfWealth: string[];
    annualIncome: number;
};

export const UserDetails = () => {
    const client = AccountManagementClientFactory();
    const userData = useContext(EquityContext)?.userData;
    const reload = useContext(EquityContext)?.reload ?? (() => { });
    const { register, handleSubmit, setValue, control, formState: { isValid, errors } } = useForm<FormInputs>({ mode: "onChange" });
    const countryList = iso.all()
    const [toggleSelectStyle, setToggleSelectStyle] = useState({employmentStatus: true, industry: true, nationality: true, countryOfBirth: true, country: true});
    const [sourcesOfWealth, setSourcesOfWealth] = useState<string[]>([]);

    useEffect(() => {
        if (userData?.firstName) {
            setValue('firstName', userData?.firstName || "", { shouldValidate: true })
            setValue('lastName', userData?.lastName || "")
            let dateOfBirth = new Date(userData?.dateOfBirth || "")
            let formattedDateOfBirth = dateOfBirth.toISOString().split('T')[0]; 
            if (dateOfBirth) {
                setValue('dateOfBirth', formattedDateOfBirth || "")
            }
            setToggleSelectStyle({
                nationality: userData?.nationality ? false : true,
                employmentStatus: userData?.employmentStatus ? false : true,
                industry: userData?.industry ? false : true,
                countryOfBirth: userData?.countryOfBirth ? false : true,
                country: userData?.currentAddress?.countryCode ? false : true
            });
            setValue('nationality', userData?.nationality || "")
            setValue('countryOfBirth', userData?.countryOfBirth || "")
            setValue('phoneNumber', userData?.phoneNumber || "")
            setValue('currentAddress.line1', userData?.currentAddress?.line1 || "")
            setValue('currentAddress.line2', userData?.currentAddress?.line2 || "")
            setValue('currentAddress.line3', userData?.currentAddress?.line3 || "")
            setValue('currentAddress.city', userData?.currentAddress?.city || "")
            setValue('currentAddress.postcode', userData?.currentAddress?.postcode || "")
            setValue('currentAddress.country', userData?.currentAddress?.countryCode || "")
            setValue('employmentStatus', userData?.employmentStatus || "");
            setValue('industry', userData?.industry || "");
            setValue('sourcesOfWealth', userData?.sourcesOfWealth || []);
            setValue('annualIncome', userData?.annualIncome || 0);
            setSourcesOfWealth(userData?.sourcesOfWealth || []);
        }
    }, [userData])

    let navigate = useNavigate();
    const onSubmit = (data: FormInputs) => {
        if (userData) {
            const address = new UpdateAccountHolder_Request_Address({
                line1: data.currentAddress.line1.trim(),
                line2: data.currentAddress.line2.trim(),
                line3: data.currentAddress.line3.trim(),
                city: data.currentAddress.city.trim(),
                postcode: data.currentAddress.postcode.trim(),
                countryCode: data.currentAddress.country.trim()
            });
            const formattedDate = dayjs.utc(data.dateOfBirth, "DD/MM/YYYY", ).toDate();
            const request = new UpdateAccountHolder_Request_Root({
                firstName: data.firstName.trim(),
                lastName: data.lastName.trim(),
                dateOfBirth: formattedDate,
                phoneNumber: data.phoneNumber.trim(),
                currentAddress: address,
                nationality: data.nationality.trim(),
                countryOfBirth: data.countryOfBirth.trim(),
                employmentStatus: data.employmentStatus.trim(),
                industry: data.industry.trim(),
                sourcesOfWealth: data.sourcesOfWealth,
                annualIncome: data.annualIncome
            });
            client.updateAccountHolder(request, userData.accountHolderId)
                .then(() => {
                    reload();
                    navigate(`/invest/funds/success`)
                }
                )
                .catch(() => {
                    //TODO navigate to error page
                })
        }
        else {
            //TODO navigate to error page
        }
    };

    return (
        <Main>
            <Helmet>
                Create account
            </Helmet> 
                <Container>
                    <BackButton to={"/invest/funds/eligibility"} text="Back" className="mb-3"/>
                    <div className="columns">
                        <div className="column is-10-widescreen is-8-fullhd">
                            <Glass opacity={100}>
                                <h1 className="pb-4 is-size-2-tablet is-size-4-mobile has-text-navy has-text-centered-mobile">Add your details</h1>
                                {/* <ProgressBar value={60} /> */}
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Columns>
                                        <Columns.Column className="is-half">
                                            <div className="input-box">
                                                <label htmlFor="firstName" className="has-text-black">First name*</label>
                                                <input {...register('firstName', {
                                                    required: "Please enter your first name",
                                                    pattern: {
                                                        value: /^[a-zA-Z'\-\s]{2,}$/,
                                                        message: "Please enter a valid name without any special characters or numbers."
                                                    }
                                                })}
                                                    className="input" type="text" placeholder="First name*" id="firstName"/>
                                                {errors.firstName && <p className="is-size-6">{errors.firstName.message}</p>}
                                            </div>
                                        </Columns.Column>
                                        <Columns.Column className="is-half">
                                            <div className="input-box">
                                                <label className="has-text-black">Last name*</label>
                                                <input {...register('lastName', {
                                                    required: "Please enter your surname",
                                                    pattern: {
                                                        value: /^[a-zA-Z'\-\s]{2,}$/,
                                                        message: "Please enter a valid name without any special characters or numbers."
                                                    }
                                                })}
                                                    className="input" type="text" placeholder="Last name*" id="lastName" />
                                                {errors.lastName && <p className="is-size-6">{errors.lastName.message}</p>}
                                            </div>
                                        </Columns.Column>
                                    </Columns>
                                    <Columns>
                                        <Columns.Column className="is-half">
                                            <div className="input-box">
                                                <label htmlFor="dateOfBirth" className="has-text-black">Date of birth*</label>
                                                <input {...register('dateOfBirth', {
                                                    required: "Please enter your date of birth",
                                                    validate: (value) => {
                                                        const today = dayjs();
                                                        const birthDate = dayjs(value, "DD/MM/YYYY");
                                                        let age = today.year() - birthDate.year();
                                                        if (today.month() < birthDate.month() || (today.month() === birthDate.month() && today.date() < birthDate.date())) {
                                                            age--;
                                                        }
                                                        return age >= 18 || "You must be at least 18 years old";
                                                    },
                                                })}
                                                    className="input" type="date" placeholder="DD/MM/YYYY*" id="dateOfBirth" />
                                                {errors.dateOfBirth && <p className="is-size-6">{errors.dateOfBirth.message}</p>}
                                            </div>
                                        </Columns.Column>

                                        <Columns.Column className="is-half">
                                            <div className="input-box">
                                                <label htmlFor="countryOfBirth" className="has-text-black">Country of Birth*</label>
                                                <div className="select is-fullwidth">
                                                    <Controller
                                                        name="countryOfBirth"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{ required: "Please select your country of birth" }}
                                                        render={({ field }) =>
                                                            <select {...field}
                                                                id="countryOfBirth"
                                                                className={`input ${toggleSelectStyle.countryOfBirth ? "select-placeholder-on" : "select-placeholder-off"}`}
                                                                onChange={(e) => { field.onChange(e); setToggleSelectStyle({...toggleSelectStyle, countryOfBirth: false})}}>
                                                                <option value="" disabled>Country of Birth*</option>
                                                                {countryList.map((country) => {
                                                                    return <option className="has-text-black" key={country.numeric} value={country.alpha2}>{country.country}</option>
                                                                })}
                                                            </select>
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Columns.Column>
                                    </Columns>
                                    <Columns>
                                        <Columns.Column className="is-half">
                                            <div className="input-box">
                                                <label htmlFor="phoneNumber" className="has-text-black">Phone number*</label>
                                                <input {...register("phoneNumber", {
                                                    required: "Phone number is required",
                                                    pattern: {
                                                        value: /^[0-9]{10,14}$/,
                                                        message:
                                                            "Please enter 10 to 14 digits without spaces or special characters.",
                                                    },
                                                })} className="input" type="tel" placeholder="Phone number*" id="phoneNumber" />
                                                {errors.phoneNumber && <p className="is-size-6">{errors.phoneNumber.message}</p>}
                                            </div>
                                        </Columns.Column>
                                        <Columns.Column className="is-half">
                                            <div className="input-box">
                                                <label htmlFor="nationality" className="has-text-black">Nationality*</label>
                                                <div className="select is-fullwidth">
                                                    <Controller
                                                        name="nationality"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{ required: "Please select your nationality" }}
                                                        render={({ field }) =>
                                                            <select {...field}
                                                                id="nationality"
                                                                className={`input ${toggleSelectStyle.nationality ? "select-placeholder-on" : "select-placeholder-off"}`}
                                                                onChange={(e) => { field.onChange(e); setToggleSelectStyle({...toggleSelectStyle, nationality: false})}}>
                                                                <option value="" disabled>Nationality*</option>
                                                                {countryList.map((country) => {
                                                                    return <option className="has-text-black" key={country.numeric} value={country.alpha2}>{country.country}</option>
                                                                })}
                                                            </select>
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Columns.Column>
                                    </Columns>
                                    <Columns>
                                        <Columns.Column className="is-half">
                                            <div className="input-box">
                                                <label htmlFor="currentAddress.line1" className="has-text-black">Address line 1*</label>
                                                <input {...register('currentAddress.line1', {
                                                    required: 'Address line 1 is required.',
                                                    pattern: {
                                                        value: /^[a-zA-Z0-9\s.,'-]*$/,
                                                        message: "Please enter a valid address."
                                                    }
                                                })} className="input" type="text" placeholder="Address line 1*" id="currentAddress.line1" />
                                                {errors.currentAddress?.line1 && <p className="is-size-6">{errors.currentAddress.line1.message}</p>}
                                            </div>
                                        </Columns.Column>
                                        <Columns.Column className="is-half">
                                            <div className="input-box">
                                                <label htmlFor="currentAddress.line2" className="has-text-black">Address line 2</label>
                                                <input {...register('currentAddress.line2', {
                                                    required: false,
                                                    pattern: {
                                                        value: /^[a-zA-Z0-9\s.,'-]*$/,
                                                        message: "Please enter a valid address."
                                                    }
                                                })} className="input" type="text" placeholder="Address line 2" id="currentAddress.line2" />
                                                {errors.currentAddress?.line2 && <p className="is-size-6">{errors.currentAddress.line2.message}</p>}
                                            </div>
                                        </Columns.Column>
                                    </Columns>
                                    <Columns>
                                        <Columns.Column className="is-half">
                                            <div className="input-box">
                                                <label htmlFor="currentAddress.line3" className="has-text-black">Address line 3</label>
                                                <input {...register('currentAddress.line3', {
                                                    required: false,
                                                    pattern: {
                                                        value: /^[a-zA-Z0-9\s.,'-]*$/,
                                                        message: "Please enter a valid address."
                                                    }
                                                })} className="input" type="text" placeholder="Address line 3" id="currentAddress.line3" />
                                                {errors.currentAddress?.line3 && <p className="is-size-6">{errors.currentAddress.line3.message}</p>}
                                            </div>
                                        </Columns.Column>
                                        <Columns.Column className="is-half">
                                            <div className="input-box">
                                                <label htmlFor="city" className="has-text-black">Town/City*</label>
                                                <input {...register('currentAddress.city', { required: true })} className="input" type="text" placeholder="Town/City" id="city" />
                                            </div>
                                        </Columns.Column>
                                    </Columns>
                                    <Columns>
                                        <Columns.Column className="is-half">
                                            <div className="input-box">
                                                <label htmlFor="postcode" className="has-text-black">Postcode*</label>
                                                <input {...register('currentAddress.postcode', {
                                                    required: 'Postcode is required.',
                                                    pattern: {
                                                        value: /^[A-Z]{1,2}[0-9R][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$/,
                                                        message: "Please enter a valid UK postcode."
                                                    }
                                                })} className="input" type="text" placeholder="Postcode" id="postcode" />
                                                {errors.currentAddress?.postcode && <p className="is-size-6">{errors.currentAddress?.postcode.message}</p>}
                                            </div>
                                        </Columns.Column>
                                        <Columns.Column className="is-half">
                                            <div className="input-box">
                                                <label htmlFor="currentAddress.country" className="has-text-black">Country of Residence*</label>
                                                <div className="select is-fullwidth">
                                                    <Controller
                                                        name="currentAddress.country"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{
                                                                required: "Please select your country of residence",
                                                                pattern: {
                                                                    value: /^GB$/,
                                                                    message: "We can only accept customers with an address in the UK"
                                                                }
                                                            }}
                                                        render={({ field }) =>
                                                            <select {...field}
                                                                id="currentAddress.country"
                                                                className={`input ${toggleSelectStyle.country ? "select-placeholder-on" : "select-placeholder-off"}`}
                                                                onChange={(e) => { field.onChange(e); setToggleSelectStyle({...toggleSelectStyle, country: false})}}>
                                                                <option value="" disabled>Country of Residence*</option>
                                                                {countryList.map((country) => {
                                                                    return <option className="has-text-black" key={country.numeric} value={country.alpha2}>{country.country}</option>
                                                                })}
                                                            </select>
                                                        }
                                                    />
                                                </div>
                                                {errors.currentAddress?.country && <p className="is-size-6">{errors.currentAddress.country.message}</p>}
                                            </div>
                                        </Columns.Column>
                                    </Columns>
                                    <Columns>
                                        <Columns.Column className="is-half">
                                            <div className="input-box">
                                                <label htmlFor="employmentStatus" className="has-text-black">Employment Status*</label>
                                                <div className="select is-fullwidth">
                                                    <Controller
                                                        name="employmentStatus"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{ required: "Please select your employment status" }}
                                                        render={({ field }) =>
                                                            <select {...field}
                                                                id="employmentStatus"
                                                                className={`input ${toggleSelectStyle.employmentStatus ? "select-placeholder-on" : "select-placeholder-off"}`}
                                                                onChange={(e) => { field.onChange(e); setToggleSelectStyle({...toggleSelectStyle, employmentStatus: false}) }}>
                                                                <option value="" disabled>Please select...</option>
                                                                <option className="has-text-black" key={1} value="FullTime">Full Time</option>
                                                                <option className="has-text-black" key={2} value="PartTime">Part Time</option>
                                                                <option className="has-text-black" key={3} value="SelfEmployed">Self Employed</option>
                                                                <option className="has-text-black" key={4} value="Unemployed">Unemployed</option>
                                                                <option className="has-text-black" key={5} value="Retired">Retired</option>
                                                                <option className="has-text-black" key={6} value="Student">Student</option>
                                                                <option className="has-text-black" key={7} value="NotWorkingDueToIllnessOrDisability">Not Working Due To Illness Or Disability</option>
                                                                <option className="has-text-black" key={8} value="CarerOrParent">Carer Or Parent</option>
                                                            </select>
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Columns.Column>
                                        <Columns.Column className="is-half">
                                            <div className="input-box">
                                                <label htmlFor="industry" className="has-text-black">Industry*</label>
                                                <div className="select is-fullwidth">
                                                    <Controller
                                                        name="industry"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{ required: "Please select your industry" }}
                                                        render={({ field }) =>
                                                            <select {...field}
                                                                id="industry"
                                                                className={`input ${toggleSelectStyle.industry ? "select-placeholder-on" : "select-placeholder-off"}`}
                                                                onChange={(e) => { field.onChange(e); setToggleSelectStyle({...toggleSelectStyle, industry: false}) }}>
                                                                <option value="" disabled>Please select...</option>
                                                                <option className="has-text-black" key={1} value="AgricultureForestryAndFishing">Agriculture Forestry And Fishing</option>
                                                                <option className="has-text-black" key={2} value="ArtsSportAndCreative">Arts Sport And Creative</option>
                                                                <option className="has-text-black" key={3} value="ConstructionAndEngineering">Construction And Engineering</option>
                                                                <option className="has-text-black" key={4} value="CryptoIndustryAndCryptocurrencies">Crypto Industry And Cryptocurrencies</option>
                                                                <option className="has-text-black" key={5} value="CulturalArtefacts">Cultural Artefacts</option>
                                                                <option className="has-text-black" key={6} value="DatingOrAdultIndustry">Dating Or Adult Industry</option>
                                                                <option className="has-text-black" key={7} value="Education">Education</option>
                                                                <option className="has-text-black" key={8} value="EnergyAndWaterSupply">Energy And Water Supply</option>
                                                                <option className="has-text-black" key={9} value="FinanceAndInsurance">Finance And Insurance</option>
                                                                <option className="has-text-black" key={10} value="GamblingOrIGamingIndustry">Gambling Or IGaming Industry</option>
                                                                <option className="has-text-black" key={11} value="GovernmentPublicServiceAndDefence">Government Public Service And Defence</option>
                                                                <option className="has-text-black" key={12} value="HealthAndSocialWork">Health And Social Work</option>
                                                                <option className="has-text-black" key={13} value="Hospitality">Hospitality</option>
                                                                <option className="has-text-black" key={14} value="ImportExport">Import Export</option>
                                                                <option className="has-text-black" key={15} value="InformationAndCommunication">Information And Communication</option>
                                                                <option className="has-text-black" key={16} value="LegalAndRegulatory">Legal And Regulatory</option>
                                                                <option className="has-text-black" key={17} value="Manufacturing">Manufacturing</option>
                                                                <option className="has-text-black" key={18} value="Mining">Mining</option>
                                                                <option className="has-text-black" key={19} value="MoneyTransfer">Money Transfer</option>
                                                                <option className="has-text-black" key={20} value="MotorTrades">Motor Trades</option>
                                                                <option className="has-text-black" key={21} value="PreciousMetals">Precious Metals</option>
                                                                <option className="has-text-black" key={22} value="Property">Property</option>
                                                                <option className="has-text-black" key={23} value="Retail">Retail</option>
                                                                <option className="has-text-black" key={24} value="ScientificAndTechnical">Scientific And Technical</option>
                                                                <option className="has-text-black" key={25} value="Tobacco">Tobacco</option>
                                                                <option className="has-text-black" key={26} value="TransportAndStorage">Transport And Storage</option>
                                                                <option className="has-text-black" key={27} value="Wholesale">Wholesale</option>
                                                            </select>
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Columns.Column>
                                    </Columns>
                                    <Columns>
                                        <Columns.Column className="is-half">
                                            <label htmlFor="sourcesOfWealth" className="has-text-black">Sources Of Wealth*
                                                {/* <span className="is-block is-size-smaller has-text-grey-300"><i>Please select all that apply</i></span> */}
                                            </label>                  
                                            <Select isMulti={true} className="input-box multi-select" options={[
                                                { value: 'Salary', label: 'Salary' },
                                                { value: 'Inheritance', label: 'Inheritance' },
                                                { value: 'Gift', label: 'Gift' },
                                                { value: 'BusinessOwnership', label: 'Business Ownership' },
                                                { value: 'SaleOfProperty', label: 'Sale Of Property' },
                                                { value: 'GamblingOrLottery', label: 'Gambling Or Lottery' },
                                                { value: 'PersonalSavings', label: 'Personal Savings' },
                                                { value: 'LegalSettlement', label: 'Legal Settlement' },
                                                { value: 'SaleOfInvestments', label: 'Sale Of Investments' },
                                                { value: 'Dividend', label: 'Dividend' },
                                            ]} placeholder={"Please select all that apply..."}
                                                onChange={(val) => { setValue('sourcesOfWealth', val.map(x => x.value)); setSourcesOfWealth(val.map(x => x.value)) }}
                                                value={sourcesOfWealth.map(x => ({ value: x, label: x.replace(/([A-Z])/g, ' $1').trim() }))}
                                            />
                                        </Columns.Column>
                                        <Columns.Column className="is-half">
                                            <div className="is-fullheight is-flex is-align-items-end">
                                                <div className="input-box is-fullwidth">
                                                    <div className="field">
                                                        <label htmlFor="annualIncome" className="has-text-black">Annual Income*</label>
                                                        <p className="control has-icons-left has-icons-right">
                                                            <input {...register('annualIncome', { required: true })} className="input" type="text" placeholder="Annual Income" />
                                                            <span className="icon is-small is-left">
                                                                <Gpb/>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Columns.Column>
                                    </Columns>
                                    <button type="submit" className={`button is-primary is-rounded`} disabled={!isValid}>Next</button>
                                </form>
                            </Glass>
                        </div>
                    </div>
                </Container>
        </Main>
    );
};