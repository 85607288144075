interface IContentTitleProps {
    text: string,
    size?: "Default" | "Small"
}

export const ContentTitle = ({ text, size = "Default" } : IContentTitleProps) => {

    let sizeClasses = "";
    switch (size) {
        case "Small":
            sizeClasses = "is-size-4";
            break;
        default:
            sizeClasses = "is-size-4 is-size-3-desktop";
            break;
    }

    return (
        <h3 className={`section-title title mr-5 has-text-purple-blue-500 ${sizeClasses}`}>
            {text}
        </h3>                 
    );
}