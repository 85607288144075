import { ComponentType, ReactElement } from "react";
import { Header } from "../components/Header/Header";

export const withHeader = <P extends object>(
    WrappedComponent: ComponentType<P>,
  ): ((props: P) => ReactElement) => (props: P): ReactElement => {
        return (
            <WrappedComponent { ...props as P }>
                <Header />
            </WrappedComponent>
        );
  };