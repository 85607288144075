import { LoaderData } from "../../../components/Layout/Layout";
import { useRouteLoaderData } from "react-router-dom";
import { OverlayCardColor, OverlayCardProps } from "../../../../../components/Cards/OverlayCard";
import { CarouselWithPartsOverlay } from "../../../../../components/CarouselWithPartsOverlay/CarouselWithPartsOverlay";
import { ImageSize, getImageUrl } from "../../../../../helpers/cms";

interface CategoryCarouselProps {
    title: string;
    categorySlug: string;
    color?: OverlayCardColor;
    countType?: string;
}

export const SubCategoryCarousel = ({ title, categorySlug, color, countType } : CategoryCarouselProps) => {
    let { categories } = useRouteLoaderData("learn-root") as LoaderData;

    let category : any = categories.find(x => x.slug === categorySlug);

    if (category === undefined || category.children === undefined || category.children.length === 0) {
        return <></>;
    }

    let contents : OverlayCardProps[] = category.children.map((x : any) : OverlayCardProps & { url: string } => {
        return {
            heading: x.acf.heading.length > 0 ? x.acf.heading : x.name,
            url: `/learn/${category.slug}/${x.slug}`,
            imageUrl: getImageUrl(x.acf.thumbnail, ImageSize.Medium),
            count: x.count,
            countType: countType || 'parts'
        }
    });

    return (
        <CarouselWithPartsOverlay title={title} items={contents} color={color} linkTo={`/learn/${categorySlug}`}/>
    );

}