import * as Script from "../../../../../../../components/Chat/Script";

export const RiskCapacityScript = () => {
    let script = new Script.Script;
    script.paths.push({
        id: 1,
        botMessages: [
            "Let's talk about you and your finances.",
            "What is your investment time horizon? (ie how long can you invest money for)"
        ],
        answer: {
            name: "investment-time-horizon",
            type: "MultipleChoiceRadio",
            options: [
                {
                    label: "Less than 3 years",
                    value: "1",
                    pathId: 2
                },
                {
                    label: "3-5 years",
                    value: "3",
                    pathId: 2
                },
                {
                    label: "5-10 years",
                    value: "4",
                    pathId: 2
                },
                {
                    label: "More than 10 years",
                    value: "5",
                    pathId: 2
                }
            ]
        } as Script.Radio
    });
    script.paths.push({
        id: 2,
        botMessages: [
            "What is your age range?"
        ],
        answer: {
            name: "age",
            type: "MultipleChoiceRadio",
            options: [
                {
                    label: "18-25",
                    value: "5",
                    pathId: 3
                },
                {
                    label: "26-35",
                    value: "4",
                    pathId: 3
                },
                {
                    label: "36-45",
                    value: "3",
                    pathId: 3
                },
                {
                    label: "46-55",
                    value: "2",
                    pathId: 3
                },
                {
                    label: "56-65",
                    value: "2",
                    pathId: 3
                },
                {
                    label: "Over 65",
                    value: "1",
                    pathId: 3
                }
            ]
        } as Script.Radio
    })
    script.paths.push({
        id: 3,
        botMessages: [
            "What is your annual salary?"
        ],
        answer: {
            name: "salary",
            type: "MultipleChoiceRadio",
            options: [
                {
                    label: "Under £30,000",
                    value: "1",
                    pathId: 4
                },
                {
                    label: "£30,000-£50,000",
                    value: "2",
                    pathId: 4
                },
                {
                    label: "£50,000-£75,000",
                    value: "3",
                    pathId: 4
                },
                {
                    label: "£75,000-£100,000",
                    value: "4",
                    pathId: 4
                },
                {
                    label: "Over £100,000",
                    value: "5",
                    pathId: 4
                },
                {
                    label: "I don't currently earn money",
                    value: "1",
                    pathId: 4
                }
            ]
        } as Script.Radio
    })

    script.paths.push({
        id: 4,
        botMessages: [
            "How much savings or cash in the bank do you have?"
        ],
        answer: {
            name: "savings",
            type: "MultipleChoiceRadio",
            options: [
                {
                    label: "Under £5,000",
                    value: "1",
                    pathId: 5
                },
                {
                    label: "£5,000-£10,000",
                    value: "2",
                    pathId: 5
                },
                {
                    label: "£10,000-£25,000",
                    value: "3",
                    pathId: 5
                },
                {
                    label: "£25,000-£50,000",
                    value: "4",
                    pathId: 5
                },
                {
                    label: "Over £50,000",
                    value: "5",
                    pathId: 5
                },
                {
                    label: "I don't currently have any savings",
                    value: "1",
                    pathId: 5
                }
            ]
        } as Script.Radio
    });
    
    script.paths.push({
        id: 5,
        botMessages: [
            "What's your approximate net worth (your total assets - your total liabilities)?"
        ],
        answer: {
            name: "worth",
            type: "MultipleChoiceRadio",
            options: [
                {
                    label: "Under £50,000",
                    value: "1",
                    pathId: 6
                },
                {
                    label: "£50,000-£100,000",
                    value: "2",
                    pathId: 6
                },
                {
                    label: "£100,000-£500,000",
                    value: "3",
                    pathId: 6
                },
                {
                    label: "£500,000-£1,000,000",
                    value: "4",
                    pathId: 6
                },
                {
                    label: "Over £1,000,000",
                    value: "4",
                    pathId: 6
                }
            ]
        } as Script.Radio
    });
    script.paths.push({
        id: 6,
        botMessages: [
            "How would you describe your current investment knowledge?"
        ],
        answer: {
            name: "knowledge",
            type: "MultipleChoiceRadio",
            options: [
                {
                    label: "Low - I know very little about investing",
                    value: "1",
                    pathId: 7
                },
                {
                    label: "Limited - I know a little about investing",
                    value: "2",
                    pathId: 7
                },
                {
                    label: "Moderate - I know a fair bit, but there's more I can learn",
                    value: "3",
                    pathId: 7
                },
                {
                    label: "Substantial - I'm a fairly confident investor",
                    value: "4",
                    pathId: 7
                },
                {
                    label: "High - I am an active and confident investor",
                    value: "5",
                    pathId: 7
                }
            ]
        } as Script.Radio
    });
    script.paths.push({
        id: 7,
        botMessages: [
            "How would you describe your current savings and investments?"
        ],
        answer: {
            name: "current-investments",
            type: "MultipleChoiceRadio",
            options: [
                {
                    label: "I have no savings or investments",
                    value: "1",
                    pathId: 100
                },
                {
                    label: "I have limited savings and/or conservative investments",
                    value: "2",
                    pathId: 100
                },
                {
                    label: "I have moderate savings and/or diversified investments",
                    value: "3",
                    pathId: 100
                },
                {
                    label: "I have substantial savings and/or moderately aggressive investments",
                    value: "4",
                    pathId: 100
                },
                {
                    label: "I have significant savings and/or highly aggressive investments",
                    value: "5",
                    pathId: 100
                }
            ]
        } as Script.Radio
    });
    script.paths.push({
        id: 100,
        botMessages: [
            "Thanks for your answers. Let's look at your overall risk capacity. "
        ]
    });

    return script;
}