import * as Script from "../../../../../../../components/Chat/Script";

export const RiskToleranceScript = () => {
    let script = new Script.Script;
    script.paths.push({
        id: 1,
        botMessages: [
            "Let's talk about your disposable income. That's money that is left once you've paid your rent or mortgage, your bills and all your monthly outgoings like food, shopping, going out, transport or gifts.",
            "Imagine you had, at some point, invested £25,000 of your disposable income in an investment account. Several months later you check and it has fallen to £20,000 - how would you feel?"
        ],
        answer: {
            name: "disposable-income-drop-1",
            type: "MultipleChoiceRadio",
            options: [
                {
                    label: "Absolutely fine",
                    value: "5",
                    pathId: 2
                },
                {
                    label: "Fine",
                    value: "4",
                    pathId: 2
                },
                {
                    label: "OK",
                    value: "3",
                    pathId: 2
                },
                {
                    label: "A bit worried",
                    value: "2",
                    pathId: 2
                },
                {
                    label: "Freaked out or panicked",
                    value: "1",
                    pathId: 2
                }
            ]
        } as Script.Radio
    });
    script.paths.push({
        id: 2,
        botMessages: [
            "Does the drop of £5,000 actually change your day to day life in any way?"
        ],
        answer: {
            name: "disposable-income-drop-1-livelihood",
            type: "MultipleChoiceRadio",
            options: [
                {
                    label: "Yes",
                    value: "1",
                    pathId: 3
                },
                {
                    label: "Not really",
                    value: "3",
                    pathId: 3
                },
                {
                    label: "No",
                    value: "5",
                    pathId: 3
                },
            ]
        } as Script.Radio
    })
    script.paths.push({
        id: 3,
        botMessages: [
            "Imagine you had, at some point, invested £25,000 of your disposable income in an investment account. Several months later you check and it has fallen to £12,500 - how would you feel?"
        ],
        answer: {
            name: "disposable-income-drop-2",
            type: "MultipleChoiceRadio",
            options: [
                {
                    label: "Absolutely fine",
                    value: "5",
                    pathId: 4
                },
                {
                    label: "Fine",
                    value: "4",
                    pathId: 4
                },
                {
                    label: "OK",
                    value: "3",
                    pathId: 4
                },
                {
                    label: "A bit worried",
                    value: "2",
                    pathId: 4
                },
                {
                    label: "Freaked out or panicked",
                    value: "1",
                    pathId: 4
                }
            ]
        } as Script.Radio
    });
    script.paths.push({
        id: 4,
        botMessages: [
            "Does the drop of £12,500 actually change your day to day life in any way?"
        ],
        answer: {
            name: "disposable-income-drop-2-livelihood",
            type: "MultipleChoiceRadio",
            options: [
                {
                    label: "Yes",
                    value: "1",
                    pathId: 5
                },
                {
                    label: "Not really",
                    value: "3",
                    pathId: 5
                },
                {
                    label: "No",
                    value: "5",
                    pathId: 5
                },
            ]
        } as Script.Radio
    })
    script.paths.push({
        id: 5,
        botMessages: [
            "Imagine you had, at some point, invested £25,000 of your disposable income in an investment account. Several months later you check and it has fallen to £5,000 - how would you feel?"
        ],
        answer: {
            name: "disposable-income-drop-3",
            type: "MultipleChoiceRadio",
            options: [
                {
                    label: "Absolutely fine",
                    value: "5",
                    pathId: 6
                },
                {
                    label: "Fine",
                    value: "4",
                    pathId: 6
                },
                {
                    label: "OK",
                    value: "3",
                    pathId: 6
                },
                {
                    label: "A bit worried",
                    value: "2",
                    pathId: 6
                },
                {
                    label: "Freaked out or panicked",
                    value: "1",
                    pathId: 6
                }
            ]
        } as Script.Radio
    });
    script.paths.push({
        id: 6,
        botMessages: [
            "Does the drop of £20,000 actually change your day to day life in any way?"
        ],
        answer: {
            name: "disposable-income-drop-3-livelihood",
            type: "MultipleChoiceRadio",
            options: [
                {
                    label: "Yes",
                    value: "1",
                    pathId: 7
                },
                {
                    label: "Not really",
                    value: "3",
                    pathId: 7
                },
                {
                    label: "No",
                    value: "5",
                    pathId: 7
                },
            ]
        } as Script.Radio
    })
    script.paths.push({
        id: 7,
        botMessages: [
            "How would you classify your risk tolerance?"
        ],
        answer: {
            name: "self-classification",
            type: "MultipleChoiceRadio",
            options: [
                {
                    label: "Low",
                    value: "1",
                    pathId: 100
                },
                {
                    label: "Medium",
                    value: "3",
                    pathId: 100
                },
                {
                    label: "High",
                    value: "5",
                    pathId: 100
                },
            ]
        } as Script.Radio
    })
    script.paths.push({
        id: 100,
        botMessages: [
            "Let's look at your overall risk tolerance."
        ]
    });

    return script;
}