import { forwardRef } from "react";
import { CssClasses } from "../../Helpers/CssClasses"

interface IGlassProps {
    opacity?: 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100,
    className?: string,
    children?: any,
    equalPadding?: boolean,
}

export const Glass = forwardRef<HTMLDivElement, IGlassProps>(({ opacity = 100, children, className, equalPadding = false } : IGlassProps, ref) => {

    let css = new CssClasses([`glass_gga2r5 px-6-desktop py-${equalPadding ? "6" : "7" }-desktop has-background-grey-100-${opacity}-desktop is-rounded-2`]);

    if (className) {
        css.add(className);
    }

    return (
        <div ref={ref} className={css.aggregated()}>
            { children }
        </div>
    )
});