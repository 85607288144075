import { useContext, useEffect, useRef } from "react";
import { ScreenSize, WindowContext } from "../App/WindowContext";

export const ScrollIntoView = ({ 
    disabled = false, 
    children = undefined as any, 
    dependency = undefined as any, // Only scroll when this value changes 
    upToSize = ScreenSize.FullHD // Only scroll if the screen size is less than this
}) => {
        
    const ref = useRef<HTMLDivElement>(null);
    const windowContext = useContext(WindowContext);

    const useEffectDeps = [ref.current];
    if (dependency) {
        useEffectDeps.push(dependency);
    }

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView();
        }
    }, useEffectDeps)

    if (disabled || windowContext.screenSize > upToSize) {
        return <>{children}</>
    }

    return <div ref={ref}>
        { children }
    </div>
}