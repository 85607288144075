import { useEffect } from "react";
import { useLoader } from "../../../../../../../hoc/withLoader";
import { TradingClientFactory } from "../../../../../services/TradingClientFactory";
import { GetDeposit_Response } from "../../../../../services/generated/TradingClient";
import dayjs from "dayjs";
import { InvestmentsDashboard } from "../../../../../components/InvestmentsDashboard/InvestmentsDashboard";
import { formatCurrency } from "../../../../../../../helpers/currency";
import { GetPortfolioTransactions_TransactionType } from "../../../../../services/generated/PortfoliosClient";

interface ContributionOverlayDetailsProps {
    transaction: {
        id: string;
        dateRequested?: Date;
        amount?: number;
        type?: string;
        status?: string;
    };
    showOrderDetails: (orderId: string, type: GetPortfolioTransactions_TransactionType) => void;
}

export const ContributionOverlayDetails = ( {transaction, showOrderDetails} : ContributionOverlayDetailsProps) => {

    const client = TradingClientFactory();
    const [loaderState, loader] = useLoader<GetDeposit_Response>();

    useEffect(() => {
        loader.load(client.getDeposit(transaction.id));
    }, []);

    const onOrderDetailsClick = (orderId: string) => {
        showOrderDetails(orderId, GetPortfolioTransactions_TransactionType.Deposit);
    }

    const getOrderReceipts = () => {
        if (loaderState.response?.investedOn === undefined) {
            return "Pending";
        }

        return <>
            <span onClick={() => onOrderDetailsClick(loaderState.response?.buyIds![0]!)} className="is-underlined is-link is-clickable has-text-purple-blue-400">Buy Order - Contract Note</span>
        </>
    }

    const inner = () => {
        return <>
            <InvestmentsDashboard.Overlay.OverlaySection>
                <p className="has-text-purple-blue-500 has-text-weight-semibold" style={{fontSize: "20px"}}>Contribution details</p>
            </InvestmentsDashboard.Overlay.OverlaySection>
            <InvestmentsDashboard.Overlay.OverlaySection>
                <p className="has-text-purple-blue-500 has-text-weight-medium">Date requested</p>
                <p>{dayjs(transaction.dateRequested).format("DD/MM/YYYY")}</p>
            </InvestmentsDashboard.Overlay.OverlaySection>
            <InvestmentsDashboard.Overlay.OverlaySection>
                <p className="has-text-purple-blue-500 has-text-weight-medium">Date money arrived in WK account</p>
                <p>{loaderState.response?.settledOn !== undefined ? dayjs(loaderState.response?.settledOn).format("DD/MM/YYYY") : "Pending"}</p>
            </InvestmentsDashboard.Overlay.OverlaySection>
            <InvestmentsDashboard.Overlay.OverlaySection>
                <p className="has-text-purple-blue-500 has-text-weight-medium">Date invested</p>
                <p>{loaderState.response?.investedOn !== undefined ? dayjs(loaderState.response?.investedOn).format("DD/MM/YYYY") : "Pending"}</p>
            </InvestmentsDashboard.Overlay.OverlaySection>
            <InvestmentsDashboard.Overlay.OverlaySection>
                <p className="has-text-purple-blue-500 has-text-weight-medium">Transaction receipt</p>
                <p>{ getOrderReceipts() }</p>
            </InvestmentsDashboard.Overlay.OverlaySection>
            <InvestmentsDashboard.Overlay.OverlaySection background="Purple" className="py-4 is-rounded-bottom">
                <div className="columns is-mobile">
                    <div className="column">
                        <p className="has-text-purple-blue-500 has-text-weight-medium">Amount</p>
                    </div>
                    <div className="column">
                        <div className="is-fullheight is-flex is-flex-direction-row is-align-items-center is-justify-content-end">
                            <p className="has-text-purple-blue-500 has-text-weight-medium">{formatCurrency(transaction?.amount ?? 0)}</p>
                        </div>
                    </div>
                </div>
            </InvestmentsDashboard.Overlay.OverlaySection>
        </> 
    }

    return loader.defaultDisplayUntilLoaded(inner(), "p-4")

}