import "./Select.scss";
import { AnswerProps } from "../Answer/Answer";
import { useContext } from "react";
import { ChatContext } from "../Chat";

export interface SelectProps extends AnswerProps {
    name: String,
    children?: React.ReactElement<SelectOptionProps>[]
}

export function Select(props: SelectProps) {
    return (
        <div className="select-input chat-message-line is-flex is-flex-wrap-wrap">
            { props.children }
        </div>
    )
}

export interface SelectOptionProps {
    name: string;
    value: string;
    label: string;
    nextPath?: number;
}

export function SelectOption(props: SelectOptionProps) {

    const context = useContext(ChatContext);

    function onSelected() {
        context.onAnswerReceived?.(props.label, props.value, props.nextPath)
    }

    return (
        <div className="chat-message answer" onClick={onSelected}>
            <label><input type="checkbox" name={props.name} value={props.value} onChange={onSelected}/><span>{props.label}</span></label>
        </div>
    )
}