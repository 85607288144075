export interface ProgressBarProps {
  values: {
    value: number;
    color: string;
    filledColor?: string;
  }[]
}

export const ProgressBar = (props:ProgressBarProps) => {

  const containerStyles = {
    position: 'relative' as 'relative',
    height: "16px",
    width: '100%',
    backgroundColor: "#CDCDCD",
    borderRadius: 50,
    margin:"auto"
  }

  let progresses = props.values.map((x, i) => {

    if (x.filledColor === undefined) {
      x.filledColor = x.color;
    }

    return <div className="filled-area" style={{
      height: '100%',
      width: `${x.value}%`,
      minWidth: x.value > 0 ? '10px' : '0px',
      backgroundColor: (x.value < 100 ? x.color : x.filledColor),
      borderTopLeftRadius: 'inherit',
      borderBottomLeftRadius: 'inherit',
      borderTopRightRadius: (x.value < 100 ? 'none' : 'inherit'),
      borderBottomRightRadius: (x.value < 100 ? 'none' : 'inherit'),
      position: 'absolute',
      zIndex: i
    }} key={i}></div> 
  });


  return (
    <div style={containerStyles}>
      { progresses }
    </div>
  );
};