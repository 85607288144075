@use "../../styles/colors";

$linkColor: map-get(map-get(colors.$color-pallete, "grey"), "600");

.link {
    color: $linkColor;

    :visited {
        color: $linkColor;
    }

    :hover {
        color: $linkColor;
    }
}
