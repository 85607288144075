import { Card } from "react-bulma-components";
import './OverlayCard.scss';
import { ReactNode } from "react";
import { withLink } from "../../hoc/withLink";

export interface OverlayCardProps {
    heading: string;
    imageUrl: string;
    count: number;
    countType: string;
    color?: OverlayCardColor;
    children?: ReactNode;
    className?: string;
}

export enum OverlayCardColor {
    Lilac = "lilac",
    Navy = "navy",
    Blue = "blue",
}

export const OverlayCard = ({className = "", ...props}: OverlayCardProps) => {

    let color = null;
    if (props.color === OverlayCardColor.Lilac) {
        color = "purple-blue-300"
    } else if (props.color === OverlayCardColor.Navy) {
        color = "purple-blue-500"
    } else if (props.color === OverlayCardColor.Blue) {
        color = "purple-blue-400"
    }

    return (
        <Card className={`p-3-desktop ${className}`}>
            <div className="card-image">
                <div className={`card-image-overlay ${ color !== null ? color : ""}`}>{props.count} {props.countType}</div>
                <figure className="image is-2by1">
                    <img src={props.imageUrl} />
                </figure>
            </div>
            <Card.Header>
                <h5 className="is-family-primary card-header-title px-1 pb-0 has-text-weight-medium">{props.heading}</h5>
            </Card.Header>
            {   props.children && 
                    <Card.Content className="px-1 py-0">
                        {props.children}
                    </Card.Content>
            }
        </Card>
    );
}

export const OverlayCardWithLink = withLink(OverlayCard, "card-wrapper");