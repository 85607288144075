import { useContext } from "react";
import { ChatContext } from "../Chat";
import { RadioOptions, RadioOption, RadioOptionsProps, RadioOptionExplainer } from "../RadioOptions/RadioOptions";
import { Options } from "../Options/Options";
import { Path, Paths } from "../Paths/Paths";
import * as Script from "./Script";
import { Input, InputProps } from "../Input/Input";
import { Select, SelectOption, SelectProps } from "../Select/Select";

interface DialogProps {
    existingAskOnceData?: string[]
}

export function Dialog({ existingAskOnceData = []} : DialogProps) {

    const chatContext = useContext(ChatContext);
    let script = Script.GetScript(existingAskOnceData);

    let paths = chatContext.previousPaths.concat(chatContext.currentPath);

    return (
        <div className="chat-dialog">
            <Paths>
                {
                    paths.map((pathState, i) => {
                       
                        let path = script.paths.find(p => p.id == pathState.pathId);
                    
                        if (path != undefined) {

                            // Don't include paths we're forwarding on from
                            if (path.forwardTo !== undefined && path.forwardTo !== null) {
                               
                                return <></>;
                            }

                            let pathKey = "chat_instance_" + chatContext.chatInstanceId + "_path_" + pathState.pathId.toString();
                            return (
                                <Path id={path.id} name={pathKey} botMessages={path.botMessages} key={pathKey} isFirst={i==0} isFinal={!path?.answer ? true : false} forwardTo={path.forwardTo}>
                                    { 
                                        path.answer 
                                            ? (
                                                <Options pathId={path.id}>
                                                    {
                                                        renderOptions(path.answer)
                                                    }
                                                </Options>)
                                            : <></>
                                    }        
                                </Path>
                            )
                        }

                        return <span key={pathState.pathId}></span>
                    })
                }
            </Paths>      
        </div>
    )

    function getForwardToPathId(pathId: number | undefined): number | undefined {
        if (pathId === undefined || pathId === null) {
            return undefined;
        }

        let path = script.paths.find(x => x.id == pathId);

        if (path?.forwardTo) {
            return getForwardToPathId(path.forwardTo);
        }

        return pathId;
    }

    function renderOptions(answer: Script.Answer): React.ReactElement {
        switch (answer.type) {
            case 'MultipleChoiceRadio':
                return renderRadioOptions(answer as Script.Radio);
            case 'Input':
                return renderInput(answer as Script.Input);
            case 'Select':
                return renderSelect(answer as Script.Select);
            default:
                return <></>;
        }
    }

    function renderRadioOptions(option: Script.Radio): React.ReactElement<RadioOptionsProps> {
        return (
            <RadioOptions name={option.name}>
                {
                    option.options.map((x, i) => {
                        let key= option.name + "_" + x.value.toString() + "_" + i.toString()

                        if (x.explainer) {
                            return (
                                <RadioOption value={x.value} label={x.label} key={key} name={option.name} nextPath={getForwardToPathId(x.pathId)}>
                                    <RadioOptionExplainer>
                                        <p className="is-size-7 explainer">{x.explainer.description}</p>
                                        <ul className="is-size-7 explainer">
                                            {
                                                x.explainer.bullets.map((b, i) => {
                                                    let bulletKey = key + "_bullet" + i.toString();
                                                    
                                                    return <li key={bulletKey}>{b}</li>
                                                })
                                            }
                                        </ul>
                                    </RadioOptionExplainer>
                                </RadioOption>
                            )
                        }

                        return <RadioOption value={x.value} label={x.label} key={key} nextPath={getForwardToPathId(x.pathId)}/>
                    })
                }
            </RadioOptions>
        )
    }

    function renderInput(option: Script.Input): React.ReactElement<InputProps> {
        return (
            <Input 
                type={option.inputType} 
                inputFormatter={option.inputFormatter} 
                postPlaceholderText={option.postPlaceholderText} 
                nextPath={getForwardToPathId(option.pathId)}
                textLengthValidator={option.textLengthValidator}
                numberValidator={option.numberValidator}
                regexValidator={option.regexValidator} />
        )
    }

    function renderSelect(select: Script.Select): React.ReactElement<SelectProps> {
        return (
            <Select name={select.name}>
                {
                    select.options.map(x => {
                        let key = "select_" + select.name + "_option_" + x.value;

                        return <SelectOption label={x.label} key={key} name={key} value={x.value} nextPath={getForwardToPathId(x.pathId)} />
                    })
                }
            </Select>
        )
    }
}