import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Button as DefaultButton, Icon } from 'react-bulma-components';
import { ReactNode } from 'react';

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>{
    children: ReactNode;
    hideIcon?:boolean;
    className?:string;
    action: (e?:any)=>void
}

export const Button = ({type, hideIcon, children, className, action} : IButtonProps) => {
    return (
        <DefaultButton type={type} color={"primary"} rounded className={className} onClick={action}>
            <span>{children}</span>
            {!hideIcon && 
            <Icon size={"small"}>
                   <FontAwesomeIcon icon={faArrowRight}/>
               </Icon>     
            }

        </DefaultButton>
    )
}