import { Carousel, CarouselItem } from "../Carousel/Carousel";
import { ArticleCardProps, ArticleCardWithLink } from "../Cards/ArticleCard";

export interface RelatedContentCarouselProps {
    title: string,
    contents: ArticleCardProps[];
}

export const RelatedContentCarousel = (props: RelatedContentCarouselProps) => {
    return (
        <Carousel className="content-carousel mx--3-desktop" title={props.title}>
            {
                props.contents.map((x) => {
                    return (
                        <CarouselItem key={x.id}>
                            <ArticleCardWithLink {...x} />
                        </CarouselItem>
                    )
                })
            }
        </Carousel>
    );
}