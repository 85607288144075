import { useMemo } from "react";
import { useAuth } from "react-oidc-context";
import { AuthHelper } from "../../components/Auth/AuthHelper";
import { SigninRedirectArgs } from "oidc-client-ts";

export const CheckoutComplete = () => {
    const auth = useAuth();

    useMemo(() => {
        // Ensure tokens are renewed before navigating away. This is to ensure the user's membership is up to date
        if (auth.isAuthenticated) {
            let state = AuthHelper.generateRedirectState('/membership/welcome');

            const args = {
                ...auth.settings,
                state: state,
            } as SigninRedirectArgs;

            auth.signinRedirect(args);
        }
    }, []);

    return <></>
}