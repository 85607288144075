import { Helmet } from "react-helmet-async";
import { CenteredContent } from "../../components/CenteredContent/CenteredContent";
import { Footer } from "../../../../components/Layout/Footer/Footer";
import { Link } from "react-router-dom";
import { WealthMappingNavbar } from "../../components/WealthMappingNavbar/WealthMappingNavbar";
import { Content } from "../../../../components/Layout/Content/Content";

export function Start() {
    return (
        <>
            <WealthMappingNavbar />
            <div className="is-flex is-flex-grow-1 is-flex-direction-column is-fullheight is-justify-content-space-between">
                <Helmet>
                    <title>Wealth mapping</title>
                </Helmet>
                <CenteredContent>
                    <Content className="mb-6">
                        <h1 className="title is-1">Getting started</h1>
                        <Content>
                            <p className="has-text-grey-500 is-size-5-deskop">Let's begin your Propelle investment journey!</p>
                        </Content>
                        <Content>
                            <p className="has-text-grey-500 is-size-5-deskop">Whether you're ready to get started right now or want to explore your options first, you've come to the right place.</p>
                        </Content>
                        <Content>
                            <p className="has-text-grey-500 is-size-5-deskop">We'll ask you a few questions to understand your financial goals and show you how you might reach them.</p>
                        </Content>
                        <Content>
                            <p className="has-text-grey-500 is-size-5-deskop">This will only take a couple of minutes. Ready?</p>
                        </Content>
                    </Content>
                    <Content className="has-text-centered-touch">
                        <Content>
                            <Link to="/wealth-mapping/chat" className={'button is-primary is-rounded'}>
                                Let's get started
                            </Link>
                        </Content>
                        <Content>
                            <Link className="is-underlined" to={`${process.env.NAVIGATION_REGISTER}`}>Skip</Link>
                        </Content>
                    </Content>
                </CenteredContent>
                <div className="is-fullheight is-flex is-align-items-flex-end">
                    <Footer className="is-fullwidth"/>
                </div>
            </div>
        </>
    );
}