import { useContext, useEffect, useState } from "react";
import { ChatContext, ChatMessage, ChatMessageProps } from "../Chat";
import { OptionsProps } from "../Options/Options";
import "./Paths.scss";
import { ScrollIntoView } from "../../../../../components/ScrollIntoView/ScrollIntoView";
import { isArray } from "lodash";

export interface PathsProps {
    children: React.ReactElement<PathProps>[] | React.ReactElement<PathProps>
}

export function Paths(props: PathsProps) {
    return (
        <>
        {
            props.children
        }
        </>
    )
}

export interface PathProps {
    id: number,
    name: string,
    botMessages: Array<string>,
    children?: React.ReactElement<OptionsProps>,
    isFirst: boolean,
    isFinal: boolean
}

export function Path(props: PathProps) {

    const context = useContext(ChatContext);

    let target = context.previousPaths.concat(context.currentPath).find(x => x.pathId == props.id);

    let intialBotMessages = new Array<MessageStreamMessageProps>();
    props.botMessages.map(x => {
        intialBotMessages.push({
            value: x,
            isBot: true
        });
    });

    let validInputMessages = new Array<MessageStreamMessageProps>();

    if (target?.validInput) {
        validInputMessages.push({
            value: target.validInput.label,
            isBot: false
        });
    }

    function onAllMessagesDisplayed() {
        if (props.isFinal) {
            setTimeout(() => {
                context.onFinished?.();
            }, 3000)
            
            return;
        }

        context.onWaitingForUserInput?.();
    }

    let pathMessageStreamId = props.name + "_message_stream";

    return (
        <>
            <MessageStream 
                id={pathMessageStreamId + "_initial_bot_messages"}  
                messages={intialBotMessages} 
                onAllMessagesDisplayed={onAllMessagesDisplayed} 
                dontScrollIntoView={props.isFirst} />
            {
                target?.invalidInputs?.map((x, i) => {
                    let invalidInputsId = pathMessageStreamId + "_invalid_inputs_" + i.toString();
                    let inputMessages = new Array<MessageStreamMessageProps>();
                    inputMessages.push({
                        value: x.label,
                        isBot: false
                    });

                    let botMessagesStreamId = pathMessageStreamId + "_invalid_inputs_" + i.toString() + "_bot_response";
                    let botMessages = new Array<MessageStreamMessageProps>();
                    botMessages.push({
                        value: x.validationMessage,
                        isBot: true
                    });

                    return (
                        <ValidationResponse key={invalidInputsId}>
                            <MessageStream 
                                id={invalidInputsId} 
                                messages={inputMessages} />
                            <MessageStream 
                                id={botMessagesStreamId} 
                                messages={botMessages}
                                onAllMessagesDisplayed={onAllMessagesDisplayed} />
                        </ValidationResponse>
                    );
                })
            }
            {
                validInputMessages.length > 0
                    ? <MessageStream id={pathMessageStreamId + "_valid_input_messages"} messages={validInputMessages}/>
                    : target?.waitingForUserInput
                        ? <>{props?.children}</>
                        : <></>
            }
        </>
    )
}

interface ValidationResponseProps {
    children: React.ReactElement<MessageStreamProps>[];
}

function ValidationResponse(props: ValidationResponseProps) {
    return <>
            { props.children }
        </>
}

interface MessageStreamMessageProps {
    value: string;
    isBot: boolean;
}

interface MessageStreamProps {
    id: string,
    messages: MessageStreamMessageProps[] | MessageStreamMessageProps;
    dontScrollIntoView?: boolean,
    onAllMessagesDisplayed?: () => void;
}

function MessageStream(props: MessageStreamProps) {

    let messages = new Array<MessageStreamMessageProps>();

    if (isArray(props.messages)) {
        messages = props.messages;
    } else {
        messages.push(props.messages as MessageStreamMessageProps)
    }

    const [displayedMessageCounter, setDisplayedMessageCounter] = useState(0);

    useEffect(() => {
        if (displayedMessageCounter === messages.length) {
            props.onAllMessagesDisplayed?.();
        }
    }, [displayedMessageCounter])

    function onFinishedTypingMessage() {
        setDisplayedMessageCounter(x => x + 1);
    }

    let messageIndexes = new Array<number>();
    let messagesToRender = displayedMessageCounter > messages.length - 1
        ? messages.length - 1
        : displayedMessageCounter;

    for (let x = 0; x <= messagesToRender; x++) {
        messageIndexes.push(x);
    }

    return (
        <ScrollIntoView disabled={props.dontScrollIntoView !== undefined && props.dontScrollIntoView === true}>
            {
                 messageIndexes.map(x => {
                    let key = props.id.toString() + "_message_" + x.toString();
                    let chatMessage = messages[x];

                    let chatMessageProps = {
                        isBot: chatMessage?.isBot,
                        onFinishedTyping: onFinishedTypingMessage
                    } as ChatMessageProps;

                    return <ChatMessage key={key} {...chatMessageProps}>{chatMessage?.value}</ChatMessage>
                })
            }
        </ScrollIntoView>)
}