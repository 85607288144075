import * as classes from "./InvestmentsDashboard.module.scss";
import PlusOrMinus from "./plus-or-minus.tsx.svg";
import BarChart from "./bar-chart.tsx.svg";
import Notes from "./notes.tsx.svg";
import PiggyBank from "./piggy-bank.tsx.svg";
import { Info } from "../../../../components/Info/Info";
import { X } from "react-feather";
import { OutsideAlerter } from "../../../../components/OutsideAlerter/OutsideAlerter";
import { CssClasses } from "../../../../components/Helpers/CssClasses";
import { DropDownExplainer, DropDownExplainerProps } from "../../../../components/Explainer/DropDownExplainer";

export const InvestmentsDashboard = (props: { children: any }) => {

    return (
        <div className="has-background-grey-100 p-6-desktop p-4-mobile p-4-tablet is-rounded-2">
            <div className="is-position-relative">
                {
                    props.children
                }
            </div>
        </div>
    )
}

const Header = () => {
    const lastUpdated = () => {
        const today = new Date();

        const year = new Intl.DateTimeFormat('en-GB', { year: "2-digit" }).format(today)
        const month = new Intl.DateTimeFormat('en-GB', { month: "2-digit" }).format(today);
        const dayOfMonth = new Intl.DateTimeFormat('en-GB', { day: "2-digit" }).format(today);
        
        return `${dayOfMonth}/${month}/${year}`;
    }

    return (
        <div className="is-position-absolute is-top is-right">
            <div className="p-1">
                <p className="has-text-purple-blue-500 is-size-7">As of {lastUpdated()}</p>
            </div>
        </div>
    )
}

const Title = (props: { text: string }) => {
    return (
        <div className="mb-6">
            <h4 className="title is-size-4 is-size-3-desktop has-text-purple-blue-500 has-text-centered">{props.text}</h4>
        </div>
    );
}

const DataPoints = (props: { children?: any }) => {
    return (
        <div className="mb-6">
            <div className="columns is-desktop">
                { props.children }
            </div>
        </div>
    )
}

const DataPointGroup = (props: { children?: any }) => {
    return (
        <div className="column">
            <div className="is-flex is-flex-direction-row is-justify-content-center">
                <div className="columns is-desktop">
                    { props.children }
                </div>
            </div>
        </div>
    )
}

export const DataPoint = (props: { 
    label: string, 
    value: string, 
    info: { text: string, positioning: "left" | "right" }, 
    icon: any
}) => {

    const infoProps = {
        positioning: props.info.positioning === "left"
            ? ["bottom"] as Array<any>
            : ["bottom", "right"] as Array<any>,
        notificationStyle: props.info.positioning === "left"
            ? { right: "100px"}
            : undefined
    };

    return (
        <div className="column mb-6-touch">
            <div className="has-text-centered">{props.icon}</div>
            <p className={`${classes.label} has-text-purple-blue-500 has-text-weight-semibold has-text-centered`}>{props.value}</p>
            <div className="is-flex is-flex-direction-row is-align-items-center is-flex-gap-1">
                <p className="has-text-purple-blue-500 has-text-centered has-text-weight-medium">{props.label}</p>
                <Info {...infoProps}><p>{props.info.text}</p></Info>
            </div>
        </div>
    );
}

const Overlay = ({ children = undefined as any, isVisible = false, onClose = () => {} }) => {

    const onOverlayClose = () => {
        if (onClose) {
            onClose();
        }
    }

    if (!isVisible) {
        return <></>;
    }

    return (
        <div className={classes["overlay-container"] + " is-position-absolute is-fullwidth is-top is-left is-flex is-flex-direction-column is-justify-content-center is-align-items-center"}>
            <div className="is-fullheight is-fullwidth py-8 px-2-mobile px-2-tablet px-4-desktop">
                <OutsideAlerter className="is-fullheight is-fullwidth" onClickOutside={onOverlayClose}>
                    <div className={classes.overlay + " is-relative has-background-grey-100 is-fullheight is-fullwidth is-rounded-2 has-border-grey-200-2"}>
                        <div className="is-position-absolute is-top is-right p-2 is-clickable" onClick={onOverlayClose}>
                            <X color="#393939"/>
                        </div>
                        { 
                            children 
                        }
                    </div>
                </OutsideAlerter>
            </div>
        </div>
    )
}

const OverlaySection = ({ children = undefined as any, background = "Default" as "Default" | "Peach" | "Purple", className = undefined as string | undefined }) => {
    const overlaySectionCss = new CssClasses(["px-4 py-2"]);

    if (className) {
        overlaySectionCss.add(className);
    }

    if (background === "Peach") {
        overlaySectionCss.add(classes["peach-gradient-background"] ?? "");
    }

    if (background === "Purple") {
        overlaySectionCss.add("has-background-purple-blue-300" ?? "");
    }

    return (
        <>
            <div className={overlaySectionCss.aggregated()}>
                { children }
            </div>
            <hr className="has-background-grey-200 m-0" />
        </>
    )
}

const Icons = {
    PiggyBank: () => <PiggyBank />,
    Notes: () => <Notes />,
    BarChart: () => <BarChart />,
    PlusOrMinus: () => <PlusOrMinus />
}

const Footer = (props: DropDownExplainerProps) => {
    return (
        <div className="mb--4-mobile mb--4-tablet mb--6-desktop">
            <hr className="has-background-grey-200 m-0" />
            <DropDownExplainer {...props}/>
        </div>
    )
};

DataPointGroup.DataPoint = DataPoint;
DataPoints.DataPointGroup = DataPointGroup;

Overlay.OverlaySection = OverlaySection;

InvestmentsDashboard.Overlay = Overlay;
InvestmentsDashboard.Header = Header;
InvestmentsDashboard.Title = Title;
InvestmentsDashboard.DataPoints = DataPoints;
InvestmentsDashboard.Icons = Icons;
InvestmentsDashboard.Footer = Footer;
