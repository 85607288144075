import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./components/App/App";
import dayjs from './dayjs'; // Keep this for extensions

import './styles/main.scss';

console.log("Environment: ", process.env.NODE_ENV);

if (process.env.NODE_ENV === 'production') {
  console.log = function () {};
  console.warn = function () {};
  console.error = function () {};
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);