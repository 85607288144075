import { useEffect } from "react";
import { UserInfo, useAuthApiClient } from "../../components/Auth/AuthApiClient";
import { MembershipClientFactory } from "../membership/services/MembershipClientFactory";
import { getUserData } from "../../helpers/auth";
import { GetUserMembership_Response } from "../membership/services/generated/MembershipClient";
import { useLoader, loadingStateAggregator } from "../../hoc/withLoader";
import { AxiosResponse } from "axios";
import { GetAccountHolder_Response_AddressModel, GetAccountHolder_Response_Root } from "../invest/services/generated/AccountManagementClient";
import { AccountManagementClientFactory } from "../invest/services/AccountManagementClientFactory";

export const useMyAccountLoader = () => {
    const membershipClient = MembershipClientFactory();
    const accountClient = AccountManagementClientFactory();
    const userId = getUserData()?.id ?? '';
    const [getUserInfo] = useAuthApiClient();
    const [userInfoState, userInfo] = useLoader<AxiosResponse<UserInfo>>();
    const [userMembershipState, userMembership] = useLoader<GetUserMembership_Response>();
    const [accountHolderState, accountHolder] = useLoader<GetAccountHolder_Response_Root>();

    useEffect(() => {
        if (userInfoState.loadingState === "NotStarted") {
            userInfo.load(getUserInfo());
        }

        if(userMembershipState.loadingState === "NotStarted") {
            userMembership.load(membershipClient.getUserMembership(userId));
        }

        if(accountHolderState.loadingState === "NotStarted") {
            accountHolder.load(accountClient.getAccountHolder());
        }

    }, []);

    const loadingState = loadingStateAggregator([userInfoState.loadingState, userMembershipState.loadingState]);

    return {
        loadingState,
        membership: userMembershipState?.response,
        user: userInfoState?.response?.data,
        accountHolder: accountHolderState?.response
    }
}