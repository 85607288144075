import { Columns } from "react-bulma-components";
import { Link, useNavigate } from "react-router-dom";
import logo from "./start-over.svg";
import "./Chat.scss";
import { WealthMappingNavbar } from "../../components/WealthMappingNavbar/WealthMappingNavbar";
import { createContext, useContext, useEffect, useState } from "react";
import { Dialog } from "./Script/Dialog";
import { WealthMappingContext } from "../../WealthMappingContext";
import { CreateWealthPicture_Request, CreateWealthPicture_Request_FinancialIndependenceRequest, CreateWealthPicture_Request_FinancialSecurityRequest, CreateWealthPicture_Request_GivenGender, CreateWealthPicture_Request_QualityOfLifeRequest, CreateWealthPicture_Request_RetirementRequest, CreateWealthPicture_Request_SpecificEventRequest, CreateWealthPicture_Response } from "../../services/generated/WealthMappingClient";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from 'uuid';
import { WealthMappingClientFactory } from "../../services/WealthMappingClientFactory";
import { Helmet } from "react-helmet-async";
import { useAuth } from "react-oidc-context";
import { WindowContext, ScreenSize } from "../../../../components/App/WindowContext";
import { getUserData } from "../../../../helpers/auth";

export interface PathState {
    pathId: number;
    invalidInputs?: Array<{label: string, value: string, validationMessage: string}>;
    validInput?: { label: string, value: string };
    waitingForUserInput: boolean;
}

export interface ChatState {
    chatInstanceId: number,
    previousPaths: Array<PathState>,
    currentPath: PathState,
    isFinished: boolean,
    inProgressRequest: CreateWealthPicture_Request,
    existingAskOnceData: Array<string>,
    onWaitingForUserInput?: () => void;
    onAnswerReceived?: (label: string, value: string, nextPath?: number) => void;
    onInvalidAnswerReceived?: (label: string, input: string, validationMessage: string) => void;
    onFinished?: () => void;
}

export const DefaultChatState = (prospectiveUserId: string) : ChatState => {
    return {
        chatInstanceId: 1,
        inProgressRequest: {
            prospectiveUserId: prospectiveUserId
        } as CreateWealthPicture_Request,
        existingAskOnceData: [],
        previousPaths: Array<PathState>(),
        currentPath: { pathId: 1, waitingForUserInput: false },
        isFinished: false
    } as ChatState;
}

export const ChatContext = createContext(DefaultChatState(uuidv4()));

export function Chat() {

    const wealthMappingContext = useContext(WealthMappingContext);
    const [chatState, setChatState] = useState(DefaultChatState(wealthMappingContext.wealthMappingData?.prospectiveUserId ?? uuidv4()));
    const navigate = useNavigate();
    const wealthMappingClient = WealthMappingClientFactory();
    const windowContext = useContext(WindowContext);
    const user = getUserData();

    function onWaitingForUserInput() {
        setChatState(state => {
            let newState = cloneDeep(state);
            newState.currentPath.waitingForUserInput = true;

            return newState;
        })
    }

    function onAnswerReceived(label: string, value: string, nextPath?: number) {
        setChatState(state => {
            let newState = cloneDeep(state);
            updateInProgressRequest(newState, value);

            newState.currentPath.validInput = { label, value };
            newState.previousPaths.push(newState.currentPath);
            newState.currentPath = { pathId: nextPath ? nextPath : state.currentPath.pathId + 1, waitingForUserInput: false };

             // Brittle, but works for now. Use Id = 400 for bad input terminal path
            let isValid = validateInProgressRequest(newState.inProgressRequest);
            if (!isValid) {
               
                newState.currentPath = { pathId: 400, waitingForUserInput: false };

                return newState;
            }

            return newState;
        })
    }

    function onInvalidAnswerReceived(label: string, value: string, validationMessage: string) {
        setChatState(state => {
            let newState = cloneDeep(state);
            
            if (!newState.currentPath.invalidInputs) {
                newState.currentPath.invalidInputs = new Array<{label: string, value: string, validationMessage: string}>();
            }

            newState.currentPath.invalidInputs.push({label, value, validationMessage});
            newState.currentPath.waitingForUserInput = false;

            return newState;
        });
    }

    function onStartOver() {
        setChatState(x => {
            let newState = DefaultChatState(wealthMappingContext.wealthMappingData?.prospectiveUserId ?? uuidv4());
            newState.chatInstanceId = x.chatInstanceId + 1;

            return newState;
        });
    }

    function updateInProgressRequest(currentState: ChatState, value: string) {

        // This is a bit brittle, but difficult to map input data from bot script to API request any other way
        let pathIds = {
            age: 100,
            gender: 101,
            emailAddress: 102,
            financialIndependence: {
                salaryReduction: 10,
                years: 11,
                currentGrossSalary: 12
            },
            financialSecurity: {
                target: 20,
                years: 21
            },
            qualityOfLife: {
                additionalIncome: 30,
                years: 31
            },
            specificEvent: {
                name: 40,
                customName: 41,
                target: 42,
                years: 43
            },
            retirement: {
                salary: 50
            }
        }

        switch(currentState.currentPath.pathId) {
            case pathIds.age:
                currentState.inProgressRequest.age = parseInt(value);
                break;
            case pathIds.gender:
                currentState.inProgressRequest.gender = value as CreateWealthPicture_Request_GivenGender ?? CreateWealthPicture_Request_GivenGender.NotSpecified;
                break;
            case pathIds.emailAddress:
                currentState.inProgressRequest.emailAddress = value?.trim();
                break;
            case pathIds.financialIndependence.salaryReduction:
                currentState.inProgressRequest.financialIndependence = {
                    ...currentState.inProgressRequest.financialIndependence,
                    percentageWorkReduction: parseFloat(value)
                } as CreateWealthPicture_Request_FinancialIndependenceRequest;
                break;
            case pathIds.financialIndependence.currentGrossSalary:
                currentState.inProgressRequest.financialIndependence = {
                    ...currentState.inProgressRequest.financialIndependence,
                    currentGrossSalary: parseInt(value)
                } as CreateWealthPicture_Request_FinancialIndependenceRequest;
                break; 
            case pathIds.financialIndependence.years:
                currentState.inProgressRequest.financialIndependence = {
                    ...currentState.inProgressRequest.financialIndependence,
                    years: parseInt(value)
                } as CreateWealthPicture_Request_FinancialIndependenceRequest;
                break;
            case pathIds.financialSecurity.target:
                currentState.inProgressRequest.financialSecurity = {
                    ...currentState.inProgressRequest.financialSecurity,
                    target: parseInt(value)
                } as CreateWealthPicture_Request_FinancialSecurityRequest;
                break;
            case pathIds.financialSecurity.years:
                currentState.inProgressRequest.financialSecurity = {
                    ...currentState.inProgressRequest.financialSecurity,
                    years: parseInt(value)
                } as CreateWealthPicture_Request_FinancialSecurityRequest;
                break;
            case pathIds.qualityOfLife.additionalIncome:
                currentState.inProgressRequest.qualityOfLife = {
                    ...currentState.inProgressRequest.qualityOfLife,
                    extraMonthlyIncome: parseInt(value)
                } as CreateWealthPicture_Request_QualityOfLifeRequest;
                break;
            case pathIds.qualityOfLife.years:
                currentState.inProgressRequest.qualityOfLife = {
                    ...currentState.inProgressRequest.qualityOfLife,
                    years: parseInt(value)
                } as CreateWealthPicture_Request_QualityOfLifeRequest;
                break;
            case pathIds.specificEvent.years:
                currentState.inProgressRequest.specificEvent = {
                    ...currentState.inProgressRequest.specificEvent,
                    years: parseInt(value)
                } as CreateWealthPicture_Request_SpecificEventRequest;
                break;
            case pathIds.specificEvent.name:
            case pathIds.specificEvent.customName:
                currentState.inProgressRequest.specificEvent = {
                    ...currentState.inProgressRequest.specificEvent,
                    description: value?.trim()
                } as CreateWealthPicture_Request_SpecificEventRequest;
                break;
            case pathIds.specificEvent.target:
                currentState.inProgressRequest.specificEvent = {
                    ...currentState.inProgressRequest.specificEvent,
                    target: parseInt(value)
                } as CreateWealthPicture_Request_SpecificEventRequest;
                break;
            case pathIds.retirement.salary:
                currentState.inProgressRequest.retirement = {
                    ...currentState.inProgressRequest.retirement,
                    salary: parseInt(value)
                } as CreateWealthPicture_Request_RetirementRequest;
                break;
        }
        
    }

    function validateInProgressRequest(inProgressRequest: CreateWealthPicture_Request): boolean {

        // Custom validation here (e.g. validation spanning multiple fields)
        const retirementAge = 67;
        let ageToReachFinancialIndependence = (inProgressRequest.age ?? 0) + (inProgressRequest.financialIndependence?.years ?? 0);
        if (ageToReachFinancialIndependence > retirementAge) {
            return false;
        }

        let ageToReachFinancialSecurity = (inProgressRequest.age ?? 0) + (inProgressRequest.financialSecurity?.years ?? 0);
        if (ageToReachFinancialSecurity > retirementAge) {
            return false;
        }

        let ageToImproveQualityOfLife = (inProgressRequest.age ?? 0) + (inProgressRequest.qualityOfLife?.years ?? 0);
        if (ageToImproveQualityOfLife > retirementAge) {
            return false;
        }

        return true;
    }

    function onFinished() {
        setChatState(state => {
            let newState = cloneDeep(state);

            if (newState.currentPath.pathId >= 400) {
                // This is a non successful final state. Don't set the request or update the state to finished
                return newState;
            }

            newState.isFinished = true;

            wealthMappingContext?.setWealthPictureRequest(state.inProgressRequest);

            return newState;
        });
    }

    const onWealthPictureCreated = (response: CreateWealthPicture_Response) => {
        wealthMappingContext.setWealthPictureResponse(response);

        navigate("/wealth-mapping/result");
    }

    const onWealthPictureCreationFailed = () => {
        setChatState(state => {
            let newState = cloneDeep(state);
            newState.previousPaths.push(newState.currentPath);
            newState.currentPath = { pathId: 500, waitingForUserInput: false };

            return newState;
        });
    }

    useEffect(() => {
        async function updateValuesFromPrevious() {
            
            let previousMap = await wealthMappingClient.getPreviousWealthPicture();

            setChatState(state => {
                
                let newState = cloneDeep(state);
    
                if (previousMap?.gender && !newState.existingAskOnceData.includes("gender")) {
                    newState.existingAskOnceData.push("gender");
                    newState.inProgressRequest.gender = previousMap.gender as unknown as CreateWealthPicture_Request_GivenGender
                }

                if (wealthMappingClient.isLoggedIn() && !newState.existingAskOnceData.includes("email")) {
                    newState.existingAskOnceData.push("email");
                    newState.inProgressRequest.emailAddress = user.email;
                }

                return newState;
            });
        }

        if (wealthMappingClient.isLoggedIn()) {
            updateValuesFromPrevious();
        }
     
    }, []);

    useEffect(() => {
        if (chatState.isFinished && wealthMappingContext.wealthMappingData?.wealthPictureRequest && !wealthMappingContext.wealthMappingData?.wealthPictureResponse) {
            if (wealthMappingClient.isLoggedIn()) {
                wealthMappingClient.createOwnedWealthPicture(wealthMappingContext.wealthMappingData.wealthPictureRequest)
                    .then(resp => onWealthPictureCreated(resp))
                    .catch(() => onWealthPictureCreationFailed());
            } else {
                wealthMappingClient.createWealthPicture(wealthMappingContext.wealthMappingData.wealthPictureRequest)
                    .then(resp => onWealthPictureCreated(resp))
                    .catch(() => onWealthPictureCreationFailed());
            }
        }
    }, [chatState.isFinished])

    return (
        <ChatContext.Provider value={{ ...chatState, onAnswerReceived: onAnswerReceived, onInvalidAnswerReceived: onInvalidAnswerReceived, onWaitingForUserInput: onWaitingForUserInput, onFinished: onFinished }}>
            <Helmet>
                <title>Wealth Mapping - Chat</title>
            </Helmet>
            {
                windowContext.screenSize < ScreenSize.Desktop
                    ? <div className="is-hidden-desktop chat-page is-flex is-flex-direction-column is-flex-grow-1">
                            <section className="section is-nav">
                                <div className="is-flex is-flex-direction-column">
                                    <WealthMappingNavbar />
                                    <div className="is-flex is-flex-direction-row px-4 py-3 is-justify-content-space-between">
                                        <h6 className="title is-7 m-0" style={{paddingTop: "3px"}}>Goal Mapper</h6>
                                        <StartOver onStartOver={onStartOver} />
                                    </div>
                                </div>
                            </section>
                            <section className="section chat is-flex is-flex-direction-column is-flex-grow-1">
                                <div className="mobile is-flex is-flex-direction-column">
                                    <Columns>
                                        <Columns.Column className="pt-5">
                                            <Dialog existingAskOnceData={chatState.existingAskOnceData}/>
                                            <SkipBar display="mobile"/>
                                        </Columns.Column>
                                    </Columns>
                                </div>     
                            </section>
                        </div>
                    : <div className="is-hidden-touch chat-page is-flex is-flex-direction-column is-flex-grow-1">
                    <section className="section is-nav">
                        <div className="is-flex is-flex-direction-column">
                            <WealthMappingNavbar />
                        </div>
                    </section>
                    <section className="section is-menu">
                        <Columns className="is-flex-grow-1">
                            <Columns.Column className="pt-8" size={3}>
                                <div className="is-flex is-flex-direction-row is-justify-content-center">
                                    <StartOver onStartOver={onStartOver} />
                                </div>
                            </Columns.Column>
                        </Columns>
                    </section>
                    <section className="section chat is-flex is-flex-direction-column is-flex-grow-1">
                        <div className="desktop is-flex is-flex-direction-column is-flex-grow-1">
                            <Columns className="is-flex-grow-1">
                                <Columns.Column className="pt-5" size={3}>
                                </Columns.Column>
                                <Columns.Column className="pt-5">
                                    <div className="chat-dialog">
                                        <Dialog existingAskOnceData={chatState.existingAskOnceData}/>
                                    </div>
                                    <SkipBar display="desktop"/>
                                </Columns.Column>
                                <Columns.Column className="pt-5" size={3}></Columns.Column>
                            </Columns>
                        </div>
                    </section>
                </div>
            }
        </ChatContext.Provider>
    )
}

export interface ChatMessageProps {
    children?: React.ReactNode;
    isBot?: Boolean;
    onFinishedTyping?: () => void;
}

export function ChatMessage(props: ChatMessageProps) {

    const loadingTime: number = 1000;
    const [isLoading, setIsLoading] = useState(true);

    let messageLineClasses = props.isBot ? "chat-message-line is-flex" : "chat-message-line is-flex is-flex-direction-row-reverse";
    let messageClasses = props.isBot ? "chat-message bot is-flex is-align-items-center is-justify-content-center" : "chat-message customer is-flex is-align-items-center is-justify-content-center";

    useEffect(() => {
        if (props.isBot && isLoading) {
            setTimeout(() => {
                setIsLoading(false);
            }, loadingTime)
        } else {
            props.onFinishedTyping?.()
        }
    }, [isLoading]);

    if (props.isBot && isLoading) {
        return (
            <div className={messageLineClasses}>
                <div className={messageClasses + " loading"}>
                    <p className="has-text-centered is-flex">
                        <span>•</span>
                        <span>•</span>
                        <span>•</span>
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div className={messageLineClasses}>
            <div className={messageClasses}>
                <p>{props.children}</p>
            </div>
        </div>
    )
}

interface StartOverProps {
    onStartOver: () => void;
}

function StartOver(props: StartOverProps) {

    function onStartOver() {
        props.onStartOver?.()
    }

    return (
        <div className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center is-clickable" onClick={onStartOver}>
            <img className="start-over-img" src={logo} alt="Start over" />  
            <p className="is-size-7 is-size-6-desktop">Start over</p>
        </div>
    );
};

const SkipBar = (props: { display: "mobile" | "desktop"  }) => {
    const backgroundCss = props.display === "desktop" ? "has-background-propelle-grey-light" : "has-background-white";
    let auth = useAuth();
    
    const skip = auth.isAuthenticated
        ? "/invest/tools/goal-planning"
        : process.env.NAVIGATION_REGISTER ?? "";

    return (
        <div className="skip-bar">
            <div style={{marginBottom: "60px"}}> { /* Ensure margin matches the fixed content below */}
            </div>
            <div className="is-position-fixed is-bottom is-fullwidth">
                { /* Ensure that the column size matches with the matches with the parent so the fixed element has the same width */ }
                <Columns>
                    <Columns.Column className="is-6-desktop">
                        <div className={"is-flex is-flex-direction-column is-align-items-center p-4 " + backgroundCss }>
                            <Link to={skip} className="has-text-navy is-underlined">Skip</Link>
                        </div>
                    </Columns.Column>
                </Columns>
            </div>
        </div>
    )
}
