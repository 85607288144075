import { Helmet } from "react-helmet-async"
import { Main } from "../../../../../components/Layout/Main/Main"
import { BackButton } from "../../../../../components/BackButton/BackButton"
import { Glass } from "../../../../../components/Layout/Glass/Glass"
import { Section } from "../../../../../components/Layout/Section/Section"
import { AvailableFundsContainer } from "../../dataComponents/AvailableFundsContainer"
import { AvailableFundsCarousel } from "../../components/AvailableFundsCarousel"
import { useContext, useEffect, useState } from "react"
import { FundsContext } from "../../components/FundsContext"
import { Loader } from "../../../../../components/Loader/Loader"
import { ChangeFundConfirmation } from "./ChangeFundConfirmation"
import { useNavigate } from "react-router-dom"
import { GetPortfolioAvailableActions_Action } from "../../../services/generated/PortfoliosClient"
import { Content } from "../../../../../components/Layout/Content/Content"

export const ChangeFund = () => {
    const navigate = useNavigate();
    const context = useContext(FundsContext);
    const [selectedFund, setSelectedFund] = useState<{ isin: string, name: string } | undefined>(undefined);

    useEffect(() => {
        context.fetchPortfolio();
        context.fetchPortfolioActions();
    }, []);

    const handleFundSelected = (fundIsin: string, fundName: string) => {
        setSelectedFund({ isin: fundIsin, name: fundName });
    }

    const handleBack = () => {
        if (selectedFund !== undefined) {
            setSelectedFund(undefined);
        } else {
            navigate(-1);
        }
    }

    const inner = () => {

        if (context.actions) {

            var changeFund = context.actions.actions?.find(x => x.action === GetPortfolioAvailableActions_Action.Rebalance);

            if (!changeFund?.allowed) {
                return (
                    <Content>
                        <p>Sorry, you can't change your fund at this time for the following reason: <b>{changeFund?.reason}</b></p>
                    </Content>
                )
            }
        }

        return selectedFund === undefined 
            ? <div className="column is-12">
                <Glass>
                    <Section>
                        <div className="container" id="available-funds">
                            <h1 className="is-size-2-tablet is-size-4-mobile has-text-navy has-text-centered-mobile mb-3">Change your fund</h1>
                            <p className="mb-3">Select one of 3 funds that best suits your investment needs.</p>
                            {
                                context.portfolio === undefined
                                    ? <Loader className="py-6" />
                                    :  <AvailableFundsContainer>
                                            <AvailableFundsCarousel availableFunds={[]} onFundSelected={handleFundSelected} selectedFundIsin={context.portfolio.fundAllocations![0]?.fundIsin} />
                                        </AvailableFundsContainer>
                            }
                        </div>
                    </Section>
                </Glass>
            </div>
            : <ChangeFundConfirmation accountType={context.account!.accountType!} fundIsin={selectedFund!.isin!} fundName={selectedFund!.name} portfolioId={context.portfolio!.id!} />
    }

    return (
        <>
            <Helmet>
                <title>Funds - Change your fund</title>
            </Helmet>
            <Main>
                <div className="container">
                    <BackButton action={handleBack} text="Back" className="mb-3"/>
                    <div className="columns">
                        { inner() }
                    </div>
                </div>
            </Main>
        </>
    )
}