import { Helmet } from "react-helmet-async";
import { Main } from "../../../../components/Layout/Main/Main";
import { Container } from "react-bulma-components";
import { PageTitle } from "../../../../components/Layout/PageTitle/PageTitle";
import { CourseTaxonomyParams, WpTaxonomyRequest } from "../../../../components/Auth/AuthenticatedCmsClient";
import { Glass } from "../../../../components/Layout/Glass/Glass";
import { CourseCard } from "../components/CourseCard";
import dayjs from "dayjs";
import { useLoader, useWpLoader } from "../../../../hoc/withLoader";
import { EducationClientFactory } from "../../services/EducationClientFactory";
import { GetCourses_Response, GetCourses_Response_CourseResponse, GetCourses_Response_LevelResponse } from "../../services/generated/EducationClient";
import { useEffect } from "react";
import Breadcrumb from "../../../../components/Breadcrumb/Breadcrumb";
import { Section } from "../../../../components/Layout/Section/Section";
import { ImageSize, getImageUrl } from "../../../../helpers/cms";

export const AllCourses = () => {

    const educationClient = EducationClientFactory();
    const [loaderState, { load }] = useLoader<GetCourses_Response>();
    const [loaderStateWp, wpLoad] = useWpLoader();

    useEffect(() => {
        load(educationClient.getCourses());
        wpLoad.load(new WpTaxonomyRequest({
            url: "course-taxonomy",
            params: new CourseTaxonomyParams({
                type: "course",
                include_topics: true,
            })
        }));
    }, []);

    const getCompletedTopicsForCourse = (courseId: number) => {
        const course = loaderState.response?.courses?.find((courseData: GetCourses_Response_CourseResponse) => courseData.courseId === courseId);
        const completedTopics = course?.levels
            ?.flatMap((levelData: GetCourses_Response_LevelResponse) => levelData.topics ?? [])
            .reduce((count: number, topic: any) => {
                return count + (topic.isComplete ? 1 : 0);
            }, 0);

        return completedTopics ?? 0;
    };
 
    return (
        <>
            <Helmet>
                <title>Learn - Courses</title>
            </Helmet>
            <Main>
                <Container>
                    <Section>
                        <Breadcrumb/>
                        <Glass>
                            <PageTitle text="Courses"/>
                            <div className="mb-6">
                                <p className="has-text-black">We've designed courses on money and investment topics so you can learn at your own pace.</p>
                            </div>
                            {
                                wpLoad.defaultDisplayUntilLoaded(
                                    <div className="columns is-multiline">
                                        {
                                            loaderStateWp.response?.map((course : any) => {
                                                const topicDuration = course.topics.reduce((total : number, topic : any) => {
                                                    return total + parseInt(topic.duration);
                                                }, 0);
                                                const formattedDuration = dayjs.duration(topicDuration, "minutes").format("H[h] m[m]");

                                                return (
                                                    <div className="column is-4 p-0-desktop pb-4-touch" key={course.id}>
                                                        <CourseCard
                                                            totalChildren={course.topics.length}
                                                            childrenCompleted={loaderState.loadingState === "Loaded" ? getCompletedTopicsForCourse(parseInt(course.id)) : undefined}
                                                            description={course.description !== "" ? course.description : undefined}
                                                            url={`/learn/courses/${course.slug}`}
                                                            thumbnail={getImageUrl(course.acf.thumbnail, ImageSize.MediumLarge)}
                                                            name={course.name}
                                                            duration={formattedDuration}
                                                            className="is-fullheight"
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                        </Glass>
                    </Section>
                </Container>
            </Main>
        </>
    );
}