export const convertToSassModule = (
    classes: { [key: string]: string },
    className: string | undefined ) : string | undefined => {
    
    if (className == undefined) {
        return className;
    }

    const classNames = className.split(",");
    classNames.forEach((x) => {
        if (classes[x]) {
            className = className!.replace(x, classes[x]!);
        }
    });

    return className;
};