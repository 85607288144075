export function GetScript(existingAskOnceData: string[] = []): Script {
    let contents = new Script();
    contents.paths.push({
        id: 1,
        botMessages: [
            "Hello! It's great  to meet you! I'm Elle and I'm going to help you get a better understanding of why you want to invest and show what it takes to reach your goals. To get started, I'd like to know your financial motivations a bit better.",
            "What's your main investment goal for the future?"
        ],
        answer: {
            name: "goal",
            type: "MultipleChoiceRadio",
            options: [
                {
                    label: "I want to become financially independent",
                    value: "independence",
                    explainer: {
                        description: "Reducing the dependence on your salary or building new income streams in addition to your salary. Financial independence could allow you to:",
                        bullets: [
                            "Reduce/stop work",
                            "Separate from partner and be independently ok financially",
                            "Retire early (I mean who wouldn't want this 😜)"
                        ]
                    },
                    pathId: 10
                },
                {
                    label: "I want to build more financial security",
                    value: "security",
                    pathId: 20,
                    explainer: {
                       description: "Building up more resistance to unplanned life events. This money that you're investing and accumulating could be used in situations like:",
                       bullets: [
                            "A life event preventing you from working for a while",
                            "The addition of a new family member (because nurseries and nannies aren't cheap!)",
                            "Something (a car, a home appliance etc) breaking down and needing replacement"
                       ]
                    }
                },
                {
                    label: "I want to improve my quality of life",
                    value: "quality",
                    pathId: 30,
                    explainer: {
                        description: " Increase the amount of disposable income  you can spend on a regular basis. This could be to:",
                        bullets: [
                            "Move to a bigger home",
                            "Start an activity that you like (gym, get a side hustle going or take up laughter yoga… yes - it's a real thing!)",
                            "Take an additional holiday every year"
                        ]
                    }
                },
                {
                    label: "I want to build up money for a specific event",
                    value: "event",
                    pathId: 40,
                    explainer: {
                        description: "Save money for an occasion. This might be:",
                        bullets: [
                            "Childcare (because nurseries and nannies aren't cheap!)",
                            "The latest must have mobile phone",
                            "Buying a new car",
                            "Saving for a deposit for your dream home"
                        ]
                    }
                },
                {
                    label: "I want to prepare for retirement",
                    value: "retirement",
                    pathId: 50,
                    explainer: {
                        description: "Saving enough money to allow you to have a good standard of living once you're retired. This may look like:",
                        bullets: [
                            "Saving enough to continue having the same lifestyle",
                            "Saving enough to be able to retire early",
                            "Saving enough to have an even better retirement lifestyle than your current lifestyle (if you've worked and invested well, then why not treat yourself!)"
                        ]
                    }
                }
            ]
        } as Radio
    })

    /* Become financially independent */
    contents.paths.push({
        id: 10,
        botMessages: [
            "Ok great! You want to become financially independent. For many women this means relying less on their salary. So by how much (in % terms) would you like to reduce your working hours, while still keeping the same standard of living?"
        ],
        answer: {
            name: "hoursReduction",
            type: "Select",
            options: [
                {
                    label: "10%",
                    value: "0.1",
                    pathId: 11
                },{
                    label: "20%",
                    value: "0.2",
                    pathId: 11
                },{
                    label: "30%",
                    value: "0.3",
                    pathId: 11
                },{
                    label: "40%",
                    value: "0.4",
                    pathId: 11
                },{
                    label: "50%",
                    value: "0.5",
                    pathId: 11
                },{
                    label: "60%",
                    value: "0.6",
                    pathId: 11
                },{
                    label: "70%",
                    value: "0.7",
                    pathId: 11
                },{
                    label: "80%",
                    value: "0.8",
                    pathId: 11
                },{
                    label: "90%",
                    value: "0.9",
                    pathId: 11
                },{
                    label: "I want to stop working completely. I wasn't made for working life.",
                    value: "1",
                    pathId: 11
                }
            ]
        } as Select
    })
    contents.paths.push({
        id: 11,
        botMessages: [
            "In how much time would you like to make that change"
        ],
        answer: {
            name: "timeFrame",
            type: "Input",
            inputType: "number",
            postPlaceholderText: "years",
            pathId: 12,
            numberValidator: {
                message: "The Propelle investment philosophy suggests a plan to invest for somewhere between 3 and 50 years. Please enter a new timeframe within this range to reach your goal.",
                minValue: 3,
                maxValue: 50
            }
        } as Input
    })
    contents.paths.push({
        id: 12,
        botMessages: [
            "Fantastic! And what's your current gross salary? (ie your annual salary before any deductions)"
        ],
        answer: {
            name: "salary",
            type: "Input",
            inputType: "number",
            inputFormatter: 'Currency',
            pathId: 100,
            numberValidator: {
                message: "Please enter a salary between £10,000 and £10,000,000. If you earn less than £10k a year, investing likely isn't a priority for you right now. If you earn more than £10M… you don't need our help, just keep doing what you're doing!",
                maxValue: 10000000,
                minValue: 10000
            }
        } as Input
    })

    /* Build more financial security */ 
    contents.paths.push({
        id: 20,
        botMessages: [
            "Great! You want to build more financial security. We tend to think of financial security as a sum of money that we can fall back on in moments of need. How much money would you need to have set aside in order to feel more financially secure?"
        ],
        answer: {
            name: "potSize",
            type: "Input",
            inputType: "number",
            inputFormatter: 'Currency',
            pathId: 21,
            numberValidator: {
                message: "Please enter an amount between £100 and £10,000,000",
                minValue: 100,
                maxValue: 10000000
            }
        } as Input
    }),
    contents.paths.push({
        id: 21,
        botMessages: [
            "In how many years will you need the money?"
        ],
        answer: {
            name: "years",
            type: "Input",
            inputType: "number",
            postPlaceholderText: "years",
            pathId: 100,
            numberValidator: {
                message: "The Propelle investment philosophy suggests a plan to invest for somewhere between 3 and 50 years. Please enter a new timeframe within this range to reach your goal.",
                minValue: 3,
                maxValue: 50
            }
        } as Input
    })

    /* Improve quality of life */
    contents.paths.push({
        id: 30,
        botMessages: [
            "Great! You want to improve your quality of life. How much additional monthly income would you need to improve your quality of life?"
        ],
        answer: {
            name: "additional-monthly-income",
            type: "Input",
            inputType: "number",
            inputFormatter: 'Currency',
            postPlaceholderText: "extra per month",
            pathId: 31,
            numberValidator: {
                message: "Please enter an amount between £100 and £100,000",
                minValue: 100,
                maxValue: 100000
            }
        } as Input
    }),
    contents.paths.push({
        id: 31,
        botMessages: [
            "In how many years will you need the money?"
        ],
        answer: {
            name: "years",
            type: "Input",
            inputType: "number",
            postPlaceholderText: "years",
            pathId: 100,
            numberValidator: {
                message: "The Propelle investment philosophy suggests a plan to invest for somewhere between 3 and 50 years. Please enter a new timeframe within this range to reach your goal.",
                minValue: 3,
                maxValue: 50
            }
        } as Input
    })

    /* Save for a specific event */
    contents.paths.push({
        id: 40,
        botMessages: [
            "Great! You want to save for a specific event. Can you tell us what this event is?"
        ],
        answer: {
            name: "event",
            type: "Select",
            options: [
                {
                    label: "Holiday",
                    value: "holiday",
                    pathId: 42
                },
                {
                    label: "Wedding",
                    value: "wedding",
                    pathId: 42
                },
                {
                    label: "Car",
                    value: "car",
                    pathId: 42
                },
                {
                    label: "House deposit",
                    value: "house deposit",
                    pathId: 42
                },
                {
                    label: "Childcare",
                    value: "childcare",
                    pathId: 42
                },
                {
                    label: "Something else",
                    value: undefined,
                    pathId: 41
                }
        ]} as Select
    }),
    contents.paths.push({
        id: 41,
        botMessages: [
            "Ok, can you be more specific??"
        ],
        answer: {
            name: "event",
            type: "Input",
            inputType: "text",
            pathId: 42,
            textLengthValidator: {
                message: "Please describe what you're investing for in 1 to 100 characters",
                maxLength: 100,
                minLength: 1
            }
        } as Input
    }),
    contents.paths.push({
        id: 42,
        botMessages: [
            "Fantastic! How much money do you need to build up?"
        ],
        answer: {
            name: "event",
            type: "Input",
            inputType: "number",
            inputFormatter: 'Currency',
            pathId: 43,
            numberValidator: {
                message: "Please enter an amount between £100 and £10,000,000",
                minValue: 100,
                maxValue: 10000000
            }
        } as Input
    }),
    contents.paths.push({
        id: 43,
        botMessages: [
            "In how many years will you need the money?"
        ],
        answer: {
            name: "years",
            type: "Input",
            inputType: "number",
            postPlaceholderText: "years",
            pathId: 100,
            numberValidator: {
                message: "The Propelle investment philosophy suggests a plan to invest for somewhere between 3 and 50 years. Please enter a new timeframe within this range to reach your goal.",
                minValue: 3,
                maxValue: 50
            }
        } as Input
    })

    /* Prepare for retirement */
    contents.paths.push({
        id: 50,
        botMessages: [
            "You and me both! I have big plans for my retirement… I'm thinking total freedom to travel around the world and live well, anywhere.",
            "Anyway, enough about me! You want to prepare for retirement. How much yearly income would you like to have at retirement?"
        ],
        answer: {
            name: "retirement-income",
            type: "Input",
            inputType: "number",
            inputFormatter: 'Currency',
            pathId: 100,
            numberValidator: {
                message: "Please enter a yearly income between £10,000 and £10,000,000",
                maxValue: 10000000,
                minValue: 10000
            }
        } as Input
    }),

    /* General questions to finish */
    contents.paths.push({
        id: 100,
        botMessages: [
            "Ok, so we're almost there. A few final things I need to know. How old are you?"
        ],
        answer: {
            name: "age",
            type: "Input",
            inputType: "number",
            postPlaceholderText: "years old",
            pathId: 101,
            numberValidator: {
                message: "Please enter an age between 18 and 100",
                maxValue: 100,
                minValue: 18
            }
        } as Input
    })
    contents.paths.push({
        id: 101,
        forwardTo: existingAskOnceData.find(x => x === "gender") === undefined ? undefined : 102,
        botMessages: [
            "Ok great, and what gender do you identify as?"
        ],
        answer: {
            name: "gender",
            type: "Select",
            options: [
                {
                    label: "Woman",
                    value: "Woman",
                    pathId: 102
                },
                {
                    label: "Transgender-woman",
                    value: "TransgenderWoman",
                    pathId: 102
                },
                {
                    label: "Man",
                    value: "Man",
                    pathId: 102
                },
                {
                    label: "Transgender-man",
                    value: "TransgenderMan",
                    pathId: 102
                },
                {
                    label: "Non-binary",
                    value: "NonBinary",
                    pathId: 102
                },
                {
                    label: "Not listed",
                    value: "NotListed",
                    pathId: 102
                },
                {
                    label: "I prefer not to say",
                    value: "NotSpecified",
                    pathId: 102
                }
            ]
        } as Select
    })
    contents.paths.push({
        forwardTo: existingAskOnceData.find(x => x === "email") === undefined ? undefined : 103,
        id: 102,
        botMessages: [
            "And finally, what's your email address?"
        ],
        answer: {
            name: "email",
            type: "Input",
            inputType: "text",
            pathId: 103,
            regexValidator: {
                message: "Please enter a valid email address",
                regex: "^(.+)@(.+)$"
            }
        } as Input
    })
    contents.paths.push({
        id: 103,
        botMessages: [
            "I've finished asking questions! Let's take a look at your goal and how to reach it!"
        ]
    });
    contents.paths.push({
        id: 400,
        botMessages: [
            "Unfortunately some of the responses you've given me don't work with the way I do the calculations.",
            "I assume your retirement age is 67, and that you want to achieve your goal before retirement. If you’ve said you want to achieve your goal after you've reached this age, the maths doesn’t work.",
            "So please start the chat again and bear in mind the retirement age limit."
        ]
    });
    contents.paths.push({
        id: 500,
        botMessages: [
            "Unfortunately I'm not able to calculate your goal. Sorry about that. Bear with me though.",
            "This can sometimes happen when our services are temporarily unavailable.",
            "Feel free to restart the chat and try again!"
        ]
    })

    return contents
}

export class Script {
    paths = new Array<Path>();
}

export class Path {
    id: number
    forwardTo?: number
    botMessages: string[]
    answer?: Answer
}

export class Answer {
    type: string
    name: string
}

export class Radio extends Answer {
    options: Array<MultipleChoiceRadioOption>
}

export class MultipleChoiceRadioOption {
    label: string
    value: string
    explainer?: MultipleChoiceRadioOptionExplainer
    pathId?: number
}

export class MultipleChoiceRadioOptionExplainer {
    description: string
    bullets: Array<string>
}

export class Input extends Answer {
    inputType: string
    inputFormatter?: InputFormatter
    postPlaceholderText?: string
    textLengthValidator?: TextLengthValidator
    regexValidator?: RegexValidator
    numberValidator?: NumberValidator
    pathId?: number
}

type InputFormatter =
    'Currency'

export class Select extends Answer {
    options: Array<SelectOption>
}

export class SelectOption extends Answer {
    label: string
    value: string
    pathId?: number
}

export class TextLengthValidator {
    maxLength?: number
    minLength?: number
    message: string
}

export class RegexValidator {
    regex: string
    message: string
}

export class NumberValidator {
    maxValue?: number
    minValue?: number
    message: string
}