import { useContext, useState } from "react";
import { Columns, Content, Form } from "react-bulma-components";
import "./RadioOptions.scss";
import chevron from "./expander-chevron.svg";
import { AnswerProps } from "../Answer/Answer";
import { ChatContext } from "../Chat";

export interface RadioOptionsProps extends AnswerProps {
    name: String,
    children?: React.ReactElement<RadioOptionProps>[]
}

export function RadioOptions(props: RadioOptionsProps) {

    let extended = props.children?.map((x) => {
        return <RadioOption {...x.props} key={x.key} />
    });

    return (<>{extended}</>);
}

export interface RadioOptionProps {
    name?: string;
    value: string;
    label: string;
    nextPath?: number;
    children?: React.ReactElement<RadioOptionExplainerProps>;
}

export function RadioOption(props: RadioOptionProps) {

    const [isExpanded, toggleExpand] = useState(false);

    const context = useContext(ChatContext);

    let expanderStyles = isExpanded
        ? "image is-16x16 expander is-expanded"
        : "image is-16x16 expander";

    let answerStyles = "chat-message answer";
    if (props.children) {
        answerStyles += " is-clickable";
    }

    function onSelected() {
        context.onAnswerReceived?.(props.label, props.value, props.nextPath)
    }

    let extendedExplainer = {
        ...props?.children?.props,
        isExpanded: isExpanded
    }

    return (
        <div className="multiple-choice-radio-option">
            <Columns {...{ breakpoint: 'mobile' }}>
                <Columns.Column>
                    <div className="chat-message-line">
                        <div className={answerStyles} onClick={() => toggleExpand(!isExpanded)}>
                            <Columns {...{ breakpoint: 'mobile' }}>
                                <Columns.Column>
                                    <Form.Control>
                                        <label className="radio">
                                            <input type="radio" name={props.name} onChange={_ => onSelected()} />
                                        </label>
                                        <span>{props.label}</span>
                                        {
                                            <RadioOptionExplainer {...extendedExplainer} />
                                        }
                                    </Form.Control>
                                </Columns.Column>
                                {
                                    props.children
                                        ?
                                        (
                                            <Columns.Column size={1}>
                                                <figure className={expanderStyles}>
                                                    <img src={chevron} alt="expander" />
                                                </figure>
                                            </Columns.Column>
                                        )
                                        : (<></>)
                                }
                            </Columns>
                        </div>
                    </div>
                </Columns.Column>
                <Columns.Column size={4}></Columns.Column>
            </Columns>
        </div>
    )
}

export interface RadioOptionExplainerProps {
    isExpanded?: Boolean;
    children?: React.ReactNode;
}

export function RadioOptionExplainer(props: RadioOptionExplainerProps) {

    if (!props.isExpanded) {
        return <></>;
    }

    return (
        <Content className="pt-2">
            {props.children}
        </Content>
    );
}