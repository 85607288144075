
import { SecondaryNav } from "../../../../components/SecondaryNav/SecondaryNav";
import { FeatureFlag, isFeatureEnabled } from "../../../../helpers/feature-flags";

export const Header = () => {

    const links = [];

    if (isFeatureEnabled(FeatureFlag.Equities)) {
        links.push({ to:"/invest/funds", name:"Funds" })
    }

    links.push({ to:"/invest/tools", name:"Tools" });

    if (isFeatureEnabled(FeatureFlag.Property)) {
        links.push({ to:"/invest/property", name:"Property" })
    }

    return (
        <SecondaryNav links={links} />
    );
}