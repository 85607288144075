import { useContext, useEffect, useState } from "react"
import { Content } from "../../../../../components/Layout/Content/Content"
import { Glass } from "../../../../../components/Layout/Glass/Glass"
import { Section } from "../../../../../components/Layout/Section/Section"
import { Summary } from "../../components/Summary/Summary"
import { PortfoliosClientFactory } from "../../../services/PorfoliosClientFactory"
import { RequestRebalance_Request, RequestRebalance_Request_FundAllocation } from "../../../services/generated/PortfoliosClient"
import { Link, useNavigate } from "react-router-dom"
import { FundsContext } from "../../components/FundsContext"
import { useWpLoader } from "../../../../../hoc/withLoader"
import { WpPostTypeRequest } from "../../../../../components/Auth/AuthenticatedCmsClient"
import { Loader } from "../../../../../components/Loader/Loader"

interface ChangeFundConfirmationProps {
    accountType: string,
    fundName: string,
    fundIsin: string,
    portfolioId: string
}

export const ChangeFundConfirmation = ({ accountType, fundName, fundIsin, portfolioId } : ChangeFundConfirmationProps) => {
    const context = useContext(FundsContext);
    const navigate = useNavigate();
    const [isProcessing, setIsProcessing ] = useState<boolean>(false);
    const client = PortfoliosClientFactory();
    const [wpLoaderState, wpLoader] = useWpLoader();

    useEffect(() => {
        wpLoader.load(new WpPostTypeRequest({
            url: "fund"
        }));
    }, []);


    let cmsFund = wpLoaderState.response?.find((y : any) => y.acf.isin === context.portfolio?.fundAllocations![0]!.fundIsin);


    const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        setIsProcessing(true);

        try {
            await client.requestRebalance(new RequestRebalance_Request({
                fundAllocations: [
                    new RequestRebalance_Request_FundAllocation({
                        allocationPercentage: 1,
                        fundIsin: fundIsin
                    })
                ],
            }), portfolioId);

            // Bit hacky here but need to refresh the cache to reflect the new fund
            context.refreshPortfolioData();
            
        } catch (e) {
            setIsProcessing(false);
        }

        navigate("/invest/funds");
    }

    if (!cmsFund) return (
        <Loader />
    );


    return (
        <div className="column is-12 is-7-widescreen">
            <Glass>
                <Section>
                    <div className="container" id="available-funds">
                        <h1 className="is-size-2-tablet is-size-4-mobile has-text-navy has-text-centered-mobile mb-3">Change your fund</h1>
                        <Content>
                                <Summary>
                                    <Summary.Title>
                                        <p className="is-size-5 has-text-weight-semibold has-text-purple-blue-500">Review</p>
                                    </Summary.Title>
                                    <Summary.Item description="Account type" value={accountType} />
                                    <Summary.Item description="Fund"value={fundName} />
                                </Summary>
                        </Content>
                        <Section>
                            <Content>
                                <p className="is-size-5 has-text-purple-blue-500 has-text-weight-semibold mt-6">Before we continue, we just need to confirm a few more things.</p>
                            </Content>
                            <form onSubmit={handleFormSubmit}>
                                <Content>
                                    <p className="has-text-purple-blue-500 mt-4 has-text-weight-medium">Key Investment Information and Ex-ante Document</p>
                                    <p>The <a href={cmsFund.acf.kiid.url} target="_blank">KIID</a> and <a href="https://propelle.io/ex-ante-cost-document" target="_blank">Ex-ante</a> contains all the information relating to the fund you're switching to. </p>
                                </Content>
                                <Content>
                                    <p className="has-text-purple-blue-500 mt-4 has-text-weight-medium">Plan switch timeline</p>
                                    <p>You understand that switching plans requires us to sell all your investment from your current plan and reinvest them in your new plan. This can take up to 2-3 working days.</p>
                                </Content>
                                <button type="submit" className="button is-primary is-rounded mt-4" disabled={isProcessing}>Confirm</button>
                            </form>
                        </Section>
                    </div>
                </Section>
            </Glass>
        </div>
    )
}