import { useContext, useEffect, useState } from "react";
import { InvestmentsDashboard } from "../../../../../components/InvestmentsDashboard/InvestmentsDashboard";
import { TradingClientFactory } from "../../../../../services/TradingClientFactory";
import { FundsContext } from "../../../../components/FundsContext";
import dayjs from "dayjs";
import { formatCurrency } from "../../../../../../../helpers/currency";
import { BackButton } from "../../../../../../../components/BackButton/BackButton";
import { useLoader } from "../../../../../../../hoc/withLoader";
import { GetAccountHolder_Response_Root } from "../../../../../services/generated/AccountManagementClient";
import { AccountManagementClientFactory } from "../../../../../services/AccountManagementClientFactory";

interface ContractNoteOverlayDetailsProps {
    transactionId?: string;
    orderId?: string;
    transactionType?: "Deposit" | "Withdrawal" | "Rebalance";
    hideOrderDetails: (transactionId: string) => void;
}

export const ContractNoteOverlayDetails = ({ transactionId, orderId, transactionType, hideOrderDetails } : ContractNoteOverlayDetailsProps) => {
    const [order, setOrder] = useState<Order | undefined>(undefined);
    const [loaderState, loader] = useLoader<GetAccountHolder_Response_Root>();
    const fundsContext = useContext(FundsContext);
    const client = AccountManagementClientFactory();

    useEffect(() => {
        fundsContext.fetchFundsContent();

        loader.load(client.getAccountHolder());

        const fetchData = async () => {
            if (transactionId !== undefined && orderId !== undefined && transactionType !== undefined) {
                let response = await getOrder(transactionId, orderId, transactionType);
                setOrder(response);
            }
        }
        fetchData();
    }, [transactionId, orderId, transactionType]);

    var fund = fundsContext.fundsContent !== undefined
        ? fundsContext.fundsContent.find((x : any) => x.acf.isin === order?.isin)
        : undefined;

    return ( order !== undefined &&
        <>
            <InvestmentsDashboard.Overlay.OverlaySection>
                <BackButton action={() => hideOrderDetails(transactionId!)}/>
                <p className="has-text-purple-blue-500 has-text-weight-semibold" style={{fontSize: "20px"}}>Contract Note</p>
            </InvestmentsDashboard.Overlay.OverlaySection>
            <InvestmentsDashboard.Overlay.OverlaySection>
                <p className="has-text-purple-blue-500 has-text-weight-medium">Client name</p>
                <p>{loader.defaultDisplayUntilLoaded(<>{loaderState.response?.firstName} {loaderState.response?.lastName}</>)}</p>
            </InvestmentsDashboard.Overlay.OverlaySection>
            <InvestmentsDashboard.Overlay.OverlaySection>
                <div className="columns is-mobile">
                    <div className="column">
                        <p className="has-text-purple-blue-500 has-text-weight-medium">Isin</p>
                        <p>{ order.isin }</p>
                    </div>
                    <div className="column">
                        <p className="has-text-purple-blue-500 has-text-weight-medium">Fund name</p>
                        <p>{ fund?.title }</p>
                    </div>
                </div>
            </InvestmentsDashboard.Overlay.OverlaySection>

            <InvestmentsDashboard.Overlay.OverlaySection>
                <p className="has-text-purple-blue-500 has-text-weight-medium">{ order.dealType === "Buy" ? "Date invested" : "Date sold down" }</p>
                <p>{ dayjs(order.movementDate).format("DD/MM/YYYY") }</p>
            </InvestmentsDashboard.Overlay.OverlaySection>

            <InvestmentsDashboard.Overlay.OverlaySection>
                <div className="columns is-mobile">
                    <div className="column">
                        <p className="has-text-purple-blue-500 has-text-weight-medium">Order Type</p>
                        <p>{ order.orderType }</p>
                    </div>
                    <div className="column">
                        <p className="has-text-purple-blue-500 has-text-weight-medium">Deal type</p>
                        <p>{ order.dealType }</p>
                    </div>
                </div>
            </InvestmentsDashboard.Overlay.OverlaySection>

            <InvestmentsDashboard.Overlay.OverlaySection>
                <div className="columns is-mobile">
                    <div className="column">
                        <p className="has-text-purple-blue-500 has-text-weight-medium">Unit quantity</p>
                        <p>{ order.unitQuantity !== undefined ? order.unitQuantity : "Pending" }</p>
                    </div>
                    <div className="column">
                        <p className="has-text-purple-blue-500 has-text-weight-medium">Unit price</p>
                        <p>{ order.unitPrice !== undefined ? formatCurrency(order.unitPrice) : "Pending" }</p>
                    </div>
                </div>
            </InvestmentsDashboard.Overlay.OverlaySection>
            
            <InvestmentsDashboard.Overlay.OverlaySection background="Purple" className="py-4 is-rounded-bottom">
                <div className="columns is-mobile">
                    <div className="column">
                        <p className="has-text-purple-blue-500 has-text-weight-medium">Total</p>
                    </div>
                    <div className="column">
                        <div className="is-fullheight is-flex is-flex-direction-row is-align-items-center is-justify-content-end">
                            <p className="has-text-purple-blue-500 has-text-weight-medium">{ order.consideration !== undefined ? formatCurrency(order.consideration) : "Pending"}</p>
                        </div>
                    </div>
                </div>
            </InvestmentsDashboard.Overlay.OverlaySection>
        </>
        )
}

class Order {
    isin: string;
    movementDate?: Date;
    orderType: string;
    dealType: string;
    unitQuantity?: number;
    unitPrice?: number;
    consideration?: number;

    constructor(isin: string, orderType: string, dealType: string, movementDate?: Date, unitQuantity?: number, unitPrice?: number, consideration?: number) {
        this.isin = isin;
        this.movementDate = movementDate;
        this.orderType = orderType;
        this.dealType = dealType;
        this.unitQuantity = unitQuantity;
        this.unitPrice = unitPrice;
        this.consideration = consideration;
    }
}

const getOrder = async (transactionId: string, orderId: string, transactionType: "Deposit" | "Withdrawal" | "Rebalance"): Promise<Order> => {
    const client = TradingClientFactory();
    let response;

    switch(transactionType) {
        case "Deposit":
            response = await client.getDepositOrder(transactionId, orderId);
            break;
        case "Withdrawal":
            response = await client.getWithdrawalOrder(transactionId, orderId);
            break;
        case "Rebalance":
            response = await client.getRebalanceOrder(transactionId, orderId);
            break;
    }

    return new Order(
        response.isin!,
        response.orderType!,
        response.dealType!,
        response.dateInvested,
        response.unitQuantity,
        response.unitPrice,
        response.consideration
    );
}