import { getUserData } from "../../../helpers/auth";

declare global {
    namespace JSX {
      interface IntrinsicElements {
        'stripe-pricing-table': React.DetailedHTMLProps<
          React.HTMLAttributes<HTMLElement>,
          HTMLElement
        >;
      }
    }
  }

export const PricingTable = () => {

    const userInfo = getUserData();

    const pricingTableId = process.env.STRIPE_PRICING_TABLE_ID ?? '';
    const publishableKey = process.env.STRIPE_PUBLISHABLE_KEY ?? '';

    return (
        <div className="py-8 has-background-grey-100 is-rounded-2">
            <stripe-pricing-table 
                pricing-table-id={pricingTableId}
                publishable-key={publishableKey}
                client-reference-id={userInfo.id}
                customer-email={userInfo.email}>
            </stripe-pricing-table>
        </div>
    )
}


