import { Helmet } from "react-helmet-async";
import { Main } from "../../../../components/Layout/Main/Main";
import { Container } from "react-bulma-components";
import { PageTitleWithinGlass } from "../../../../components/Layout/PageTitle/PageTitle";
import { LoaderFunction, useLoaderData } from "react-router-dom";
import { CourseTaxonomyParams, Order, OrderBy, UnitParams, WpPostTypeRequest, WpTaxonomyRequest, useAuthenticatedCmsClient } from "../../../../components/Auth/AuthenticatedCmsClient";
import { Glass } from "../../../../components/Layout/Glass/Glass";
import { useLoader, useWpLoader } from "../../../../hoc/withLoader";
import { EducationClientFactory } from "../../services/EducationClientFactory";
import { GetCourses_Response, GetCourses_Response_CourseResponse, GetCourses_Response_LevelResponse, GetCourses_Response_TopicResponse } from "../../services/generated/EducationClient";
import { useEffect, useMemo } from "react";
import Breadcrumb from "../../../../components/Breadcrumb/Breadcrumb";
import { Section } from "../../../../components/Layout/Section/Section";
import { BackButton } from "../../../../components/BackButton/BackButton";
import { DetailedLevelCard } from "../components/DetailedLevelCard/DetailedLevelCard";
import { Level } from "../utils/Level";
import { Unit } from "../utils/Unit";
import { AuthHelper } from "../../../../components/Auth/AuthHelper";
import { FeatureFlag, isFeatureEnabled } from "../../../../helpers/feature-flags";

export const loader: LoaderFunction = async ({ params }) => {

    let client = useAuthenticatedCmsClient();
    let levelResponse = await client!.getAs(new WpTaxonomyRequest({
        url: "course-taxonomy",
        params: new CourseTaxonomyParams({
            slug: params!.levelSlug,
            include_children: true,
            include_topics: true, // need duration
            include_parent: true
        })
    }), Level);

    try {
        let level : Level = levelResponse.data[0];

        if (level === undefined) {
            throw new Response("This level doesn't exist.", { status: 404 });
        }

        if (isFeatureEnabled(FeatureFlag.Membership)) {
            // Level 2 and above are members only
            if (parseInt(level.level) > 1 && !AuthHelper.isPropelleMember()) {
                throw new Response("You don't have access to this lesson.", { status: 403 });
            }
        }

        return { wpLevel: level };

    } catch (e) {

        if (e instanceof Response) {
            throw e;
        }

        throw new Response("Bad request.", { status: 400 });
    }
}

interface LoaderData {
    wpLevel : Level
}

export const LevelOverview = () => {
    const { wpLevel } = useLoaderData() as LoaderData;
    const educationClient = EducationClientFactory();
    const [loaderState, { load, defaultDisplayUntilLoaded }] = useLoader<GetCourses_Response>();
    const [wpLoaderState, wpLoad] = useWpLoader();

    // Level which will update with wp unit data when available
    const levelWithUnitData = useMemo(() => {
        // Need to add on unit data to calculate percentages etc
        if (wpLoaderState.loadingState === "Loaded") {
            wpLevel.topics.forEach(topic => {
                let units = wpLoaderState.response?.filter((unit : any) => (unit['course-taxonomy'] as any[]).map(x => x.id).includes(topic.id));
                // Adds unit to topics in order from cms (which is correct!)
                units.forEach((unit: any, i: number) => {
                    if (topic.units.find((u : Unit) => u.id === unit.id) === undefined) {
                        topic.units.push(new Unit({...unit, order: i}));
                    }
                });
            });
        }
        return wpLevel;
    }, [wpLoaderState.loadingState]);

    // Level which will update with education client data when available
    const levelAllData = useMemo(() => {
        if (loaderState.loadingState === "Loaded" && wpLoaderState.loadingState === "Loaded") {

            // Makes data easier to work with
            let levelCompletionData = loaderState.response?.courses
                ?.flatMap((course : GetCourses_Response_CourseResponse) => {
                    return course.levels ?? [];
                })
                .find((level : GetCourses_Response_LevelResponse) => level.levelId === levelWithUnitData.id);


            let currentTopicSet = false;
            levelWithUnitData.topics.forEach(topic => {
                let topicData = levelCompletionData?.topics?.find((topicData : GetCourses_Response_TopicResponse) => topicData.topicId === topic.id);
                
                // Set topics to complete or current if applicable
                topic.isComplete = topicData?.isComplete ?? false;
                if (!currentTopicSet && topic.isComplete === false) {
                    topic.isCurrentTopic = true;
                    currentTopicSet = true;
                }

                // Also set current unit if applicable
                const currentUnit = topic.units.find((unit : Unit) => unit.id === topicData?.currentUnitId);
                topic.currentUnitId = currentUnit ? topicData?.currentUnitId : 0                    
            });
        }
        return levelWithUnitData;
    }, [loaderState.loadingState, wpLoaderState.loadingState]);

    useEffect(() => {
        load(educationClient.getCourses());
        wpLoad.load(new WpPostTypeRequest({
            url: "unit",
            params: new UnitParams({
                orderby: OrderBy.TermOrder,
                order: Order.Ascending,
                per_page: 100,
                in_topics: wpLevel.getTopicIds() 
            })
        }));
    }, []);

    return (
        <>
            <Helmet>
                <title>Learn - Course: {levelAllData.course.name}, Level: {levelAllData.name}</title>
            </Helmet>
            <Main>
                <Container>
                    <Section>
                        <Breadcrumb/>
                        <Glass equalPadding={true}>
                            <BackButton className="mb-3" to={`/learn/courses/${levelAllData.course.slug}`}/>
                            <PageTitleWithinGlass text={levelAllData.course.name}/>
                            {
                                defaultDisplayUntilLoaded(
                                    <DetailedLevelCard
                                        levelNum={levelAllData.level}
                                        totalTime={levelAllData.getTotalFormattedDuration()}
                                        name={levelAllData.name}
                                        description={levelAllData.description}
                                        topicsComplete={levelAllData.getCompletedTopics()}
                                        status={levelAllData.status}
                                        topics={levelAllData.topics}
                                        levelUrl={`/learn/courses/${levelAllData.course.slug}/${levelAllData.slug}`}
                                    />
                                )
                            }
                        </Glass>
                    </Section>
                </Container>
            </Main>
        </>
    );
}