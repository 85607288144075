import { Columns, Container } from "react-bulma-components";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AccountManagementClientFactory } from "../../../../services/AccountManagementClientFactory";
import { UpdateAccountHolder_Request_Root } from "../../../../services/generated/AccountManagementClient";
import { useContext, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import JwtDecode, { JwtPayload } from "jwt-decode";
import { EquityContext } from "../OnboardingContext";
import { Main } from "../../../../../../components/Layout/Main/Main";
import { Glass } from "../../../../../../components/Layout/Glass/Glass";
import { BackButton } from "../../../../../../components/BackButton/BackButton";
// import { ProgressBar } from "../../components/ProgressBar/ProgressBar";


type FormInputs = {
    nino: string;
};

type FormVisibility = {
    isUkTaxResidentVisible: boolean,
    isNiVisibile: boolean,
    isSubmitVisible: boolean
}

const useFormVisibility = () => {
    const [state, setState] = useState<FormVisibility>({
        isUkTaxResidentVisible: false,
        isNiVisibile: false,
        isSubmitVisible: false
    });

    const onUsCitizenshipDenied = () => {
        if (!state.isUkTaxResidentVisible) {
            setState({
                ...state,
                isUkTaxResidentVisible: true
            });
        }
    }

    const onUkTaxResidencyConfirmed = () => {
        if (!state.isNiVisibile || !state.isSubmitVisible) {
            setState({
                ...state,
                isNiVisibile: true,
                isSubmitVisible: true
            });
        }
    }

    return {
        visibility: state,
        visibilityAdjustments: { onUsCitizenshipDenied, onUkTaxResidencyConfirmed }
    };
}

export const Eligibility = () => {
    const client = AccountManagementClientFactory();
    const auth = useAuth();
    const { visibility, visibilityAdjustments } = useFormVisibility();
    const userData = useContext(EquityContext)?.userData;
    const reload = useContext(EquityContext)?.reload ?? (() => { });

    const {
        register,
        handleSubmit,
        setValue,
        formState: { isValid, errors }
    } = useForm<FormInputs>({ mode: "onChange" });
    let navigate = useNavigate();

    useEffect(() => {

        if (userData) {
            if (userData.isUsCitizen !== undefined && userData.isUsCitizen !== null && !userData.isUsCitizen) {
                visibilityAdjustments.onUsCitizenshipDenied();
            }
            if (userData.isUkTaxResident !== undefined && userData.isUkTaxResident !== null && userData.isUkTaxResident) {
                visibilityAdjustments.onUkTaxResidencyConfirmed();
            }
            setValue('nino', userData.nino || "", { shouldValidate: true })
        }
    }, [userData, visibility.isNiVisibile, visibility.isSubmitVisible, visibility.isUkTaxResidentVisible]);

    const onSubmit = (data: FormInputs) => {
        if (data) {
            const jwtPayload = JwtDecode<JwtPayload>(auth.user?.access_token || "");
            const emailAddress = (jwtPayload as any).propelle_email;
            const request = new UpdateAccountHolder_Request_Root({
                isUsCitizen: false,
                isUkTaxResident: true,
                nino: data.nino,
                email: emailAddress
            });
            if (userData) {
                client.updateAccountHolder(request, userData.accountHolderId)
                    .then(() => {
                        reload();
                        navigate(`/invest/funds/user-details/`)
                    })
                    .catch(() => {
                        // TODO navigate to error page
                    })
            }
        }
        else {
            // TODO navigate to error page
        }
    };

    const navigateToNotEligible = () => {
        navigate("/invest/funds/not-eligible")
    }

    const onUsCitizenshipSelected = (isUsCitizen: boolean) => {
        if (isUsCitizen) {
            navigateToNotEligible();
        } else {
            visibilityAdjustments.onUsCitizenshipDenied();
        }
    }

    const onUkTaxResidencySelected = (isUkTaxResident: boolean) => {
        if (isUkTaxResident) {
            visibilityAdjustments.onUkTaxResidencyConfirmed();
        } else {
            navigateToNotEligible();
        }
    }

    const preventSpaces = (e: React.KeyboardEvent) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };

    if (!userData) { return <></> }

    return (
        <Main>
            <Helmet>
                Create account
            </Helmet>
            <Container>
                    <BackButton to={"/invest/funds/account-setup"} text="Back" className="mb-3"/>
                    <div className="columns">
                        <div className="column is-10-widescreen is-8-fullhd">
                            <Glass opacity={100}>
                                <h1 className="is-size-2 is-size-4-mobile has-text-navy has-text-centered-mobile">Add your details</h1>
                                {/* <ProgressBar value={30}/> */}
                                <Columns >
                                    <Columns.Column className="is-5">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="pb-6 pt-4">
                                                <p className="pb-4 has-text-black is-size-5">Are you a US citizen?</p>
                                                <div className="radio-toggle">
                                                    <input type="radio" id="usCitizenRadioYes" name="radioUsCitizen" className="is-clickable" value="true" onClick={() => onUsCitizenshipSelected(true)} />
                                                    <label htmlFor="usCitizenRadioYes" className="left is-clickable">Yes</label>

                                                    <input type="radio" id="usCitizenRadioNo" name="radioUsCitizen" value="false" onClick={() => onUsCitizenshipSelected(false)} defaultChecked={userData.isUsCitizen !== undefined && userData.isUsCitizen !== null && !userData.isUsCitizen} />
                                                    <label htmlFor="usCitizenRadioNo" className="right is-clickable" >No</label>
                                                </div>
                                            </div>
                                            <div className={"pb-6 " + (!visibility.isUkTaxResidentVisible ? "is-hidden" : "")}>
                                                <p className="pb-4 is-size-5 has-text-black">Are you a UK tax resident?</p>
                                                    <div className="radio-toggle">
                                                        <input id="ukTaxResidentRadioYes" type="radio" value="true" name="radioUkTaxResident" onClick={() => onUkTaxResidencySelected(true)} className="is-clickable" defaultChecked={userData.isUkTaxResident !== undefined && userData?.isUkTaxResident !== null && userData.isUkTaxResident} />
                                                        <label htmlFor="ukTaxResidentRadioYes" className="left is-clickable">Yes</label>
                                                        <input id="ukTaxResidentRadioNo" type="radio" value="false" name="radioUkTaxResident" onClick={() => onUkTaxResidencySelected(false)} className="is-clickable" />
                                                        <label htmlFor="ukTaxResidentRadioNo"className="right is-clickable">No</label>
                                                    </div>
                                            </div>
                                            <div className={"pb-6 " + (!visibility.isNiVisibile ? "is-hidden" : "")}>
                                                <p className="pb-4 is-size-5 has-text-black">What is your national insurance number?</p>
                                                <input {...register('nino', {
                                                    required: "National insurance number is required",
                                                    pattern: {
                                                        value: /^(?!BG|GB|KN|NK|NT|TN|ZZ|PP|PZ|PY|NC)[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z][0-9]{6}[A-D]{1}$/i,
                                                        message: "Invalid National Insurance number"
                                                    },
                                                    validate: {
                                                        noSpaces: value => !/\s/.test(value) || 'No spaces are allowed in the National insurance number'
                                                    }
                                                })} className="input" type="text" placeholder="National insurance number*" onKeyDown={preventSpaces}/>
                                                {errors.nino && <p className="is-size-6">{errors.nino.message}</p>}
                                            </div>
                                            <button type="submit" className={`button is-primary is-rounded ${!visibility.isSubmitVisible ? "is-hidden" : ""}`} disabled={!isValid}>Next</button>
                                        </form>
                                    </Columns.Column>
                                </Columns>
                            </Glass>
                        </div>
                    </div>
            </Container>
        </Main>
    );
};