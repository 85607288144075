import { Card, Columns } from 'react-bulma-components';
import "./DetailedLevelCard.scss"
import { ProgressBar } from '../../../../../components/ProgressBar/ProgressBar';
import { PlayCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import { Topic } from '../../utils/Topic';
import { defaultLoaderDisplay } from '../../../../../hoc/withLoader';

export interface DetailedLevelCardProps {
  description: string,
  levelNum: string,
  levelUrl: string,
  status: string,
  name: string,
  topics: Array<Topic>
  topicsComplete?: number,
  totalTime: string
}

export const DetailedLevelCard = ({ description, levelNum, levelUrl, status, name, topics, topicsComplete, totalTime }: DetailedLevelCardProps) => {

  return (
		<Card className={`has-text-purple-blue-500 level-card is-align-items-center p-6 px-4-mobile pb-3-mobile ${status}`}>
			<Columns className="reverse-columns">
		  		<Columns.Column className="level-num pb-0 py-0-mobile is-flex is-align-items-end" desktop={{ size: 7 }} >
					Level {levelNum}
		  		</Columns.Column>
				<Columns.Column className="total-time py-0">
					{`Total time - ${totalTime}`}
				</Columns.Column>
			</Columns>
		<Columns>
			<Columns.Column className="py-0" desktop={{ size: 7 }}>
				<h5 className="is-family-primary is-size-5 has-text-weight-medium">{name}</h5>
			</Columns.Column>
			{
				topics.length > 0 && 
					<Columns.Column className="py-0 is-hidden-mobile is-flex-tablet is-justify-content-end">
						{
							topicsComplete === undefined
								? defaultLoaderDisplay()
								: `${topicsComplete}/${topics.length} topics complete`
						}
					</Columns.Column>
			}
		</Columns>
		<Columns >
			<Columns.Column className="level-description py-0">
				{description}
			</Columns.Column>
		</Columns>
		{
			topics && (
				<div className="topics-container">
					{
						topics.map((topic: Topic) => {
							return (
								<Columns key={topic.id} className="topic-row p-2 px-1-mobile m-0">
										<Columns.Column className="title-and-duration p-0" desktop={{ size: 8 }}>
											<span className="topic-title">{ topic.name }</span>
											<div className="topic-duration">
												<i>{ topic.formattedDuration() }</i>
											</div>
										</Columns.Column>
										<Columns.Column className="topic-progress-bar p-0 pl-2 is-auto-desktop">
											<div className="progress-bar-container">
												{
													topic.percentageComplete !== undefined
														? <>
															<ProgressBar values={[{
																value: topic.percentageComplete ?? 0,
																color: "#A594F9",
																filledColor: "#7FE97D"
															}]} />
															<div className="percentage-label">
																{ topic.percentageComplete }%
															</div>
														</>
													: defaultLoaderDisplay()
												}
											</div>
										</Columns.Column>
										<Columns.Column className="topic-play-icon p-0 pl-1-mobile is-auto is-align-items-center is-justify-content-end">
											<Link className={"url-icon " + topic.status} to={`${levelUrl}/${topic.slug}`}>
												<PlayCircle className="level-card-icon play-icon" />
											</Link>
										</Columns.Column>
								</Columns>
						)}
					)}
				</div>
			)}
	  	</Card>
  	);
};