import { Helmet } from "react-helmet-async";
import { Main } from "../../../../../components/Layout/Main/Main";
import { AccountManagementClientFactory } from "../../../services/AccountManagementClientFactory";
import { Onboarding } from "./components/Onboarding";
import { Section } from "../../../../../components/Layout/Section/Section";
import { TagContentCarousel } from "../../../../../components/ContentCarousel/TagContentCarousel";
import { FundsDashboard } from "./components/FundsDashboard/FundsDashboard";
import { Container } from "react-bulma-components";
import { ActionsContainer } from "./components/Actions/Actions";
import { FAQMiniContainer } from "../../../../../components/FAQs/FAQMini";
import { BasicTile } from "../../../../../components/Layout/Wrappers/BasicTile";
import { FundCard } from "../../../../../components/Cards/FundCard/FundCard";
import { AccountCard } from "./components/AccountCard/AccountCard";
import { AccountType, GetOnboardingStage_OnboardingStage, GetOnboardingStage_Response_Root } from "../../../services/generated/AccountManagementClient";
import { useLoader } from "../../../../../hoc/withLoader";
import { useContext, useEffect } from "react";
import { FundsContext } from "../../components/FundsContext";
import { Loader } from "../../../../../components/Loader/Loader";
import { Fund } from "../../dataComponents/AvailableFundsContainer";
import { ScreenSize, WindowContext } from "../../../../../components/App/WindowContext";

export const Dashboard = () => {
    const { screenSize } = useContext(WindowContext);
    const client = AccountManagementClientFactory();
    const [loaderState, loader] = useLoader<GetOnboardingStage_Response_Root>();

    var fundsContext = useContext(FundsContext);

    useEffect(() => {
        loader.load(client.getOnboardingStage());
    }, []);

    useEffect(() => {
        if (loaderState.response?.stage === GetOnboardingStage_OnboardingStage.Complete) {
            fundsContext.fetchPortfolio();
            fundsContext.fetchFundsContent();
            fundsContext.fetchPortfolioBalance();
        }
    }, [loaderState.loadingState]);

    var chosenFund = fundsContext.fundsContent !== undefined
        ? fundsContext.fundsContent.find((x : any) => x.acf.isin === fundsContext.portfolio?.fundAllocations![0]?.fundIsin)
        : undefined; 

    const inner = (stage: GetOnboardingStage_OnboardingStage) => {

        if (stage !== GetOnboardingStage_OnboardingStage.Complete) {
            return <Onboarding />
        } else {
            return <>
                        <h1 className="is-size-h3 has-text-purple-blue-500 mb-4 mb-6-desktop mt--2 mt-0-desktop has-text-centered-touch">Your funds dashboard</h1>
                        <Section>
                            <div className="columns is-desktop">
                                <div className="column is-8">
                                    <FundsDashboard currentValue={fundsContext.balance?.holdingsBalanceExcludingReservations} simpleReturn={fundsContext.balance?.simpleReturn?.percentage} portfolioId={fundsContext.portfolio?.id}/>
                                    { 
                                        screenSize < ScreenSize.Desktop
                                            && <ActionsContainer wrap={(children: JSX.Element) => <BasicTile className="mt-4 mb-3">{children}</BasicTile>}/>
                                    }
                                    <div className="columns mt-3">
                                        <div className="column is-half-desktop">
                                            <BasicTile>
                                                {
                                                    fundsContext.balance === undefined || fundsContext.account === undefined
                                                        ? <Loader />
                                                        : <AccountCard
                                                            type={fundsContext.account?.accountType!}
                                                            breakdown={{
                                                                current: fundsContext.balance?.holdingsBalanceExcludingReservations!,
                                                                incomingContributions: fundsContext.balance?.incomingContributions?.currentTaxYear!,
                                                                settledContributions: fundsContext.balance?.settledContributions?.currentTaxYear!,
                                                            }}
                                                            maxLimit={fundsContext.account?.accountType === AccountType.Isa ? 20000 : undefined} />
                                                }
                                            </BasicTile>
                                        </div>
                                        <div className="column is-half-desktop">
                                            {
                                                chosenFund === undefined
                                                    ? <Loader />
                                                    : <FundCard fund={new Fund(chosenFund)} selectable={false} hasBackground={true}/>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-4">
                                    { 
                                        screenSize >= ScreenSize.Desktop
                                            && <ActionsContainer wrap={(children: JSX.Element) => <BasicTile className="mb-5">{children}</BasicTile>}/>
                                    }
                                    <FAQMiniContainer platformArea="funds" wrap={(children: JSX.Element) => <BasicTile>{children}</BasicTile>} />
                                </div>
                            </div>
                        </Section>
                        <Section>
                            
                        </Section>
                        <Section className="pb-6">
                            <TagContentCarousel tag="equity" title="Funds Content"/>
                        </Section>
                    </>;
        }
    }

    return (
        <>
            <Helmet>
                <title>Funds</title>
            </Helmet>
            <Main>
                <Container>
                    { loader.defaultDisplayUntilLoaded(inner(loaderState.response?.stage!))}
                </Container>
            </Main>
        </>
    );
};