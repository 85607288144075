import { CssClasses } from "../Helpers/CssClasses";
import startOverIcon from "./start-over.svg";
import "./StartOver.scss";

interface StartOverProps {
    className?: string;
    onStartOver?: () => void;
}

export const StartOver = (props: StartOverProps) => {

    function onStartOver() {
        props.onStartOver?.()
    }

    let cssClasses = new CssClasses(["start-over"]);
    if (props.className) {
        cssClasses.add(props.className);
    }

    return (
        <div className={cssClasses.aggregated()}>
            <a onClick={onStartOver} className="is-flex is-flex-direction-row">
                <img className="start-over-img" src={startOverIcon} alt="Start over" />
                <span className="pl-2">Start over</span>
            </a>
        </div>
    );
};
