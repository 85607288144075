import { Container, Section, Content, Columns, Button } from "react-bulma-components";
import { Link, useNavigate } from "react-router-dom";
import { PropertyClientFactory } from "../../../services/PropertyClientFactory";
import property from "./property.png";
import signingUp from "./signing-up.png";
import thinking from "./thinking.png";
import bitsAndPieces from "./bits-and-pieces.jpg";
import { Helmet } from "react-helmet-async";

export const GetStarted = () => {

    const client = PropertyClientFactory();
    let navigate = useNavigate();

    let updateStatus = async () => {
        window.open('https://app.blendnetwork.com/users/sign_up#initialization?utm_source=propelle&utm_medium=referral&utm_campaign=signupflow', '_blank')?.focus();
        await client.visitBlend();
        navigate("/invest/property");
    };

    return <Section>
        <Helmet>
            <title>Get Started</title>
        </Helmet>
        <Container className="criss-cross-mobile is-max-desktop ml-0">
            <Columns>
                <Columns.Column tablet={{
                    size: 6
                }}>
                    <Content display="flex" alignItems="center">
                        <figure className="image mr-0">
                            <img src={property} alt="A potential property" />
                        </figure>
                    </Content>
                </Columns.Column>
                <Columns.Column size={6}>
                    <Content>
                        <h3>Get started</h3>
                        <h4>How it works</h4>
                        <ul>
                            <li>We've partnered with Blend, who is a Property Lending platform that specialises in allowing investors to collectively lend money (for a fixed expected return) to property developers in the UK. These property developers use the funds to develop homes for people to live in.</li>
                            <li>All property investment opportunities listed on our platform are sourced, due diligenced and managed by Blend.</li>
                        </ul>
                    </Content>
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column tablet={{
                    size: 6
                }}>
                    <Content display="flex" alignItems="center">
                        <figure className="image ml-0">
                            <img src={bitsAndPieces} alt="Blueprint, piggy bank, magnifying glass and model house" />
                        </figure>
                    </Content>
                </Columns.Column>
                <Columns.Column size={6}>
                    <Content>
                        <h4>What you get</h4>
                        <ul>
                            <li>Access to property lending investment opportunities where you can participate in loaning money (for a fixed expected return) to property developers alongside other investors</li>
                            <li>Targeting 8-12% per annum returns.</li>
                            <li>For up to 24 months typically.</li>
                            <li>Minimum investment £1,000</li>
                        </ul>
                        <p className="is-size-7">As with all investments your capital is at risk. Also past performance is not indicative of future performance.</p>
                    </Content>
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column tablet={{
                    size: 6
                }}>
                    <Content display="flex" alignItems="center">
                        <figure className="image mr-0">
                            <img src={signingUp} alt="Women typing on a laptop" />
                        </figure>
                    </Content>
                </Columns.Column>
                <Columns.Column size={6}>
                    <Content>
                        <h4>Signing up to Blend</h4>
                        <ul>
                            <li>You need to hold an account with Blend in order to make property investments.</li>
                            <li>Setting up an account is fairly straightforward, but it can take a few days for your account to be approved, as there are some requirements that you need to fulfil, like passing their Know Your Client (KYC) checks. KYC checks are a legal requirement where companies have to get to know more about you before they start working with you. You will also need to complete a suitability questionnaire to make sure that the investments are suitable for you before you go ahead and invest.</li>
                            <li>We recommend you go through the process in advance, so that you're ready to invest when a deal that you're interested in goes live.</li>
                        </ul>
                    </Content>
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column tablet={{
                    size: 6
                }}>
                    <Content display="flex" alignItems="center">
                        <figure className="image ml-0">
                            <img src={thinking} alt="A women thinking" />
                        </figure>
                    </Content>
                </Columns.Column>
                <Columns.Column size={6}>
                    <Content>
                        <h4>How to invest in a deal</h4>
                        <ul>
                            <li>Deals will become available at various times in a given month. Some months have more investment opportunities than others.</li>
                            <li>You'll need to have already deposited your funds on the Blend platform, so they're ready to be invested when a deal you like becomes available.</li>
                            <li>Blend suggests that you enable their “Auto Lend” feature to be able to participate in upcoming deals. We explain what this means, in depth in <a href="/learn/article/how-to-invest-in-a-property-debt-deal-with-blend-a-step-by-step-guide">this article here</a>.</li>
                        </ul>
                    </Content>
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column size={6}>
                    <div className="mt-3">
                        <p>By clicking agree, you have read and agree to our investors' declaration. <Link to="/invest/property/disclaimer" target={"_blank"}>You can read it here.</Link></p>
                        <Button onClick={updateStatus} rounded className="mt-8" color={'primary'}>Agree and Continue to Blend</Button>
                    </div>
                </Columns.Column>
            </Columns>
        </Container>
    </Section>;
}
