import { useContext, useEffect, useState } from "react";
import { LoaderFunction, useLoaderData } from "react-router-dom";
import { CourseTaxonomyParams, Order, OrderBy, Params, UnitParams, WpPostTypeRequest, WpTaxonomyRequest, useAuthenticatedCmsClient } from "../../../../../components/Auth/AuthenticatedCmsClient";
import { Container, Section, Tile, Content } from "react-bulma-components";
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat'
import './TopicPage.scss';
import { Helmet } from "react-helmet-async";
import { Button } from "../../../../../components/Button/Button";
import { BackButton } from "../../../../../components/BackButton/BackButton";
import { MultichoiceQuestion, PossibleAnswers } from "../../utils/Learn";
import { v1 } from "uuid"
import { EducationClientFactory } from "../../../services/EducationClientFactory";
import { MarkTopicComplete_Request, RecordQuizAnswers_Request, RecordQuizAnswers_Request_Answer, RecordTopicProgress_Request } from "../../../services/generated/EducationClient";
import { getTopicStorageInfo } from "../../../services/utils/taxonomyUtils";
import completedTopicImage from "./completedTopicImage.png";
import { defaultErrorDisplay, useWpLoader } from "../../../../../hoc/withLoader";
import { Link } from "react-router-dom";
import { WindowContext } from "../../../../../components/App/WindowContext";

dayjs.extend(AdvancedFormat);

export const loader : LoaderFunction = async ({ params }) => {

    let client = useAuthenticatedCmsClient();

    if (params.topicSlug === undefined || params.levelSlug === undefined || params.courseSlug === undefined) {
        throw new Error("Missing params.");
    }

    const courseTaxonomy = await client!
    .get(new WpTaxonomyRequest({
        url: "course-taxonomy",
        params: new CourseTaxonomyParams({
            slug: [params.topicSlug, params.levelSlug, params.courseSlug],
            per_page: 100,
            orderby: OrderBy.TermOrder,
            order: Order.Ascending
        })
    }));
    
    const topicInfo = courseTaxonomy.data.find(({ slug } : any) => slug === params.topicSlug)
    const levelInfo = courseTaxonomy.data.find(({ slug } : any) => slug === params.levelSlug)
    const courseInfo = courseTaxonomy.data.find(({ slug } : any) => slug === params.courseSlug)

    const quizQuestions: Array<MultichoiceQuestion> = topicInfo.acf.topic_quiz.multichoice_question;

    try {
        let questions: Array<MultichoiceQuestion> = quizQuestions;
        return { questions, topicInfo, levelInfo, courseInfo } as LoaderData;

    } catch {
        throw new Error("This unit doesn't exist.");
    }
}

interface LoaderData {
    questions: Array<MultichoiceQuestion>
    topicInfo: any
    levelInfo: any
    courseInfo: any
}

export interface UnitProgressBarProps {
    completedPercentage: number
    totalItemsCount: number
    completedItemsCount: number
    previousProgress: number
}

const UnitProgressBar: React.FC<UnitProgressBarProps> = (props: UnitProgressBarProps) => {

    const containerStyles : React.CSSProperties = {
        height: "16px",
        width: '100%',
        backgroundColor: "#CDCDCD",
        borderRadius: 50,
        margin: "auto",
        position: 'relative',
        overflow: 'hidden'
    }

    const fillerStyles : React.CSSProperties = {
        height: '100%',
        width: `${props.completedPercentage}%`,
        backgroundColor: props.previousProgress === 100 || props.completedPercentage === 100 ? '#7FE97D' : '#A594F9',
        marginLeft: "8px",
        borderRadius: ((props.completedPercentage === 0 || props.completedPercentage === 100) ? "8px" : "8px 0% 0% 8px"),
    }

    const revisitFillerStyles : React.CSSProperties = {
        height: '100%',
        width: `${props.previousProgress}%`,
        backgroundColor: "rgb(184 255 183)",
        borderRadius: ((props.previousProgress === 0 || props.previousProgress === 100) ? "8px" : "8px 0% 0% 8px"),
        position: 'absolute',
    }

    const dots = []
    for (let i = 0; i < props.totalItemsCount; i++) {
        const dotPosition = Math.round(((i) / props.totalItemsCount) * 100)
        dots.push(
            <div
                key={i}
                style={{
                    height: '16px',
                    width: '16px',
                    borderRadius: '8px',
                    left: `${dotPosition}%`,
                    position: 'absolute',
                    backgroundColor: props.previousProgress === 100 || props.completedPercentage === 100 ? '#7FE97D' : '#A594F9'
                }}
            />
        )
    }

    return (
        <div style={containerStyles}>
            {props.previousProgress === 100 &&
                <div style={revisitFillerStyles}>
                    <div style={fillerStyles} className="filled-area">
                        {dots}
                    </div>
                </div>
            }
            {props.previousProgress < 100 &&
                <>
                    <div style={fillerStyles} className="filled-area">
                        {dots}
                    </div>
                    <div style={revisitFillerStyles}>
                    </div>
                </>
            }
        </div>


    );
};

export default UnitProgressBar;

export const TopicPage = () => {
    const loaderData = useLoaderData() as LoaderData;
    const [loaderState, { load, defaultLoaderDisplay }] = useWpLoader();

    const setVideoHeight = () => {
        document.querySelectorAll('.wp-block-embed__wrapper iframe').forEach((iframe) => {
            let height = iframe.clientWidth * (9/16);

            iframe.setAttribute('style', `height: ${height}px`);    
        });
    }

    useEffect(() => {
        if (loaderState.loadingState === "NotStarted") {
            load(new WpPostTypeRequest({
                url: "unit",
                params: new UnitParams({
                    per_page: 100,
                    orderby: OrderBy.TermOrder,
                    order: Order.Ascending,
                    in_topics: [loaderData.topicInfo.id]
                })
            }));
        }
    }, [loaderState.loadingState]);

    const parser = new DOMParser();
    const [isLoading, setIsLoading] = useState(true);

    const [activeScreen, setActiveScreen] = useState("units")
    const [questionSubmitted, setQuestionSubmitted] = useState(false)
    const [coursesStorageResponse, setCoursesStorageResponse] = useState<any>(null);
    const [initialUnitIndex, setInitialUnitIndex] = useState(0)
    const [currentUnitIndex, setCurrentUnitIndex] = useState(0)
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const [clickedQuestionIndex, setClickedQuestionIndex] = useState(-1);
    const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
    const [aswersCollection, setAnswersCollection] = useState([]) as Array<any>;
    const { onWindowResize } = useContext(WindowContext);

    const educationClient = EducationClientFactory();

    let units : any[] = [];

    if (loaderState.loadingState === "Loaded" && loaderData?.topicInfo) {
        loaderState.response.forEach((unit: any) => {
            if (unit["course-taxonomy"].length > 0) {
                if ((unit["course-taxonomy"] as any[]).map(x  => x.id).includes(loaderData.topicInfo.id)) {
                    const { id, slug, acf: { unit_duration }, title, content } = unit;
                    units.push({ unitId: id, unitTile: parser.parseFromString(title, "text/html").body.textContent, slug, totalDuration: unit_duration, unitContent: content.rendered })
                }
            }
        });
    }

    useEffect(() => {
        const fetchCoursesStorageResponse = async () => {
            const response = await educationClient.getCourses();
            setCoursesStorageResponse(response);
        };
        fetchCoursesStorageResponse();
    }, []);

    useEffect(() => {
        if (coursesStorageResponse != null && loaderState.loadingState === "Loaded" && loaderData?.topicInfo) {
            const topicStorageInfo : any = getTopicStorageInfo({ topicId: loaderData.topicInfo.id, coursesStorageResponse: coursesStorageResponse })
            const topicStatusInStorage = (topicStorageInfo === undefined ||
                !topicStorageInfo.hasOwnProperty("currentUnitId"))
                    ? "topicNotStarted"
                    : topicStorageInfo.hasOwnProperty("isComplete") && topicStorageInfo["isComplete"] === true
                        ? "topicComplete"
                        : "topicInProgress"
            const initialIndex =
                topicStatusInStorage === "topicNotStarted"
                    ? 0
                    : units.findIndex((unit: any) => unit.unitId === topicStorageInfo.currentUnitId) + 1;

            const indexToDisplay =
                topicStatusInStorage === "topicNotStarted" || topicStatusInStorage === "topicComplete"
                    ? 0
                    : units.findIndex((unit: any) => unit.unitId === topicStorageInfo.currentUnitId) + 1;

            setInitialUnitIndex(initialIndex);
            setCurrentUnitIndex(indexToDisplay);
            setIsLoading(false);

            setTimeout(() => {
                setVideoHeight();
            }, 200);
        }
    }, [coursesStorageResponse, loaderState.loadingState, loaderData]);  

    onWindowResize(() => {
        setVideoHeight();
    });

    if (isLoading || loaderState.loadingState === "Loading") {
        return <Section>{defaultLoaderDisplay()}</Section>;
    }

    if (loaderState.loadingState === "Failed") {
        return <Section>{defaultErrorDisplay()}</Section>;
    }

    let currentUnit = units[currentUnitIndex]
    let unitsProgress = Math.round(((currentUnitIndex) / units.length) * 100)
    let previousProgress = Math.round(((initialUnitIndex) / units.length) * 100)
    let questionsProgress = Math.round(((currentQuestionIndex) / loaderData.questions.length) * 100);
    let currentQuestion = loaderData.questions[currentQuestionIndex];

    return (
        <Section className="pb-6 mt-0-mobile p-0-mobile pb-3-mobile max-width-desktop topic-page is-align-content-stretch" >
            <Helmet>
                <title>{parser.parseFromString(loaderData.topicInfo.name, "text/html").body.textContent}</title>
            </Helmet>

            <Container >
                <BackButton text="Back to level overview" className="ml-3-touch mt-4-mobile mb-4-desktop"/>
                <Tile className="glass-desktop p-6 p-4-mobile " >
                    <div className="tile is-child" >
                        <div className="is-flex unit-progress-container pl-1-mobile pr-1-mobile mb-5" >
                            <UnitProgressBar
                                previousProgress={previousProgress}
                                completedPercentage={
                                    activeScreen === "units" || activeScreen === "allUnitsRead"
                                        ? unitsProgress
                                        : questionsProgress
                                }
                                totalItemsCount={activeScreen === "units" || activeScreen === "allUnitsRead" ? units.length : loaderData.questions.length}
                                completedItemsCount={
                                    activeScreen === "units" || activeScreen === "allUnitsRead"
                                        ? currentUnitIndex
                                        : currentQuestionIndex
                                }
                            />
                            <div className="is-flex unit-progress-label">
                                {activeScreen === "units" || activeScreen === "allUnitsRead" ? unitsProgress : questionsProgress}%
                            </div>
                        </div>
                        {(currentUnit && activeScreen === "units") && (
                            <div>
                                <h1 className="title is-4">{currentUnit.unitTile}</h1>
                                <div className="wordpress-content" dangerouslySetInnerHTML={{ __html: currentUnit.unitContent }} />
                                <Button
                                    className="mt-3 is-fullwidth-mobile"
                                    hideIcon action={(e) => {
                                        if (initialUnitIndex <= currentUnitIndex) {
                                            const request = new RecordTopicProgress_Request({
                                                currentUnitId: currentUnit.unitId
                                            });
                                            educationClient.recordTopicProgress(request, loaderData.courseInfo.id, loaderData.levelInfo.id, loaderData.topicInfo.id).then(() => {
                                                if (currentUnitIndex < units.length) setCurrentUnitIndex(currentUnitIndex + 1);
                                                if (currentUnitIndex === units.length - 1) {
                                                    e.preventDefault();
                                                    const request = new MarkTopicComplete_Request({
                                                        topicId: loaderData.topicInfo.id
                                                    });
                                                    educationClient.markTopicComplete(request, loaderData.courseInfo.id, loaderData.levelInfo.id).then(() => {
                                                        currentUnit = false;
                                                        setActiveScreen("allUnitsRead")
                                                    })
                                                }
                                            })
                                        }
                                        if (currentUnitIndex < units.length) setCurrentUnitIndex(currentUnitIndex + 1);
                                        if (currentUnitIndex === units.length - 1) {
                                            setActiveScreen("allUnitsRead")
                                        }
                                    }
                                    }
                                >
                                    {initialUnitIndex <= currentUnitIndex ? " Mark as complete" : "Next"}

                                </Button>
                            </div>
                        )}
                        {activeScreen === "allUnitsRead" &&
                            <div className="topic-complete-container is-flex-direction-column is-flex is-align-items-center is-justify-content-center">
                                <div className="topic-complete-content is-flex-direction-column is-flex is-align-items-center is-justify-content-center is-fullwidth is-mid-width ">
                                    <Content display="flex" alignItems="center">
                                        <figure className="image mt-4">
                                            <img src={completedTopicImage} alt="Party Popper Emoji" />
                                        </figure>
                                    </Content>
                                    <h1 className="topic-complete-title is-flex is-align-items-center is-fullwidth">
                                        You’ve completed this topic, now we are going to test that knowledge.
                                    </h1>
                                    <div className="is-flex is-justify-content-center mt-5 mb-1 is-fullwidth is-align-center is-small-width-btn-container">
                                        <Button className="is-fullwidth is-align-center is-justify-content-center" hideIcon action={() => {
                                            setActiveScreen("quiz");
                                        }}>
                                            Start quiz
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        }
                        {(activeScreen === "quiz" && currentQuestion) &&

                            <div className="quiz-questions-container is-fullwidth is-flex-direction-column is-flex is-justify-content-center mb-5-mobile is-align-content-stretch">
                                <div className="is-flex is-flex-direction-column is-flex is-align-items-baseline is-justify-content-center">
                                    <div className="mt-1 mb-1 is-fullwidth">
                                        <p className="is-fullwidth quiz-question-index is-align-items-center mb-4">
                                            QUESTION  {currentQuestionIndex + 1}/{loaderData.questions.length}
                                        </p>
                                        <p className="is-fullwidth quiz-question-index is-align-items-center mt-2 is-align-center is-size-5 mb-4-tablet">
                                            {currentQuestion["question_text"]}
                                        </p>
                                    </div>
                                    <div className="is-flex is-align-content-stretch is-fullwidth  quiz-complete-btn-container is-flex is-flex-direction-column is-justify-content-center is-align-items-center ">
                                        {currentQuestion["possible_answers"].map((answerOption: PossibleAnswers, index: number) => {
                                            let selectedStyle = clickedQuestionIndex === index ? "selected" : ""
                                            let rightOrWrongStyle =
                                                answerOption["answer_option"].is_this_the_correct_answer === "yes"
                                                    ? "correct"
                                                    : "wrong"

                                            let styleAfterSubmit =
                                                questionSubmitted && answerOption["answer_option"].is_this_the_correct_answer === "yes"
                                                    ? rightOrWrongStyle + " btn-locked"
                                                    : questionSubmitted && clickedQuestionIndex === index
                                                        ? rightOrWrongStyle + " btn-locked"
                                                        : questionSubmitted
                                                            ? " btn-locked"
                                                            : ""
                                            return (
                                                <div key={loaderData.topicInfo.id + currentQuestionIndex + index} className="is-flex  is-fullwidth is-align-items-center is-justify-content-center">
                                                    <div className="is-flex mt-4 is-fullwidth is-mid-width-btn-container">
                                                        <Button
                                                            key={v1()}
                                                            hideIcon
                                                            className={"is-fullwidth quiz-option is-align-self-center " + " " + selectedStyle + " " + styleAfterSubmit}
                                                            action={() => {
                                                                setClickedQuestionIndex(index)
                                                                setQuestionSubmitted(false)
                                                            }}
                                                        >
                                                            {answerOption["answer_option"].possible_answer_text}
                                                        </Button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {!questionSubmitted && (
                                            <div className="is-flex  is-fullwidth is-align-items-center is-justify-content-center">
                                                <div className="is-flex mt-5 is-fullwidth is-mid-width-btn-container">
                                                    <Button
                                                        hideIcon
                                                        className={"is-fullwidth topic-btn " + (clickedQuestionIndex < 0 ? "is-invisible-tablet" : "")}
                                                        action={(e) => {
                                                            e.preventDefault();
                                                            setQuestionSubmitted(true);
                                                            const isCorrect = currentQuestion!["possible_answers"][clickedQuestionIndex]!.answer_option["is_this_the_correct_answer"]
                                                            if (isCorrect === "yes") setCorrectAnswersCount(correctAnswersCount + 1)
                                                            const correctAnswerOb = currentQuestion!["possible_answers"].find(({ answer_option }) => answer_option.is_this_the_correct_answer === "yes")
                                                            const questionId = loaderData.topicInfo.id + currentQuestionIndex;
                                                            const givenAnswer = currentQuestion!["possible_answers"][clickedQuestionIndex]!.answer_option["possible_answer_text"]
                                                            const correctAnswer = correctAnswerOb!.answer_option.possible_answer_text
                                                            const answer = new RecordQuizAnswers_Request_Answer();
                                                            answer.questionId = questionId;
                                                            answer.givenAnswer = givenAnswer;
                                                            answer.correctAnswer = correctAnswer;
                                                            setAnswersCollection([...aswersCollection, ...[answer]])
                                                        }}
                                                    >
                                                        Check answer
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                        {questionSubmitted &&
                                            <div className="is-flex  is-fullwidth is-align-items-center is-justify-content-center">
                                                <div className="is-flex mt-5 is-fullwidth is-mid-width-btn-container">
                                                    <Button hideIcon action={() => {
                                                        if (currentQuestionIndex < loaderData.questions.length) {
                                                            setCurrentQuestionIndex(currentQuestionIndex + 1);
                                                            setClickedQuestionIndex(-1)
                                                            setQuestionSubmitted(false)
                                                        }
                                                        if (currentQuestionIndex === loaderData.questions.length - 1) {

                                                            const request = new RecordQuizAnswers_Request();
                                                            request.answers = aswersCollection;
                                                            setAnswersCollection(request.answers)
                                                            const requestData = request.toJSON();
                                                            educationClient.recordQuizAnswers(requestData, loaderData.topicInfo.id).then(() => {
                                                                setActiveScreen("quizComplete")
                                                            })
                                                        }

                                                    }} className="is-fullwidth topic-btn">
                                                        {currentQuestionIndex === loaderData.questions.length - 1 ? "Finish quiz" : "Next question"}
                                                    </Button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {questionSubmitted &&
                                        <div className="mt-4 is-flex-direction-column">
                                            <p className="quiz-answer has-text-weight-medium">
                                                Answer:
                                            </p>
                                            <p className="mt-2">
                                                {currentQuestion["explanation"]}
                                            </p>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {activeScreen === "quizComplete" &&
                            <div className="topic-complete-container is-flex-direction-column is-flex is-align-items-center is-justify-content-center">
                                <div className="topic-complete-content is-flex-direction-column is-flex is-align-items-center is-justify-content-center is-fullwidth is-mid-width ">
                                    <Content display="flex" alignItems="center">
                                        <figure className="image mt-4">
                                            <img src={completedTopicImage} alt="Party Popper Emoji" />
                                        </figure>
                                    </Content>
                                    <h1 className="topic-complete-title--xl is-flex is-align-items-center">
                                        {correctAnswersCount}/{loaderData.questions.length}
                                    </h1>
                                    <h1 className="topic-complete-title is-flex is-align-items-center">
                                        Questions correct
                                    </h1>
                                    <Link to={`/learn/courses/${loaderData.courseInfo.slug}/${loaderData.levelInfo.slug}`} className="button is-rounded is-primary is-fullwidth-mobile mt-5 mb-1">Close quiz</Link>
                                </div>
                            </div>
                        }
                    </div>
                </Tile>
                {(currentUnitIndex != 0 && activeScreen === "units") && (
                    <div className="is-hidden-mobile mt-4 ml-3-touch">
                        <BackButton
                            text="Previous topic"
                            className="mr-5 mb-4"
                            action={() => {
                                setCurrentUnitIndex(currentUnitIndex - 1);
                            }}
                        />
                    </div>
                )}
            </Container>
        </Section>
    );
}