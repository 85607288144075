import { GetWealthMappingOverview_Response_WealthPictureResponse_GoalResponse, GetWealthMappingOverview_Response_WealthPictureResponse_WealthPictureInputs } from "../../../../services/generated/WealthMappingClient";

export type GoalType =
    | "FinancialIndependence"
    | "FinancialSecurity"
    | "QualityOfLife"
    | "SpecificEvent"
    | "Retirement"

export interface PhraseProps {
    type: GoalType;
    event?: string;
}

export const getQualitativeGoal = (props: PhraseProps) => {
    switch (props.type) {
        case "FinancialIndependence": 
            return "Become financially independent";
        case "FinancialSecurity": 
            return "Feel financially secure";
        case "QualityOfLife": 
            return "Improve your quality of life";
        case "SpecificEvent": 
            return "Save for a " + props.event;
        case "Retirement": 
            return "Save for retirement";
        default:
            return "";
        }
}

export const getQualitativeGoalFromResponse = (goal?: GetWealthMappingOverview_Response_WealthPictureResponse_GoalResponse) => {
    if (!goal) {
        return "";
    }

    const phraseProps = {
        type: goal.type,
        event: goal.customDescription
    } as PhraseProps;

    return getQualitativeGoal(phraseProps);
}
