@use "../../../../../../../styles/colors" as colors;

.active {
    span {
        color: map-get(map-get(colors.$color-pallete, "purple-blue"), "400");
    }
    > svg {
        stroke: map-get(map-get(colors.$color-pallete, "pink"), "400");
    }
}

.inactive {
    span {
        color: map-get(map-get(colors.$color-pallete, "grey"), "300");
    }
    > svg {
        stroke: map-get(map-get(colors.$color-pallete, "grey"), "300");
    }
}

