import axios, { AxiosResponse } from "axios";
import { useAuth } from "react-oidc-context";

export const useAuthApiClient = () => {

    const auth = useAuth();
    const authority = process.env.OIDC_AUTHORITY;

    axios.defaults.headers.common['Authorization'] = `Bearer ${auth.user?.access_token}`;

    const getUserInfo = () => {
        return axios.get<UserInfo>(`${authority}/connect/userinfo`);
    }

    let result: [getUserInfo: () => Promise<AxiosResponse<UserInfo, any>>] = [getUserInfo]
    return result;
}

export interface UserInfo {
    firstName: string;
    surname: string;
    email: string;
    userName: string;
    membershipTier: "Pro" | "Free";
}

