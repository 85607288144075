import { useContext, useEffect } from "react";
import { Card, Columns } from "react-bulma-components";
import { Footer } from "../../../../components/Layout/Footer/Footer";
import { WealthMappingNavbar } from "../../components/WealthMappingNavbar/WealthMappingNavbar";
import { CalculateFutureValue_Request, CalculateFutureValue_Response } from "../../services/generated/WealthMappingClient";
import { WealthMappingContext } from "../../WealthMappingContext";
import "./Calculator.scss";
import { Calculation } from "./components/Calculation/Calculation";
import { CalculationSummary } from "./components/CalculationSummary/CalculationSummary";
import { useNavigate } from "react-router-dom";
import { AuthHelper } from "../../../../components/Auth/AuthHelper";
import { Helmet } from "react-helmet-async";

export function Calculator () {
   
    const wealthMappingContext = useContext(WealthMappingContext);

    if (!wealthMappingContext.wealthMappingData?.wealthPictureResponse) {
        // Will navigate back if there's no response to work with
        return <></>;
    }

    const onFutureValueCalculated = (request: CalculateFutureValue_Request, response: CalculateFutureValue_Response) => {
        wealthMappingContext.setFutureValueData(request, response);
    }

    // Brittle, but required to understand whether a user is logged in or not.
    const navigate = useNavigate();

    useEffect(() => {
        const token = AuthHelper.getAccessToken();
        if (token) {
            navigate("/invest/tools/goal-planning");
        }
    });

    return (
        <div className="calculator">
            <Helmet>
                <title>Wealth Mapping - Calculator</title>
            </Helmet>
            <WealthMappingNavbar />
            <section className="section is-flex is-flex-direction-column">
                <div className="container">
                    <Columns>
                        <Columns.Column size={8}>
                            <CalculationSummary lumpSumRequired={wealthMappingContext?.wealthMappingData?.wealthPictureResponse?.lumpSumRequired} />
                            <Calculation 
                                prospectiveUserId={wealthMappingContext.wealthMappingData.prospectiveUserId} 
                                lumpSumRequired={wealthMappingContext.wealthMappingData.wealthPictureResponse?.lumpSumRequired}
                                futureValueRequest={wealthMappingContext.wealthMappingData.futureValueRequest}
                                futureValueResponse={wealthMappingContext.wealthMappingData.futureValueResponse}
                                onFutureValueCalculated={onFutureValueCalculated} />
                        </Columns.Column>
                        <Columns.Column>
                           <SignUp />
                        </Columns.Column>
                    </Columns>
                </div>
                <div className="footer-container">
                    <Footer />
                </div>
            </section>
        </div>
    )
}

const SignUp = () => {
    return (
        <Card className="sign-up is-rounded">
            <Card.Content>
                <h4 className="title is-4 has-text-centered">
                    Sign up
                </h4>
                <p>Sign up to Propelle to continue your investing journey with us. Propelle helps you:</p>
                <ul>
                    <li>Understand your risk level</li>
                    <li>Put together your own portfolio</li>
                    <li>Learn about investing</li>
                </ul>
                <p className="has-text-centered">
                    <a href={process.env.NAVIGATION_REGISTER as string} className="button is-primary is-rounded is-fullwidth is-justify-content-center">
                        Sign up
                    </a>
                </p>
            </Card.Content>
        </Card>
    );
}