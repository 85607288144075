import { Columns } from "react-bulma-components";
import "./Input.scss";
import { AnswerProps } from "../Answer/Answer";
import { useContext } from "react";
import { ChatContext } from "../Chat";
import { FieldValues, RegisterOptions, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";

export interface TextLengthValidator {
    maxLength?: number,
    minLength?: number,
    message: string
}

export interface RegexValidator {
    regex: string,
    message: string
}

export interface NumberValidator {
    maxValue?: number,
    minValue?: number,
    message: string
}

export interface InputProps extends AnswerProps {
    inputFormatter?: InputFormatter,
    postPlaceholderText?: string,
    type: string,
    textLengthValidator?: TextLengthValidator,
    regexValidator?: RegexValidator,
    numberValidator?: NumberValidator,
    nextPath?: number
}

type InputFormatter = 
    'Currency'

export function Input(props: InputProps) {

    const context = useContext(ChatContext);
    const { register, handleSubmit } = useForm<FormInput>();

    interface FormInput {
        input: any
    }

    const onSubmit: SubmitHandler<FormInput> = data => {
        if (!data?.input) {
            // Don't allow empty inputs
            return;
        }

        context.onAnswerReceived?.(format(data?.input), data.input ? data.input.toString() : "", props.nextPath)
    }

    function format(input?: string) {
        let formatted = "";

        switch (props.inputFormatter)
        {
            case 'Currency':
                const formatter = new Intl.NumberFormat('en-GB', {
                    style: 'currency',
                    currency: 'GBP',
                    maximumFractionDigits: 0
                });

                formatted = formatter.format(parseInt(input ?? ""));
                break;
            default:
                formatted = input?.toString() ?? "";
        }

        let postPlaceholderText = props.postPlaceholderText ? " " + props.postPlaceholderText : "";
        formatted += " " + postPlaceholderText;

        return formatted;
    }

    const onError: SubmitErrorHandler<FormInput> = (errors) => {
        let validationMessage = errors.input?.message as string;
        let value = (errors.input?.ref as any)?.value as string;

        if (!validationMessage) {
            validationMessage = "Oops sorry I didn't understand that answer! Please try again";
        }

        context.onInvalidAnswerReceived?.(format(value), value, validationMessage);
    }

    let validation = {} as RegisterOptions<FieldValues, "input">;

    if (props.textLengthValidator) {
        if (props.textLengthValidator.maxLength) {
            validation.maxLength = {value: props.textLengthValidator.maxLength, message: props.textLengthValidator.message };
        }

        if (props.textLengthValidator.minLength) {
            validation.minLength = {value: props.textLengthValidator.minLength, message: props.textLengthValidator.message };
        }
    }

    if (props.numberValidator) {
        if (props.numberValidator.maxValue) {
            validation.max = { value: props.numberValidator.maxValue, message: props.numberValidator.message };
        }

        if (props.numberValidator.minValue) {
            validation.min = { value: props.numberValidator.minValue, message: props.numberValidator.message };
        }
    }

    if (props.regexValidator) {
        validation.pattern = { value: new RegExp(props.regexValidator.regex), message: props.regexValidator.message }
    }

    return (
        <div className="chat-input">
            <div className = "chat-message-line">
                <div className="chat-message answer">
                    <Columns {...{breakpoint: 'mobile'}}>
                        <Columns.Column>
                            <form onSubmit={handleSubmit(onSubmit, onError)}>
                                <div className="is-flex is-flex-direction-row is-align-items-center is-flex-wrap-nowrap">
                                    {
                                        props.inputFormatter === 'Currency'
                                            ? <p className="placeholder-text">£</p>
                                            : <></>
                                    }
                                        <input className="input" type={props.type} {...register("input", {...validation} )} />
                                    {
                                        props.postPlaceholderText 
                                            ? <p className="placeholder-text">{props.postPlaceholderText}</p>
                                            : <></>
                                    }
                                </div>
                            </form>
                        </Columns.Column>
                    </Columns>
                </div>
            </div>
        </div>
    )
}
