import { Level } from "./Level";

export class Course {
    id: number;
    slug: string;
    description: string;
    name: string;
    levels: Level[] = [];
    
    constructor(course: any) { // Expects a WP REST API response
        this.id = parseInt(course.id);
        this.slug = course.slug;
        this.description = course.description;
        this.name = course.name;
        if (course.levels) {
            course.levels.forEach((level: any) => {
                if (level === typeof Level) {
                    this.levels.push(level);
                } else {
                    this.levels.push(new Level(level));
                }
            });
        }
    }
}