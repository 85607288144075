import { Helmet } from "react-helmet-async"
import { Main } from "../../../../../../components/Layout/Main/Main"
import { Glass } from "../../../../../../components/Layout/Glass/Glass"
import { Section } from "../../../../../../components/Layout/Section/Section"
import { Content } from "../../../../../../components/Layout/Content/Content"
import { Form } from "../../../../../../components/Forms/Form"
import { ObjectSchema, string } from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useContext, useEffect, useState } from "react"
import { AccountManagementClientFactory } from "../../../../services/AccountManagementClientFactory"
import { AddBankDetailsToAccountHolder_Request } from "../../../../services/generated/AccountManagementClient"
import { useNavigate } from "react-router"
import { BackButton } from "../../../../../../components/BackButton/BackButton"
import { EquityContext } from "../OnboardingContext"

interface BankDetailsForm {
    name?: string
    accountNumber?: string,
    sortCode?: string
}

const validation = new ObjectSchema<BankDetailsForm>({
    name: string()
        .required('Please enter your name as it appears on your bank account'),
    accountNumber: string()
        .required('Please enter a account number')
        .min(8, 'Please enter a valid account number')
        .max(10, 'Please enter a valid account number')
        .test({
            name: 'accountNumber',
            message: 'Please enter a valid account number',
            test: x => !(!parseInt(x))
        }),
    sortCode: string()
        .required('Please enter a valid sort code (e.g. 12-34-56)')
        .matches(/^([0-9]{2})-([0-9]{2})-([0-9]{2})$/, 'Please enter a valid sort code (e.g. 12-34-56)')
});

export const BankDetails = () => {

    const context = useContext(EquityContext);

    const { register, formState: { isValid, errors }, getValues, handleSubmit } = useForm<BankDetailsForm>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(validation)
    });

    const onFormCompleted = () => {
        toggleSubmissionStatus({ isSubmitting: true, isSubmitted: false });
    }
    
    const [submissionState, toggleSubmissionStatus] = useState({ isSubmitting: false, isSubmitted: false });
            
    const submit = async () => {
        let client = AccountManagementClientFactory();

        let accountHolder = await client.getAccountHolder();

        try {
            await client.addBankDetailsToAccountHolder(new AddBankDetailsToAccountHolder_Request({
                accountName: getValues("name") ?? "",
                accountNumber: getValues("accountNumber") ?? "",
                sortCode: getValues("sortCode") ?? ""
            }), accountHolder.accountHolderId ?? "");

            toggleSubmissionStatus({ isSubmitting: false, isSubmitted: true })
        } catch (e) {
            toggleSubmissionStatus({ isSubmitting: false, isSubmitted: false })
        }
    }

    const navigate = useNavigate();

    useEffect(() => {
        // If page has been refreshed - make them go back to deposit
        if (context?.intialDepositId === undefined) {
            navigate('/invest/funds/make-a-deposit');
        }
    }, []);

    useEffect(() => {
        if (submissionState.isSubmitting) {
            submit();
        } else if (submissionState.isSubmitted) {
            navigate(`/invest/funds/transfer-details/${context?.intialDepositId}`)
        }
    }, [submissionState.isSubmitting])

    return (
        <>
            <Helmet>
                <title>Funds - Make a deposit</title>
            </Helmet>
            <Main>
                <div className="container">
                    <BackButton to={"/invest/funds/make-a-deposit"} text="Back" className="mb-3"/>
                    <div className="columns">
                        <div className="column is-12 is-7-widescreen">
                            <Glass opacity={100}>
                                <Section>
                                    <Content>
                                        <h1 className="is-size-4 has-text-centered has-text-left-desktop is-size-2-desktop has-text-purple-blue-500">Make a contribution</h1>
                                    </Content>
                                    <Content>
                                        <p className="is-size-5 has-text-weight-semibold has-text-purple-blue-500">Enter bank details</p>
                                        <p>Please enter your bank details below. Please note they must be the same as the account through which you will want to complete your bank transfer.</p>
                                    </Content>
                                    <Form columns={1} className="mx-0" onSubmit={handleSubmit(onFormCompleted)}>
                                        <Form.Inputs>
                                            <Form.FormInput>
                                                <Form.FormInput.Label text="Full name on bank account"/>
                                                    <input type="text" className="input" {...register("name")} />
                                                <Form.FormInput.ValidationMessage text={errors.name?.message} />
                                            </Form.FormInput>
                                            <Form.FormInput>
                                                <Form.FormInput.Label text="Account number"/>
                                                    <input type="number" className="input" {...register("accountNumber")} />
                                                <Form.FormInput.ValidationMessage text={errors.accountNumber?.message} />
                                            </Form.FormInput>
                                            <Form.FormInput>
                                                <Form.FormInput.Label text="Sort code"/>
                                                    <input type="text" className="input" {...register("sortCode")} />
                                                <Form.FormInput.ValidationMessage text={errors.sortCode?.message} />
                                            </Form.FormInput>
                                        </Form.Inputs>
                                        <Form.Submit disabled={!isValid || submissionState.isSubmitting} loading={submissionState.isSubmitting}>Next</Form.Submit>
                                    </Form>
                                </Section>
                            </Glass>
                        </div>
                    </div>
                </div>
            </Main>
        </>
    )
}