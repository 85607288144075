import { ReactNode, createContext, useEffect } from "react";
import { GetPortfolioAllowance_Response, GetPortfolioAvailableActions_Response, GetPortfolioBalance_Response, GetPortfoliosForAccount_Response_Portfolio } from "../../services/generated/PortfoliosClient";
import { AccountManagementClientFactory } from "../../services/AccountManagementClientFactory";
import { useState } from "react";
import { PortfoliosClientFactory } from "../../services/PorfoliosClientFactory";
import { GetAccountsByAccountHolder_Response_Account } from "../../services/generated/AccountManagementClient";
import { WpPostTypeRequest, useAuthenticatedCmsClient } from "../../../../components/Auth/AuthenticatedCmsClient";
import { set } from "lodash";

export interface FundsContextState extends FundsCache {
    fetchAccountHolderId: () => void;
    fetchAccount: () => void;
    fetchPortfolio: () => void;
    fetchPortfolioActions: () => void;
    fetchPortfolioBalance: () => void;
    fetchFundsContent: () => void;
    fetchPortfolioAllowance: () => void;
    refreshPortfolioData: (portfolio?: boolean, actions?: boolean, balance?: boolean, allowance?: boolean) => void;
}

interface FundsCache {
    accountHolderId?: string;
    account?: GetAccountsByAccountHolder_Response_Account;
    portfolio?: GetPortfoliosForAccount_Response_Portfolio;
    actions?: GetPortfolioAvailableActions_Response;
    balance?: GetPortfolioBalance_Response;
    allowance?: GetPortfolioAllowance_Response;
    fundsContent?: any[];
}

export const FundsContext = createContext<FundsContextState>({} as FundsContextState);

interface FundsProviderProps {
    children: ReactNode
}

export const FundsProvider = ({children} : FundsProviderProps) => {
    let [accountHolderId, setAccountHolderId] = useState<string | undefined>();
    let [account, setAccount] = useState<GetAccountsByAccountHolder_Response_Account | undefined>();
    let [portfolio, setPortfolio] = useState<GetPortfoliosForAccount_Response_Portfolio | undefined>();
    let [actions, setActions] = useState<GetPortfolioAvailableActions_Response | undefined>();
    let [balance, setBalance] = useState<GetPortfolioBalance_Response | undefined>();
    let [allowance, setAllowance] = useState<GetPortfolioAllowance_Response | undefined>();
    let [fundsContent, setFundsContent] = useState<any[]>();

    let [accountHolderIdProgress, setAccountHolderIdProgress] = useState<boolean>(false);
    let [accountProgress, setAccountProgress] = useState<boolean>(false);
    let [portfolioProgress, setPortfolioProgress] = useState<boolean>(false);
    let [actionsProgress, setActionsProgress] = useState<boolean>(false);
    let [balanceProgress, setBalanceProgress] = useState<boolean>(false);
    let [allowanceProgress, setAllowanceProgress] = useState<boolean>(false);
    let [fundsContentProgress, setFundsContentProgress] = useState<boolean>(false);

    // Potench later can use accountHolderId from session storage
    // let auth = useAuth();
	// auth.events.addUserUnloaded(() => {
	// 	sessionStorage.removeItem("funds-cache");
	// });

    let client = AccountManagementClientFactory();
    let portfoliosClient = PortfoliosClientFactory();
    let cmsClient = useAuthenticatedCmsClient();

    useEffect(() => {
        const fetchAccountHolderId = async () => {
            if (accountHolderId === undefined) {
                const resp = await client.getAccountHolder();
                setAccountHolderIdProgress(false);
                setAccountHolderId(resp.accountHolderId);
            }
        }

        if (accountHolderIdProgress === true) {
            fetchAccountHolderId();
        }
    
    }, [accountHolderIdProgress]);

    const fetchAccountHolderId = () => {
        if (accountHolderId === undefined && accountHolderIdProgress === false) {
            setAccountHolderIdProgress(true);
        }
    };

    useEffect(() => {
        const fetchAccount = async () => {
            if (account === undefined) {
                const resp = await client.getAccountsByAccountHolder(accountHolderId!);
                setAccount(resp.accounts?.[0]);
                setAccountProgress(false);
            }
        }

        if (accountHolderId === undefined) {
            fetchAccountHolderId();
        } else if (accountProgress === true) {
            fetchAccount();
        }
    
    }, [accountProgress, accountHolderId]);

    const fetchAccount = async () => {
        if (account === undefined && accountProgress === false) {
            setAccountProgress(true);
        }
    }

    useEffect(() => {
        const fetchPortfolio = async () => {
            if (portfolio === undefined) {
                const resp = await portfoliosClient.getPortfoliosForAccount(account?.id!);
                setPortfolio(resp.portfolios?.[0]);
                setPortfolioProgress(false);
            }
        }

        if (account === undefined) {
            fetchAccount();
        } else if (portfolioProgress === true) {
            fetchPortfolio();
        }
    
    }, [portfolioProgress, account]);

    const fetchPortfolio = async () => {
        if (portfolio === undefined && portfolioProgress === false) {
            setPortfolioProgress(true);
        }
    }

    useEffect(() => {
        const fetchPortfolioActions = async () => {
            if (actions === undefined) {
                if (portfolio) {
                    const resp = await portfoliosClient.getPortfolioAvailableActions(portfolio.id!);
                    setActions(resp);
                    setActionsProgress(false);
                }
            }
        }

        if (portfolio === undefined) {
            fetchPortfolio();
        } else if (actionsProgress === true) {
            fetchPortfolioActions();
        }
    }, [actionsProgress, portfolio]);

    const fetchPortfolioActions = async () => {
        if (actions === undefined) {
            setActionsProgress(true);
        }
    }

    useEffect(() => {
        const fetchPortfolioBalance = async () => {
            if (balance === undefined) {
                if (portfolio) {
                    const resp = await portfoliosClient.getPortfolioBalance(portfolio.id!);
                    setBalance(resp);
                    setBalanceProgress(false);
                    return resp;
                }
            }
            return balance!;
        }

        if (portfolio === undefined) {
            fetchPortfolio();
        } else if (balanceProgress === true) {
            fetchPortfolioBalance();
        }
    }, [balanceProgress, portfolio]);

    const fetchPortfolioBalance = async () => {
        if (balance === undefined) {
            setBalanceProgress(true);
        }
    }

    useEffect(() => {
        const fetchFundsContent = async () => {
            if (fundsContent === undefined) {
                const resp = await cmsClient?.get(new WpPostTypeRequest({
                    url: "fund"
                }));
                setFundsContent(resp.data);
                setFundsContentProgress(false);
            }
        }

        if (fundsContentProgress === true) {
            fetchFundsContent();
        }

    }, [fundsContentProgress]);

    const fetchFundsContent = async () => {
        if (fundsContent === undefined) {
            setFundsContentProgress(true);
        }
    }

    useEffect(() => {
        const fetchPortfolioAllowance = async () => {
            if (allowance === undefined) {
                const resp = await portfoliosClient.getPortfolioAllowance(portfolio!.id!);
                setAllowance(resp);
                setAllowanceProgress(false);
            }
        }

        if (portfolio === undefined) {
            fetchPortfolio();
        } else if (allowanceProgress === true) {
            fetchPortfolioAllowance();
        }
    }, [allowanceProgress, portfolio]);

    const fetchPortfolioAllowance = async () => {
        if (allowance === undefined) {
            setAllowanceProgress(true);
        }
    }

    const refreshPortfolioData = (
        portfolio = true,
        actions = true,
        balance = true,
        allowance = true
    ) => {
        if (portfolio) {
            setPortfolio(undefined);
        }
        
        if (actions) {
            setActions(undefined);
        }

        if (balance) {
            setBalance(undefined);
        }

        if (allowance) {
            setAllowance(undefined);
        }
    }

    return (
        <FundsContext.Provider value={
            {
                accountHolderId,
                account,
                portfolio,
                actions,
                balance,
                fundsContent,
                allowance,
                fetchAccountHolderId,
                fetchAccount,
                fetchPortfolio,
                fetchPortfolioActions,
                fetchPortfolioBalance,
                fetchFundsContent,
                fetchPortfolioAllowance,
                refreshPortfolioData
            }}>
            {children}
        </FundsContext.Provider>
    );
}