export const formatPercentage = (value: number, withOperation?: boolean) => {

    let fractionPercentage = value * 100;
    const positive = fractionPercentage >= 0;

    if (!positive) {
        fractionPercentage *= -1;
    }

    fractionPercentage = Math.round(fractionPercentage * 100) / 100;

    if (!positive) {
        fractionPercentage *= -1;
    }

    const operation = withOperation ? (positive ? '+' : '-') : '';
    return `${operation}${fractionPercentage}%`;
}