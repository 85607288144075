import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export interface LocationContextState {
    path: string;
}

export const LocationContext = createContext<LocationContextState>({ path: "/" } as LocationContextState);

export interface LocationManagerProps {
    children?: React.ReactElement[];
}

export const LocationManager = (props: LocationManagerProps) => {

    const [state, setState] = useState({ path: "/" } as LocationContextState);

    let location = useLocation();

    useEffect(() => {
        setState({ path: location.pathname });
    }, [location]);

    return (
        <LocationContext.Provider value={state}> { props.children }</LocationContext.Provider>
    )
}