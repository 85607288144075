import { useState } from "react";
import { SelectInvestmentAccount } from "./SelectInvestmentAccount";
import { useNavigate } from "react-router-dom";
import { ChooseYourFund } from "./ChooseYourFund";
import { Declaration } from "./Declaration/Declaration"

export interface AccountSelectionSectionProps {
    moveForward: () => void;
    moveBackward: () => void;
}

export const AccountSelection = () => {

    const [stage, setStage] = useState<string>("Account");
    const navigate = useNavigate();

    const moveForward = () => {
        const currentStage = stages.findIndex(x => x.stage === stage);
        const nextStage = stages[currentStage + 1];
    
        if (nextStage) {
            setStage(nextStage.stage);
        } else {
            navigate("/invest/funds");
        }
    }

    const moveBackward = () => {
        const currentStageIndex = stages.findIndex(x => x.stage === stage);

        if (currentStageIndex > 0) {
            const previousStage = stages[currentStageIndex - 1];

            if (previousStage) {
                setStage(previousStage.stage);
                return;
            }
        }

        navigate("/invest/funds/account-setup");
    }

    const stages = [
        {
            stage: "Account",
            component: <SelectInvestmentAccount moveForward={moveForward} moveBackward={moveBackward}/>
        },
        {
            stage: "Funds",
            component: <ChooseYourFund moveForward={moveForward} moveBackward={moveBackward}/>
        },
        {
            stage: "Declaration",
            component: <Declaration moveBackward={moveBackward} />
        }
    ];

    return stages.find(x => x.stage === stage)?.component;
}
