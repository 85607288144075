
import { SecondaryNav } from "../../../../components/SecondaryNav/SecondaryNav";

export const Header = () => {

    const links = [
        // { to:"/connect/feed", name:"Feed" },
        { to:"/connect/events", name:"Events" }
    ];

    return (
        <SecondaryNav links={links} />
    );
}