import { useRef } from "react";
import { CssClasses } from "../../../../../../components/Helpers/CssClasses";
import { Table } from "../../../../../../components/Table/Table"
import { useOverflowDetector } from "../../../../../../customHooks/useOverflowDetector";
import { RagCell } from "./RagCell"

import * as classes from "./RagTable.module.scss";

export const RagTable = ({data = new Array<Array<{val: number | undefined, formatAs: "percentage" | "currency" }>>() }) => {

    const tableRef = useRef<HTMLDivElement>(null);
    const isOverflow = useOverflowDetector(tableRef);

    return (
        <div className={new CssClasses([classes["rag-table"], isOverflow ? "mr--7" : undefined]).aggregated()} ref={tableRef}>
            <div className={classes["dimension-limits"]}>
                <AxisLabels x="GDV (Selling price)" xClassName="ml-4 mb--4" y="Cost (incl. finance cost)" yClassName="mt-7 mr-4">
                    <Table hasBorderedRows={false} columnGap={0} rowHeight={8} className="is-table-layout-fixed">
                        <Table.Data data={
                            data.map((x, i) => { 
                                return {
                                    values: x.map((y, j) => {

                                        const css = new CssClasses();
                                        if (i > 0 && j > 0) {
                                            css.add(classes["with-border-top"]);
                                            css.add(classes["with-border-left"]);
                                        } else {
                                            css.add("my--2");
                                        }

                                        return <RagCell formatAs={y.formatAs} val={y.val} className={css.aggregated()} />
                                    })
                                }
                            })} />
                    </Table>
                </AxisLabels>                      
            </div>
        </div>
    )
}

const AxisLabels = ({ 
    x = undefined as string | undefined, 
    y = undefined as string | undefined, 
    xClassName = undefined as string | undefined,
    yClassName = undefined as string | undefined,
    children = undefined as undefined | any }) => {

    const xCss = new CssClasses(["has-text-grey-500 has-text-centered", xClassName]);
    const yCss = new CssClasses([`${classes["y-axis-label"]} has-text-grey-500`, yClassName]);

    return ( 
        <div>
            <p className={xCss.aggregated()}>{x}</p>
            <div className="is-flex is-align-items-center">
                <p className={yCss.aggregated()}>{y}</p>
                <div>
                    { children }
                </div>
            </div>
        </div>   
    )
}