import { Container } from "react-bulma-components";
import { GetRiskSummary_Response } from "../../../../../services/generated/PropertyClient";
import { Ranking } from "../../Risk";
import { Link } from "react-router-dom";
import { useLoader } from "../../../../../../../hoc/withLoader";
import { useEffect } from "react";
import { PropertyClientFactory } from "../../../../../services/PropertyClientFactory";
import "./RiskCapacityResultsPage.scss";
import { Helmet } from "react-helmet-async";
import { Main } from "../../../../../../../components/Layout/Main/Main";
import { Glass } from "../../../../../../../components/Layout/Glass/Glass";
import { Section } from "../../../../../../../components/Layout/Section/Section";
import { Content } from "../../../../../../../components/Layout/Content/Content";
import { RiskMeter } from "../../components/RiskMeter/RiskMeter";

export const RiskCapacityResultsPage = () => {
    const [loaderState, { load, defaultLoaderDisplay, defaultErrorDisplay }] = useLoader<GetRiskSummary_Response>();
    const client = PropertyClientFactory();

    useEffect(() => {
        if (loaderState.loadingState === "NotStarted") {
            load(client.getRiskSummary());
        }

    }, [loaderState.loadingState])

    if (loaderState.error) {
        return <>{defaultErrorDisplay()}</>;
    }

    if (loaderState.loadingState === "NotStarted" || loaderState.loadingState === "Loading") {
        return <>{defaultLoaderDisplay()}</>;
    }

    if (loaderState.response?.capacity?.description) {
        const ranking = loaderState.response.capacity.description;
        
        const copy = {
            low: "Your financial situation doesn't allow you to take too much risk. You should always consider an investment opportunity carefully to ensure it aligns with your capacity to accept losses.",
            medium: "Your financial situation allows you to take some risk to aim for some returns. You should always consider an investment opportunity carefully to ensure it aligns with your capacity to accept losses.",
            high: "Your financial situation allows you to take higher risk to aim for higher returns. You should always consider an investment opportunity carefully to ensure it aligns with your capacity to accept losses."
        };

        let applicableCopy = "";
        switch(ranking.toLocaleLowerCase()) {
            case "high":
                applicableCopy = copy.high;
                break;
            case "medium":
                applicableCopy = copy.medium;
                break;
            case "low":
                applicableCopy = copy.low;
                break;
        }

        return (
            <>
                <Helmet>
                    <title>Risk Capacity Results</title>
                </Helmet>
                <Main>
                    <Container>
                        <Section>
                            <Glass>
                                <Content isMain={true}>
                                    <h4 className="title is-size-3-touch is-2 has-text-purple-blue-500 has-text-centered has-text-left-desktop">Your risk capacity is <span className="blob-underline">{ranking.toLocaleLowerCase()}</span></h4>
                                </Content>
                                <div className="is-hidden-touch">
                                    <div className="is-flex is-flex-direction-row is-align-items-center is-flex-gap-8">
                                        <Content>
                                            <RiskMeter rank={ranking as Ranking} size={180}>
                                                <h3 className="is-size-3 has-text-purple-blue-500">{ranking ?? "Inactive"}</h3>
                                            </RiskMeter>
                                        </Content>
                                        <Content>
                                            <p>{applicableCopy}</p>
                                        </Content>
                                    </div>
                                    <div className="is-flex is-flex-direction-row is-align-items-center is-flex-gap-8">
                                        <Link to="/invest/tools/risk" className="button has-text-centered is-primary is-rounded">View your attitude to risk</Link>
                                    </div>
                                </div>
                                <div className="is-hidden-desktop">
                                    <div className="is-flex is-flex-direction-column">
                                        <Content>
                                            <RiskMeter rank={ranking as Ranking} size={160}>
                                                <h3 className="is-size-4 has-text-purple-blue-500">{ranking ?? "Inactive"}</h3>
                                            </RiskMeter>
                                        </Content>
                                        <Content>
                                            <p>{applicableCopy}</p>
                                        </Content>
                                    </div>
                                </div>
                            </Glass>
                        </Section>
                        <div className="is-hidden-desktop">
                            <Section>
                                <Content className="is-fullwidth">
                                    <Link to="/invest/tools/risk" className="button has-text-centered is-primary is-rounded is-fullwidth is-justify-content-center">View your attitude to risk</Link>
                                </Content>
                            </Section>
                        </div>
                    </Container>
                </Main>
            </>
        )
    }

    return <></>
}





