import { createContext, useEffect, useState } from "react";

export enum ScreenSize {
    Mobile = 0,
    Tablet = 769,
    Desktop = 1024,
    Widescreen = 1216,
    FullHD = 1408
}

export interface WindowContextState {
    screenSize: ScreenSize;
    onWindowResize: (action : () => void) => void;
}

interface IWindowContextProps {
    children: React.ReactNode;
}

export const WindowContext = createContext<WindowContextState>({} as WindowContextState);

export const WindowContextProvider = ({children} : IWindowContextProps) => {

    const getScreenSize = () => {
        const width = window.innerWidth;
        return Object.values(ScreenSize)
            .reverse()
            .find(size => width >= (size as ScreenSize)) as ScreenSize;
    }

    const [screenState, setScreenState] = useState<{ screenSize: ScreenSize, windowWidth: number }>({ screenSize: getScreenSize(), windowWidth: window.innerWidth });

    const onWindowResize = (action : () => void) => {
        action();
    }

    useEffect(() => {
        const onResize = () => {
            setScreenState({ screenSize: getScreenSize(), windowWidth: window.innerWidth });
        }
        window.addEventListener("resize", onResize);
        onResize();
        return () => window.removeEventListener("resize", onResize);
    }, []);

    return (
        <WindowContext.Provider value={{ screenSize: screenState.screenSize, onWindowResize: onWindowResize }}>
            { children }
        </WindowContext.Provider>
    )
}