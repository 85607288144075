import "./Carousel.scss";
import left from "./left.svg";
import right from "./right.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import Glide, { Options } from '@glidejs/glide';
import { ScreenSize } from "../App/WindowContext";

interface CarouselProps {
    className?: string;
    title?: string;
    children: React.ReactElement<CarouselItemProps>[] | React.ReactElement<CarouselItemProps>;
    itemSize?: "Default" | "Large";
    type?: "card" | "tile";
}

export const Carousel = ({className = "", title, children, itemSize = "Default", type = "card"}: CarouselProps) => {
    const glideRef = useRef<HTMLDivElement>(null);
    const [glideEnabled, setGlideEnabled] = useState<Boolean>(true);
    const [domLoaded, setDomLoaded] = useState<Boolean>(false);
    
    const items = Array.isArray(children) ? children : [children]; 
   
    let slideSize = 288;
    switch (itemSize) {
        case "Large":
            slideSize = 340;
            break;
    }
        
    const matchHeight = (wrapper : HTMLDivElement) => {
        setTimeout(() => { // For some reason sliders are notorious for being a pain with this 🥲
            let cardHeaders = wrapper!.querySelectorAll('.match-height');
            let maxHeaderHeight = Math.max(...Array.from(cardHeaders).map(x => x.clientHeight));
    
            cardHeaders.forEach((x : any) => x.style.height = `${maxHeaderHeight}px`);
        }, 200);
    }

    const getOptions = (glide : HTMLDivElement) : Partial<Options> & { glideEnabled : boolean }=> {
        
        const parentWidth = glide.parentElement?.offsetWidth ?? 0;

        let numberOfSlides = Math.floor(parentWidth / slideSize);
        let remainderWidth = parentWidth % slideSize; // Allows a lil "peek" of next card

        // For when screen is smaller than a single slide
        if (numberOfSlides === 0) {
            numberOfSlides = 1;
            remainderWidth = 0;
        }

        // If remainder is too small, don't show it
        if (remainderWidth < 50) {
            remainderWidth = 0;
        }

        // If only a small amount of remainder, add another slide
        if (slideSize - remainderWidth < 50) {
            ++numberOfSlides;
            remainderWidth = 0;
        }

        // Don't need a slider if there aren't enough cards!
        let glideEnabled = numberOfSlides < items.length;

        let screenSize = glide.closest('#root')?.clientWidth ?? 0;

        let gap = screenSize <= ScreenSize.Tablet ? 15 : 30; 
        if (type === "card") {
            gap = screenSize <= ScreenSize.Desktop ? 15 : 5; // To allow for card hover state
        }
        
        let options : Partial<Options> & { glideEnabled : boolean } = {
            type: glideEnabled ? "carousel" : "slider", // For some reason slider works better for no controls
            bound: true,
            perView: numberOfSlides,
            gap: gap,
            peek: {
                before: 0,
                after: glideEnabled ? remainderWidth : 0
            },
            startAt: 0,
            glideEnabled: glideEnabled
        };

        return options;
    }

    const toggleGlide = (enabled: boolean, glide: Glide) => {
        enabled ? glide.enable() : glide.disable();

        setGlideEnabled(enabled);
    }

    useMemo(() => {
        if (glideRef.current !== null) {

            let settings = getOptions(glideRef.current!);
            let glide = new Glide(glideRef.current, settings);

            glide.mount();

            toggleGlide(settings.glideEnabled, glide);

            glide.on('resize', () => {
                if (glideRef.current !== undefined && glideRef.current !== null) {
                    var settings = getOptions(glideRef.current!)
                    
                    glide.update(settings);

                    toggleGlide(settings.glideEnabled, glide);
                }
            });

            return glide;
        }

        return undefined;
    }, [domLoaded]);

    useEffect(() => {

        if (glideRef.current !== null) {
            setDomLoaded(true);
            matchHeight(glideRef.current);
        }
        
    }, []);

    return (
        <div className="carousel">
            { 
                title && <h3 className=" mr-5 has-text-purple-blue-500 is-size-4 is-size-3-desktop mb-2-touch">{title}</h3>
            }
            <div className={`glide ${className}`} ref={glideRef}>
                <CarouselControls className={`${title ? "controls-with-title" : ""} ${!glideEnabled ? "is-hidden" : ""}`} />
                <div className="glide__track__wrapper">
                    <div className="glide__track" data-glide-el="track">
                        <ul className="glide__slides pb-3-desktop pt-2-desktop">
                            {
                                items.map((x : any, i: number) => {
                                    return <CarouselItem {...x.props} key={i} />
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

const CarouselControls = ({className = ""} : {className?: string}) => {
    return (
        <div className={`carousel-controls is-flex is-justify-content-end pr-3-desktop pb-2-touch ${className}`} data-glide-el="controls">
            <div className="is-left" data-glide-dir="<">
                <button className="button is-ghost p-0"><img src={left}/></button>
            </div>
            <div className="is-right" data-glide-dir=">">
                <button className="button is-ghost p-0 ml-2 ml-3-desktop"><img src={right}/></button>
            </div>
        </div>
    );
}

export interface CarouselItemProps {
    children?: any;
}

export const CarouselItem = (props: CarouselItemProps) => {
    return (
        <li className={"glide__slide"}>
            { props.children }
        </li>
    )
}