import { useNavigate } from "react-router-dom";
import { AuthHelper } from "../Auth/AuthHelper"
import { useEffect } from "react";

export const MembersOnly = ({ children = undefined as any }) => {
    const isMember = AuthHelper.isPropelleMember();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isMember) {
            navigate("/membership");
        }
    }, []);

    if (!isMember) {
        return <></>;
    }

    return <>{ children }</>;
}