import { ReactElement, cloneElement, useEffect } from "react";
import { loadingStateAggregator, useLoader, useWpLoader } from "../../../../hoc/withLoader";
import { PortfoliosClientFactory } from "../../services/PorfoliosClientFactory";
import { GetAvailableFunds_Response_Root } from "../../services/generated/PortfoliosClient";
import { Order, OrderBy, WpPostTypeRequest } from "../../../../components/Auth/AuthenticatedCmsClient";
import { LoaderProps } from "../../../../components/Layout/Wrappers/ILoaderProps";
import { ImageSize, getImageUrl } from "../../../../helpers/cms";

export class Fund {
    isin: string;
    name: string;
    imageUrl: string;
    risk: number;
    equity: number;
    bonds: number;
    cost: number;
    provider: string;
    fundInfoPdfUrl: string;
    kiidPdfUrl: string;

    constructor(wpFund: any) {
        this.isin = wpFund.acf.isin;
        this.name = wpFund.title;
        this.imageUrl = getImageUrl(wpFund.acf.thumbnail, ImageSize.Medium);
        this.risk = wpFund.acf.risk;
        this.equity = wpFund.acf.equity;
        this.bonds = wpFund.acf.bonds;
        this.cost = wpFund.acf.cost;
        this.provider = wpFund.acf.provider;
        this.fundInfoPdfUrl = wpFund.acf.fund_info.url;
        this.kiidPdfUrl = wpFund.acf.kiid.url;
    }
}

export interface AvailableFundProps {
    availableFunds: Fund[];
}

interface AvailableFundsContainerProps extends LoaderProps {
    children: ReactElement<AvailableFundProps>;
}

export const AvailableFundsContainer = ({wrap, children}: AvailableFundsContainerProps) => {
    const client = PortfoliosClientFactory();
    const [getAvailableFundsLoaderState, { load, defaultErrorDisplay, defaultLoaderDisplay }] = useLoader<GetAvailableFunds_Response_Root>();
    const [wpLoaderState, wpLoader] = useWpLoader();
    const aggregatedLoadingState = loadingStateAggregator([getAvailableFundsLoaderState.loadingState, wpLoaderState.loadingState]);

    useEffect(() => {
        load(client.getAvailableFunds());
        wpLoader.load(new WpPostTypeRequest({
            url: "fund",
            params: {
                per_page: 10,
                orderby: OrderBy.MenuOrder,
                order: Order.Ascending
            }
        }));
    }, []);

    if (aggregatedLoadingState === "Loaded" && wpLoaderState.response !== undefined) {

        let availableFunds : Fund[] = [];

        wpLoaderState.response?.forEach((x : any) => {
            let available = getAvailableFundsLoaderState.response?.funds?.find((y : any) => y.isin === x.acf.isin);

            if (available) {
                availableFunds.push(new Fund(x));
            }
        });

        return wrap
            ? wrap(cloneElement(children, { availableFunds }))
            : cloneElement(children, { availableFunds });
    }

    if (aggregatedLoadingState === ("Failed" || "Loaded") && wpLoaderState.response === undefined) {
        return defaultErrorDisplay();
    }

    return defaultLoaderDisplay();
}