import { CalculateFutureValue_Response } from "../../../../services/generated/WealthMappingClient";
import { Card, Columns } from "react-bulma-components";
import up from "./up.svg";
import down from "./down.svg";
import level from "./level.svg";
import "./CalculationResult.scss";
import { CssClasses } from "../../../../../../components/Helpers/CssClasses";
import { Chevron } from "../../../../../../components/Chevron/Chevron";
import { useState } from "react";
import { Loader } from "../../../../../../components/Loader/Loader";
import { Info } from "../../../../../../components/Info/Info";


export interface CalculatorResultProps {
    lumpSumRequired?: number;
    isFutureValueResponseLoading: boolean;
    futureValueResponse: CalculateFutureValue_Response | undefined;
}

export const CalculatorResult = (props: CalculatorResultProps) => {

    if (props.isFutureValueResponseLoading) {
        return (
            <Card.Content className="has-text-centered default-background is-rounded-bottom">
                <Title />
                <Loader className="py-6"/>
            </Card.Content>
        )
    }

    if (!props.futureValueResponse) {
        return (
            <Card.Content className="has-text-centered default-background is-rounded-bottom">
                <Title />
                <p className="has-text-navy">To see the estimated projected value of your investment, input your initial investment, monthly investment, time period and desired return above and click 'Calculate'.</p>
            </Card.Content>
        );
    }

    let goalReached = props.lumpSumRequired && ((props.futureValueResponse.performsOK ?? 0) > props.lumpSumRequired);

    let cardCssClasses = new CssClasses(["is-rounded-bottom", "has-text-centered", "calculator-result", "goal-planning-x", "pt-4"]);

    if (goalReached) {
        cardCssClasses.add("goal-reached");
    } else {
        cardCssClasses.add("default-background");
    }

    const copy = {
        ok: "If you achieve your desired return",
        less: "If you achieve 2% less",
        more: "If you achieve 2% more"
    }

    return (
        <Card className={cardCssClasses.aggregated()}>
            <Card.Content className="pb-3">
                <Title />
                <div className="is-hidden-desktop">
                    <div className="highlighted">
                        <h4 className="title is-4 has-text-centered has-text-navy"> {currencyFormatter.format(props.futureValueResponse.performsOK ?? 0)} </h4>
                        <p className="has-text-centered has-text-weight-medium">{copy.ok}</p>
                    </div>
                    <Columns breakpoint={'mobile'}>
                        <Columns.Column>
                            <div className="has-text-centered has-text-navy">
                                <figure className="image is-1-by-2 is-inline-block">
                                    <img src={down} />
                                </figure>
                            </div>
                            <h4 className="title is-4 has-text-centered has-text-navy"> {currencyFormatter.format(props.futureValueResponse.performsBadly ?? 0)} </h4>
                            <p className="has-text-centered has-text-weight-medium">{copy.less}</p>
                        </Columns.Column>
                        <Columns.Column>
                            <div className="has-text-centered has-text-navy">
                                <figure className="image is-1-by-2 is-inline-block">
                                    <img src={up} />
                                </figure>
                            </div>
                            <h4 className="title is-4 has-text-centered has-text-navy"> {currencyFormatter.format(props.futureValueResponse.performsWell ?? 0)} </h4>
                            <p className="has-text-centered has-text-weight-medium">{copy.more}</p>
                        </Columns.Column>
                    </Columns>
                    <PerformanceExplainer />
                </div>
                <div className="is-hidden-touch">
                    <Columns>
                        <Columns.Column>
                            <div className="has-text-centered">
                                <figure className="image is-1-by-2 is-inline-block">
                                    <img src={down} />
                                </figure>
                            </div>
                            <h4 className="title is-4 has-text-centered has-text-navy"> {currencyFormatter.format(props.futureValueResponse.performsBadly ?? 0)} </h4>
                            <p className="has-text-centered has-text-weight-medium">{copy.less}</p>
                        </Columns.Column>
                        <Columns.Column>
                            <div className="highlighted">
                                <h4 className="title is-4 has-text-centered has-text-navy"> {currencyFormatter.format(props.futureValueResponse.performsOK ?? 0)} </h4>
                                <p className="has-text-centered has-text-weight-medium">{copy.ok}</p>
                            </div>
                        </Columns.Column>
                        <Columns.Column>
                            <div className="has-text-centered">
                                <figure className="image is-1-by-2 is-inline-block">
                                    <img src={up} />
                                </figure>
                            </div>
                            <h4 className="title is-4 has-text-centered has-text-navy"> {currencyFormatter.format(props.futureValueResponse.performsWell ?? 0)} </h4>
                            <p className="has-text-centered has-text-weight-medium">{copy.more}</p>
                        </Columns.Column>
                    </Columns>
                    <PerformanceExplainer />
                </div>
            </Card.Content>
        </Card>
    );
}

const Title = () => {
    return (
        <>
            <div className="is-flex is-flex-direction-row is-justify-content-center is-flex-gap-2 mb-4">
                <h4 className="title is-4 mb-0 has-text-navy">
                    Projected value
                </h4>
                <span className="has-text-navy">
                    <Info positioning={["top"]} notificationStyle={{marginLeft: "-13rem"}} >
                        <p className="has-text-navy">The projected value is not a guarantee and is based on the assumption that the initial amount is invested and the monthly amounts are added for the whole period.</p>
                    </Info>
                </span>
            </div>
        </>
    );
}

const PerformanceExplainer = () => {
    const [isExpanded, toggleExpanded] = useState(false);

    return (
        <div className="performance-explainer">
            {
                !isExpanded &&
                    <div className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center is-clickable" onClick={() => toggleExpanded(true)}>
                        <p className="has-text-navy has-text-centered is-underlined pr-2">Want to understand more about market performance?</p>
                        <Chevron direction="Down" size="Small" />
                    </div>
            }
            
            {
                isExpanded &&
                    <div>
                        <div className="is-flex is-flex-direction-row pb-3 is-justify-content-start has-performance-bullet-down">
                            <p className="has-text-navy has-text-left">We've chosen to show a return of 2% less than the return you've selected. This is to highlight that returns aren't guaranteed and markets can return less than expected.</p>
                        </div>
                        <div className="is-flex is-flex-direction-row pb-3 is-justify-content-start has-performance-bullet-level">
                            <p className="has-text-navy has-text-left">We've assumed the return that you've selected for the total period of your investment.</p>
                        </div>
                        <div className="is-flex is-flex-direction-row pb-3 is-justify-content-start has-performance-bullet-up">
                            <p className="has-text-navy has-text-left">We've chosen to show a return of 2% more than the return you've selected. This is to highlight that returns aren't guaranteed and markets can return more than expected.</p>
                        </div>
                        <p className="has-text-navy has-text-centered is-underlined pr-2 is-clickable" onClick={() => toggleExpanded(false)}>See less</p>
                    </div>

            }
        </div>
    )
}


const currencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    maximumFractionDigits: 0
});