import { AuthHelper } from "../components/Auth/AuthHelper";
import { Membership, Status } from "../components/Auth/AuthenticatedCmsClient";

export enum ImageSize {
    Thumbnail = "thumbnail",
    Medium = "medium",
    MediumLarge = "medium_large",
    Large = "large",
    Full = "full"
  }
  
export const getImageUrl = (image : any, size? : ImageSize) : string => {
    if (typeof image === 'string') {
        return image;
    } else if (typeof image === 'object') {
        if (size === ImageSize.Full ) {
            return image.url;
        }
        return image.sizes[size || "medium"];
    }

    return "";
}

export const getMembershipStatus = (wpObject : any) : Membership => {
    if (wpObject.status === Status.Private) {
        const dateAvailableToNonMembers = wpObject.acf.available_to_non_members_until !== undefined
            ? new Date(wpObject.acf.available_to_non_members_until)
            : undefined;

        const hasAccess = AuthHelper.isPropelleMember() 
            || (dateAvailableToNonMembers !== undefined && dateAvailableToNonMembers > new Date());

        return new Membership({
            hasAccess: hasAccess,
            requiresMembership: true,
            availableUntil: dateAvailableToNonMembers,
        });
    } else {
        return new Membership({
            hasAccess: true,
            requiresMembership: false,
        });
    }
}