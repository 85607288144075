import { Container } from "react-bulma-components";
import { GetRiskSummary_Response } from "../../../../../services/generated/PropertyClient";
import { Ranking } from "../../Risk";
import { Link } from "react-router-dom";
import { useLoader } from "../../../../../../../hoc/withLoader";
import { useEffect } from "react";
import { PropertyClientFactory } from "../../../../../services/PropertyClientFactory";
import "../RiskCapacity/RiskCapacityResultsPage.scss";
import { Main } from "../../../../../../../components/Layout/Main/Main";
import { Section } from "../../../../../../../components/Layout/Section/Section";
import { Glass } from "../../../../../../../components/Layout/Glass/Glass";
import { Content } from "../../../../../../../components/Layout/Content/Content";
import { RiskMeter } from "../../components/RiskMeter/RiskMeter";
import { Helmet } from "react-helmet-async";

export const RiskToleranceResultsPage = () => {
    const [loaderState, { load, defaultLoaderDisplay, defaultErrorDisplay }] = useLoader<GetRiskSummary_Response>();
    const client = PropertyClientFactory();

    useEffect(() => {
        if (loaderState.loadingState === "NotStarted") {
            load(client.getRiskSummary());
        }

    }, [loaderState.loadingState])

    if (loaderState.error) {
        return <>{defaultErrorDisplay()}</>;
    }

    if (loaderState.loadingState === "NotStarted" || loaderState.loadingState === "Loading") {
        return <>{defaultLoaderDisplay()}</>;
    }

    if (loaderState.response?.tolerance?.description) {
        const ranking = loaderState.response.tolerance.description;

        const copy = {
            low: "Your risk tolerance is low. You may feel uneasy about taking investment risk generally. You wouldn't like to be in the situation where your investments have dropped much - even for short periods. This doesn't mean you can't invest at all, but you should consider your risk tolerance when choosing which types of assets to invest in to ensure you take levels of risk that you feel comfortable with. Lower risk options, with lower expected returns exist and may be more suited to you that other types of investments.",
            medium: "Your risk tolerance is medium. You're likely willing to explore opportunities with a moderate level of risk while being cautious. You feel comfortable with the idea that investments can go up and down and are prepared to accept some dips for the opportunity of moderate levels of returns. Keep in mind that investments are for the long run and leaving money invested allows markets to recover and balance out the dips.",
            high: "Your risk tolerance is high which means that you understand that investing involves inherent risks and are willing to accept the possibility of high losses in pursuit of high returns. It's important to note that while risk-seeking investors can potentially achieve higher returns, they should also carefully assess their risk tolerance and diversify their portfolio to manage risk effectively."
        };

        let applicableCopy = "";
        switch(ranking.toLocaleLowerCase()) {
            case "high":
                applicableCopy = copy.high;
                break;
            case "medium":
                applicableCopy = copy.medium;
                break;
            case "low":
                applicableCopy = copy.low;
                break;
        }

        return (
            <>
                <Helmet>
                    <title>Risk Tolerance Results</title>
                </Helmet>
                <Main>
                    <Container>
                        <Section>
                            <Glass>
                                <Content isMain={true}>
                                    <h4 className="title is-size-3-touch is-2 has-text-purple-blue-500 has-text-centered has-text-left-desktop">Your risk tolerance is <span className="blob-underline">{ranking.toLocaleLowerCase()}</span></h4>
                                </Content>
                                <div className="is-hidden-touch">
                                    <div className="is-flex is-flex-direction-row is-align-items-center is-flex-gap-8">
                                        <Content>
                                            <RiskMeter rank={ranking as Ranking} size={180}>
                                                <h3 className="is-size-3 has-text-purple-blue-500">{ranking ?? "Inactive"}</h3>
                                            </RiskMeter>
                                        </Content>
                                        <Content>
                                            <p>{applicableCopy}</p>
                                        </Content>
                                    </div>
                                    <div className="is-flex is-flex-direction-row is-align-items-center is-flex-gap-8">
                                        <Link to="/invest/tools/risk/capacity" className="button has-text-centered is-primary is-rounded">Calculate risk capacity</Link>
                                        <Link to="/invest/tools/risk" className="is-underlined has-text-purple-blue-500">Skip</Link>
                                    </div>
                                </div>
                                <div className="is-hidden-desktop">
                                    <div className="is-flex is-flex-direction-column">
                                        <Content>
                                            <RiskMeter rank={ranking as Ranking} size={160}>
                                                <h3 className="is-size-4 has-text-purple-blue-500">{ranking ?? "Inactive"}</h3>
                                            </RiskMeter>
                                        </Content>
                                        <Content>
                                            <p>{applicableCopy}</p>
                                        </Content>
                                    </div>
                                </div>
                            </Glass>
                        </Section>
                        <div className="is-hidden-desktop">
                            <Section>
                                <Content>
                                    <Link to="/invest/tools/risk/capacity" className="button has-text-centered is-primary is-rounded is-fullwidth is-justify-content-center">Calculate risk capacity</Link>
                                </Content>
                                <Content className="is-fullwidth is-flex is-flex-direction-row is-justify-content-center">
                                    <Link to="/invest/tools/risk" className="is-underlined has-text-purple-blue-500">Skip</Link>
                                </Content>
                            </Section>
                        </div>
                    </Container>
                </Main>
            </>
        )
    }

    return <></>
}





