import * as classes from "./Stackable.module.scss";

export type Gap = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export const Stackable = (props: {
    stackBelowSize?: "mobile" | "tablet";
    gap?: Gap;
    gapOnStack?: Gap;
    children?: any;
    className?: string;
}) => {

    const propsOrDefaults = {
        stackBelowSize: props.stackBelowSize ?? "tablet",
        gap: props.gap ?? 0,
        gapOnStack: props.gapOnStack ?? props.gap ?? 0
    };

    return (
        <div className={`${classes.stack} ${classes[`below-size-${propsOrDefaults.stackBelowSize}`]} ${classes[`gap-${propsOrDefaults.gap}`]} ${classes[`gap-on-stack-${propsOrDefaults.gapOnStack}`]} ${props.className ?? ""}`}>
            { props.children }
        </div>
    )
}