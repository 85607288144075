import { useAuth } from "react-oidc-context";
import { PropertyClientFactory } from "../../../../services/PropertyClientFactory";
import { getUserData } from "../../../../../../helpers/auth";
import { loadingStateAggregator, useLoader, useWpLoader } from "../../../../../../hoc/withLoader";
import { PropertyOpportunityParams, WpPostTypeRequest } from "../../../../../../components/Auth/AuthenticatedCmsClient";
import { useEffect } from "react";
import { GetPropertyInvestments_Response } from "../../../../services/generated/PropertyClient";

interface DealMetadata {
    id: number;
    link: string;
    title: string;
    returnRatePerYear: number;
    timeHorizonMonths: number;
    startDate: Date;
    endDate: Date;
    dealType: string;
    provider: string;
}

interface Deal {
    amountInvested: number;
    metadata: DealMetadata;
}

const usePropertyInvestmentsLoader = () => {
    const propertyClient = PropertyClientFactory();
    const [state, { load, defaultLoaderDisplay, defaultErrorDisplay }] = useLoader<GetPropertyInvestments_Response>();
    const auth = useAuth();
    const userId = getUserData(auth)?.id ?? "";

    const loadInvestments = () => {
        if (state.loadingState === "NotStarted") {
            load(propertyClient.getPropertyInvestments(userId));
        }
    }

    return { loadingState: state.loadingState, response: state.response, functions: { loadInvestments, defaultLoaderDisplay, defaultErrorDisplay } };
}


const usePropertyDealsLoader = () =>  {
    const [state, { load }] = useWpLoader();

    const loadDeals = (dealIds: number[]) => {
        if (state.loadingState === "NotStarted") {
            load(new WpPostTypeRequest({
                url: "property-opportunity",
                params: new PropertyOpportunityParams({
                    deal_ids: dealIds.reduce((acc, x) => `${acc},${x}`, "")
                })
            }));
        }
    }

    return { 
        loadingState: state.loadingState, 
        response: (state.response ?? [] as []).map((x: any) => {
            return {
                id: parseInt(x.acf.deal_id),
                link: x.acf.external_link as string,
                title: x.title as string,
                returnRatePerYear: parseFloat(x.acf.return_rate),
                timeHorizonMonths: parseInt(x.acf.time_horizon),
                startDate: new Date(x.acf.start_date),
                endDate: new Date(x.acf.end_date),
                dealType: x.acf.deal_type as string,
                provider: x.acf.provider as string,
            } as DealMetadata;
        }) as Array<DealMetadata>,
        functions: { loadDeals } };
}

export const useDashboardLoader = () => {
    const investmentsLoader = usePropertyInvestmentsLoader();
    const dealsLoader = usePropertyDealsLoader();

    useEffect(() => {
        if (investmentsLoader.loadingState === "NotStarted") {
            investmentsLoader.functions.loadInvestments();
        }

        if (investmentsLoader.loadingState === "Loaded" && investmentsLoader.response && dealsLoader.loadingState === "NotStarted") {
            const dealIds = investmentsLoader.response?.deals?.map(x => x.simpleCrowdfundingDealId ?? 0) ?? [];
            dealsLoader.functions.loadDeals(dealIds);
        }

    }, [investmentsLoader.loadingState, dealsLoader.loadingState]);

    return { 
        loadingState: loadingStateAggregator([investmentsLoader.loadingState, dealsLoader.loadingState]),
        response: {
            deals: investmentsLoader.response?.deals?.map(x => {
                const dealMetadata = dealsLoader.response?.find(y => y.id === x.simpleCrowdfundingDealId);
                return {
                    amountInvested: x.amountInvested,
                    metadata: dealMetadata
                } as Deal
            }) as Array<Deal>
        },
        functions: {
            defaultLoaderDisplay: investmentsLoader.functions.defaultLoaderDisplay,
            defaultErrorDisplay: investmentsLoader.functions.defaultErrorDisplay
        }
    }
}