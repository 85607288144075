import { AuthContextProps, useAuth } from "react-oidc-context";
import JwtDecode, { JwtPayload } from "jwt-decode";

export interface UserData {
    id: string | undefined;
    name: string | undefined;
    email: string | undefined;
    role: string | undefined;
    membership: "free" | "pro" | undefined;
    hasBetaAccess: "true" | "false";
}

export const getUserData = (auth? : AuthContextProps): UserData => {
    if (!auth) {
        auth = useAuth();
    }

    if (auth.isAuthenticated && auth.user?.access_token) {
        const token = JwtDecode<JwtPayload>(auth.user.access_token);

        return {
            id: (token as any)["propelle_user_id"],
            name: (token as any)["propelle_name"],
            email: (token as any)["propelle_email"],
            role: (token as any)["propelle_role"],
            membership: (token as any)["propelle_membership"],
            hasBetaAccess: (token as any)["propelle_beta_access"]
        }
    }

    return {
    } as UserData;
}

export const isPropelleEmployee = (auth?: AuthContextProps): boolean => {

    if (!auth) {
        auth = useAuth();
    }

    if (auth.isAuthenticated && auth.user?.access_token) {
        const token = JwtDecode<JwtPayload>(auth.user.access_token);

        let role = (token as any)["propelle_role"];
        return role === "staff";
    }

    return false;
}

export const isPropelleMember = (auth?: AuthContextProps): boolean => {
    if (!auth) {
        auth = useAuth();
    }
    
    if (auth.isAuthenticated && auth.user?.access_token) {
        const token = JwtDecode<JwtPayload>(auth.user.access_token);

        let role = (token as any)["propelle_membership"];
        return role === "pro";
    }

    return false;
}
