import { CssClasses } from "../Helpers/CssClasses";
import * as classes from "./Label.module.scss";

export const Label = (props: { text: string, className?: string }) => {

    const cssClasses = new CssClasses(["has-text-weight-medium"]);

    if (classes.label) {
        cssClasses.add(classes.label);
    }

    if (props.className) {
        cssClasses.add(props.className);
    }

    return (
        <span className={cssClasses.aggregated()}>{props.text}</span>
    );
}