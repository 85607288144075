import { OverlayCardColor, OverlayCardProps } from "../../../../../components/Cards/OverlayCard";
import { CarouselWithPartsOverlay } from "../../../../../components/CarouselWithPartsOverlay/CarouselWithPartsOverlay";
import { useWpLoader } from "../../../../../hoc/withLoader";
import { useEffect } from "react";
import { Order, OrderBy, Params, WpTaxonomyRequest } from "../../../../../components/Auth/AuthenticatedCmsClient";
import { ImageSize, getImageUrl } from "../../../../../helpers/cms";

interface CoursesCarouselProps {
    title: string;
    color?: OverlayCardColor;
}

export const CoursesCarousel = ({ title, color } : CoursesCarouselProps) => {
    const [loaderState, { load, defaultLoaderDisplay }] = useWpLoader();

    useEffect(() => {
        if (loaderState.loadingState === "NotStarted") {
            load(new WpTaxonomyRequest({
                url: "course-taxonomy",
                params: new Params({
                    per_page: 100,
                    orderby: OrderBy.TermOrder,
                    order: Order.Ascending
                })
            }));
        }
        
    }, [loaderState.loadingState]);

    const parser = new DOMParser();

    let inner = defaultLoaderDisplay();
    if (loaderState.loadingState === "Loaded") {

        const courses = loaderState.response.filter((x : any) => x["acf"]["taxonomy_type"] === "course");
        const levels = loaderState.response.filter((x : any) => x["acf"]["taxonomy_type"] === "level");

        let getTopicsForCourseId = (courseId : number) => {
            let courseLevels = levels.filter((x : any) => x.parent === courseId);
            let totalTopics = 0;
            courseLevels.forEach((level : any) => {
                totalTopics = totalTopics + parseInt(level.children_count);
            });
            return totalTopics;
        }

        let contents : OverlayCardProps[] = courses.map((x : any) : OverlayCardProps & { url: string }=> {
            return {
                heading: parser.parseFromString(x.name, "text/html").body.textContent || "",
                url: `/learn/courses/${x.slug}`,
                imageUrl: getImageUrl(x.acf.thumbnail, ImageSize.Medium),
                count: getTopicsForCourseId(x.id),
                countType: 'topics'
            }
        });
    
        inner = (
            <CarouselWithPartsOverlay title={title} items={contents} color={color} linkTo={`/learn/courses`}/>
        );
    }

    return (
        inner
    );
}