import './OverlayCard.scss';
import { ReactNode } from "react";
import { CardBadgeMembership } from "./CardBadgeMembership/CardBadgeMembership";
import { MembershipProps } from "../Membership/MembershipProps";
import { withLink } from "../../hoc/withLink";

export interface CardProps extends MembershipProps {
    heading: string;
    imageUrl: string;
    children?: ReactNode;
    className?: string;
    matchHeight?: boolean;
}

export const Card = ({heading, imageUrl, children, membership, className = "", matchHeight = false} : CardProps) => {

    return (
        <div className={`card ${className} p-3-desktop`}>
            <div className="card-image">
                {
                    membership?.requiresMembership &&
                        <div className="is-overlay">
                            <CardBadgeMembership membership={membership} className="is-pulled-right"/>
                        </div>  
                }
                <figure className="image is-2by1">
                    <img src={imageUrl} />
                </figure>
            </div>
            <div className={`card-header ${ matchHeight ? 'match-height' : ''}`}>
                <h5 className="is-family-primary card-header-title px-1 pb-0 has-text-weight-medium has-text-black">{heading}</h5>
            </div>
            {   children && 
                    <div className="card-content px-1 py-0">
                        {children}
                    </div>
            }
        </div>
    )
}

export const CardWithLink = withLink(Card, "card-wrapper");