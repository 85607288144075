import { createContext, useContext, useState } from "react"
import { Info } from "react-feather"
import { Explainer } from "../Explainer/Explainer"
import { ScreenSize, WindowContext } from "../App/WindowContext";

import * as classes from "./Form.module.scss";

const FormContext = createContext({ 
    columns: 2,
    gap: 4
});

export const Form = ({ 
    children = undefined as undefined | any,
    onSubmit = (() => {}) as React.FormEventHandler<HTMLFormElement>,
    columns = 2,
    gap = 4,
    className = undefined as undefined | string
}) => {
    return (
        <div className={`${classes.form} ${className}`}>
            <form onSubmit={onSubmit}>
                <FormContext.Provider value={{columns, gap}}>
                    { children}
                </FormContext.Provider>
            </form>
        </div>
    )
};

const Inputs = ({ 
    children = undefined as React.ReactElement[] | React.ReactElement | undefined 
}) => {

    const formContext = useContext(FormContext);
    const windowContext = useContext(WindowContext);
    const items = Array.isArray(children) ? children : [children]; 

    const columns = Array<Array<React.ReactElement | undefined>>(formContext.columns);
    for (let i = 0; i < columns.length; i++) {

        columns[i] = new Array<React.ReactElement | undefined>();

        for (let j = 0; j < items.length; j++) {
            if ((j - i) % formContext.columns === 0) {
                columns[i]?.push(items[j]);
            }
        }
    }

    if (windowContext.screenSize < ScreenSize.Tablet) {
        return (
            <div className="columns mb-0">
                <div className="column">
                    { 
                        items?.map((item, k) => {
                            return (
                                <div className={`pb-${formContext.gap}`} key={`input_${k}`}>
                                    { item }
                                </div>
                            )
                        }) 
                    }
                </div>
            </div>
        )
    }

    return (
        <div className="columns mb-0">
            { 
                columns?.map((column, i) => {
                    return (
                        <div className="column" key={i}>
                            <div className={`${i % 2 === 0 ? `pr-${formContext.gap}-tablet pr-${formContext.gap}-desktop` : ''}`}>
                                { 
                                    column?.map((item, j) => {
                                        return (
                                            <div className={`pb-${formContext.gap}`} key={`input_${j}`}>
                                                { item }
                                            </div>
                                        )
                                })}
                            </div>
                        </div>
                    )
            })}
        </div>
    )
}

const FormInput = ({ children = undefined as undefined | any }) => {
    return (
        <>{ children }</>
    )
}

const Label = ({ 
    text = undefined as undefined | string,
    infoExplainer = undefined as undefined | string,
    textSize = 5 as 5 | 6 
}) => {

    const [showInfoExplainer, toggleInfoExplainer] = useState(false);

    const LabelText = ({ children = undefined as undefined | any }) => <p className={`has-text-purple-blue-500 is-size-${textSize} has-text-weight-medium`}>{text}{children}</p>;

    if (!infoExplainer) {
        return <LabelText />
    }

    return (
        <div className="is-position-relative mb-1">
            {
                showInfoExplainer &&           
                <div className="is-position-absolute is-left is-bottom mb-6 is-z-index-40">
                    <Explainer onDismiss={() => toggleInfoExplainer(false)}>
                        { infoExplainer }
                    </Explainer>
                </div>
            }
            <div className="is-flex is-flex-direction-row is-align-items-center is-flex-gap-1">
                <LabelText />
                <figure className="is-flex is-flex-direction-column is-justify-content-center">
                    <Info color="#1B0777" size={25} className="is-clickable" onClick={() => toggleInfoExplainer(!showInfoExplainer)}/>
                </figure>
            </div>
        </div>
    )
}

const PrefixedInput = ({
    prefix = undefined as undefined | any,
    children = undefined as any | undefined
}) => {
    return (
        <div className="is-flex is-flex-direction-row is-align-items-center is-flex-gap-1">
            <p className="has-text-weight-medium has-text-purple-blue-500 is-size-5">{prefix}</p>
            <div className="is-flex-grow-1">
                {children}
            </div>
        </div>
    )
}

const ValidationMessage = ({
    text = undefined as undefined | string
}) => {

    if (!text) {
        return <></>
    }

    return (
        <p className="help is-danger">{text}</p>
    )
}

const Submit = ({ 
    disabled = false,
    loading = false,
    tabIndex = undefined as undefined | number,
    children = undefined as undefined | any 
}) => {
    return (
        <button type="submit" className={`button is-primary is-rounded${loading ? " is-loading" : ""}`} disabled={disabled} tabIndex={tabIndex}>
            {children}
        </button>
    )
}

Form.Inputs = Inputs;
Form.FormInput = FormInput;
Form.Submit = Submit;
FormInput.Label = Label;
FormInput.PrefixedInput = PrefixedInput;
FormInput.ValidationMessage = ValidationMessage;