import { Button, Container, Content, Section } from "react-bulma-components";
import { useNavigate } from "react-router-dom";
import { BodyHeading } from "../../../../components/Heading/Heading";
import { PropertyClientFactory } from "../../services/PropertyClientFactory";
import { Helmet } from "react-helmet-async";

export const InvestmentChecklist = () => {

    const client = PropertyClientFactory();
    let navigate = useNavigate();

    let updateStatus = async () => {
        await client.completeInvestmentChecklist();
        navigate("/invest/property");
    };

    return (
        <Section>
            <Helmet>
                <title>Investment Checklist</title>
            </Helmet>
            <Container>
                <Content>
                    <h2>Investment Checklist</h2>
                    <p>Investing is a decision that should be well thought through. We recommend reading the following list and making sure you've actioned each of them and feel comfortable with your decision to proceed and invest in property in this way.</p>

                    <BodyHeading>Declaration</BodyHeading>
                    <ul className="propelle-list">
                        <li>You've just just gone through a risk assessment to understand your risk appetite. This tool is available for you to use at any time to re-assess your risk appetite if your circumstances change.</li>
                        <li>You can ask any outstanding questions you have about a deal in our Property investments community chat group <a href="https://join.slack.com/t/propellecommunity/shared_invite/zt-1s0efq24i-DvxOnJFpKxU72m9xSqwhjw" target="_blank">here</a>.</li>
                    </ul>
                    <Button onClick={updateStatus} rounded className="mt-3" color={'primary'}>I'm ready to invest</Button>
                </Content>
            </Container>
        </Section>);
}