import { Link, useLocation } from "react-router-dom";
import './Breadcrumb.scss';
import { ChevronRight } from "react-feather";

export interface BreadcrumbProps {
  removeIndexes?: Array<number>
  className?:string
}

const Breadcrumb = ({ removeIndexes = [], className }: BreadcrumbProps) => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);

  const links = pathnames
    .filter((_, index) => !removeIndexes.includes(index))
    .map((name, index, filteredPathnames) => {
      const routeTo = `/${filteredPathnames.slice(0, index + 1).join('/')}`;
      const displayName = name.replace(/-/g, ' '); 
      return { displayName, slug: routeTo };
    });

  return (
    <div className={className}>
      <nav className="breadcrumb is-size-5 pb-5 px-3-desktop pb-3-desktop pt-0" aria-label="breadcrumbs">
        <ul>
          {
            links.map((link, index) => {
              return (
                <li key={link.displayName}>
                  <Link to={link.slug} className="px-0">
                    {index != 0 &&
                      <span className="icon has-text-black">
                        <ChevronRight size={24} strokeWidth="1" />
                      </span>
                    }
                    <span className="breadcrumb-display-name has-text-black">{link.displayName}</span>
                  </Link>
                </li>
              )
            })
          }
        </ul>
      </nav>
    </div>
  );
};

export default Breadcrumb;
