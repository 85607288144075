import { ReactNode } from "react";
import * as classes from "./SectionWithSeparator.module.scss";
import { MembershipProps } from "../../Membership/MembershipProps";
import { Lock, Unlock } from "react-feather";
import { Section } from "./Section";

interface SectionWithSeparatorProps extends MembershipProps {
    children?: ReactNode;
    className?: string;
    title: string;
    separatorHeaderOverride?: string;
}

export const SectionWithSeparator = ({ children, className = "", title, membership, separatorHeaderOverride } : SectionWithSeparatorProps) => {

    const getMembershipSeparatorHeader = () => {

        if (membership?.requiresMembership) {
            return (
                membership?.hasAccess
                    ? <>
                        PRO FEATURES UNLOCKED 
                        <Unlock style={{height: '18px'}} className="ml-1"/>
                    </>
                    : <>
                        UPGRADE TO PRO FOR ACCESS 
                        <Lock style={{height: '18px'}} className="ml-1"/>
                    </>
            )
        }

        return <></>;
    }

    return (
        <Section className={`${className} ${classes['section']}`} smallMargin={true}>
            <h4 className={`mb-4 ${classes['title']}`}>
                <span className="is-family-montserrat has-text-weight-bold has-letter-spacing-2 has-text-purple-blue-500">{title.toUpperCase()}</span>
                {
                    (membership?.requiresMembership || separatorHeaderOverride !== undefined) &&
                        <span className={`${classes['separator-header']} is-family-primary has-text-weight-medium has-text-purple-blue-300`}>
                            {
                                separatorHeaderOverride !== undefined
                                    ? separatorHeaderOverride.toUpperCase()
                                    :  getMembershipSeparatorHeader()
                            }
                        </span>
                }
            </h4>
            { children }
        </Section>
    )
}