import { Link } from "react-router-dom";
import { Content } from "../../../../../../../components/Layout/Content/Content";
import { IconProps, MinusCircle, PlusCircle, Repeat, User } from "react-feather";
import * as classes from "./Actions.module.scss";
import { CssClasses } from "../../../../../../../components/Helpers/CssClasses";
import { LoaderProps } from "../../../../../../../components/Layout/Wrappers/ILoaderProps";
import { useContext, useEffect } from "react";
import { useLoader } from "../../../../../../../hoc/withLoader";
import { Info } from "../../../../../../../components/Info/Info";
import { GetPortfolioAvailableActions_Action, GetPortfolioAvailableActions_Response } from "../../../../../services/generated/PortfoliosClient";
import { FundsContext } from "../../../../components/FundsContext";


interface Action {
    allowed: boolean;
    reason?: string;
}

interface ActionsProps {
    activeActions: {
        deposit?: Action
        withdraw?: Action;
        changeFund?: Action;
    }
}

export const Actions = ({ activeActions } : ActionsProps) => {
    return (
        <Content>
            <h3 className="is-size-h5 has-text-purple-blue-500">Actions</h3>
            <Action icon={<PlusCircle/>} name="Deposit" link="/invest/funds/deposit" action={activeActions.deposit} />
            <Action icon={<MinusCircle/>} name="Withdraw" link="/invest/funds/withdraw" action={activeActions.withdraw}/>
            <Action icon={<Repeat/>} name="Change Fund" link="/invest/funds/change-fund" action={activeActions.changeFund}/>
            <Action icon={<User/>} name="View User Details" link="/my-account" action={{ allowed: true }} />
        </Content>
    )
};


export const ActionsContainer = ({ wrap } : LoaderProps) => {
    const [loaderState, { defaultLoaderDisplay, defaultErrorDisplay }] = useLoader<GetPortfolioAvailableActions_Response>();
    const context = useContext(FundsContext);

    useEffect(() => {
        context.fetchPortfolioActions();
    }, []);

    const inner = () => {
        if (context.actions) {

            let deposit = context.actions.actions?.find(x => x.action == GetPortfolioAvailableActions_Action.Deposit);
            let withdraw = context.actions.actions?.find(x => x.action == GetPortfolioAvailableActions_Action.Withdrawal);
            let changeFund = context.actions.actions?.find(x => x.action == GetPortfolioAvailableActions_Action.Rebalance);

            let activeActions = {
                deposit: {
                    allowed: deposit?.allowed ?? false,
                    reason: deposit?.reason
                },
                withdraw: {
                    allowed: withdraw?.allowed ?? false,
                    reason: withdraw?.reason
                },
                changeFund: {
                    allowed: changeFund?.allowed ?? false,
                    reason: changeFund?.reason
                },
            }

            return <Actions activeActions={activeActions}/>;
        }

        if (loaderState.loadingState === "Failed") {
            return defaultErrorDisplay();
        }

        return defaultLoaderDisplay();
    }

    return wrap
        ? wrap(inner())
        : inner();
}

interface ActionProps {
    icon: IconProps;
    name: string;
    link: string;
    action?: Action;
}

const Action = ({ icon, name, link, action } : ActionProps) => {

    let cssClasses = new CssClasses([(action?.allowed ?? false) ? classes.active : classes.inactive, "is-flex is-align-items-center is-flex-gap-2 mt-4"]);

    const inner = () => {
        return (
            <>
                <>{icon}</>
                <span>{name}</span>
                {
                    !action?.allowed &&
                        <Info positioning={["right"]}><span>{action?.reason}</span></Info>
                }
            </>
        )
    }

    return action?.allowed
        ? <Link to={link} className={cssClasses.aggregated()}>{inner()}</Link>
        : <span className={cssClasses.aggregated()}>{inner()}</span>

}