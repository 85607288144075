import { useNavigate } from "react-router-dom";
import { Section } from "../../../../../../components/Layout/Section/Section";


export const Onboarding = () => {
    let navigate = useNavigate();    
    const handleClick = () => {
        navigate("/invest/funds/account-setup")
    }

    return (
        <Section>
            <div className="columns">
                <div className="column is-7">
                    <h1 className="title is-size-2-tablet is-size-3-mobile has-text-centered-mobile">🎉</h1>
                    <h1 className="title is-size-2-tablet is-size-3-mobile pb-2-desktop has-text-navy has-text-centered-mobile">Investment funds have arrived!</h1>
                    <p className="is-size-5-tablet is-size-6-mobile has-text-black pb-4">
                        Investment funds are now an investment option on the Propelle platform.
                        </p>
                    <p className="is-size-5-tablet is-size-6-mobile has-text-black pb-4">
                        Open an Individual Savings Account (ISA) or a General Investment Account (GIA) and start investing. You’ll be able to chose one of 3 funds that aligns with your investment strategy.
                    </p>
                    <button className="button is-primary is-rounded is-normal" onClick={handleClick}>Open your account</button>
                    <p className="is-size-smaller mt-4">As with all investments, your capital is at risk.</p>
                </div>
            </div>
        </Section>
    );
};