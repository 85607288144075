import { ComponentType, ReactElement } from "react";
import { MembershipProps } from "../components/Membership/MembershipProps";
import { Link } from "react-router-dom";

interface WithLinkProps extends MembershipProps {
    url?: string;
}

export const withLink = <P extends object>(Component: ComponentType<P & WithLinkProps>, linkClassName: string = "")
    : ((props: P & WithLinkProps) => ReactElement) => 
        ({url, membership, ...props}: P & WithLinkProps): ReactElement => {

            const urlUnlocked = membership === undefined || (!membership.requiresMembership || membership.hasAccess);

            const isExternalUrlOrPdf = (url: string) : boolean => {
                try {
                    const parsedUrl = new URL(url);
                    return parsedUrl.pathname.endsWith(".pdf") || parsedUrl.hostname !== window.location.hostname;
                } catch (error) {
                    return false;
                }
            }

            let to = urlUnlocked ? url : "/membership";

            if (to !== undefined && to !== "") {
                return isExternalUrlOrPdf(to)
                    ? <a href={to} className={`${linkClassName} is-clickable`} target="_blank">
                        <Component membership={membership} {...props as P} />
                    </a>
                    : <Link to={to} className={`${linkClassName} is-clickable`}>
                        <Component membership={membership} {...props as P} />
                    </Link>
            }

            return (
                <div className={linkClassName}>
                    <Component membership={membership} {...props as P} />
                </div>
            ) 
        };