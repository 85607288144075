import dayjs from "dayjs";
import { useContext } from "react";
import { PropertyContext } from "../../Property/PropertyContext";
import { DashboardTile } from "../../../../../home/Dashboard/components/DashboardTile/DashboardTile";
import { CheckCircle } from "react-feather";

export const SelfCertificationTile = () => {
    const context = useContext(PropertyContext);

    const getDaysLeftUntilReCertification = (expiresOn : Date ) => {
        const now = dayjs();
        const expiresOnDayJs = dayjs(expiresOn);

        return -now.diff(expiresOnDayJs.add(1, 'day'), 'day'); // Difference + 1 day to make sure days are rounded up
    }

    if (context.loadingState === "Loaded" && !context.isEligibleForInvestment) {

        if (context.canSubmitSelfCertification && (context.reSubmissionAllowedOn === undefined || dayjs(context.reSubmissionAllowedOn).isBefore(dayjs()))) {
            return <DashboardTile 
                    className="has-background-peach-100 has-background-yellow-100-desktop" 
                    title={<h5 className="is-flex is-family-primary has-text-purple-blue-500 is-size-5 has-text-weight-semibold"><CheckCircle className="mr-3" fontWeight={700}/> Check you qualify to invest</h5>}
                    description="Please complete our investor certification questionnaire to check your suitability and access property investment opportunities."
                    button={{ text: "Complete investor certification", to: "/invest/property/self-certification", isSolid: true }}
                />
        }

        if (!context.canSubmitSelfCertification) {

            const daysLeft = getDaysLeftUntilReCertification(context.reSubmissionAllowedOn!)

            return <DashboardTile 
                className="has-background-peach-100 has-background-yellow-100-desktop" 
                title={<h5 className="is-family-primary has-text-purple-blue-500 is-size-5 has-text-weight-semibold">Sorry you don’t qualify for this investment type currently.</h5>}
                description={<p>Please wait 30 days to re-take the investor certification. You have <span className="has-text-weight-bold has-text-purple-blue-400">{daysLeft} {daysLeft == 1 ? "day" : "days"}</span> left until you can re-take the investor certification.</p>}
                button={{ text: "Complete investor certification", to: "/invest/property/self-certification", isSolid: true, disabled: true }}
            />
        }
    }    

    return <></>;
}