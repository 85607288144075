import { Helmet, HelmetProvider } from "react-helmet-async";
import { AuthorizationContext } from "../Auth/AuthorizationContext";
import { RouteConfig } from "../../RouteConfig";
import { WindowContextProvider } from "./WindowContext";

export const App = () => {
  return (
    <WindowContextProvider>
      <AuthorizationContext>
          <HelmetProvider>
            <Helmet
              titleTemplate="Propelle Platform - %s"
              defaultTitle="Propelle Platform"/>
            <RouteConfig/>
          </HelmetProvider>
      </AuthorizationContext>
    </WindowContextProvider>
  );
}