import { LatestArticles } from "./components/LatestArticles/LatestArticles";
import { SubCategoryCarousel } from "./components/SubCategoryCarousel";
import { Helmet } from "react-helmet-async";
import { OverlayCardColor } from "../../../../components/Cards/OverlayCard";
import { CoursesCarousel } from "./components/CoursesCarousel";
import { Main } from "../../../../components/Layout/Main/Main";
import { Section } from "../../../../components/Layout/Section/Section";

export const Learn = () => {
    return (
        <>
            <Helmet>
                <title>Learn</title>
            </Helmet>
            <Main>
                <Section>
                    <LatestArticles/>
                </Section>
                <Section>
                    <SubCategoryCarousel title={"Videos"} categorySlug={"videos"} color={OverlayCardColor.Lilac} countType="videos"/>
                </Section>
                <Section>
                    <CoursesCarousel title={"Courses"} color={OverlayCardColor.Navy}/>
                </Section>
            </Main>
        </>
    );
}


