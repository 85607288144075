import { Outlet } from "react-router-dom";
import { Navbar } from "../Nav/Navbar";
import { PropelleMenu } from "../Menu/PropelleMenu";

import './_Layout.scss';
import { PropelleMobileMenu, PropelleMobileMenuSpacer } from "../Menu/PropelleMobileMenu";
import { LocationManager } from "../Location/LocationContext";
import { Footer } from "./Footer/Footer";

export interface LayoutProps {
    children?: React.ReactElement[] | React.ReactElement;
}

export const Layout = (props: LayoutProps) => {
    return (
        <LocationManager>
            <Navbar/>
            <PropelleMenu/>
            <div className="content-container">
                <main>
                    <Background />
                    { props.children }
                    <Outlet />
                </main>
                <Footer />
                <PropelleMobileMenuSpacer />
            </div>
            <PropelleMobileMenu />
        </LocationManager>
    );
}

const Background = () => {
    return <div className="background"></div>;
}