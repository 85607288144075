import { formatCurrency } from "../../../../../../../helpers/currency";
import { currentTaxYear } from "../../../../../../../helpers/dates";
import dayjs from "dayjs";
import { Info } from "../../../../../../../components/Info/Info";
import { ExternalLink, Square } from "react-feather";
import { Link } from "react-router-dom";
import { AccountType } from "../../../../../services/generated/AccountManagementClient";
import { ProgressBar } from "../../../../../../../components/ProgressBar/ProgressBar";

interface AccountCardProps {
    type: AccountType;
    breakdown: {
        current: number;
        settledContributions: number;
        incomingContributions: number;
    }
    maxLimit?: number;
}

export const AccountCard = ({ type, breakdown, maxLimit } : AccountCardProps) => {

    const getCurrentTaxYearEndYear = () : string => {
        const now = dayjs();
        const endOfTaxYear = dayjs().month(3).date(5);
        if (now.isAfter(endOfTaxYear)) {
            return endOfTaxYear.add(1, "year").year().toString();
        }
        return endOfTaxYear.year().toString();
    }

    const taxYear = currentTaxYear();

    return (
        <div className="has-text-purple-blue-500">
            <h3 className="is-size-h5">Your {type.toLocaleUpperCase()}</h3>
            <div className="mb-2 has-text-weight-medium">
                <div className="is-size-h7 has-text-weight-medium is-flex is-align-items-center is-flex-gap-2 mb-2">
                    Tax Year {taxYear.start}/{taxYear.end}
                </div>
                <p>Total net contributions: {formatCurrency(breakdown.incomingContributions + breakdown.settledContributions)}</p>
                {
                    type === AccountType.Isa && 
                        <p>Remaining Allowance: {formatCurrency(maxLimit! - (breakdown.incomingContributions + breakdown.settledContributions))}</p>
                }
            </div>
            { 
                type === AccountType.Isa && 
                <>
                    <p>The tax year ends on the 5th April {getCurrentTaxYearEndYear()}</p>
                    <div className="py-3 is-flex">
                        <ProgressBar values={[
                            {
                                value: (breakdown.incomingContributions + breakdown.settledContributions) / maxLimit! * 100,
                                color: "#FFB938"
                            },
                            {
                                value: (breakdown.settledContributions / maxLimit!) * 100,
                                color: "#A594F9"
                            }
                        ]} />
                        <span className="is-size-smaller has-text-grey-300 has-text-nowrap ml-2">Max. £20K</span>
                    </div>
                    <p className="is-size-smaller has-text-weight-medium is-flex is-align-items-center is-flex-gap-1">
                        <Square fill="#A594F9" stroke="#A594F9" height={14} width={20}/>
                        <span>Settled contributions {formatCurrency(breakdown.settledContributions)}</span>
                        <Info positioning={["right"]}>
                            <span>This is the gross contribution you’ve paid into your account (in this tax year). Gross means, we don’t subtract the fees taken, we only add up all the contributions you’ve made and don’t consider withdrawals you’ve made.</span>
                        </Info>
                    </p>
                    <p className="is-size-smaller has-text-weight-medium is-flex is-align-items-center is-flex-gap-1">
                        <Square fill="#FFB938" stroke="#FFB938" height={14} width={20}/>
                        <span>Incoming contributions {formatCurrency(breakdown.incomingContributions)}</span>
                        <Info positioning={["right"]}>
                            <span>This shows the contributions you’ve created, but where we haven’t received the money yet. If you’ve sent the money, it should be with us shortly. If you haven’t sent money and don’t plan to do so, please get in touch so we can delete your contribution.</span>
                        </Info>
                    </p>
                </>
            }
            <h4 className="is-size-h7 mt-4">Useful Links</h4>
            {
                type === AccountType.Gia &&
                <>
                    <UsefulLink name="What's an GIA?" link="/learn/article/what-is-a-gia" />
                    <UsefulLink name="Key Feature Document" link="https://propelle.io/gia-key-feature-document" external={true} />
                </>
            }
            {
                type === AccountType.Isa &&
                <>
                    <UsefulLink name="What's an ISA?" link="/learn/article/what-is-an-isa" />
                    <UsefulLink name="Key Feature Document" link="https://propelle.io/isa-key-feature-document" external={true} />
                </>
            }
        </div>
    )
}

interface UsefullLinkProps {
    name: string;
    link: string;
    external?: boolean;
}

const UsefulLink = ({ name, link, external = false } : UsefullLinkProps) => {

    let inner = <>
        <ExternalLink height={20}/>
        <span className="has-text-purple-blue-400">{name}</span>
    </>

    return external
        ? <a href={link} className="is-flex is-align-items-center is-flex-gap-2 mt-2 is-underlined" target="_blank">
            {inner}
        </a>
        : <Link to={link} className="is-flex is-align-items-center is-flex-gap-2 mt-2 is-underlined" target="_blank">
            {inner}
        </Link>
}