import { Container } from "react-bulma-components";
import { Helmet } from "react-helmet-async";
import { Link} from "react-router-dom";
import { Main } from "../../../../../../components/Layout/Main/Main";
import { Glass } from "../../../../../../components/Layout/Glass/Glass";
import { BackButton } from "../../../../../../components/BackButton/BackButton";

export const Success = () => {
    return (
        <Main>
            <Helmet>
                Create account
            </Helmet>
            <Container>
                <BackButton to={"/invest/funds/user-details"} text="Back" className="mb-3"/>
                <div className="columns">
                    <div className="column is-10-widescreen is-8-fullhd">
                        <Glass>
                            <h1 className="is-size-2 has-text-navy has-text-centered-mobile">Add your details</h1>
                            <h2 className="title is-1 has-text-centered p-4">🎉</h2>
                            <h2 className="title is-4 has-text-centered has-text-navy pb-6">You’re all done! It’s time to select your account type. </h2>
                            <div className="is-flex is-justify-content-center"><Link className="button is-primary is-rounded" to={"/invest/funds/account-setup"}>Next</Link></div>
                        </Glass>
                    </div>
                </div>
            </Container>
        </Main>
    );
};