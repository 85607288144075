import { Helmet } from "react-helmet-async"
import { Main } from "../../../../../../components/Layout/Main/Main"
import { Glass } from "../../../../../../components/Layout/Glass/Glass"
import { Section } from "../../../../../../components/Layout/Section/Section"
import { Content } from "../../../../../../components/Layout/Content/Content"
import { Form } from "../../../../../../components/Forms/Form"
import { ObjectSchema, number } from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { PortfoliosClientFactory } from "../../../../services/PorfoliosClientFactory"
import { EquityContext } from "../OnboardingContext"
import { RequestDepositToPortfolio_Request } from "../../../../services/generated/PortfoliosClient"
import { AccountManagementClientFactory } from "../../../../services/AccountManagementClientFactory"
import { BackButton } from "../../../../../../components/BackButton/BackButton"

export const Deposit = ({

}) => {
    interface DepositForm {
        amount?: number
    }

    const validation = new ObjectSchema<DepositForm>({
        amount: number()
            .typeError('Please enter a valid amount')
            .required('Please enter an amount')
            .moreThan(4, 'Amount must be more than £5')
            .test(
                "amountToNearestPenny",
                "Amount must be to the nearest penny",
                x => {
                    const split = x.toString().split('.');
                    if (split.length <= 1) {
                        return true;
                    }

                    if ((split[1]?.length ?? 0) <= 2) {
                        return true;
                    }

                    return false;
                } 
            )
            .lessThan(20000, 'Amount must be less than £20,000')
    });
    
    const { register, formState: { isValid, errors }, getValues, handleSubmit } = useForm<DepositForm>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(validation)
    });

    const context = useContext(EquityContext);

    const navigate = useNavigate();

    const onFormCompleted = () => {
        toggleSubmissionStatus({ isSubmitting: true, isSubmitted: false});
    }

    const submit = async () => {

        try {
            const accountsClient = AccountManagementClientFactory();
            const accounts = await accountsClient.getAccountsByAccountHolder(context?.userData?.accountHolderId ?? "");
            const defaultAccount = accounts?.accounts?.[0]; // Only 1 account right now
    
            const portfoliosClient = PortfoliosClientFactory();
            const portfolios = await portfoliosClient.getPortfoliosForAccount(defaultAccount?.id ?? "");
            const defaultPortfolio = portfolios?.portfolios?.[0]; // Only 1 portfolio right now
    
            const response = await portfoliosClient.requestDepositToPortfolio(new RequestDepositToPortfolio_Request({
                amount: getValues("amount")
            }), (defaultPortfolio?.id ?? -1).toString());

            context?.setInitialDepositId(response.depositId);

            toggleSubmissionStatus({ isSubmitting: false, isSubmitted: true});
        } catch (e) {
            toggleSubmissionStatus({ isSubmitting: false, isSubmitted: false});
        }        
    }
    
    const [submissionState, toggleSubmissionStatus] = useState({ isSubmitting: false, isSubmitted: false });

    useEffect(() => {
        if (submissionState.isSubmitting) {
            submit()
        } else if (submissionState.isSubmitted) {
            navigate('/invest/funds/bank-details')
        }
    }, [submissionState.isSubmitting])

    return (
        <>
            <Helmet>
                <title>Funds - Make a deposit</title>
            </Helmet>
            <Main>
                <div className="container">
                    <BackButton to={"/invest/funds/account-setup"} text="Back" className="mb-3"/>
                    <div className="columns">
                        <div className="column is-12 is-7-widescreen">
                            <Glass opacity={100}>
                                <Section>
                                    <Content>
                                        <h1 className="is-size-4 has-text-centered has-text-left-desktop is-size-2-desktop has-text-purple-blue-500">Make a contribution</h1>
                                    </Content>
                                    <Content>
                                        <p className="is-size-5 has-text-weight-semibold has-text-purple-blue-500">How much would you like to contribute?</p>
                                    </Content>
                                    <Form className="mx-0" onSubmit={handleSubmit(onFormCompleted)}>
                                        <Form.Inputs>
                                            <Form.FormInput>
                                                <Form.FormInput.Label text="Amount" infoExplainer="The amount you want to contribute. Heads up - this deposit will go into the fund you chose in the previous step. As with all investments, your capital is at risk." />
                                                <Form.FormInput.PrefixedInput prefix={"£"}>
                                                    <input type="text" className="input" {...register("amount", { valueAsNumber: true })} />
                                                </Form.FormInput.PrefixedInput>
                                                <Form.FormInput.ValidationMessage text={errors.amount?.message} />
                                            </Form.FormInput>
                                        </Form.Inputs>
                                        <Form.Submit disabled={!isValid || submissionState.isSubmitting} loading={submissionState.isSubmitting}>Confirm amount</Form.Submit>
                                    </Form>
                                </Section>
                            </Glass>
                        </div>
                    </div>
                </div>
            </Main>
        </>
    )
}