import { useEffect, useLayoutEffect, useState } from "react";
import { Container, Section } from "react-bulma-components";
import "./Faqs.scss";
import { Helmet } from "react-helmet-async";
import { useWpLoader } from "../../hoc/withLoader";
import { Order, OrderBy, Params, WpPostTypeRequest } from "../../components/Auth/AuthenticatedCmsClient";
import { useLocation } from "react-router-dom";
import { Accordion } from "../../components/Accordian/Accordian";

export const Faqs = () => {
    const location = useLocation();
    const [loaderState, { load, defaultLoaderDisplay, defaultErrorDisplay }] = useWpLoader();
    const anchor = location.hash.substring(1);

    useEffect(() => {
        if (loaderState.loadingState === "NotStarted") {
            load(new WpPostTypeRequest({
                url: "faqs",
                params: new Params({
                    per_page: 100,
                    orderby: OrderBy.MenuOrder,
                    order: Order.Ascending
                })
            }));
        }
        
    }, [loaderState.loadingState]);
  
    useEffect(() => {
        if (loaderState.loadingState !== "Loaded") {
            return;
        }

        const element = document.getElementById(anchor);
        if (element) {
            window.scrollTo({
                top: element.offsetTop,
                behavior: "smooth",
            });
        }
    }, [location.hash, loaderState.loadingState]);

    return (
        <div className="faqs">
            <Helmet>
                <title>FAQs</title>
            </Helmet>
            <Section>
                <Container className="glass-desktop p-8-desktop">
                    <h2 className="title is-size-2">FAQs</h2>
                    {
                        loaderState.loadingState === "Loading"
                            && defaultLoaderDisplay()
                    }
                    {
                        loaderState.loadingState === "Failed"
                            && defaultErrorDisplay()
                    }
                    {
                        loaderState.loadingState === "Loaded"
                            && loaderState.response.map((x : any) => {
                                return (
                                    <div key={`faq-${x.slug}`} id={x.slug}>
                                        <h3 className="is-size-3 pt-5 border-bottom">{x.title}</h3>
                                        {x.acf.faqs.map((y: any) => {
                                            return <FaqsDropdown key={`faqs-${y.unique_id}`} question={y.question} answer={y.answer} id={y.unique_id} isInitiallyHidden={anchor !== y.unique_id}/>
                                        })}
                                    </div>
                                )
                            })
                    }
                </Container>
            </Section>
        </div>
    );
};

interface FaqsDropdownProps {
    question: string,
    answer?: any;
    id: string;
    isInitiallyHidden?: boolean;
}

const FaqsDropdown = ({ question, answer, id, isInitiallyHidden = true}: FaqsDropdownProps) => {
    return (
        <Accordion isCollapsed={isInitiallyHidden} className="faqs-dropdown border-bottom py-3" id={id}>
            <Accordion.Header className="has-text-weight-bold">
                <p>{question}</p>
            </Accordion.Header>
            <Accordion.Content>
                <p>{answer}</p>
            </Accordion.Content>
        </Accordion>
    )
}

