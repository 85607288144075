import { Lock } from "react-feather";
import * as classes from './CardBadgeMembership.module.scss';
import { MembershipProps } from "../../Membership/MembershipProps";
import dayjs from "dayjs";

interface CardBadgeMembershipProps extends MembershipProps {
    className?: string;
    hasBackground?: boolean;
}

export const CardBadgeMembership = ({ membership, hasBackground = false, className = "" } : CardBadgeMembershipProps) => {

    const getDaysLeft = () => {
        const daysLeft = Math.ceil(dayjs(membership!.availableUntil).diff(dayjs(), 'day', true));

        let daysString = 'day';

        if (daysLeft !== 1) {
            daysString = 'days';
        }

        return `${daysLeft > 0 ? daysLeft : 0} ${daysString}`;
    }

    const badgeType = {
        upgrade: {
            background: 'has-background-purple-blue-100',
            textColor: 'has-text-purple-blue-300',
            inner: <>
                    Upgrade to access
                    <Lock style={{height: '18px'}} className="ml-1"/>
                </>
        },
        available: {
            background: 'has-background-purple-blue-100',
            textColor: 'has-text-purple-blue-300',
            inner: 'Pro Feature'
        },
        temporarilyAvailable: {
            background: 'has-background-gradient-peach-light',
            textColor: 'has-text-peach-400',
            inner: `Available for ${getDaysLeft()}`
        }
    }

    let state = membership?.requiresMembership 
        ? membership.hasAccess
            ? membership.availableUntil !== undefined && dayjs(membership.availableUntil) > dayjs()
                ? badgeType.temporarilyAvailable
                : badgeType.available
            : badgeType.upgrade
        : undefined;

    if (state === undefined) {
        return <></>;
    }

    return (
        <span className={`${classes.badge} ${className} ${hasBackground ? state.background : ''} ${state.textColor} badge px-1 has-text-weight-medium is-uppercase`}>
            { state.inner }
        </span>
    )
}