import { ReactNode } from 'react';

interface IHeadingProps {
    renderAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    type?: 'body' | 'title';
    children: ReactNode;
}

export const BodyHeading = ({renderAs = 'h4', children} : IHeadingProps) => {
    let HeadingType = renderAs;

    return (
        <HeadingType className='is-family-primary has-text-weight-bold is-size-6 mb-1'>{children}</HeadingType>
    )
  
}