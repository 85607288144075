import { ReactNode, useState } from "react";
import { Accordion } from "../../../../../../components/Accordian/Accordian";
import { Radio } from "../../../../../../components/Forms/Radio/Radio";
import {
    InvestorCertificationSelfCertifiedInvestorType as InvestorType
}  from "../../../../services/generated/PropertyClient"

export interface IOptionProps {
    option: string;
    children: ReactNode;
    value: InvestorType;
    tabIndex?: number;
    onChange?: (isOptionValid: boolean, investorType?: string) => void;
}

export const BasicOption = ({ option, children, value, onChange, tabIndex } : IOptionProps ) => {

    // Allow control over accordian as well as the default click
    const [isCollapsed, setIsCollapsed] = useState(true);
    const onToggle = (isToggleCollapsed: boolean) => {
        setIsCollapsed(isToggleCollapsed);
    }

    const onChanged = (value: string) => {
        if (onChange) {
            onChange(true, value);
        }
        setIsCollapsed(false);
    }

    return (
        <Accordion className="is-rounded-2 has-background-purple-blue-100 p-3 mb-2" isCollapsed={isCollapsed} onToggle={onToggle}>
            <Accordion.Header className={"is-size-5"} chevronOptions={{directionOnCollapsed: "Down", directionOnExpanded: "Right"}}>
                <Radio name="investorType" value={value.toString()} onChange={onChanged} tabIndex={tabIndex}/>
                {option}
            </Accordion.Header>
            <Accordion.Content className="content ml-6">
                {children}
            </Accordion.Content>
        </Accordion>
    );
}