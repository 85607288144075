import { Helmet } from "react-helmet-async";
import { Main } from "../../../../components/Layout/Main/Main";
import { Container } from "react-bulma-components";
import { PageTitleWithinGlass } from "../../../../components/Layout/PageTitle/PageTitle";
import { LoaderFunction, useLoaderData } from "react-router-dom";
import { CourseTaxonomyParams, WpTaxonomyRequest, useAuthenticatedCmsClient } from "../../../../components/Auth/AuthenticatedCmsClient";
import { SectionWithSeparator } from "../../../../components/Layout/Section/SectionWithSeparator";
import { Glass } from "../../../../components/Layout/Glass/Glass";
import { LevelCard } from "../components/LevelCard";
import dayjs from "dayjs";
import { useLoader } from "../../../../hoc/withLoader";
import { EducationClientFactory } from "../../services/EducationClientFactory";
import { GetCourses_Response, GetCourses_Response_CourseResponse, GetCourses_Response_LevelResponse, GetCourses_Response_TopicResponse } from "../../services/generated/EducationClient";
import { useEffect } from "react";
import Breadcrumb from "../../../../components/Breadcrumb/Breadcrumb";
import { Section } from "../../../../components/Layout/Section/Section";
import { ImageSize, getImageUrl } from "../../../../helpers/cms";
import { isPropelleMember } from "../../../../helpers/auth";

export const loader: LoaderFunction = async ({ params }) => {

    let client = useAuthenticatedCmsClient();

    let courseResponse = await client!.get(new WpTaxonomyRequest({
        url: "course-taxonomy",
        params: new CourseTaxonomyParams({
            slug: params!.courseSlug,
            include_children: true,
            include_topics: true,
        })
    }));

    try {
        let course : any = courseResponse.data[0];

        if (course === undefined) {
            throw new Response("Bad Request", { status: 404 });
        }

        return { course: course };

    } catch {
        throw new Response("This course doesn't exist.", { status: 404 });
    }
}

interface LoaderData {
    course : any
}

export const CourseOverview = () => {
    const { course } = useLoaderData() as LoaderData;
    const educationClient = EducationClientFactory();
    const [loaderState, { load }] = useLoader<GetCourses_Response>();

    const hasAccess = isPropelleMember();

    useEffect(() => {
        load(educationClient.getCourses());
    }, []);

    let groupedLevels = course.children.filter((child : any) => {
        return child.taxonomy_type === "level";
    }).reduce((levels : any, level : any) => {
        const levelRank = level.level ?? 1; // Use level 1 if no level is set
        if (!levels[levelRank]) {
            levels[levelRank] = [];
        }
        levels[levelRank].push(level);
        return levels;
    }, {});

    let flattenedLevels = loaderState.response?.courses?.flatMap((course : GetCourses_Response_CourseResponse) => {
        return course.levels ?? [];
    });

    const getCompletedTopicsForLevel = (levelId: number) => {
        const levelData = flattenedLevels?.find((levelData: GetCourses_Response_LevelResponse) => levelData.levelId === levelId);
        const completedTopics = levelData?.topics?.reduce((count: number, topic: GetCourses_Response_TopicResponse) => {
          return count + (topic.isComplete ? 1 : 0);
        }, 0);
        return completedTopics ?? 0;
    };

    return (
        <>
            <Helmet>
                <title>Learn - Course: {course.name}</title>
            </Helmet>
            <Main>
                <Container>
                    <Section>
                        <Breadcrumb/>
                        <Glass>
                            <PageTitleWithinGlass text={course.name}/>
                            {
                                Object.keys(groupedLevels).map((levelRank: string) => {
                                    const levelRankNumber = parseInt(levelRank);
                                    return (
                                        <SectionWithSeparator
                                            title={`Level ${levelRank}`}
                                            key={`level-${levelRank}`}
                                            membership={{
                                                requiresMembership: levelRankNumber > 1,
                                                hasAccess: hasAccess
                                            }}>
                                            <div className="columns is-multiline">
                                                {
                                                    groupedLevels[levelRank].map((level : any) => {
                                                        const totalTopics = level.topics.length;

                                                        const duration = level.topics.reduce((duration : any, current : any) => {
                                                            return duration + parseInt(current.duration);
                                                        }, 0);
                                                        const formattedDuration = dayjs.duration(duration, "minutes").format("H[h] m[m]");

                                                        return (
                                                            <div className="column is-4 p-0-desktop pb-4-touch" key={level.id}>
                                                                <LevelCard
                                                                    totalChildren={totalTopics}
                                                                    childrenCompleted={loaderState.loadingState === "Loaded" ? getCompletedTopicsForLevel(level.levelId) : undefined}
                                                                    description={level.description}
                                                                    url={`/learn/courses/${course.slug}/${level.slug}`}
                                                                    thumbnail={getImageUrl(level.thumbnail, ImageSize.MediumLarge)}
                                                                    name={level.name}
                                                                    duration={formattedDuration}
                                                                    membership={{
                                                                        requiresMembership: levelRankNumber > 1,
                                                                        hasAccess: hasAccess
                                                                    }}
                                                                    className="p-3-desktop is-fullheight"
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </SectionWithSeparator>
                                    )
                                })
                            }
                        </Glass>
                    </Section>
                </Container>
            </Main>
        </>
    );
}