import { ExternalLink as ExternalLinkIcon } from "react-feather";
import * as classes from "./ExternalLink.module.scss";

export const ExternalLink = (props: { href: string, text: string, className?: string }) => {
    return (
        <span className={classes.link}>
            <a href={props.href} target="_blank" className={props.className ?? ""}>{props.text}</a> 
            <ExternalLinkIcon className="ml-1" size="16px" color={"#1B0777"}/>
        </span>
    );
}