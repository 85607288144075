
import { useParams, useRouteLoaderData } from "react-router-dom";
import { NavItemProps, SecondaryNav, SecondarySubNav } from "../../../../components/SecondaryNav/SecondaryNav";
import { LoaderData as LayoutLoaderData } from "../../components/Layout/Layout";

export const Header = () => {
    const { categories } = useRouteLoaderData("learn-root") as LayoutLoaderData;
    const params = useParams();

    const topLevelCategory = categories.find(x => x.slug === params.categorySlug);

    let getSubLinks = undefined;
    if (topLevelCategory?.children !== undefined) {
        getSubLinks = topLevelCategory.children.map((x : any) : NavItemProps => {
            return {
                to:`/learn/${params.categorySlug}/${x.slug}`,
                name:x.name
            }});

        // Add all
        getSubLinks.unshift({
            to:`/learn/${params.categorySlug}`,
            name:'All'
        });
    }

    var categorySlugs = categories.map((x) : string => {
        return x.slug;
    })

    const staticLinks = [
        {
            to:"",
            name:"All",
            category: false
        },
        {
            to:"blog",
            name:"Blog",
            category: true
        },
        {
            to:"videos",
            name:"Videos",
            category: true
        },
        {
            to:"guides",
            name:"Guides",
            category: true
        }
    ];

    // Insert in 3rd position if enabled
    staticLinks.splice(2, 0, {
        to:"courses",
        name:"Courses",
        category: false
    });

    let getLinks : NavItemProps[] = [];

    staticLinks.forEach((link) => {
        if (!link.category || categorySlugs.find(x => x === link.to)) {
            getLinks.push(link);
        }
    });

    return (
        <header>
            <SecondaryNav urlRoot="/learn/" links={getLinks}/>
            { getSubLinks !== undefined &&
                <SecondarySubNav urlRoot={`/learn/${params.categorySlug}`} links={getSubLinks}/>
            }
        </header>
    );
}