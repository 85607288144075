import { Container } from "react-bulma-components"
import { Link } from "react-router-dom";
import { Back } from "../../../../../components/Back/Back";
import { useLoader } from "../../../../../hoc/withLoader";
import { GetRiskSummary_Response } from "../../../services/generated/PropertyClient";
import { useEffect } from "react";
import "./Risk.scss";
import { PropertyClientFactory } from "../../../services/PropertyClientFactory";
import { Helmet } from "react-helmet-async";
import { Main } from "../../../../../components/Layout/Main/Main";
import { Section } from "../../../../../components/Layout/Section/Section";
import { Glass } from "../../../../../components/Layout/Glass/Glass";
import { Content } from "../../../../../components/Layout/Content/Content";
import { RiskMeter } from "./components/RiskMeter/RiskMeter";
import { Stackable } from "../../../../../components/Layout/Stacking/Stackable";

export const Risk = () => {

    const client = PropertyClientFactory();
    const [loaderState, { load, defaultLoaderDisplay, defaultErrorDisplay }] = useLoader<GetRiskSummary_Response>();

    const getRiskAssessmentProcess = () => {

        if (loaderState.response?.tolerance) {
            
            if (loaderState.response?.capacity) {
                return "CompletedAll";
            }

            return "CompletedRiskTolerance";
        }

        return "NotStarted";
    }

    useEffect(() => {
        if (loaderState.loadingState === "NotStarted") {
            load(client.getRiskSummary());
        }

    }, [loaderState.loadingState])

    if (loaderState.error) {
        return <>{defaultErrorDisplay()}</>;
    }

    if (loaderState.loadingState === "NotStarted" || loaderState.loadingState === "Loading") {
        return <>{defaultLoaderDisplay()}</>;
    }

    if (loaderState.response) {
        return (
            <div className="risk">
                <Helmet>
                    <title>Risk</title>
                </Helmet>
                <Main>
                    <Container>
                        <Section>
                            <Back navigate={{to: "/invest/tools"}}>Return to tools</Back>
                        </Section>
                        {
                            <Section>
                                <Glass>
                                    <Content isMain={true}>
                                        {
                                            (loaderState.loadingState === "Loaded" && getRiskAssessmentProcess() !== "CompletedAll")
                                                ? <h2 className="title is-size-2-desktop is-size-3-touch has-text-purple-blue-500 has-text-centered has-text-left-desktop">Let's learn more about your attitude to risk</h2>
                                                : <h2 className="title is-size-2-desktop is-size-3-touch has-text-purple-blue-500 has-text-centered has-text-left-desktop">Your attitude towards risk</h2>
                                        }
                                    </Content>
                                    <Content>
                                        {
                                            (loaderState.loadingState === "Loaded" && getRiskAssessmentProcess() !== "CompletedAll")
                                                ? <p className="has-text-grey-500">When we talk about the risk we are willing to take when we invest, there are several factors that are important to take into account. Two key factors are your <span className="has-text-weight-bold">risk tolerance</span>, which is your emotional ability to take risk and your <span className="has-text-weight-bold">risk capacity</span>, which is your financial ability to take risk. Generally speaking, the idea is to invest at a risk level that is both emotionally and financially comfortable for you. If you find there is a big discrepancy between the two, then it's likely you won't be able to invest to your fullest potential. How do your risk tolerance and risk capacity compare? Take the quizzes below to find out.</p>
                                                : <p className="has-text-grey-500">When your risk tolerance and risk capacity are aligned, this means that you can and want to take the same amount of risk.<br/> If they differ, this indicates that you're not in a position and emotionally not willing to take the same amount of risk. In this case, you should be careful when selecting assets to invest in, as you don't want to take risks that you're not comfortable with and financially not capable of absorbing.</p>
                                        }
                                    </Content>
                                    {
                                        (loaderState.loadingState === "Loaded" && getRiskAssessmentProcess() === "NotStarted") &&
                                            <Section>
                                                <RiskAssessmentNavigation path="/invest/tools/risk/tolerance" cta="Calculate risk tolerance" />
                                            </Section>   
                                    }
                                    {
                                        (loaderState.loadingState === "Loaded" && getRiskAssessmentProcess() === "CompletedRiskTolerance") &&
                                            <Content>
                                                <RiskAssessmentNavigation className="py-4" path="/invest/tools/risk/capacity" cta="Calculate risk capacity" />
                                            </Content>
                                    }
                                </Glass>
                            </Section>
                        }
                        <Stackable gap={6} stackBelowSize="tablet">
                            <Section>
                                <Glass>
                                    <RiskToleranceResult 
                                        ranking={loaderState.response?.tolerance?.description as Ranking}
                                        disableRetake={getRiskAssessmentProcess() !== "CompletedAll"} />
                                </Glass>
                            </Section>
                            <Section>
                                <Glass>
                                    <RiskCapacityResult ranking={loaderState.response?.capacity?.description as Ranking} />
                                </Glass>
                            </Section>
                        </Stackable>
                        <Section>
                            <p className="has-text-grey-600">*This tool is for educational purposes and should not be taken as financial advice.</p>
                        </Section>
                    </Container>
                </Main>
            </div>
        )
    };

    // Shouldn't reach here (response or error should be present when loader has finished)
    return <></>;
}

interface RiskAssessmentNavigationProps {
    className?: string;
    path: string;
    cta: string;
}

export const RiskAssessmentNavigation = (props: RiskAssessmentNavigationProps) => {
    return (
        <>
            <div className={"is-hidden-touch" + " " + props.className}>
                <Link to={props.path} className="button is-primary is-rounded">{props.cta}</Link>
            </div>
            <div className={"has-text-centered is-hidden-desktop" + " " + props.className}>
                <Link to={props.path} className="button is-primary is-rounded">{props.cta}</Link>
            </div>
        </>
    )
}

export type Ranking = "Low" | "Medium" | "High" | undefined;

const RiskToleranceResult = (props: { ranking?: Ranking, disableRetake?: boolean }) => {

    const copy = {
        description: "This is your emotional ability to take risk - how \"willing\" you are to take risks."
    }

    const disableRetake = props.disableRetake !== undefined && props.disableRetake === true;

    return (
        <>
            <Content isMain={true}>
                <h4 className="title is-4 has-text-centered has-text-purple-blue-500">Risk tolerance</h4>
            </Content>
            <Content>
                <div className="is-flex is-flex-direction-row is-align-items-center is-flex-gap-5">
                    <div>
                        <RiskMeter rank={props.ranking} size={120}>
                            <h5 className="is-size-5 has-text-purple-blue-500">{props.ranking ?? "Inactive"}</h5>
                        </RiskMeter>
                    </div>
                    <div className="is-flex-grow-1">
                        {
                            !props.ranking &&
                            <Content>
                                <p>{copy.description}</p>
                            </Content>
                        }
                        {
                            props.ranking &&
                            <>
                                <Content>
                                    <p>You have a <span className="has-text-weight-bold has-text-purple-blue is-size-5">{props.ranking.toLocaleLowerCase()}</span> risk tolerance</p>
                                </Content>
                                <Content>
                                    <p>{copy.description}</p>
                                </Content>
                            </>
                        }
                    </div>
                </div>
            </Content>
            {
                (props.ranking && !disableRetake) &&
                <div className="is-flex is-flex-direction-row is-justify-content-center is-fullwidth">
                    <Link to="/invest/tools/risk/tolerance" className="button is-primary is-rounded">Retake risk tolerance</Link>
                </div>
            }
        </>
    )
}

export const RiskCapacityResult = (props: { ranking?: Ranking }) => {
    
    const copy = {
        description: "This is your financial capacity to take risks - how \"able\" you are to take risks."
    }

    return (
        <>
            <h4 className="title is-4 has-text-centered has-text-purple-blue-500">Risk capacity</h4>
            <Content>
                <div className="is-flex is-flex-direction-row is-align-items-center is-flex-gap-5">
                    <div>
                        <RiskMeter rank={props.ranking} size={120}>
                            <h5 className="is-size-5 has-text-purple-blue-500">{props.ranking ?? "Inactive"}</h5>
                        </RiskMeter>
                    </div>
                    <div className="is-flex-grow-1">
                        {
                            !props.ranking &&
                            <p>{copy.description}</p>
                        }
                        {
                            props.ranking &&
                            <>
                                <Content>
                                    <p>You have a <span className="has-text-weight-bold has-text-purple-blue is-size-5">{props.ranking.toLocaleLowerCase()}</span> risk capacity</p>
                                </Content>
                                <Content>
                                    <p>{copy.description}</p>
                                </Content>
                            </>
                        }
                    </div>
                </div>
            </Content>
            {
                props.ranking &&
                <div className="is-flex is-flex-direction-row is-justify-content-center is-fullwidth">
                    <Link to="/invest/tools/risk/capacity" className="button is-primary is-rounded">Retake risk capacity</Link>
                </div>
            }
        </>
    )
}