export class Script {
    paths = new Array<Path>();
}

export class Path {
    id: number
    botMessages: string[]
    answer?: Answer
}

export class Answer {
    type: string
    name: string
}

export class Radio extends Answer {
    options: Array<MultipleChoiceRadioOption>
}

export class MultipleChoiceRadioOption {
    label: string
    value: string
    explainer?: MultipleChoiceRadioOptionExplainer
    pathId?: number
}

export class MultipleChoiceRadioOptionExplainer {
    description: string
    bullets: Array<string>
}

export class Input extends Answer {
    inputType: string
    inputFormatter?: InputFormatter
    postPlaceholderText?: string
    textLengthValidator?: TextLengthValidator
    regexValidator?: RegexValidator
    numberValidator?: NumberValidator
    pathId?: number
}

type InputFormatter =
    'Currency'

export class Select extends Answer {
    options: Array<SelectOption>
}

export class SelectOption extends Answer {
    label: string
    value: string
    pathId?: number
}

export class TextLengthValidator {
    maxLength?: number
    minLength?: number
    message: string
}

export class RegexValidator {
    regex: string
    message: string
}

export class NumberValidator {
    maxValue?: number
    minValue?: number
    message: string
}