import dayjs from "dayjs";
import { Unit } from "./Unit";

export class Topic {
    protected _units: Unit[] =[];
    protected _status: string;

    id: number;
    slug: string;
    name: string;
    isComplete?: boolean;
    duration: number = 0;
    currentUnitId?: number;
    isCurrentTopic?: boolean;

    constructor(topic: any) { // Expects a WP REST API response
        this.id = parseInt(topic.id);
        this.slug = topic.slug;
        this.name = topic.name;
        this.duration = parseInt(topic.duration ?? topic.acf.duration);
    }

    public get status() : string {
        return this.units.length === 0
            ? "inactive"
            : this.isComplete
                ? "complete"
                : this.isCurrentTopic
                    ? "active"
                    : "inactive";
    }

    public get units() : Unit[] {
        return this._units;
    }

    public totalUnitsComplete() : number | undefined {
        if (this.currentUnitId && this.currentUnitId > 0) {
            const currentUnit = this.units.find(unit => unit.id === this.currentUnitId)!;
            return this.units.filter(unit => unit.order >= currentUnit.order).length ?? 0;
        } else if (this.currentUnitId === 0) {
            return 0;
        }
        return undefined;
    }

    public get percentageComplete() : number | undefined {
        // Make sure data has been loaded
        const totalCompleteUnits = this.totalUnitsComplete();
        if (totalCompleteUnits === undefined) {
            return undefined;
        }
        return this.isComplete ? 100 : Math.round(totalCompleteUnits / (this.units.length > 0 ? this.units.length : 1) * 100);
    }

    public formattedDuration() : string {
        return dayjs.duration(this.duration, "minutes").format(this.duration < 60 ? "m[ mins]" : "H[h] m[ mins]");
    }
}