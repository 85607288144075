export const unique = <T>(value: T, index: number, array: Array<T>) => {
    return array.indexOf(value) === index;
}

export const largestToSmallest = (first: number, second: number) => {
    return second - first;
}

export const smallestToLargest = (first: number, second: number) => {
    return (largestToSmallest(first, second) * -1);
}