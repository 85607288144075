import { ReactNode, createContext, useEffect, useState } from 'react';
import { AccountManagementClientFactory } from '../../../services/AccountManagementClientFactory';
import { ApiException } from '../../../services/generated/PropertyClient';
import { AccountType } from '../../../services/generated/AccountManagementClient';

export const EquityContext = createContext<ContextData | undefined>(undefined);

interface IUserData {
    accountHolderId: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    phoneNumber: string;
    isUkTaxResident: boolean | null | undefined,
    isUsCitizen: boolean | null | undefined,
    nino: string,
    nationality: string;
    countryOfBirth: string;
    currentAddress: {
        line1: string;
        line2: string;
        line3: string;
        city: string;
        region: string;
        postcode: string;
        countryCode: string;
    },
    employmentStatus: string;
    industry: string;
    sourcesOfWealth: string[];
    annualIncome: number;
}

interface IAccountData {
    accountId: string;
    accountType: AccountType;
    portfolioId?: string;
    initialFundIsin?: string;
    initialFundName?: string;
}

type EquityProviderProps = {
    children: ReactNode
}

type ContextData = {
    userData: IUserData | undefined;
    accountData: IAccountData | undefined;
    setAccountData: (accountData: IAccountData) => void;
    intialDepositId?: string;
    setInitialDepositId: (depositId?: string) => void;
    reload: () => void;
}

export const OnboardingContext = ({ children }: EquityProviderProps) => {
    const client = AccountManagementClientFactory();

    const reload = () => {
        if (!contextData?.userData) {
            client.getAccountHolder()
                .then(resp => {
                    setContextData(prevState => {
                        let newState = {
                            ...prevState,
                            userData: {
                                accountHolderId: resp?.accountHolderId || 0,
                                firstName: resp?.firstName || "",
                                lastName: resp?.lastName || "",
                                dateOfBirth: resp.dateOfBirth ? resp.dateOfBirth.toString() : "",
                                phoneNumber: resp?.phoneNumber || "",
                                isUkTaxResident: resp?.isUkTaxResident,
                                isUsCitizen: resp?.isUsCitizen,
                                nino: resp?.nino || "",
                                nationality: resp?.nationality || "",
                                countryOfBirth: resp?.countryOfBirth || "",
                                currentAddress: {
                                    line1: resp?.currentAddress?.line1 || "",
                                    line2: resp?.currentAddress?.line2 || "",
                                    line3: resp?.currentAddress?.line3 || "",
                                    city: resp?.currentAddress?.city || "",
                                    region: resp?.currentAddress?.region || "",
                                    postcode: resp?.currentAddress?.postcode || "",
                                    countryCode: resp?.currentAddress?.countryCode || "",
                                },
                                employmentStatus: resp?.employmentStatus || "",
                                industry: resp?.industry || "",
                                sourcesOfWealth: resp?.sourcesOfWealth || [],
                                annualIncome: resp?.annualIncome || 0,
                            }
                        } as ContextData;

                        return newState;
                    })
                })
                .catch(err => {
                    let apiException = err as ApiException;
                    if (apiException.result === 404) {
                        //navigate to error page 
                    }
                })
        }
    }

    const setAccountData = (accountData: IAccountData) => {
        setContextData(prevState => {
            let newState = {
                ...prevState,
                accountData: accountData
            } as ContextData;

            return newState;
        })
    }

    const setInitialDepositId = (depositId: string) => {
        setContextData(prevState => {
            let newState = {
                ...prevState,
                intialDepositId: depositId
            } as ContextData;

            return newState;
        })
    }

    const [contextData, setContextData] = useState<ContextData>({ reload, userData: undefined, accountData: undefined, setAccountData, intialDepositId: undefined, setInitialDepositId: setInitialDepositId });

    useEffect(() => {
        reload();
    }, [contextData?.userData])

    return (
        <EquityContext.Provider value={contextData}>{children}</EquityContext.Provider>
    )
}