import { useEffect, useState } from "react";

export const useOverflowDetector  = <TRef,> (ref: React.RefObject<TRef>) => {
    const [isOverflow, setIsOverflow] = useState(false);

    useEffect(() => {
        const current = (ref.current as any);
        const hasOverflow = current.scrollHeight > current.clientHeight || current.scrollWidth > current.clientWidth;

        if (isOverflow !== hasOverflow) {
            setIsOverflow(hasOverflow);
        }
    });

    return isOverflow;
};