import { Outlet } from "react-router-dom";
import { WealthMappingProvider } from "../../WealthMappingContext";
import "./_Layout.scss";

export function Layout () {
    return (
        <WealthMappingProvider>
            <div className="wealth-mapping-layout">
                <Outlet />
            </div>    
        </WealthMappingProvider>
    );
}