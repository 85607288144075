import { useNavigate } from "react-router-dom";
import * as classes from "./ToolsTile.module.scss"

export const ToolsTile = ({ 
    navigateTo = undefined as string | undefined, 
    children = undefined as any, 
    background = "PeachGradient" as "PeachGradient" | "LightPurpleBlue",
}) => {

    const navigate = useNavigate();

    const onClick = () => {
        if (navigateTo) {
            navigate(navigateTo);
        }
    }

    const backgroundClassMapping = {
        "PeachGradient": classes["peach-gradient"],
        "LightPurpleBlue": "has-background-purple-blue-100"
    }

    return (
        <article className={`is-position-relative p-5 is-clickable is-rounded is-inline-block-tablet is-fullheight ${classes['tools-tile']} ${backgroundClassMapping[background]}`} onClick={onClick}>
            {children}
        </article>
    )
}

const Icon = ({icon = undefined as React.ReactElement | undefined }) => <figure className={classes.icon}>{icon}</figure>
const Title = ({title = undefined as string | undefined }) => <h4 className="mt-1 is-size-4 has-text-navy">{title}</h4>
const Description = ({description = undefined as string | undefined }) => <p className="mt-1 has-text-black-bis">{description}</p>

const Overlay = ({ children = undefined as any }) => {
    return (
        <div className="is-position-absolute m--5 is-fullwidth is-fullheight">
            { children }
        </div>
    );
};

const Badge = ({ children = undefined as any }) => {
    return (
        <div className="is-flex is-flex-direction-row is-justify-content-flex-end pr-1 pt-1">
            { children }
        </div>
    );
}

Overlay.Badge = Badge;

ToolsTile.Icon = Icon;
ToolsTile.Title = Title;
ToolsTile.Description = Description;
ToolsTile.Overlay = Overlay;



