import { Helmet } from "react-helmet-async"
import { Main } from "../../../../../../../components/Layout/Main/Main"
import { Section } from "../../../../../../../components/Layout/Section/Section"
import { Glass } from "../../../../../../../components/Layout/Glass/Glass"
import { Summary } from "../../../../components/Summary/Summary"
import { Content } from "../../../../../../../components/Layout/Content/Content"
import { Link, useNavigate } from "react-router-dom"
import * as classes from "./Declaration.module.scss"
import { useContext, useEffect, useState } from "react"
import { EquityContext } from "../../OnboardingContext"
import { AccountManagementClientFactory } from "../../../../../services/AccountManagementClientFactory"
import { BackButton } from "../../../../../../../components/BackButton/BackButton"
import { GetAccountHolder_Response_Root } from "../../../../../services/generated/AccountManagementClient"
import { useLoader, useWpLoader } from "../../../../../../../hoc/withLoader"
import dayjs from "dayjs"
import { WpPostTypeRequest } from "../../../../../../../components/Auth/AuthenticatedCmsClient"
import { Loader } from "../../../../../../../components/Loader/Loader"

export const Declaration = ({ 
    moveBackward = () => {}
}) => {
    const [currentStage, setCurrentStage] = useState<"General"|"Isa">("General");

    return (
        <>
            <Helmet>
                <title>Funds - Declaration</title>
            </Helmet>
            <Main>
                <div className="container">
                    <BackButton action={moveBackward} text="Back" className="mb-3"/>
                    <div className="columns">
                        <div className="column is-10-widescreen is-8-fullhd">
                            { currentStage === "General" && <GeneralStage setCurrentStage={setCurrentStage}/> }
                            { currentStage === "Isa" && <IsaStage /> }
                        </div>
                    </div>
                </div>
            </Main>
        </>
    )
}

interface GeneralStageProps {
    setCurrentStage: (stage: "General"|"Isa") => void;
}

const GeneralStage = ( {setCurrentStage } : GeneralStageProps) => {
    const [isMakingDeclaration, toggleIsMakingDeclaration] = useState<boolean>(false);
    const context = useContext(EquityContext);
    const accountManagementClient = AccountManagementClientFactory();
    const [wpLoaderState, wpLoader] = useWpLoader();
    const navigate = useNavigate();

    useEffect(() => {
        wpLoader.load(new WpPostTypeRequest({
            url: "fund"
        }));
    }, []);

    useEffect(() => {
        if (isMakingDeclaration) {
            const accountId = context?.accountData?.accountId;
            
            accountManagementClient.makeDeclaration(accountId!)
                .then(() => {
                    navigate("/invest/funds/account-setup");
                });
        }
    }, [isMakingDeclaration]);

    const onConfirmDetails =() => {
        // if (context?.accountData?.accountType === AccountType.Gia) {
        //     toggleIsMakingDeclaration(true);
        // } else {
            setCurrentStage("Isa");
        // }
    };

    if (context?.accountData?.initialFundIsin === undefined || wpLoaderState.response === undefined) {
        return (
            <Glass opacity={100}>
                <Loader />
            </Glass>
        );
    }

    let cmsFund = wpLoaderState.response?.find((y : any) => y.acf.isin === context.accountData?.initialFundIsin);

    return (<Glass opacity={100}>
        <Section>
            <Content>
                <h1 className="is-size-4 has-text-centered has-text-left-desktop is-size-2-desktop has-text-purple-blue-500">Choose your fund</h1>
            </Content>
            <Content>
                <div className="columns is-touch">
                    <div className="column is-two-thirds m-auto-touch">
                        <Summary>
                            <Summary.Title>
                                <p className="is-size-5 has-text-weight-semibold has-text-purple-blue-500">Review</p>
                            </Summary.Title>
                            <Summary.Item description="Account type" value={context?.accountData?.accountType} />
                            <Summary.Item description="Fund"value={context?.accountData?.initialFundName} />
                        </Summary>
                    </div>
                </div>
            </Content>
        </Section>
        <Section>
            <div className="columns is-touch">
                <div className="column is-two-thirds m-auto-touch">
                    <Content>
                        <p className="is-size-5 has-text-purple-blue-500 has-text-weight-semibold mb-3">Before we continue, we just need to confirm a few more things.</p>
                    </Content>
                    <form onSubmit={onConfirmDetails}>
                        <Content>
                            <p className="has-text-purple-blue-500 has-text-weight-medium">Investments carry risks</p>
                            <p>As with all investments, your capital is at risk. Early and unforeseen withdrawals could negatively impact your investment returns. The value of your investment can go down as well as up and past performance is no guide of future returns.</p>
                        </Content>
                        <Content>
                            <p className="has-text-purple-blue-500 has-text-weight-medium">Terms and Conditions</p>
                            <p>By opening an ISA or GIA account with us, you agree to the <a href="https://propelle.io/terms-and-conditions" target="_blank" className="is-underlined">Terms and Conditions</a> of Propelle and to <a href="https://propelle.io/app/uploads/2024/04/Customer-TCs-Schedule-1-General-retail-terms-Propelle-1.pdf" target="_blank" className="is-underlined">Schedule 1</a> and <a href="https://propelle.io/app/uploads/2024/04/20231221-Customer-TCs-Schedule-2-Stocks-Shares-ISA-1.pdf" target="_blank" className="is-underlined">Schedule 2</a> our custodian WealthKernel.</p>
                        </Content>
                        <Content>
                            <p className="has-text-purple-blue-500 has-text-weight-medium">Key Investment Information and Ex-ante Document</p>
                            <p>The <a href={cmsFund.acf.kiid.url} className="is-underlined" target="_blank">KIID</a> and <a href="https://propelle.io/ex-ante-cost-document" target="_blank" className="is-underlined">Ex-ante</a> contains all the information relating to the fund you’re investing in. </p>
                        </Content>
                        <Content className="m-auto-touch pt-3">
                            <button className={`${classes.submit} button is-fullwidth is-justify-content-center is-rounded is-primary`} type="submit">
                                Confirm
                            </button>
                        </Content>
                    </form>
                </div>
            </div>
        </Section>
    </Glass>)
}

const IsaStage = () => {
    const [isMakingDeclaration, toggleIsMakingDeclaration] = useState<boolean>(false);
    const context = useContext(EquityContext);
    const accountManagementClient = AccountManagementClientFactory();
    const navigate = useNavigate();
    const [loaderState, loader] = useLoader<GetAccountHolder_Response_Root>();

    useEffect(() => {
        if(loaderState.loadingState === "NotStarted") {
            loader.load(accountManagementClient.getAccountHolder());
        }
    });

    useEffect(() => {
        if (isMakingDeclaration) {
            const accountId = context?.accountData?.accountId;
            
            accountManagementClient.makeDeclaration(accountId!)
                .then(() => {
                    navigate("/invest/funds/account-setup");
                });
        }
    }, [isMakingDeclaration])

    const onConfirmDetails = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        toggleIsMakingDeclaration(true);
    };

    return (<Glass opacity={100}>
        <Section>
            <Content>
                <h1 className="is-size-4 has-text-centered has-text-left-desktop is-size-2-desktop has-text-purple-blue-500">Declaration</h1>
            </Content>
            <Content>
                {
                    loader.defaultDisplayUntilLoaded(
                        <>
                            <p className="is-size-5 has-text-weight-semibold has-text-purple-blue-500">{`${loaderState.response?.firstName} ${loaderState.response?.lastName}`}</p>
                            <address>
                                <p>{loaderState.response?.currentAddress?.line1}</p>
                                { loaderState.response?.currentAddress?.line2 && <p>{loaderState.response?.currentAddress?.line2}</p> }
                                { loaderState.response?.currentAddress?.line3 && <p>{loaderState.response?.currentAddress?.line3}</p> }
                                <p>{loaderState.response?.currentAddress?.city}</p>
                                <p>{loaderState.response?.currentAddress?.region}</p>
                                <p>{loaderState.response?.currentAddress?.postcode}</p>
                            </address>
                            <p className="mt-3">Date of birth: {dayjs(loaderState.response?.dateOfBirth).format("DD/MM/YYYY")}</p>
                            <p className="mt-3">National Insurance Number: {loaderState.response?.nino}</p>
                        </>
                    )
                }
                
            </Content>
            <div className="columns is-touch">
                <div className="column is-two-thirds m-auto-touch">
                    <form onSubmit={onConfirmDetails}>
                        <Content>
                            <p className="has-text-purple-blue-500 has-text-weight-semibold">I declare that:</p>
                            <div className="content">
                                <ul>
                                    <li>all subscriptions made, and to be made, belong to me;</li>
                                    <li>I am 18 years old or over;</li>
                                    <li>I have not subscribed/made payments, and will not subscribe/make payments, more than the overall subscription/payment limit in total to a cash ISA, a stocks and shares ISA, an innovative finance ISA, and a Lifetime ISA in the same tax year;</li>
                                    <li>I have not subscribed and will not subscribe to another stocks and shares ISA in the same tax year that I subscribe to this stocks and shares ISA;</li>
                                    <li>I am resident in the United Kingdom for tax purposes or, if not so resident, either perform duties which, by virtue of Section 28 of Income Tax (Earnings & Pensions) Act2003 (Crown employees serving overseas), are treated as being performed in theUnited Kingdom, or I am married to, or in a civil partnership with, a person who performs such duties.</li>
                                    <li>I will inform Propelle if I cease to be so resident or to perform such duties or be married to, or in a civil partnership with, a person who performs such duties; and</li>
                                    <li>I agree to the ISA terms and conditions.</li>
                                </ul>
                            </div>
                        </Content>
                        <Content>
                            <p className="has-text-purple-blue-500 has-text-weight-bold">I authorise Wealthkernel, as ISA manager for Propelle customers</p>
                            <div className="content">
                                <ul>
                                    <li>to hold my cash subscription, ISAinvestments, interest, dividends and any other rights or proceeds in respect of thoseinvestments and any other cash and</li>
                                    <li>to make on my behalf any claims to relief from tax in respect of ISA investments.</li>
                                </ul>
                            </div>
                        </Content>
                        <Content>
                            <p>I confirm that the information I’ve given in this application is correct and I will notify Propelle if any of this information changes. </p>
                        </Content>
                        <Content className="m-auto-touch pt-3">
                            <button className={`${classes.submit} button is-fullwidth is-justify-content-center is-rounded is-primary`} type="submit">
                                Confirm
                            </button>
                        </Content>
                    </form>
                </div>
            </div>
        </Section>
    </Glass>)
}
