import { Card, Level, Tag } from "react-bulma-components";
import { Tv } from "react-feather";
import { Book } from "react-feather";
import './ArticleCard.scss';
import { MembershipProps } from "../Membership/MembershipProps";
import { CardBadgeMembership } from "./CardBadgeMembership/CardBadgeMembership";
import { withLink } from "../../hoc/withLink";

export interface ArticleCardProps extends MembershipProps {
    id: number;
    header: string;
    duration: number;
    durationType?: string;
    imageUrl: string;
    category?: string;
    size?: 'default' | 'small' | 'large',
    classes?: string,
    url: string
}

export const ArticleCard = ({ size = 'default', durationType = "read", membership, ...props}: ArticleCardProps) => {
    let showCategory = props.category !== null && props.category !== '' && props.category !== undefined;

    return (
        <Card className={`card-${size} ${size === "default" ? 'p-3-desktop' : 'p-3'}`}>
            <Card.Image src={props.imageUrl} size={size === 'small' ? '5by3' : '2by1'}/>
            <div>
                <Card.Header className="match-height is-flex-direction-column">
                    {
                        membership?.requiresMembership &&
                            <div className="px-0 pt-2">
                                <CardBadgeMembership membership={membership} className={'is-rounded-1'} hasBackground={true}/>
                            </div>
                    }
                    <p className={`card-header-title has-text-weight-medium ${ size === 'small' ? 'pb-1 pl-3 pr-0 pt-0' : 'py-2 px-0'}`}>{props.header}</p>
                </Card.Header>
                <Card.Content className={`pt-2 ${ size === 'small' ? 'pb-1 pb-1-mobile pl-3 pr-0' : 'px-0 pb-0'}`}>
                    { showCategory && size !== 'large' &&
                            <Tag rounded size={'medium'} className="is-hidden-touch mb-3">{props.category}</Tag> }
                    <Level breakpoint="mobile" className={`content-label ${ size === 'large' ? 'mt-3' : ''}`}>
                        <Level.Item className="content-label-icon">
                            {
                                durationType === "read"
                                    ? <Book color={"#616161"}/>
                                    : <Tv color={"#616161"}/>
                            }
                        </Level.Item>
                        <Level.Item className="has-text-default-body">
                            { props.duration + " mins " + durationType}
                        </Level.Item>
                    </Level>
                    { showCategory && size === 'large' &&
                        <Tag rounded size={'medium'} className="is-hidden-touch mt-3">{props.category}</Tag> }
                </Card.Content>
            </div>
        </Card>
    );
}

export const ArticleCardWithLink = withLink(ArticleCard, "card-wrapper article-card");