import axios, { AxiosInstance, AxiosResponse } from "axios";
import { AuthHelper } from "./AuthHelper";
import { FeatureFlag, isFeatureEnabled } from "../../helpers/feature-flags";
import { getMembershipStatus } from "../../helpers/cms";
// import { getUserData } from "../../helpers/auth";

///////////////////////////////// KEEP ME PLZ 😇 /////////////////////////////////////////

// Note: Really want to use wordpress-api-client but it's got some issues with the "url" package - needs more investigation

// import WpApiClient from "wordpress-api-client";

// export const useAuthenticatedCmsClient = (baseUrl: string = process.env.PROPERTY_BACKEND_URL!) => {

//     let auth = useAuth();
//     let accessToken = auth.user?.access_token;
		
//     let cmsClient = new WpApiClient(baseUrl, {
//         auth: {
//             type: 'jwt',
//             token: accessToken !== undefined ? accessToken : "",
//         }
//     });

//     return cmsClient;
// }

///////////////////////////////// END OF KEEP ME PLZ 😇 ///////////////////////////////////

export const useAuthenticatedCmsClient = (baseUrl: string = process.env.CMS_BACKEND_URL!) => {
	let accessToken = AuthHelper.getAccessToken();
	var user = AuthHelper.getUserData();

	if (AuthHelper.hasUserInStorageOrTryRedirect()) {
		return new AuthenticatedCmsClient(baseUrl, accessToken!, user.role === "staff");
	}

	return undefined;
}

export class AuthenticatedCmsClient {
	private client : AxiosInstance;
	private isStaffLoggedIn: boolean = false;
	private parser = new DOMParser();

	constructor(baseUrl: string = process.env.CMS_BACKEND_URL!, accessToken: string, isStaffLoggedIn: boolean = false) {
		this.isStaffLoggedIn = isStaffLoggedIn;
		this.client = axios.create({
			baseURL: baseUrl,
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${accessToken}`,
			},
		});

		// Makes sure to redirect to auth if not authenticated for cms
		this.client.interceptors.response.use(
			response => response,
			error => {
				if (error.response?.status === 401) {
					AuthHelper.redirect();
				}
				return Promise.resolve(error.response);
			});
	}

	post(url: string, data: any): Promise<any> {
		return this.client.post(url, data)
		.then(response => {
				return this.parseResponse(response);
		});
	}

	delete(url: string, id: number): Promise<any> {
		return this.client.delete(`${url}/${id}`)
		.then(response => {
				return this.parseResponse(response);
		});
	}

	get(request: IWpRequest): Promise<any> {
		return this.client.get(this.parseRequest(request))
		.then(response => {
				return this.parseResponse(response);
		});
	}

	getAs<T>(request: IWpRequest, constructor: new (...args: any[]) => T): Promise<any> {
		return this.client.get(this.parseRequest(request))
		.then(response => {
				return this.parseResponseAs(response, constructor);
		});
	}

	protected parseRequest(request: IWpRequest): string {
		let url = request.url_prefix + request.url;

		const params = new URLSearchParams();

		if (request instanceof WpPostTypeRequest) {

			let statuses = request.status;

			if (this.isStaffLoggedIn) {
				statuses.push(Status.Draft);
			}

			params.set("status", statuses.join(","));
		}

		if (request instanceof WpCommentRequest) {
				params.set("status", request.status.join(","));
		}

		if (request instanceof WpTaxonomyRequest) {
				params.set("hide_empty", this.isStaffLoggedIn ? "false" : request.hide_empty!.toString());
		}

		url += `?${this.buildQueryParams(request.params ?? {}, params)}`;

		return url;
	}

	public parseResponseAs<T>(
		response: AxiosResponse<any, any>,
		constructor: new (...args: any[]) => T,
	) {
		const parsedResponse = this.parseResponse(response);

		if (Array.isArray(parsedResponse.data)) {
			parsedResponse.data = parsedResponse.data.map(item => {
				return new constructor(item);
			});
		} else {
			parsedResponse.data = new constructor(parsedResponse.data);
		}

		return parsedResponse;
	}

	protected parseResponse(response : AxiosResponse<any, any>) {
		if (response !== undefined) {
			if (Array.isArray(response.data)) {
				const data = response.data.map(item => {
					return this.parseItem(item);
				});
				response.data = data;
			} else if (response.data) {
				this.parseItem(response.data);
			}
		}
		
		return response;
	}

	protected parseItem(item: any) {
		if (item.title) {
			item.title = this.tryParseTitle(item.title);
		}
		if (item.name) {
			item.name = this.tryParseTitle(item.name);
		}

		item.membership = getMembershipStatus(item);
	
		return item;
	}

	protected buildQueryParams(requestParams: IParams, params: URLSearchParams): URLSearchParams {
		for (const [key, value] of Object.entries(requestParams)) {
			if (value !== undefined && value !== null) {
				if (Array.isArray(value)) {
					params.set(key, value.join(","));
				} else {
					params.set(key, value.toString());
				}
			}
		}

		return params;
	}

	protected tryParseTitle(title: any): string | null{
		if (title.rendered) {
			return this.parser.parseFromString(title.rendered, "text/html").body.textContent;
		}
		if (title !== null) {
			return this.parser.parseFromString(title, "text/html").body.textContent;
		}
		return title;
	}
}

export class Membership {
	requiresMembership: boolean;
	hasAccess: boolean;
	availableUntil?: Date;

	constructor(membership: Membership) {
		this.requiresMembership = membership.requiresMembership;
		this.hasAccess = membership.hasAccess;
		this.availableUntil = membership.availableUntil;
	}
}

export enum Order {
	Ascending = "asc",
	Descending = "desc"
}

export enum OrderBy {
	Date = "date",
	MenuOrder = "menu_order",
	TermOrder = "term_order"
}

export enum Status {
	Publish = "publish",
	Private = "private", // Members only
	Draft = "draft",
	Trash = "trash",
	PostTrashed = "post-trashed", // Used just for comments
	Approve = "approve", // Used just for comments
}

export enum Hierarchical {
	Flat = "flat"
}

export enum Embed {
	Author = "author",
	Term = 'wp:term'
}

export enum DealStatus {
	Open = "open",
	Closed = "closed",
	Funded = "funded",
	ComingSoon = "coming_soon",
}

export interface IParams {
	order?: Order;
	orderby?: OrderBy;
	per_page?: number;
}

export class ArticleParams implements IParams {
	order?: Order;
	orderby?: OrderBy;
	per_page?: number;
	_embed?: Embed[];
	slug?: string;
	category_slug?: string;
	tag_slug?: string;
	include?: number[];
	categories?: number[];

	constructor(articleParams: ArticleParams) {
		this.order = articleParams.order;
		this.orderby = articleParams.orderby;
		this.per_page = articleParams.per_page;
		this._embed = articleParams._embed;
		this.slug = articleParams.slug;
		this.category_slug = articleParams.category_slug;
		this.tag_slug = articleParams.tag_slug;
		this.include = articleParams.include;
		this.categories = articleParams.categories;
	}
}

export class Params implements IParams {
	order?: Order;
	orderby?: OrderBy;
	per_page?: number;
	slug?: string | string[];

	constructor(params: Params) {
		this.order = params.order;
		this.orderby = params.orderby;
		this.per_page = params.per_page;
		this.slug = params.slug;
	}
}

export class FaqParams extends Params {
	platform_area?: string;

	constructor(params: FaqParams) {
		super(params);
		this.platform_area = params.platform_area;
	}
}

export class EventParams extends Params {
	upcoming?: boolean = true;
	platform_area?: string;

	constructor(params: EventParams) {
		super(params);
		this.upcoming = params.upcoming || this.upcoming;
		this.platform_area = params.platform_area;
		this.orderby = params.orderby ?? OrderBy.MenuOrder;
		this.order = params.order ?? Order.Ascending;
	}
}

export class PropertyOpportunityParams extends Params {
	not_deal_status?: string;
	deal_ids?: string;

	constructor(params: PropertyOpportunityParams) {
		super(params);
		this.not_deal_status = params.not_deal_status;
		this.deal_ids = params.deal_ids;
	}
}

export class CommentParams implements IParams {
	order?: Order;
	orderby?: OrderBy;
	per_page?: number;
	post?: number;
	parent?: number;
	hierarchical?: Hierarchical;
	include_total_comments?: boolean;

	constructor(commentParams: CommentParams) {
		this.order = commentParams.order;
		this.orderby = commentParams.orderby;
		this.per_page = commentParams.per_page;
		this.post = commentParams.post;
		this.parent = commentParams.parent;
		this.hierarchical = commentParams.hierarchical;
		this.include_total_comments = commentParams.include_total_comments;
	}
}

export class UnitParams extends Params {
	in_topics?: number[];

	constructor(params: UnitParams) {
		super(params);
		this.in_topics = params.in_topics;
	}
}

export interface IWpRequest {
	url_prefix?: string;
	url: string;
	params?: IParams;
}

class WpRequest implements IWpRequest {
	url_prefix?: string = "/wp-json/wp/v2/";
	url: string;
	params?: IParams = {};

	constructor(wpRequest: WpRequest) {
			this.url_prefix = wpRequest.url_prefix || this.url_prefix;
			this.url = wpRequest.url;
			this.params = wpRequest.params;
	}
}

export class WpTaxonomyRequest extends WpRequest {
	hide_empty?: boolean = true;

	constructor(wpRequest: WpTaxonomyRequest) {
			super(wpRequest);

			if (wpRequest.hide_empty !== undefined) {
				this.hide_empty = wpRequest.hide_empty;
			}
	}
}

export class CourseTaxonomyParams extends Params {
	type?: "course" | "level";
	include_children?: boolean;
	include_topics?: boolean;
	include_parent?: boolean;

	constructor(params: CourseTaxonomyParams) {
			super(params);

			this.type = params.type;
			this.include_children = params.include_children;
			this.include_topics = params.include_topics;
			this.include_parent = params.include_parent;
	}
}

interface IWpPostTypeRequest extends IWpRequest {
	status?: Status[];
}

const defaultStatuses = isFeatureEnabled(FeatureFlag.Membership) ? [Status.Publish, Status.Private] : [Status.Publish];

export class WpPostTypeRequest extends WpRequest implements IWpPostTypeRequest {
	status: Status[] = [...defaultStatuses]; // Make sure we always be fetching published stuffs by default

	constructor(wpRequest: IWpPostTypeRequest) {
			super(wpRequest);

			wpRequest.status?.forEach(status => {
				if (!this.status?.includes(status)) {
					this.status?.push(status);
				}
			});
	}
}

interface IWpCommentRequest extends IWpRequest {
	status?: Status[];
}

export class WpCommentRequest extends WpRequest implements IWpCommentRequest {
	status: Status[] = [Status.Approve]; // Make sure we always be fetching approved comments by default

	constructor(wpRequest: IWpCommentRequest) {
			super(wpRequest);

			wpRequest.status?.forEach(status => {
				if (!this.status?.includes(status)) {
					this.status?.push(status);
				}
			});
	}
}