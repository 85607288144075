import { Container, Heading, Section } from "react-bulma-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import "./ContactUs.scss";
import { Helmet } from "react-helmet-async";

export const ContactUs = () => {
    return (
        <div className="contact-us">
            <Helmet>
                <title>Contact Us</title>
            </Helmet>
            <Section>
                <Container className="glass-desktop">
                    <div className="p-8-desktop">
                        <Heading size={2}>Contact us</Heading>
                        <p className="pb-5">You can get in touch at <a href="mailto:support@propelle.io">support@propelle.io</a></p>
                        <p className="pb-5">We love to hear from you, whether that's to let us know what you like or share your opinion about the product. Feel free to reach out at <a href="mailto:support@propelle.io">support@propelle.io</a>, we'll be happy to receive your messages!</p>
                        <p className="pb-5">We're also on social media if you'd like to visit us there.</p>
                        <p>
                            <SocialIcon icon={faInstagram} href="https://www.instagram.com/propelle/?hl=en" className="pr-5"/>
                            <SocialIcon icon={faYoutube} href="https://www.youtube.com/channel/UCNNTcb37Zh6eM_sp-lEE4ww" />
                        </p>
                    </div>
                </Container>
            </Section>
        </div>
    );
}

interface SocialIconProps {
    href: string;
    className?: string;
    icon: IconDefinition;
}

const SocialIcon = (props: SocialIconProps) => {
    return (
        <a className={props.className} href={props.href} target="_blank">
            <FontAwesomeIcon className={"social-icon p-2"} icon={props.icon} size="lg" />
        </a>
    );
}