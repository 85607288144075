import { Link } from "react-router-dom"
import { Section } from "../../../../../../components/Layout/Section/Section"

export const Unqualified = () => {
    return (
        <>
            <h3 className="has-text-purple-blue-500 is-size-5 is-family-primary has-text-weight-semibold">Sorry you don't qualify for this investment type at the moment.</h3>
            <Section className="py-3">
                <p>
                    We're sorry, but you don't currently qualify for this investment type. You can come back in 30 days and re-take our questionnaire to check whether you can invest. According to the FCA, as a restricted investor you must have only invested less than 10% of your net assets in the last 12 months or plan to do so in the coming 12 months in the types of investments mentioned.
                </p>
                <Link to="/invest/property" className="button is-primary mt-5 is-rounded">Return</Link>
            </Section>
        </>
    )
}