import { useEffect, useRef, useState } from "react";

interface ExcerptProps {
    text: string;
    maxLines: number;
}

export const Excerpt = ({ text, maxLines } : ExcerptProps) => {
    const containerRef = useRef<HTMLSpanElement>(null);
    const [displayText, setDisplayText] = useState('');

    let lineHeight = 1.2;

    useEffect(() => {
        if (containerRef.current) {
            const computedLineHeight = parseFloat(getComputedStyle(containerRef.current!).lineHeight);
            lineHeight = computedLineHeight;
            const words = text.split(' ');
            let isOverflowing = false;
            let lastValidIndex = 0;
            words.forEach((_, index) => {
                containerRef.current!.innerText = words.slice(0, index + 1).join(' ');
                if (containerRef.current!.scrollHeight > maxLines * computedLineHeight) {
                    isOverflowing = true;
                    return;
                }
                lastValidIndex = index;
            });

            let tempText = words.slice(0, lastValidIndex + 1).join(' ');
            
            if (isOverflowing) {
                tempText = words.slice(0, lastValidIndex).join(' ') + '...';
            }
            setDisplayText(tempText);
        }
    }, [text, maxLines]);

    return (
        <span ref={containerRef} className="is-block" style={{ hyphens: "auto", lineHeight: `${lineHeight}em`, overflow: 'hidden', maxHeight: `${maxLines * lineHeight}em` }}>
            {displayText}
        </span>
    );
};