import { ReactElement, cloneElement, useEffect } from "react";
import { useWpLoader } from "../../hoc/withLoader";
import { LoaderProps } from "../../components/Layout/Wrappers/ILoaderProps";
import { DealStatus, Order, OrderBy, PropertyOpportunityParams, WpPostTypeRequest } from "../../components/Auth/AuthenticatedCmsClient";
import { ImageSize, getImageUrl } from "../../helpers/cms";

export class PropertyOpportunity {
    id: string;
    link: string;
    status: string;
    imageUrl: string;
    header: string;
    returnRatePerYear: number;
    timeHorizonMonths: number;
    dealType: string;
    provider: string;
    locked: boolean
}

export interface PropertyOpportunityProps {
    propertyOpportunities: PropertyOpportunity[];
    isEligibleForInvestment: boolean;
}

interface PropertyOpportunitiesContainerProps extends LoaderProps {
    isEligibleForInvestment: boolean;
    children: ReactElement<PropertyOpportunityProps>;
}

export const PropertyOpportunitiesContainer = ({wrap, children, isEligibleForInvestment}: PropertyOpportunitiesContainerProps) => {
    const [loaderState, { load, defaultLoaderDisplay, defaultErrorDisplay }] = useWpLoader();

    useEffect(() => {
        if (loaderState.loadingState === "NotStarted") {
            load(new WpPostTypeRequest({
                url: "property-opportunity",
                params: new PropertyOpportunityParams({
                    not_deal_status: DealStatus.Closed,
                    order: Order.Ascending,
                    orderby: OrderBy.MenuOrder
                })
            }));
        }
        
    }, [loaderState.loadingState]);

    if (loaderState.loadingState === "Loaded") {

        const propertyOpportunities : PropertyOpportunity[] = loaderState.response.map((propertyOpportunity : any) => ({
            id: propertyOpportunity.acf.deal_id,
            link: propertyOpportunity.acf.external_link,
            status: propertyOpportunity.acf.status.label,
            imageUrl: getImageUrl(propertyOpportunity.acf.thumbnail, ImageSize.Medium),
            header: propertyOpportunity.title,
            returnRatePerYear: propertyOpportunity.acf.return_rate,
            timeHorizonMonths: propertyOpportunity.acf.time_horizon,
            dealType: propertyOpportunity.acf.deal_type,
            provider: propertyOpportunity.acf.provider,
            locked: !isEligibleForInvestment
        }));

        return wrap
            ? wrap(cloneElement(children, { propertyOpportunities }))
            : cloneElement(children, { propertyOpportunities });
    }

    if (loaderState.loadingState === "Failed") {
        return defaultErrorDisplay();
    }

    return defaultLoaderDisplay();
}