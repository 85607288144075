import { Copy as CopyIcon} from "react-feather";
import * as classes from "./Copy.module.scss";
import { CssClasses } from "../Helpers/CssClasses";

interface CopyProps {
    children: string | number | undefined,
    toCopy?: string | number | undefined,
    fixedWidth?: number,
    className?: string
}

export const Copy = ({ children, className, fixedWidth, toCopy = children } : CopyProps) => {

    let css = new CssClasses([classes.copy ?? "", className ?? ""]);    

    if (fixedWidth) {
        css.add('is-flex is-justify-content-space-between')
    }

    const copyToClipboard = async (value : number | string | undefined) => {
        try {
            await navigator.clipboard.writeText(value!.toString());
        } catch (error) {
            alert('Error copying to clipboard');
        }
    };

    return (
        <div className={css.aggregated()} style={{width: fixedWidth ?? "auto"}} onClick={() => copyToClipboard(toCopy)}>
            { children }
            <CopyIcon className="ml-2" height={20} width={20} alignmentBaseline="middle" color="#1B0777"/>
        </div>
    )
}