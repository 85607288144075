import { CssClasses } from "../../Helpers/CssClasses";

interface SectionProps {
    children?: any;
    className?: string;
    smallMargin?: boolean;
}

export const Section = ({ children, className = "", smallMargin = false} : SectionProps ) => {

    const css = new CssClasses([`section-VxvWTv ${ smallMargin ? 'mb-6' : 'mb-8 mb-6-desktop'}`]);

    if (className != "") {
        css.add(className);
    }

    return (
        <div className={css.aggregated()}>
            { children }
        </div>
    )
}