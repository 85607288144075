@use "../../../styles/spacing";
@use "../../../styles/colors";
@use "../../../../node_modules/bulma/sass/utilities/mixins.sass" as mixins;

.title {
    position: relative;
    display: flex;
    align-items: center;

    @include mixins.touch {
        flex-wrap: wrap;
    }

    &:after {
        content: '';
        flex: 1;
        padding: 1px;
        background-color: map-get(map-get(colors.$color-pallete, "grey"), "200");
        margin: auto map-get(spacing.$spacing-values, "2");
    }
}

.separator-header {
    order: 1;

    @include mixins.touch {
        width: 100%;
        order: -1;
    }

    svg {
        vertical-align: middle;
    }
}

.section {
    :global {
        .carousel-wrapper.with-controls {
            margin-top: 60px;

            .carousel-overlay {
                top: -3rem;
            }
        }
    }
}