import { Meter } from "../../../../../../../components/Meter/Meter";

export const RiskMeter = (props: { rank: "Low" | "Medium" | "High" | undefined, size : 50 | 60 | 70 | 80 | 90 | 100 | 110 | 120 | 130 | 140 | 150 | 160 | 170 | 180 | 190 | 200 | undefined, children?: any }) => {
    let filledSegments = 0;
    switch (props.rank) {
        case "Low":
            filledSegments = 1;
            break;
        case "Medium":
            filledSegments = 2;
            break;
        case "High":
            filledSegments = 3;
            break;
        default:
            filledSegments = 0;
    }

    return <Meter segments={3} filledSegments={filledSegments} size={props.size}>{props.children}</Meter>
}