import { GetCourses_Response } from "../generated/EducationClient";

type ParamsToGetStorageInfo = {
  topicId?: string;
  levelId?: string;
  coursesStorageResponse: GetCourses_Response
}

export const getTopicStorageInfo = ({
  topicId,
  coursesStorageResponse,
}: ParamsToGetStorageInfo) => {
  const topic = coursesStorageResponse?.courses
    ?.flatMap((course) => course?.levels ?? [])
    ?.flatMap((level) => level?.topics ?? [])
    ?.find((topic) => topic?.topicId == topicId);
  return topic;
};
