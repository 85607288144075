import { PropertyOpportunityProps } from "../../../../../../dataComponents/cms/PropertyOpportunitiesContainer"
import { PropertyOpportunity } from "../../../../../../components/Cards/PropertyOpportunity/PropertyOpportunity"
import { Carousel, CarouselItem } from "../../../../../../components/Carousel/Carousel"

export const PropertyOpportunityCarousel = ({ propertyOpportunities, isEligibleForInvestment } : PropertyOpportunityProps) => {

    if (propertyOpportunities.length === 0) {
        return <p>More deals coming soon!</p>
    }

    return (
        <>
            <Carousel itemSize="Large">
                {
                    propertyOpportunities.map((propertyOpportunity) => (
                        <CarouselItem key={`carousel-property-opportunity-${propertyOpportunity.id}`}>
                            <PropertyOpportunity {...propertyOpportunity}/>
                        </CarouselItem>
                    ))
                }
            </Carousel>
            {
                isEligibleForInvestment && propertyOpportunities.length > 0 &&
                    <p className="mt-3 is-italic">*These deals are made available by our property investment partner who review the opportunity and approve as a financial promotion. Propelle does not undertake any assessment of these promotions.
                    <br/>Approver: Focus 2020 Ltd, FRN: 727214, Date: 14th February 2024</p>
            }
        </>
    )
}