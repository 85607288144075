import { useLoaderData, LoaderFunction, useRouteLoaderData } from "react-router-dom";
import { ArticleParams, WpPostTypeRequest, useAuthenticatedCmsClient } from "../../../components/Auth/AuthenticatedCmsClient";
import { Columns, Container } from "react-bulma-components";
import { LoaderData as CategoryLoaderData } from "../components/Layout/Layout";
import { ArticleCardWithLink } from "../../../components/Cards/ArticleCard";
import { useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { ImageSize, getImageUrl } from "../../../helpers/cms";
import { Glass } from "../../../components/Layout/Glass/Glass";
import { Main } from "../../../components/Layout/Main/Main";
import { PageTitleWithinGlass } from "../../../components/Layout/PageTitle/PageTitle";

export const loader: LoaderFunction = async ({ params }) => {

    let client = useAuthenticatedCmsClient();

    let slug = params.categorySlug;
    let isSecondLevel = false;

    if (params.categoryChildSlug !== undefined) {
        slug = params.categoryChildSlug;
        isSecondLevel = true;
    }

    try {
        let articlesResponse = await client!.get(new WpPostTypeRequest({
            url: "posts",
            params: new ArticleParams({
                category_slug: slug,
                per_page: 20
            })
        }));

        return {
            categorySlug: slug,
            articles: articlesResponse.data,
            isSecondLevel: isSecondLevel,
            topLevelCategorySlug: params.categorySlug
        };

    } catch {
        throw new Response("This category doesn't exist.", { status: 404 });
    }
}

export interface LoaderData {
    categorySlug : string,
    articles : any[],
    isSecondLevel : boolean,
    topLevelCategorySlug: string
}

export const ArticleCategory = () => {
    const { categorySlug, articles, isSecondLevel, topLevelCategorySlug } = useLoaderData() as LoaderData;
    const { categories } = useRouteLoaderData("learn-root") as CategoryLoaderData;

    const containerRef = useRef<HTMLDivElement>(null);
   
    const topLevelCategory = categories.find(x => x.slug === topLevelCategorySlug);

    let category = isSecondLevel ? topLevelCategory.children.find((x : any) => x.slug === categorySlug) : topLevelCategory;

    useEffect(() => {
        if (window.outerWidth > 768) {

            let container = containerRef.current;

            let matchHeight = container!.querySelectorAll('.match-height');

            let maxHeaderHeight = Math.max(...Array.from(matchHeight).map(x => x.clientHeight));

             matchHeight.forEach((x : any) => x.style.height = `${maxHeaderHeight}px`);
        }

    }, [categorySlug]);

    const getUrl = (item : any) => {
        if (categorySlug === 'guides' && item.acf.pdf) {
            return item.acf.pdf;
        }

        return `/learn/article/${item.slug}`
    }

    return (
        <>
            <Helmet>
                <title>Article Category - {category.name}</title>
            </Helmet>
            <Main>
                <Container>
                    <Glass ref={containerRef}>
                        <PageTitleWithinGlass text={ isSecondLevel ? `${topLevelCategory.name} > ${category.name}` : category.name } />
                        <p>{category.description}</p>
                        { articles.length > 0
                            && <Columns className="mt-4">
                                { articles.map(x => {
                                    return (
                                        <Columns.Column size={4} key={x.id} className="p-0-desktop pb-4-touch">
                                            <ArticleCardWithLink
                                                key={`cat-${x.id}`}
                                                id={x.id}
                                                header={x.title}
                                                duration={x.acf.duration}
                                                durationType={topLevelCategorySlug === 'videos' ? 'watch' : 'read'}
                                                url={getUrl(x)}
                                                imageUrl={getImageUrl(x.acf.thumbnail, ImageSize.MediumLarge)}
                                                membership={x.membership}
                                            />
                                        </Columns.Column>
                                    );
                                })}
                            </Columns>
                        }
                        { articles.length === 0 
                            && <p className="mt-3">Sorry, this category doesn't have any articles yet!</p>}
                    </Glass>
                </Container>
            </Main>
        </>
    );
}