import { useEffect, useState } from "react";
import { Box, Container, Section } from "react-bulma-components";
import { Link } from "react-router-dom";
import { Back } from "../../../../../components/Back/Back";
import { defaultErrorDisplay, defaultLoaderDisplay } from "../../../../../hoc/withLoader";
import { IWealthMappingApiCache, useCache, WealthMappingApiCacheKey } from "../../../../wealth-mapping/Caching";
import { Calculation } from "../../../../wealth-mapping/pages/Calculator/components/Calculation/Calculation";
import { getQualitativeGoal } from "../../../../wealth-mapping/pages/Calculator/components/QualitativeGoal/QualitativeGoal";
import { CalculateFutureValue_Request, CalculateFutureValue_Response, GetWealthMappingOverview_Response, GetWealthMappingOverview_Response_FutureValueCalculationResponse, GetWealthMappingOverview_Response_WealthPictureResponse_GoalResponse, GetWealthMappingOverview_Response_WealthPictureResponse_GoalResponse_GoalType } from "../../../../wealth-mapping/services/generated/WealthMappingClient";
import { WealthMappingClientFactory } from "../../../../wealth-mapping/services/WealthMappingClientFactory";
import { Helmet } from "react-helmet-async";
import './GoalPlanning.scss';
import { defaultFutureValueCalculatorTimeframe } from "../../../../wealth-mapping/services/Utils";

export const GoalPlanning = () => {

    const [loader, { defaultErrorDisplay, defaultLoaderDisplay }] = useGoalPlanningLoader();

    if (loader.loadingState === "Failed") {
        return <>{defaultErrorDisplay()}</>;
    }

    if (loader.loadingState === "Loading") {
        return <>{defaultLoaderDisplay()}</>;
    }

    const goalDescription = (goal: GetWealthMappingOverview_Response_WealthPictureResponse_GoalResponse | undefined) => {
        if (!goal?.type) {
            return undefined;
        }

        switch (goal.type) {
            case GetWealthMappingOverview_Response_WealthPictureResponse_GoalResponse_GoalType.FinancialIndependence:
                return getQualitativeGoal({ type: "FinancialIndependence" }).toLocaleLowerCase();
            case GetWealthMappingOverview_Response_WealthPictureResponse_GoalResponse_GoalType.FinancialSecurity:
                return getQualitativeGoal({ type: "FinancialSecurity" }).toLocaleLowerCase();
            case GetWealthMappingOverview_Response_WealthPictureResponse_GoalResponse_GoalType.QualityOfLife:
                return getQualitativeGoal({ type: "QualityOfLife" }).toLocaleLowerCase();
            case GetWealthMappingOverview_Response_WealthPictureResponse_GoalResponse_GoalType.SpecificEvent:
                return getQualitativeGoal({ type: "SpecificEvent", event: goal?.customDescription }).toLocaleLowerCase();
            case GetWealthMappingOverview_Response_WealthPictureResponse_GoalResponse_GoalType.Retirement:
                return getQualitativeGoal({ type: "Retirement" }).toLocaleLowerCase();
        }
    }

    return (
        <div className="goal-planning">
            <Helmet>
                <title>Goal Planning</title>
            </Helmet>
            <Section className="px-8-desktop px-4-mobile">
                <Container>
                    <div className="goal-planning-content">
                        <div className="mb-3">
                            <Back navigate={{to: "/invest/tools"}}>Back</Back>
                        </div>
                        <div >
                            <Box className="is-rounded py-5">
                                <Explainer lumpSumRequired={loader.overview?.wealthPicture?.outputs?.lumpSumRequired} goalDescription={goalDescription(loader.overview?.wealthPicture?.outputs?.goal)} />
                            </Box>
                        </div>
                        {
                            loader.overview &&
                            <div className="">
                                <CalculationWrapper overview={loader.overview} />
                            </div>
                        }
                    </div>
                </Container>
            </Section>
        </div>
    );
}

interface CalculationWrapperProps {
    overview: GetWealthMappingOverview_Response;
}

const CalculationWrapper = (props: CalculationWrapperProps) => {

    const initialRequest = (response: GetWealthMappingOverview_Response | undefined) => {
        if (!response?.futureValueCalculation?.inputs) {
            if (response?.wealthPicture?.inputs) {
                return new CalculateFutureValue_Request({
                    years: defaultFutureValueCalculatorTimeframe(response.wealthPicture.inputs)
                });
            }

            return undefined;
        }

        const initial = new CalculateFutureValue_Request();
        initial.initialDeposit = response?.futureValueCalculation?.inputs?.initialDeposit;
        initial.monthlyDeposit = response?.futureValueCalculation?.inputs?.monthlyDeposit;
        initial.years = response?.futureValueCalculation?.inputs?.years;
        initial.desiredReturn = response?.futureValueCalculation?.inputs?.desiredReturn;

        return initial;
    }

    const initialResponse = (response: GetWealthMappingOverview_Response_FutureValueCalculationResponse | undefined) => {
        if (!response?.outputs) {
            return undefined;
        }

        const initial = new CalculateFutureValue_Response();
        initial.performsBadly = response?.outputs?.performsBadly;
        initial.performsOK = response?.outputs?.performsOK;
        initial.performsWell = response?.outputs?.performsWell;

        return initial;
    }

    interface State {
        futureValueRequest?: CalculateFutureValue_Request;
        futureValueResponse?: CalculateFutureValue_Response;
    }

    const [state, setState] = useState<State>({ futureValueRequest: initialRequest(props.overview), futureValueResponse: initialResponse(props.overview.futureValueCalculation) });

    const onFutureValueCalculated = (request: CalculateFutureValue_Request, response: CalculateFutureValue_Response) => {
        setState({ futureValueRequest: request, futureValueResponse: response });
    }

    return <Calculation {...state} lumpSumRequired={props.overview.wealthPicture?.outputs?.lumpSumRequired} onFutureValueCalculated={onFutureValueCalculated} />
}

interface ExplainerProps {
    lumpSumRequired?: number;
    goalDescription?: string;
}

const Explainer = (props: ExplainerProps) => {
    if (!props.lumpSumRequired) {
        return (
            <>
                <p className="has-text-centered has-text-navy has-text-weight-medium mb-1">PART 1</p>
                <h4 className="title is-4 has-text-centered has-text-navy">We'll ask you a few questions to understand your financial goals and show you how to reach them. </h4>
                <p className="has-text-centered has-text-navy"><Link to="/wealth-mapping/chat" className="button is-primary is-rounded">Define your goal</Link></p>
            </>
        )
    }

    return (
        <>
            <p className="has-text-centered has-text-navy has-text-weight-medium mb-1">PART 1</p>
            <h4 className="title is-5 has-text-centered has-text-navy is-hidden-touch mb-1">To achieve your goal, you'll need</h4>
            <h5 className="title is-5 has-text-centered has-text-navy is-hidden-desktop mb-1">To achieve your goal, you'll need</h5>
            <h4 className="title is-4 is-hidden-desktop has-text-centered has-text-navy">{currencyFormatter.format(props.lumpSumRequired)}</h4>
            <h2 className="title is-2 is-hidden-touch has-text-centered has-text-navy">{currencyFormatter.format(props.lumpSumRequired)}</h2>
            <p className="has-text-centered has-text-navy mt-4"><Link to="/wealth-mapping/chat" className="button is-primary is-rounded px-8">Redefine your goal</Link></p>
        </>
    )
}

type GoalPlanningLoaderState =
    | "Loading"
    | "Loaded"
    | "Failed";

export const useGoalPlanningLoader = () => {

    const wealthMappingClient = WealthMappingClientFactory();
    const cache = useCache<IWealthMappingApiCache>(WealthMappingApiCacheKey());

    interface State {
        loadingState: GoalPlanningLoaderState;
        overview?: GetWealthMappingOverview_Response;
    }

    const [state, setState] = useState<State>({ loadingState: "Loading" });

    const onCachedEntryFound = (cachedEntry: IWealthMappingApiCache) => {
        if (cachedEntry.wealthPictureRequest) {
            wealthMappingClient.createOwnedWealthPicture(cachedEntry.wealthPictureRequest)
                .then(resp => {
                    cachedEntry.wealthPictureResponse = resp;
                    cache.set(cachedEntry);
                    onOwnedWealthPictureCreated(cachedEntry);
                })
                .catch(_ => {
                    // Just continue if we can't process the cached entry
                    onCacheProcessingComplete();
                });
        }
    }

    const onOwnedWealthPictureCreated = (cachedEntry: IWealthMappingApiCache) => {
        if (cachedEntry.futureValueRequest) {
            wealthMappingClient.calculateOwnedFutureValue(cachedEntry.futureValueRequest)
                .then(resp => {
                    cachedEntry.futureValueResponse = resp;
                    cache.set(cachedEntry);
                    onCacheProcessingComplete();
                })
                .catch(_ => {
                    // Just continue if we can't process the cached entry
                    onCacheProcessingComplete();
                })
        }
    }

    const onCacheProcessingComplete = () => {
        wealthMappingClient.getWealthMappingOverview()
            .then(resp => {
                setState({ loadingState: "Loaded", overview: resp });
            })
            .catch(_ => {
                setState({ loadingState: "Failed" });
            })
    }

    useEffect(() => {
        if (state.loadingState === "Loading") {
            wealthMappingClient.getWealthMappingOverview()
                .then(resp => {
                    if (resp.wealthPicture) {
                        setState({ loadingState: "Loaded", overview: resp });
                    } else {
                        // The customer may have already done a wealth picture which could be stored in browser storage
                        const cachedEntry = cache.get();

                        if (cachedEntry) {
                            onCachedEntryFound(cachedEntry)
                        } else {
                            setState({ loadingState: "Loaded", overview: resp });
                        }
                    }
                });
        }

        if (state.loadingState === "Loaded") {
            cache.remove();
        }
    }, [state.loadingState]);

    let result: [State, { defaultLoaderDisplay: () => JSX.Element, defaultErrorDisplay: () => JSX.Element }];
    result = [state, { defaultLoaderDisplay, defaultErrorDisplay }]

    return result;
}

const currencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    maximumFractionDigits: 0
});