import { Button } from "react-bulma-components";
import { Link, useNavigate } from "react-router-dom";
import './BackButton.scss';
import { ChevronLeft } from "react-feather";
export interface BackButtonProps {
		text?: string
		className?: string;
		action?: () => void;
		to?: string;
}

export const BackButton = ({ text, className = '', action, to } : BackButtonProps) => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate(-1);
	};

	if (to) {
		return (
			<Button inverted className={`has-background-transparent ${className}`}>
				<Link to={to} className="is-flex is-flex-direction-row is-flex-wrap-wrap is-flex-gap-1 is-align-items-center has-text-black">
					<ChevronLeft height={20} className="mr-2"/> {text ? text : "Back"}
				</Link>
		 	</Button>
		)
	}

	return (
		<Button inverted onClick={action || handleClick} className={`is-flex is-flex-direction-row is-flex-wrap-wrap is-flex-gap-1 has-background-transparent has-text-black ${className}`}>
			<ChevronLeft height={20} className="mr-2"/> {text ? text : "Back"}
		</Button>
	);
};