const currencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    maximumFractionDigits: 0,
});

const currencyFormatterWithDecimals = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    maximumFractionDigits: 2,
});

export const formatCurrency = (value: number) => {

    if (Number.isInteger(value)) {
        return currencyFormatter.format(value);
    }
    
    return currencyFormatterWithDecimals.format(value);
}