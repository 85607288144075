import { Helmet } from "react-helmet-async"
import { ObjectSchema, number } from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { FundsContext } from "../components/FundsContext"
import { Main } from "../../../../components/Layout/Main/Main"
import { BackButton } from "../../../../components/BackButton/BackButton"
import { Glass } from "../../../../components/Layout/Glass/Glass"
import { Section } from "../../../../components/Layout/Section/Section"
import { Content } from "../../../../components/Layout/Content/Content"
import { Form } from "../../../../components/Forms/Form"
import { Loader } from "../../../../components/Loader/Loader"
import { PortfoliosClientFactory } from "../../services/PorfoliosClientFactory"
import { GetPortfolioAvailableActions_Action, MakeWithdrawal_Request } from "../../services/generated/PortfoliosClient"
import { Info } from "../../../../components/Info/Info"

interface WithdrawForm {
    amount?: number
}

export const Withdraw = () => {
    const context = useContext(FundsContext);
    
    useEffect(() => {
        context.fetchPortfolio();
        context.fetchPortfolioActions();
        context.fetchPortfolioBalance();
    }, []);

    const inner = () => {

        if (context.actions) {

            var withdrawal = context.actions.actions?.find(x => x.action === GetPortfolioAvailableActions_Action.Withdrawal);

            if (!withdrawal?.allowed) {
                return (
                    <Content>
                        <p>Sorry, you can't withdraw from your account at this time for the following reason: <b>{withdrawal?.reason}</b></p>
                    </Content>
                )
            }
        }

        return (
            <>
                <Content>
                    <p className="is-size-5 has-text-weight-semibold has-text-purple-blue-500">How much would you like to withdraw?</p>
                    <p>Your money will be paid into the bank account you've used when contributing to your account. You’ll receive your money within 10 working days. If you have an ongoing contribution, this amount may not be available to withdraw yet.</p>
                </Content>
                <WithdrawForm availableHoldingsBalance={context.balance?.holdingsBalanceIncludingReservations!} portfolioId={context.portfolio?.id!} />
            </>
        );
    }

    return (
        <>
            <Helmet>
                <title>Funds - Withdraw</title>
            </Helmet>
            <Main>
                <div className="container">
                    <BackButton to={"/invest/funds"} text="Back" className="mb-3"/>
                    <div className="columns">
                        <div className="column is-12 is-7-widescreen">
                            <Glass opacity={100}>
                                <Section>
                                    <Content>
                                        <h1 className="is-size-4 has-text-centered has-text-left-desktop is-size-2-desktop has-text-purple-blue-500">Withdraw from your fund</h1>
                                    </Content>
                                    {
                                        context.portfolio?.id === undefined || context.balance?.holdingsBalanceIncludingReservations === undefined
                                            ? <Loader className="py-6" />
                                            : inner()
                                    }
                                </Section>
                                <Content>
                                    <p className="is-size-smaller">Heads up - the value of your investment fluctuates constantly, which means that by the time we sell your units to give you your money, the unit price may have gone up or down, which may impact the amount you will receive.</p>
                                </Content>
                            </Glass>
                        </div>
                    </div>
                </div>
            </Main>
        </>
    )
}

interface WithdrawFormProps {
    availableHoldingsBalance: number;
    portfolioId: string;
}


const WithdrawForm = ({ availableHoldingsBalance, portfolioId } : WithdrawFormProps) => {
    const navigate = useNavigate();
    const context = useContext(FundsContext);

    const validation = new ObjectSchema<WithdrawForm>({
        amount: number()
            .typeError('Please enter a valid amount')
            .required('Please enter an amount')
            .moreThan(4, 'Amount must be more than £1')
            .test(
                "amountToNearestPenny",
                "Amount must be to the nearest penny",
                x => {
                    const split = x.toString().split('.');
                    if (split.length <= 1) {
                        return true;
                    }

                    if ((split[1]?.length ?? 0) <= 2) {
                        return true;
                    }

                    return false;
                } 
            )
            .lessThan(availableHoldingsBalance, 'Can only withdraw up to your available balance')
            .moreThan(0.99, 'Must withdraw at least £1')
    });
    
    const { register, formState: { isValid, errors }, getValues, handleSubmit } = useForm<WithdrawForm>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(validation)
    });

    const onFormCompleted = () => {
        toggleSubmissionStatus({ isSubmitting: true, isSubmitted: false});
    }

    const submit = async () => {

        try {
            // Submit withdrawal
            await PortfoliosClientFactory().makeWithdrawal(new MakeWithdrawal_Request({
                amount: getValues("amount")
            }), (portfolioId).toString());

            toggleSubmissionStatus({ isSubmitting: false, isSubmitted: true});
        } catch (e) {
            toggleSubmissionStatus({ isSubmitting: false, isSubmitted: false});
        }        
    }
    
    const [submissionState, toggleSubmissionStatus] = useState({ isSubmitting: false, isSubmitted: false });

    useEffect(() => {
        const submitAndNavigate = async () => {
            if (submissionState.isSubmitting) {
                await submit();
            } else if (submissionState.isSubmitted) { // && withdrawal successful
                // Bit hacky here but need to refresh the cache to reflect the withdrawal
                context.refreshPortfolioData();
                navigate(`/invest/funds`);
            }
        };
    
        submitAndNavigate();
    }, [submissionState.isSubmitting, submissionState.isSubmitted])

    return (
        <Form className="mx-0" onSubmit={handleSubmit(onFormCompleted)} columns={1}>
            <Form.Inputs>
                <Form.FormInput>
                    <Form.FormInput.Label text="Amount you want to withdraw" textSize={6}/>
                    <Form.FormInput.PrefixedInput prefix={"£"}>
                        <input type="text" className="input" {...register("amount", { valueAsNumber: true })} />
                    </Form.FormInput.PrefixedInput>
                    <Form.FormInput.ValidationMessage text={errors.amount?.message} />
                </Form.FormInput>
            </Form.Inputs>
            <div className="mb-3 has-text-weight-semibold has-text-purple-blue-500">
                Available funds to withdraw: £{availableHoldingsBalance}
                <Info><p>This balance is the amount settled in your holdings. It does not include incoming contrubitions. Any withdrawal in progress or fund change will affect this balance.</p></Info>
            </div>
            <Form.Submit disabled={!isValid || submissionState.isSubmitting} loading={submissionState.isSubmitting}>Confirm withdrawal request</Form.Submit>
        </Form>
    )
}