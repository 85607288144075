import { useState } from "react";
import { AuthHelper } from "../../../../../../components/Auth/AuthHelper";
import { useDashboardLoader } from "./propertyDashboardLoaders";
import { InvestmentsDashboard } from "../../../../components/InvestmentsDashboard/InvestmentsDashboard";
import { formatCurrency } from "../../../../../../helpers/currency";
import { Table } from "../../../../../../components/Table/Table";

export const PropertyDashboard = ({ 
    title = "Your property investments", 
    emptyDashboardExplainer = "Your dashboard will start populating once you invest in your first property deal. When you invest in a deal, we'll wait for the deal to be closed before we update the numbers on your dashboard. This might take up to 2 weeks after the funds have been released to the developer."
}) => {

    const dashboardLoader = useDashboardLoader();
    const user = AuthHelper.getUserData();
    const [overlayDealId, setOverlayDealId] = useState<number | undefined>();

    const totalInvested = dashboardLoader.response?.deals?.map(x => x.amountInvested ?? 0).reduce((partialSum, a) => partialSum + a, 0) ?? 0;
    const totalExpectedValue = dashboardLoader.response?.deals?.map(x => x.amountInvested * Math.pow(1 + (x.metadata?.returnRatePerYear / 100), (x.metadata?.timeHorizonMonths / 12)) ?? 0).reduce((partialSum, a) => partialSum + a, 0) ?? 0;
    const totalExpectedReturns = totalExpectedValue - totalInvested;
    const dealBreakdown = dashboardLoader.response?.deals?.map(x => {
        return {
            id: x.metadata?.id,
            clientName: user.name,
            name: x.metadata?.title,
            type: x.metadata?.dealType,
            provider: x.metadata?.provider,
            amountInvested: x.amountInvested,
            expectedReturn: x.amountInvested * Math.pow(1 + (x.metadata?.returnRatePerYear / 100), (x.metadata?.timeHorizonMonths / 12)) - x.amountInvested,
            startDate: x.metadata?.startDate,
            expectedEndDate: x.metadata?.endDate
        };
    })
    
    const selectedOverlayDeal = overlayDealId
        ? dealBreakdown.find(x => x.id === overlayDealId)
        : undefined;

    
    const formatDealEndDate = (date: Date) => {
        const monthFormatter = new Intl.DateTimeFormat(undefined, {
            month: "short",
        });
        
        const yearFormatter = new Intl.DateTimeFormat(undefined, {
            year: "2-digit"
        })

        return `${monthFormatter.format(date)}. ${yearFormatter.format(date)}`;
    }

    return (
        <InvestmentsDashboard>
            <InvestmentsDashboard.Title text={title} />
            {
                (dashboardLoader.loadingState === "Loading" || dashboardLoader.loadingState === "NotStarted") &&
                dashboardLoader.functions.defaultLoaderDisplay()
            }
            {
                dashboardLoader.loadingState === "Failed" &&
                dashboardLoader.functions.defaultErrorDisplay()
            }
            {
                (dashboardLoader.loadingState === "Loaded" && dashboardLoader.response) &&
                    <>
                        <InvestmentsDashboard.DataPoints>
                            <InvestmentsDashboard.DataPoints.DataPointGroup>
                                <InvestmentsDashboard.DataPoints.DataPointGroup.DataPoint
                                    icon={<InvestmentsDashboard.Icons.PiggyBank />}
                                    value={ formatCurrency(totalInvested) }
                                    label="Amount invested"
                                    info={{
                                        text: "This is the amount you have invested into property deals. We receive this information from our investment partner and do not verify the information.",
                                        positioning: "left"
                                    }} />
                            </InvestmentsDashboard.DataPoints.DataPointGroup>
                            <InvestmentsDashboard.DataPoints.DataPointGroup>
                                <InvestmentsDashboard.DataPoints.DataPointGroup.DataPoint
                                    icon={<InvestmentsDashboard.Icons.Notes />}
                                    value={ formatCurrency(totalExpectedValue) }
                                    label="Exp. total value"
                                    info={{
                                        text: "This is the value we expect you to have at the end of all the deals you've invested in. This assumes that the deals are completed using the initially provided return rates. As with all high risk property investments, returns aren't guaranteed and you should use this projection for information purposes only.",
                                        positioning: "left"
                                    }}/>
                            </InvestmentsDashboard.DataPoints.DataPointGroup>
                            <InvestmentsDashboard.DataPoints.DataPointGroup>
                                <InvestmentsDashboard.DataPoints.DataPointGroup.DataPoint
                                    icon={<InvestmentsDashboard.Icons.BarChart />}
                                    value={ formatCurrency(totalExpectedReturns) }
                                    label="Exp. return"
                                    info={{
                                        text: "This is the return we expect you to make at the end of all the deals you've invested in if all goes well. This assumes that the deals are completed using the initially provided return rates. As with all high risk property investments, returns aren't guaranteed and you should use this projection for information purposes only.",
                                        positioning: "left"
                                    }}/>
                            </InvestmentsDashboard.DataPoints.DataPointGroup>
                        </InvestmentsDashboard.DataPoints>
                        {
                            totalInvested <= 0 &&
                                <InvestmentsDashboard.Footer linkText="Why is my dashboard empty?">
                                    <p className="has-text-purple-blue-500">
                                        { emptyDashboardExplainer }
                                    </p>
                                </InvestmentsDashboard.Footer>
                        }
                        {
                            totalInvested > 0 &&
                                <InvestmentsDashboard.Footer linkText="View your deal history" expandedlinkText="Close your deal history">
                                    <Table>
                                        <Table.Header>
                                            <Table.Header.Heading><p className="has-text-purple-blue-500 has-text-weight-medium">Deal name</p></Table.Header.Heading>
                                            <Table.Header.Heading><p className="has-text-purple-blue-500 has-text-weight-medium">Amount</p></Table.Header.Heading>
                                            <Table.Header.Heading><p className="has-text-purple-blue-500 has-text-weight-medium">Exp. return</p></Table.Header.Heading>
                                            <Table.Header.Heading><p className="has-text-purple-blue-500 has-text-weight-medium">Exp. end date</p></Table.Header.Heading>
                                        </Table.Header>
                                        <Table.Data data={
                                            dealBreakdown.map(x => {
                                                return {
                                                    onClick: () => setOverlayDealId(x.id),
                                                    values: [
                                                        <>{ x.name }</>,
                                                        <>{formatCurrency(x.amountInvested)}</>,
                                                        <>{formatCurrency(x.expectedReturn)}</>,
                                                        <>{formatDealEndDate(x.expectedEndDate)}</>
                                                    ]
                                                }
                                            })
                                        } />
                                    </Table>
                                </InvestmentsDashboard.Footer>
                        }
                    </>
            }
            <InvestmentsDashboard.Overlay isVisible={overlayDealId !== undefined} onClose={() => setOverlayDealId(undefined)}>
                <InvestmentsDashboard.Overlay.OverlaySection>
                    <p className="has-text-purple-blue-500 has-text-weight-semibold" style={{fontSize: "20px"}}>Deal details</p>
                </InvestmentsDashboard.Overlay.OverlaySection>
                <InvestmentsDashboard.Overlay.OverlaySection>
                    <p className="has-text-purple-blue-500 has-text-weight-medium">Client name</p>
                    <p>{selectedOverlayDeal?.clientName}</p>
                </InvestmentsDashboard.Overlay.OverlaySection>
                <InvestmentsDashboard.Overlay.OverlaySection>
                    <div className="columns">
                        <div className="column">
                            <p className="has-text-purple-blue-500 has-text-weight-medium">Deal name</p>
                            <p>{selectedOverlayDeal?.name}</p>
                        </div>
                        <hr className="is-hidden-desktop has-background-grey-200 mx--2 my-0"/>
                        <div className="column">
                            <p className="has-text-purple-blue-500 has-text-weight-medium">Deal type</p>
                            <p>{selectedOverlayDeal?.type}</p>
                        </div>
                    </div>
                </InvestmentsDashboard.Overlay.OverlaySection>
                <InvestmentsDashboard.Overlay.OverlaySection>
                    <div className="columns is-touch">
                        <div className="column">
                            <p className="has-text-purple-blue-500 has-text-weight-medium">Provider</p>
                            <p>{selectedOverlayDeal?.provider}</p>
                        </div>
                        <hr className="is-hidden-desktop has-background-grey-200 mx--2 my-0"/>
                        <div className="column">
                            <p className="has-text-purple-blue-500 has-text-weight-medium">Exp. Return</p>
                            <p>{formatCurrency(selectedOverlayDeal?.expectedReturn ?? 0)}</p>
                        </div>
                    </div>
                </InvestmentsDashboard.Overlay.OverlaySection>
                <InvestmentsDashboard.Overlay.OverlaySection>
                    <div className="columns">
                        <div className="column">
                            <p className="has-text-purple-blue-500 has-text-weight-medium">Start date</p>
                            <p>{new Intl.DateTimeFormat(undefined, { year: "2-digit", month: "2-digit", day: "2-digit" }).format(selectedOverlayDeal?.startDate)}</p>
                        </div>
                        <hr className="is-hidden-desktop has-background-grey-200 mx--2 my-0"/>
                        <div className="column">
                            <p className="has-text-purple-blue-500 has-text-weight-medium">Exp. End Date</p>
                            <p>{new Intl.DateTimeFormat(undefined, { year: "2-digit", month: "2-digit", day: "2-digit" }).format(selectedOverlayDeal?.expectedEndDate)}</p>
                        </div>
                    </div>
                </InvestmentsDashboard.Overlay.OverlaySection>
                <InvestmentsDashboard.Overlay.OverlaySection background="Peach" className="py-4 is-rounded-bottom">
                    <div className="columns is-mobile">
                        <div className="column">
                            <p className="has-text-purple-blue-500 has-text-weight-medium">Amount invested</p>
                        </div>
                        <div className="column">
                            <div className="is-fullheight is-flex is-flex-direction-row is-align-items-center is-justify-content-start">
                                <p className="has-text-purple-blue-500 has-text-weight-medium">{formatCurrency(selectedOverlayDeal?.amountInvested ?? 0)}</p>
                            </div>
                        </div>
                    </div>
                </InvestmentsDashboard.Overlay.OverlaySection>
            </InvestmentsDashboard.Overlay>
        </InvestmentsDashboard>
    );
}