import { AuthHelper } from "./AuthHelper";

export class AuthorizedApiClientBase {

    constructor() {
        this._accessToken = undefined;
    }

    private _accessToken?: string;

    public isLoggedIn = () => {
        if (this._accessToken) {
            return true;
        }

        return false;
    }

    public setAccessToken = (accessToken: string | undefined) => {

        if (accessToken) {
            this._accessToken = accessToken;
        }
    }

    protected transformOptions = (options: RequestInit): Promise<RequestInit> => {
        if (this._accessToken) {
            options.headers = {
                ...options.headers,
                'Authorization': 'Bearer ' + this._accessToken
            };
        }

        return Promise.resolve(options);
    }
}

export const authorizeIfLoggedIn = <TClient extends AuthorizedApiClientBase,> (client: TClient) => {

    const storedAccessToken = AuthHelper.getAccessToken();
    if (storedAccessToken) {
        client.setAccessToken(storedAccessToken);
    }

    return undefined;
}