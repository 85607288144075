@use "../../../../styles/colors";

.label {
    font-size: 22px; // Doesn't fall within standard font sizes
}

.overlay-container {
    z-index: 100;
}

.overlay {
    stroke-width: 1px;
    stroke: var(--black-200, #E2E2E2);
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.10));
}

.peach-gradient-background {
    background: colors.$peach-gradient;
}