import dayjs from "dayjs"
import { InvestmentsDashboard } from "../../../../../components/InvestmentsDashboard/InvestmentsDashboard"
import { formatCurrency } from "../../../../../../../helpers/currency"
import { TradingClientFactory } from "../../../../../services/TradingClientFactory"
import { useLoader } from "../../../../../../../hoc/withLoader"
import { GetRebalance_Response } from "../../../../../services/generated/TradingClient"
import { useContext, useEffect } from "react"
import { FundsContext } from "../../../../components/FundsContext"
import { GetPortfolioTransactions_TransactionType } from "../../../../../services/generated/PortfoliosClient"

interface FundSwitchOverlayDetailsProps {
    transaction: {
        id: string;
        dateRequested?: Date;
        amount?: number;
        type?: string;
        status?: string;
    };
    showOrderDetails: (orderId: string, type: GetPortfolioTransactions_TransactionType) => void;
}

export const FundSwitchOverlayDetails = ( {transaction, showOrderDetails} : FundSwitchOverlayDetailsProps ) => {

    const client = TradingClientFactory();
    const [loaderState, loader] = useLoader<GetRebalance_Response>();
    const fundsContext = useContext(FundsContext);

    useEffect(() => {
        fundsContext.fetchFundsContent();
        loader.load(client.getRebalance(transaction.id));
    }, []);

    var oldFund = fundsContext.fundsContent !== undefined && loaderState.response != undefined
        ? fundsContext.fundsContent.find((x : any) => x.acf.isin === loaderState.response!.previousAllocation![0]!.isin)
        : undefined;

    var newFund = fundsContext.fundsContent !== undefined && loaderState.response != undefined
        ? fundsContext.fundsContent.find((x : any) => x.acf.isin === loaderState.response!.newAllocation![0]!.isin)
        : undefined;

    const onOrderDetailsClick = (orderId: string) => {
        showOrderDetails(orderId, GetPortfolioTransactions_TransactionType.Rebalance);
    }

    const getOrderReceipts = () => {

        let receipts = [];

        if (loaderState.response?.deInvestedOn === undefined) {
            return "Sell Order - Contract Note: Pending";
        } else {
            receipts.push(<span key={"buy"} onClick={() => onOrderDetailsClick(loaderState.response?.sellIds![0]!)} className="is-underlined is-link is-clickable has-text-purple-blue-400">Sell Order - Contract Note</span>);
        }

        if (loaderState.response?.investedOn === undefined) {
            return "Buy Order - Contract Note: Pending";
        } else {
            receipts.push(<br/>);
            receipts.push(<span key={"sell"} onClick={() => onOrderDetailsClick(loaderState.response?.buyIds![0]!)} className="is-underlined is-link is-clickable has-text-purple-blue-400">Buy Order - Contract Note</span>);
        }


        return receipts;
    }

    const inner = () => {
    
        return <>
            <InvestmentsDashboard.Overlay.OverlaySection>
                <p className="has-text-purple-blue-500 has-text-weight-semibold" style={{fontSize: "20px"}}>Fund switch details</p>
            </InvestmentsDashboard.Overlay.OverlaySection>
            <InvestmentsDashboard.Overlay.OverlaySection>
                <p className="has-text-purple-blue-500 has-text-weight-medium">Date requested</p>
                <p>{dayjs(transaction.dateRequested).format("DD/MM/YYYY")}</p>
            </InvestmentsDashboard.Overlay.OverlaySection>
            <InvestmentsDashboard.Overlay.OverlaySection>
                <p className="has-text-purple-blue-500 has-text-weight-medium">Date disinvested</p>
                <p>{loaderState.response?.deInvestedOn !== undefined ? dayjs(loaderState.response?.deInvestedOn).format("DD/MM/YYYY") : "Pending"}</p>
            </InvestmentsDashboard.Overlay.OverlaySection>
            <InvestmentsDashboard.Overlay.OverlaySection>
                <p className="has-text-purple-blue-500 has-text-weight-medium">Date reinvested</p>
                <p>{loaderState.response?.investedOn !== undefined ? dayjs(loaderState.response?.investedOn).format("DD/MM/YYYY") : "Pending"}</p>
            </InvestmentsDashboard.Overlay.OverlaySection>
            <InvestmentsDashboard.Overlay.OverlaySection>
                <p className="has-text-purple-blue-500 has-text-weight-medium">New fund</p>
                <p>{newFund?.title}</p>
            </InvestmentsDashboard.Overlay.OverlaySection>
            <InvestmentsDashboard.Overlay.OverlaySection>
                <p className="has-text-purple-blue-500 has-text-weight-medium">Old fund</p>
                <p>{oldFund?.title}</p>
            </InvestmentsDashboard.Overlay.OverlaySection>
            <InvestmentsDashboard.Overlay.OverlaySection>
                <p className="has-text-purple-blue-500 has-text-weight-medium">Transaction receipts</p>
                <p>{ getOrderReceipts() }</p>
            </InvestmentsDashboard.Overlay.OverlaySection>
            <InvestmentsDashboard.Overlay.OverlaySection background="Purple" className="py-4 is-rounded-bottom">
                <div className="columns is-mobile">
                    <div className="column">
                        <p className="has-text-purple-blue-500 has-text-weight-medium">Amount</p>
                    </div>
                    <div className="column">
                        <div className="is-fullheight is-flex is-flex-direction-row is-align-items-center is-justify-content-end">
                            <p className="has-text-purple-blue-500 has-text-weight-medium">{ transaction?.amount ? formatCurrency(transaction?.amount) : "-"}</p>
                        </div>
                    </div>
                </div>
            </InvestmentsDashboard.Overlay.OverlaySection>
        </>
    }

    return loader.defaultDisplayUntilLoaded(inner(), "p-4")
}