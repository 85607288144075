import { Outlet } from "react-router-dom";
import { OnboardingContext } from "../OnboardingContext";

export interface CreateAccountLayoutProps {
    children?: React.ReactElement[] | React.ReactElement;
}

export const CreateAccountLayout = (props: CreateAccountLayoutProps) => {
    return (   
        <OnboardingContext>
            { props.children }
            <Outlet />
        </OnboardingContext>
    );
}
