import { Container, Tile } from "react-bulma-components";
import { UserInfo, useAuthApiClient } from "../../../components/Auth/AuthApiClient";
import { useEffect } from "react";
import { useLoader } from "../../../hoc/withLoader";
import { AxiosResponse } from "axios";
import "./Dashboard.scss";
import { LatestArticles } from "../../learn/pages/Learn/components/LatestArticles/LatestArticles";
import { Helmet } from "react-helmet-async";
import { Main } from "../../../components/Layout/Main/Main";
import { PageTitle } from "../../../components/Layout/PageTitle/PageTitle";
import { Stackable } from "../../../components/Layout/Stacking/Stackable";
import { GoalDashboard } from "./components/GoalDashboard/GoalDashboard";
import { Section } from "../../../components/Layout/Section/Section";
import { DashboardTile } from "./components/DashboardTile/DashboardTile";
import Learn from "./learn.svg";
import Connect from "./connect.svg";
import Invest from "./invest.svg";
import { Banner } from "../../../components/Banner/Banner";
import { Info } from "react-feather";
import { CombinedInvestmentsDashboard } from "./components/CombinedInvestmentsDashboard/CombinedInvestmentsDashboard";
import { Glass } from "../../../components/Layout/Glass/Glass";
import investDash from "./invest-dash.png";
import { Link } from "react-router-dom";
import { BasicTile } from "../../../components/Layout/Wrappers/BasicTile";
import { FeatureFlag, isFeatureEnabled } from "../../../helpers/feature-flags";

export const Dashboard = () => {
    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <Main>
                <Container>
                    <ClosedBetaBanner/>
                    <Welcome />
                    <Section>
                        <div className="columns is-desktop">
                            <div className="column is-two-thirds-desktop">
                                {/* <CombinedInvestmentsDashboard /> */}

                                <BasicTile>
                                    <div className="columns">
                                        <div className="column is-6 has-order-1-tablet">
                                            <img src={investDash} alt="Invest dashboard"/>
                                        </div>
                                        <div className="column is-6 has-text-purple-blue-500">
                                            <h3 className="is-size-3 is-size-2-desktop is-family-primary mb-2 has-text-weight-semibold">Invest in funds</h3>
                                            <p className="is-size-5 has-text-weight-semibold">Open an ISA and invest up to £20,000 tax efficiently.</p>
                                            {
                                                isFeatureEnabled(FeatureFlag.Equities)
                                                    ? <Link to="/invest/funds" className="button is-primary mt-4 is-rounded">Start investing</Link>
                                                    : <p className="is-size-4 mt-4 has-text-weight-medium has-text-purple-blue-400">Coming soon!</p>
                                            }
                                        </div>
                                    </div>
                                </BasicTile>
                            </div>
                            <div className="column is-one-third-dekstop">
                                <GoalDashboard />
                            </div>
                        </div>
                    </Section>
                    <Section>
                        <Stackable gap={6} gapOnStack={3}>
                            <DashboardTile 
                                icon={<Learn />}
                                className="has-background-peach-100 is-flex-grow-1" 
                                title="Learn"  
                                description="Want to learn more about budgeting, the foundations of investing or property? Head over to our courses and get started."
                                button={{ text: "View learn", to: "/learn" }}
                                />
                            <DashboardTile 
                                icon={<Connect />}
                                className="has-background-purple-blue-100 is-flex-grow-1" 
                                title="Connect"  
                                description="Meet other like minded women in Propelle's community. Exchange, post, ask, comment and react on all topics money and investments."
                                button={{ text: "View connect", to: "/connect" }}
                                />
                            <DashboardTile 
                                icon={<Invest />}
                                className="has-background-pink-100 is-flex-grow-1" 
                                title="Invest"  
                                description="Determine your financial goals, learn about your risk level and plan your investment strategy."
                                button={{ text: "View invest", to: "/invest/tools" }}
                                />
                        </Stackable>
                    </Section>
                    <Section className="py-3 pb-6">
                        <LatestArticles />
                    </Section>
                </Container>
            </Main>
        </>
    );
}

const Welcome = () => {

    const [getUserInfo] = useAuthApiClient();
    const [loaderState, { load, defaultLoaderDisplay, defaultErrorDisplay }] = useLoader<AxiosResponse<UserInfo, any>>();

    useEffect(() => {
        if (loaderState.loadingState == "NotStarted") {
            load(getUserInfo());
        }

    }, [loaderState.loadingState]);

    if (loaderState.loadingState === "NotStarted" || loaderState.loadingState === "Loading") {
        return <div className="p-6">{defaultLoaderDisplay()}</div>;
    }

    if (loaderState.loadingState === "Failed") {
        return <div className="p-6">{defaultErrorDisplay()}</div>;
    }

    return (
        <PageTitle text={`Hi ${loaderState.response?.data.firstName}, welcome back 👋`} />
    );
}

const ClosedBetaBanner = (props: { className?: string, onClose?: () => void }) => {

    return (
        <div className={"community-guidelines mb-3 mb-5" + (props.className ? ` ${props.className}` : "") }>
            <Banner type="Info" isPermanent={true}>
                <div className="is-flex is-flex-direction-row is-align-items-center is-flex-gap-4">
                    <Info color="#1B0777" height="24px" width="24px" style={{minHeight: "24px", minWidth: "24px" }}/>
                    <p className="has-text-purple-blue-500 has-text-weight-medium">We’re currently in a closed beta. If you notice anything that doesn’t look right, let us know at <a className="has-text-purple-blue-500 is-underlined" href="mailto:feedback@propelle.io">feedback@propelle.io</a></p>
                </div>
            </Banner>
        </div>
    )
}