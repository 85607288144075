import { useRef, useState } from "react";
import { PropertyClientFactory } from "../../../../services/PropertyClientFactory";
import {
    InvestorCertificationSelfCertifiedInvestorType as InvestorType,
    InvestorCertificationSubmitSelfCertification_Request_Root as SelfCertificationRequest,
    PropellePropertyApplicationDomainInvestorCertificationCertifiedConsideration as Consideration,
    InvestorCertificationSubmitSelfCertification_Request_SelfCertificationConsideration as SelfCertificationConsideration
} from "../../../../services/generated/PropertyClient"
import { Section } from "../../../../../../components/Layout/Section/Section";
import { BasicOption } from "./BasicOption";
import { ExtraSelectOption } from "./ExtraSelectOption";
import { Button } from "react-bulma-components";

interface ISelfCertificationFormProps {
    afterSubmit: (isEligible: boolean) => void;
}

export const SelfCertificationForm = ({afterSubmit} : ISelfCertificationFormProps) => {
    const [isFormValid, setIsFormValid] = useState(false);
    const [showExtraOptions, setShowExtraOptions] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);
    let client = PropertyClientFactory();

    const optionSelected = (isOptionValid: boolean, investorType?: string) => {
        setIsFormValid(isOptionValid);

        if (investorType) {
            setShowExtraOptions(investorType === InvestorType.Restricted.toString());
        }
    }

    const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setIsSubmitting(true);
        if (!isFormValid) {
            return;
        }

        const form = formRef.current!;
        const investorType = parseInt(form.investorType.value) as InvestorType;
        let request = new SelfCertificationRequest({
            selfCertifiedInvestorType: investorType
        });

        if (investorType == InvestorType.Restricted) {
            request.selfCertificationConsiderations = [];
            request.selfCertificationConsiderations = [new SelfCertificationConsideration({
                consideration: Consideration.PreviouslyInvestedMoreThan10PercentInLast12Months,
                isApplicable: form.last12Months.value === "true"
            }),
            new SelfCertificationConsideration ({
                consideration: Consideration.WillInvestMoreThan10PercentInNext12Months,
                isApplicable: form.next12Months.value === "true"
            })];
        }

        let response = await client.investorCertificationSubmitSelfCertification(request);
        setIsSubmitting(false);

        afterSubmit(response.isEligibleForPropertyInvestment ?? false);
    }

    return (
        <>
            <h3 className="has-text-purple-blue-500 is-size-5 is-family-primary has-text-weight-semibold">In line with FCA requirements, you must confirm you fit a certain investor category to be able to invest in these types of property investments.</h3>
            <Section className="py-3">
                <form ref={formRef} onSubmit={submitForm}>
                    <BasicOption option="I am a High Net Worth Investor (HNWI)" value={InvestorType.HighNetWorth} onChange={optionSelected}>
                        <ul className="my-0">
                            <li>I earned over £100,000 net or I own more than £250,000 in net assets. Net assets don’t include: your home (primary residence), your pension or any rights under qualifying contracts of insurance.</li>
                        </ul>
                    </BasicOption>
                    <BasicOption option="I consider myself a Sophisticated Investor" value={InvestorType.Sophisticated} onChange={optionSelected}>
                        <ul className="my-0">                                         
                            <li>I have been a director of a company turning over at least £1 million within the last two years or,</li>
                            <li>I am a member of a network or syndicate of business angels and have been so for at least the last six months or,</li>
                            <li>I have made more than one investment in an unlisted company in the last two years or,</li>
                            <li>I am working in a professional capacity in the private equity sector, or in the provision of finance for small and medium enterprises</li>
                        </ul>
                    </BasicOption>
                    <ExtraSelectOption option="I am none of the above/ I am a Restricted Investor" value={InvestorType.Restricted} onChange={optionSelected} showExtraOptions={showExtraOptions}>
                        <ul className="my-0">
                            <li>If you don’t fall under the two categories of High Net Worth investor (HNWI) or Sophisticated Investor, you are considered a Restricted Investor.</li>
                        </ul>
                    </ExtraSelectOption>
                    <Button type="submit" loading={isSubmitting} className="button is-primary mt-5 is-rounded" disabled={!isFormValid || isSubmitting}>Confirm</Button>
                </form>
            </Section>
        </>
    );
}