import { Helmet } from "react-helmet-async"
import { Main } from "../../components/Layout/Main/Main"
import { Container } from "react-bulma-components"
import { Content } from "../../components/Layout/Content/Content"
import { Section } from "../../components/Layout/Section/Section"
import { useContext } from "react"
import { ScreenSize, WindowContext } from "../../components/App/WindowContext"
import { Link } from "react-router-dom"

export const Cancelled = () => {
    const { screenSize } = useContext(WindowContext);

    return (
        <>
            <Helmet>
                <title>Propelle Pro - Cancelled</title>
            </Helmet>
            <Main>
                <Container>
                    <Section>
                        <Content>
                            <p className="has-text-purple-blue-500 has-text-weight-semibold is-size-5-desktop">Propelle Pro Cancelled</p>
                        </Content>
                        <Content>
                            <h1 className={`title ${screenSize > ScreenSize.Tablet ? "is-2" : "is-3"} has-text-weight-bold has-text-purple-blue-500`}>We'll miss you as a Pro Member 😢</h1>
                        </Content>
                        <Content>
                            <p>You have successfully cancelled your Propelle Pro Membership.</p>
                        </Content>
                        <Content>
                            <p>We hope this isn't goodbye forever. As you continue your investment journey with our free tier, please know that you'll still have access to a range of great features and content.</p>
                        </Content>
                        <Content>
                            <p>If there was anything specific that led to your decision to cancel your Pro Membership, we would greatly appreciate your feedback. Please let us know by dropping us an email at <a href="mailto:support@propelle.io">support@propelle.io</a>. Your insights will help us improve and better cater to the needs and expectations of all our members. </p>
                        </Content>
                        <Content>
                            <p>If you change your mind and wish to re-activate your Propelle Pro Membership, just know our virtual doors are always open.</p>
                        </Content>
                    </Section>
                    <Section>
                        <Content>
                            <Link to="/" className="button is-primary is-rounded">Return to dashboard</Link>
                        </Content>
                    </Section>
                </Container>
            </Main>
        </>
    )
}