import { useContext, useEffect, useState } from "react";
import { LoaderFunction, useLoaderData } from "react-router-dom";
import { ArticleParams, Embed, WpPostTypeRequest, useAuthenticatedCmsClient } from "../../../../components/Auth/AuthenticatedCmsClient";
import { BackButton } from "../../../../components/BackButton/BackButton";
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import './Article.scss';
import { ArticleCardProps } from "../../../../components/Cards/ArticleCard";
import { useWpLoader } from "../../../../hoc/withLoader";
import AudioPlayer from 'react-modern-audio-player';
import { RelatedContentCarousel } from "../../../../components/ContentCarousel/RelatedContentCarousel";
import { Helmet } from "react-helmet-async";
import { ImageSize, getImageUrl } from "../../../../helpers/cms";
import { FeatureFlag, isFeatureEnabled } from "../../../../helpers/feature-flags";
import { AuthHelper } from "../../../../components/Auth/AuthHelper";
import { Glass } from "../../../../components/Layout/Glass/Glass";
import { Main } from "../../../../components/Layout/Main/Main";
import { Container } from "react-bulma-components";
import { WindowContext } from "../../../../components/App/WindowContext";

dayjs.extend(AdvancedFormat);

export const loader: LoaderFunction = async ({ params }) => {

    let client = useAuthenticatedCmsClient();

    let articleResponse = await client!.get(new WpPostTypeRequest({
        url: "posts",
        params: new ArticleParams({
            slug: params.articleSlug,
            _embed: [Embed.Author]
        })
    }));

    const membership = articleResponse.data[0].membership;

    if (membership && !membership.hasAccess) {
        throw new Response("Sorry, this content is not available. Upgrade if you'd like to view it.", { status: 403 });
    }

    try {
        let article : any = articleResponse.data[0];

        return { article: article };

    } catch {
        throw new Error("This article doesn't exist.");
    }
}

interface LoaderData {
    article : any
}

export const Article = () => {
    const { article } = useLoaderData() as LoaderData;
    const [loaderState, { load, defaultLoaderDisplay }] = useWpLoader();
    const [ isListening, setIsListening ] = useState<boolean>(false);
    const { onWindowResize } = useContext(WindowContext);

    const handleListenClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setIsListening(true);
    }

    const setVideoHeight = () => {
        document.querySelectorAll('.wp-block-embed__wrapper iframe').forEach((iframe) => {
            let height = iframe.clientWidth * (9/16);

            iframe.setAttribute('style', `height: ${height}px`);    
        });
    }

    useEffect(() => {
        if (loaderState.loadingState === "NotStarted"
                && article.acf.related_articles !== null
                && article.acf.related_articles.length > 0) {
            load(new WpPostTypeRequest({
                url: "posts",
                params: new ArticleParams({
                    include: article.acf.related_articles,
                })
            }))
        }

        // Make sure all cms links open in new tab
        document.querySelectorAll('a[class^="wp"]').forEach((link) => {
            link.setAttribute('target', '_blank');
        });

        setVideoHeight();
    }, []);

    onWindowResize(() => {
        setVideoHeight();
    });

    return (
        <>
            <Helmet>
                <title>Article - { article.title }</title>
            </Helmet>
            <Main className="article">
                <Container>
                    <BackButton className="mb-4"/>
                    <Glass>
                            <p className="mb-3">{dayjs(article.date).format('Do MMMM YYYY')}</p>
                            <h1 className="title is-4">{article.title}</h1>
                            <div className="is-flex is-align-items-center mb-4 article-info">
                                <div className="author is-flex is-align-items-center is-flex-grow-1">
                                    <img className="mr-4" src={getImageUrl(article._embedded.author[0].acf.profile_picture, ImageSize.Medium)} alt={article._embedded.author[0].name}/>
                                    <span>{article._embedded.author[0].name}</span>
                                </div>
                                <div className="mr-4 is-white-space-nowrap">{article.acf.duration} mins {article.categories.some((cat : any) => cat.slug === "videos") > 0 ? "watch" : "read"}</div>
                                { article.acf.audio_transcription
                                    && <div className="audio mt-4 mt-0-tablet">
                                        { !isListening && <a href="#" onClick={handleListenClick}><FontAwesomeIcon icon={faCirclePlay} /> Listen here</a>}
                                            { isListening && <AudioPlayer
                                                audioInitialState={{
                                                    isPlaying: isListening,
                                                    curPlayId: 1
                                                }}
                                                playList={[{
                                                        src: article.acf.audio_transcription,
                                                        id: 1,
                                                    }]} 
                                                activeUI={{
                                                    playButton: true,
                                                    progress: 'bar',
                                                    trackTime: true,
                                                    prevNnext: true
                                                }}
                                                rootContainerProps={{colorScheme: 'light'}}    

                                                />
                                            }
                                        </div>
                                    }
                            </div>
                            <div className="wordpress-content" dangerouslySetInnerHTML={{__html:article.content.rendered}}/>
                            { (article.acf.related_articles !== null && article.acf.related_articles.length > 0) &&
                                <div className="related-content pt-5">
                                    { (loaderState.loadingState === "NotStarted" || loaderState.loadingState === "Loading") && <>{ defaultLoaderDisplay() }</> }
                                    { (loaderState.response) &&
                                        <RelatedContentCarousel title="Related content" contents={loaderState.response.map((x : any) : ArticleCardProps & { url: string } => {
                                            return {
                                                id: x.id,
                                                header: x.title,
                                                url: `/learn/article/${x.slug}`,
                                                imageUrl: getImageUrl(x.acf.thumbnail, ImageSize.Medium),
                                                duration: x.acf.duration
                                            }
                                        })}/>
                                    }
                                </div>
                            }
                    </Glass>
                </Container>
            </Main>
        </>
    );
}