import { Card } from "react-bulma-components";
import { CssClasses } from "../../Helpers/CssClasses";
import { Info } from "../../Info/Info";
import "./PropertyOpportunity.scss";
import { Lock } from "react-feather";
import { PropertyClientFactory } from "../../../areas/invest/services/PropertyClientFactory";
import { AuthHelper } from "../../Auth/AuthHelper";

export interface IPropertyOpportunityProps {
    id: string;
    link: string;
    status: string;
    locked: boolean;
    imageUrl: string;
    header: string;
    returnRatePerYear: number;
    timeHorizonMonths: number;
    dealType: string;
    provider: string;
}

export const PropertyOpportunity = ({id, link, status, imageUrl, header, returnRatePerYear, timeHorizonMonths, dealType, provider, locked}: IPropertyOpportunityProps) => {

    const propertyClient = PropertyClientFactory();
    const auth = AuthHelper.getUserData();

    const cssClasses = new CssClasses(["event-card card-wrapper"]);
    if (link && !locked) {
        cssClasses.add("is-clickable");
    }

    const tag = () => {

        if (status || locked) {
            const classes = new CssClasses(["tag", "has-text-weight-medium", "is-size-smaller", "mr-3"]);
            if (locked) {
                classes.add("has-background-gradient-peach has-text-purple-blue-500 px-2");
            } else {
                classes.add("has-background-purple-blue-300 has-text-white px-3");
            }

            return (
                <span className={`${classes.aggregated()}`}>
                    { locked ? 
                        <>
                            <Lock style={{height: '20px'}}/>
                            Locked
                        </>
                        : status
                    }
                </span>
            )
        }

        return <></>
    }

    const inner = (
        <>
            <Card className="is-relative has-background-white p-3">
                { tag() }
                <Card.Image src={imageUrl} size="3by1">
                </Card.Image>
                <Card.Header className={`match-height px-1 is-fullwidth ${locked ? "locked" : ""}`}>
                    <p className="is-family-secondary has-text-black is-size-4 border-bottom is-fullwidth py-1">{header}</p>
                </Card.Header>
                <Card.Content className={`px-1 pb-3 pt-1 ${locked ? "locked" : ""}`}>
                    <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center my-2">
                        <div className="has-text-purple-blue-500 has-text-weight-semibold px-0">
                            <span className="pr-1">Return rate</span>
                            <Info notificationClassName="is-fullwidth">
                                <p>This is the expected return you'll make on your investment. If it is a peer to peer loan, the Exp. return is what is expected per year. This is not guaranteed.</p>
                            </Info>
                        </div>
                        <span className="has-text-right px-0">{returnRatePerYear}% per year</span>
                    </div>
                    <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center my-2">
                        <div className="has-text-purple-blue-500 has-text-weight-semibold px-0">
                            <span className="pr-1">Time horizon</span>
                            <Info notificationClassName="is-fullwidth">
                                <p>This indicates the planned duration of the project.</p>
                            </Info>
                        </div>
                        <span className="has-text-right px-0">{timeHorizonMonths} months</span>
                    </div>
                    <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center my-2">
                        <div className="has-text-purple-blue-500 has-text-weight-semibold px-0">
                            <span className="pr-1">Deal type</span>
                            <Info notificationClassName="is-fullwidth">
                                <p>Currently, property deals can be of two types - peer to peer lending (P2P , also known as property debt) or property equity (also known as crowdfunding).</p>
                            </Info>
                        </div>
                        <span className="has-text-right px-0">{dealType}</span>
                    </div>
                    <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center my-2">
                        <div className="has-text-purple-blue-500 has-text-weight-semibold px-0">
                            <span className="pr-1">Provider</span>
                            <Info notificationClassName="is-fullwidth">
                                <p>This is the investment platform that has made the opportunity available.</p>
                            </Info>
                        </div>
                        <span className="has-text-right px-0">{provider}</span>
                    </div>
                </Card.Content>
            </Card>
        </>
    )

    const onLinkClicked = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        await propertyClient.trackCustomerProfile(auth.id ?? '');
    }

    return (
        link
            ? <a href={link} className={`${cssClasses.aggregated()} property-opportunity card-wrapper ${locked ? "blocked" : ""}`} onClick={onLinkClicked} target="_blank" key={id}>
                { inner }
            </a>
            : <div className={`${cssClasses.aggregated()} property-opportunity card-wrapper ${locked ? "blocked" : ""}`} key={id}>
                 { inner }
            </div>
    )
}