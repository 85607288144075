import "./Chat.scss";
import { createContext, useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { Script } from "./Script";
import { Dialog } from "./Dialog";

export interface PathState {
    pathId: number;
    invalidInputs?: Array<{label: string, value: string, validationMessage: string}>;
    validInput?: { label: string, value: string };
    waitingForUserInput: boolean;
}

export interface ChatState {
    previousPaths: Array<PathState>,
    currentPath: PathState,
    onWaitingForUserInput?: () => void;
    onAnswerReceived?: (label: string, value: string, nextPath?: number) => void;
    onInvalidAnswerReceived?: (label: string, input: string, validationMessage: string) => void;
    onFinished?: () => void;
}

export const DefaultChatState = () : ChatState => {
    return {
        previousPaths: Array<PathState>(),
        currentPath: { pathId: 1, waitingForUserInput: false },
        isFinished: false
    } as ChatState;
}

export const ChatContext = createContext(DefaultChatState());

export interface ChatProps {
    script: Script;
    onAnswerReceived?: (pathId: number, label: string, value: string) => void;
    validateAnswer?: (pathId: number, value: string) => { isInvalid: boolean, invalidPathId: number };
    onFinished?: () => void;
}

export function Chat(props: ChatProps) {

    const [chatState, setChatState] = useState(DefaultChatState());

    function onWaitingForUserInput() {
        setChatState(state => {
            let newState = cloneDeep(state);
            newState.currentPath.waitingForUserInput = true;

            return newState;
        })
    }

    function onAnswerReceived(label: string, value: string, nextPath?: number) {
        setChatState(state => {
            let newState = cloneDeep(state);

            newState.currentPath.validInput = { label, value };
            newState.previousPaths.push(newState.currentPath);
            newState.currentPath = { pathId: nextPath ? nextPath : state.currentPath.pathId + 1, waitingForUserInput: false };

            let validationResult = props.validateAnswer 
                ? props.validateAnswer(state.currentPath.pathId, value)
                : { isInvalid: false, invalidPathId: 0 };

            if (validationResult.isInvalid) {
               
                newState.currentPath = { pathId: validationResult.invalidPathId, waitingForUserInput: false };
                return newState;
            }

            return newState;
        });

        props.onAnswerReceived?.(chatState.currentPath.pathId, label, value);
    }

    function onInvalidAnswerReceived(label: string, value: string, validationMessage: string) {
        setChatState(state => {
            let newState = cloneDeep(state);
            
            if (!newState.currentPath.invalidInputs) {
                newState.currentPath.invalidInputs = new Array<{label: string, value: string, validationMessage: string}>();
            }

            newState.currentPath.invalidInputs.push({label, value, validationMessage});
            newState.currentPath.waitingForUserInput = false;

            return newState;
        });
    }

    function onFinished() {
        setChatState(state => {
            let newState = cloneDeep(state);

            if (newState.currentPath.pathId >= 400) {
                // This is a non successful final state. Don't set the request or update the state to finished
                return newState;
            }

            return newState;
        });

        props.onFinished?.();
    }

    return (
        <div className="chat">
            <ChatContext.Provider value={{ ...chatState, onAnswerReceived: onAnswerReceived, onInvalidAnswerReceived: onInvalidAnswerReceived, onWaitingForUserInput: onWaitingForUserInput, onFinished: onFinished }}>
                <div className="chat-dialog">
                    <Dialog script={props.script}/>
                </div>
            </ChatContext.Provider>                    
        </div>
    )
}

export interface ChatMessageProps {
    children?: React.ReactNode;
    isBot?: Boolean;
    onFinishedTyping?: () => void;
}

export function ChatMessage(props: ChatMessageProps) {

    const loadingTime: number = 1000;
    const [isLoading, setIsLoading] = useState(true);

    let messageLineClasses = props.isBot ? "chat-message-line is-flex" : "chat-message-line is-flex is-flex-direction-row-reverse";
    let messageClasses = props.isBot ? "chat-message bot is-flex is-align-items-center is-justify-content-center" : "chat-message customer is-flex is-align-items-center is-justify-content-center";

    useEffect(() => {
        if (props.isBot && isLoading) {
            setTimeout(() => {
                setIsLoading(false);
            }, loadingTime)
        } else {
            props.onFinishedTyping?.()
        }
    }, [isLoading]);

    if (props.isBot && isLoading) {
        return (
            <div className={messageLineClasses}>
                <div className={messageClasses + " loading"}>
                    <p className="has-text-centered is-flex">
                        <span>•</span>
                        <span>•</span>
                        <span>•</span>
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div className={messageLineClasses}>
            <div className={messageClasses}>
                <p>{props.children}</p>
            </div>
        </div>
    )
}
