import { Columns } from "react-bulma-components";
import { Link } from "react-router-dom";
import { Footer } from "../../../../components/Layout/Footer/Footer";
import { WealthMappingNavbar } from "../../components/WealthMappingNavbar/WealthMappingNavbar";
import { LeftFaces, RightFaces } from "../../components/Faces/Faces";
import "./Result.scss";
import { useContext, useEffect } from "react";
import { WealthMappingContext } from "../../WealthMappingContext";
import { WealthMappingClientFactory } from "../../services/WealthMappingClientFactory";
import { CreateWealthPicture_Request, CreateWealthPicture_Response, GetWealthMappingOverview_Response, GetWealthMappingOverview_Response_WealthPictureResponse, GetWealthMappingOverview_Response_WealthPictureResponse_GoalResponse_GoalType } from "../../services/generated/WealthMappingClient";
import { getQualitativeGoal } from "../Calculator/components/QualitativeGoal/QualitativeGoal";
import { Helmet } from "react-helmet-async";
import { useLoader } from "../../../../hoc/withLoader";

export const getQualitativeGoalFromRequest = (request?: CreateWealthPicture_Request) => {
    if (request?.financialIndependence) {
        return getQualitativeGoal({ type: "FinancialIndependence"});
    } else if (request?.financialSecurity) {
        return getQualitativeGoal({ type: "FinancialSecurity"});
    } else if (request?.qualityOfLife) {
        return getQualitativeGoal({ type: "QualityOfLife"});
    } else if (request?.specificEvent) {
        return getQualitativeGoal({ type: "SpecificEvent", event: request.specificEvent.description });
    } else if (request?.retirement) {
        return getQualitativeGoal({ type: "Retirement" });
    }

    return undefined;
}

export const getQualitativeGoalFromResponse = (response?: GetWealthMappingOverview_Response_WealthPictureResponse) => {
    switch (response?.outputs?.goal?.type) {
        case GetWealthMappingOverview_Response_WealthPictureResponse_GoalResponse_GoalType.FinancialIndependence:
            return getQualitativeGoal({ type: "FinancialIndependence"});
        case GetWealthMappingOverview_Response_WealthPictureResponse_GoalResponse_GoalType.FinancialSecurity:
            return getQualitativeGoal({ type: "FinancialSecurity"});
        case GetWealthMappingOverview_Response_WealthPictureResponse_GoalResponse_GoalType.QualityOfLife:
            return getQualitativeGoal({ type: "QualityOfLife"});
        case GetWealthMappingOverview_Response_WealthPictureResponse_GoalResponse_GoalType.SpecificEvent:
            return getQualitativeGoal({ type: "SpecificEvent", event: response?.outputs?.goal?.customDescription});
        case GetWealthMappingOverview_Response_WealthPictureResponse_GoalResponse_GoalType.Retirement:
            return getQualitativeGoal({ type: "Retirement"});
        default:
            return undefined;
    }
}

export const getQuantativeGoalFromRequest = (request?: CreateWealthPicture_Request, response?: CreateWealthPicture_Response) => {

    let inputs = {
        lumpSumRequired: response?.lumpSumRequired ?? 0,
        age: request?.age ?? 0,
        financialIndependence: request?.financialIndependence
            ? { percentageWorkReduction: request.financialIndependence.percentageWorkReduction ?? 0, inYears: request.financialIndependence.years ?? 0 }
            : undefined,
        financialSecurity: request?.financialSecurity
            ? { inYears: request.financialSecurity.years ?? 0 }
            : undefined,
        qualityOfLife: request?.qualityOfLife
            ? { extraMonthlyIncome: request.qualityOfLife.extraMonthlyIncome ?? 0, inYears: request.qualityOfLife.years ?? 0 }
            : undefined,
        specificEvent: request?.specificEvent
            ? { description: request.specificEvent.description ?? '', inYears: request.specificEvent.years ?? 0 }
            : undefined,
        retirement: request?.retirement
            ? { desiredSalary: request.retirement.salary ?? 0 }
            : undefined
    };

    return quantativeGoal(inputs);
}

export const getQuantativeGoalFromResponse = (response?: GetWealthMappingOverview_Response_WealthPictureResponse) => {
    let inputs = {
        lumpSumRequired: response?.outputs?.lumpSumRequired ?? 0,
        age: response?.inputs?.age ?? 0,
        financialIndependence: response?.inputs?.financialIndependence
            ? { 
                percentageWorkReduction: response.inputs.financialIndependence.percentageWorkReduction ?? 0, 
                inYears: response.inputs.financialIndependence.years ?? 0 
            }
            : undefined,
        financialSecurity: response?.inputs?.financialSecurity
            ? { 
                inYears: response.inputs.financialSecurity.years ?? 0 
            }
            : undefined,
        qualityOfLife: response?.inputs?.qualityOfLife
            ? { 
                extraMonthlyIncome: response.inputs.qualityOfLife.extraMonthlyIncome ?? 0, 
                inYears: response.inputs.qualityOfLife.years ?? 0 }
            : undefined,
        specificEvent: response?.inputs?.specificEvent
            ? { 
                description: response.inputs.specificEvent.description ?? '', 
                inYears: response.inputs.specificEvent.years ?? 0 
            }
            : undefined,
        retirement: response?.inputs?.retirement
            ? { 
                desiredSalary: response.inputs.retirement.salary ?? 0 
            }
            : undefined
    };

    return quantativeGoal(inputs);
}

const quantativeGoal = (inputs: { 
    lumpSumRequired: number,
    age: number,
    financialIndependence?: {
        percentageWorkReduction: number,
        inYears: number
    },
    financialSecurity?: {
        inYears: number
    },
    qualityOfLife?: {
        extraMonthlyIncome: number,
        inYears: number
    },
    specificEvent?: {
        description: string,
        inYears: number
    },
    retirement?: {
        desiredSalary: number
    }
}) => {

    const currencyFormatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        maximumFractionDigits: 0
    });

    const percentFormatter = new Intl.NumberFormat('en-GB', {
        style: 'percent',
        maximumFractionDigits: 0
    })

    if (inputs?.financialIndependence) {
        return (
            <>
                Based on all the information you have shared and our calculations: 
                <strong>{ " you'll need to accumulate " + currencyFormatter.format(inputs.lumpSumRequired)  + " to reduce your working hours by " + percentFormatter.format(inputs.financialIndependence.percentageWorkReduction) + " when you are " + ((inputs.age ?? 0) + (inputs.financialIndependence.inYears ?? 0 )).toString()  + " years old" }</strong>
            </>);
    } else if (inputs?.financialSecurity) {
        return (
            <>
                Based on all the information you have shared and our calculations:
                <strong>{ " you want to save " + currencyFormatter.format(inputs.lumpSumRequired)  + " to feel more financially secure when you are " + ((inputs.age) + (inputs.financialSecurity.inYears)).toString() + " years old" }</strong>
            </>);
    } else if (inputs?.qualityOfLife) {
        return (
            <>
                Based on all the information you have shared and our calculations:
                <strong>{ " you'll need to have a " + currencyFormatter.format(inputs.lumpSumRequired)  + " lump sum to increase your quality of life by earning an extra " + currencyFormatter.format(inputs.qualityOfLife.extraMonthlyIncome) + " per month when you are " + ((inputs.age ?? 0) + (inputs.qualityOfLife.inYears)).toString() + " years old" }</strong>
            </>);
    } else if (inputs?.specificEvent) {
        return (
            <>
                Based on all the information you have shared and our calculations:
                <strong>{ " you want to save " + currencyFormatter.format(inputs.lumpSumRequired)  + " so you can then put it towards " + inputs.specificEvent.description + " when you are " + ((inputs.age) + (inputs.specificEvent.inYears)).toString() + " years old" }</strong>
            </>);
    } else if (inputs?.retirement) {
        return (
            <>
                Based on all the information you have shared and our calculations:
                <strong>{ " you'll need to have a " + currencyFormatter.format(inputs.lumpSumRequired)  + " lump sum so you can pay yourself a salary of " + currencyFormatter.format(inputs.retirement.desiredSalary) + " when you retire" }</strong>
            </>);
    }
    else {
        return <></>;
    }
}

export function Result() {

    const wealthMappingContext = useContext(WealthMappingContext);
    const wealthMappingClient = WealthMappingClientFactory();
    const calculatorNavigation = wealthMappingClient.isLoggedIn() ? "/invest/tools/goal-planning" : "/wealth-mapping/calculator";
    const [wealthMappingOverview, wealthMappingOverviewLoaderFunctions] = useLoader<GetWealthMappingOverview_Response>();

    useEffect(() => {
        if (!wealthMappingContext.wealthMappingData?.wealthPictureResponse && wealthMappingClient.isLoggedIn() && wealthMappingOverview.loadingState === "NotStarted") {
            wealthMappingOverviewLoaderFunctions.load(wealthMappingClient.getWealthMappingOverview());
        }
    }, [wealthMappingContext.wealthMappingData?.wealthPictureResponse, wealthMappingOverview.loadingState])

    if (!wealthMappingContext.wealthMappingData?.wealthPictureResponse && wealthMappingOverview.loadingState !== "Loaded") {
        if (wealthMappingOverview.loadingState === "Failed") {
            return <>{wealthMappingOverviewLoaderFunctions.defaultErrorDisplay()}</>;
        }

        return <div style={{height: "100vh", width: "100vw"}} className="is-position-fixed is-flex is-flex-direction-row is-justify-content-center">
            {wealthMappingOverviewLoaderFunctions.defaultLoaderDisplay()}
        </div>;
    }

    const qualitativeGoal = wealthMappingContext.wealthMappingData?.wealthPictureRequest
        ? getQualitativeGoalFromRequest(wealthMappingContext.wealthMappingData.wealthPictureRequest)
        : wealthMappingOverview.response?.wealthPicture
            ? getQualitativeGoalFromResponse(wealthMappingOverview.response.wealthPicture)
            : '';

    const quantativeGoal = wealthMappingContext.wealthMappingData?.wealthPictureRequest
        ? getQuantativeGoalFromRequest(wealthMappingContext.wealthMappingData.wealthPictureRequest, wealthMappingContext.wealthMappingData.wealthPictureResponse)
        : wealthMappingOverview.response?.wealthPicture
            ? getQuantativeGoalFromResponse(wealthMappingOverview.response.wealthPicture)
            : '';

    return (
        <>
            <Helmet>
                <title>Wealth Mapping - Result</title>
            </Helmet>
            <div className="result">
                <WealthMappingNavbar />
                <Columns className="is-desktop">
                    <Columns.Column size={3}>
                        <LeftFaces />
                    </Columns.Column>
                    <Columns.Column>
                        <h5 className="title is-5 mt-5 pt-5 has-text-centered">Your goal is to</h5>
                        <h2 className="goal title is-2 has-text-centered">
                            <span className="decoration top-left"></span>
                            <span className="decoration top-right"></span>
                            { qualitativeGoal }
                            <span className="decoration bottom-left"></span>
                            <span className="decoration bottom-right"></span>
                        </h2>
                        <p className="mt-8 pt-3 mx-5 has-text-centered has-text-navy">
                            { quantativeGoal }
                        </p>
                        <p className="pt-8 mx-5 has-text-centered has-text-navy">
                            We've built a calculator to show you how you might achieve this.
                        </p>
                        <p className="mt-4 mx-5 has-text-centered">
                            <Link className="button is-primary is-rounded" to={calculatorNavigation}>Use calculator</Link>
                        </p>
                    </Columns.Column>
                    <Columns.Column size={3}>
                        <RightFaces />
                    </Columns.Column>
                </Columns>
                <Columns className="is-desktop">
                    <Columns.Column size={6} offset={3}>
                        <div className="mb-8 px-2">
                            <p className="has-text-grey-600">Disclaimer: We've not accounted for your personal tax circumstance and other unique variables to you. These figures are for educational purposes to give you a guide and should not be taken as financial advice.</p>
                        </div>
                    </Columns.Column>
                </Columns>
            </div>
            <div className="footer-container">
                <Footer />
            </div>
        </>
    )
}