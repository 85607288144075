import { useContext, useEffect, useState } from 'react';
import { Menu } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import { Chevron } from '../Chevron/Chevron';
import { CssClasses } from '../Helpers/CssClasses';
import { LocationContext } from '../Location/LocationContext';
import './PropelleMenu.scss';
import { FeatureFlag, isFeatureEnabled } from '../../helpers/feature-flags';

export const PropelleMenu = () => {
    const [isExpanded, setIsExpanded] = useState({
        invest: false,
        learn: false,
        connect: false
    });

    // This brittle useEffect logic can be removed layouts do not cause this component to be re-rendered 
    const context = useContext(LocationContext); 
    useEffect(() => {
        setIsExpanded({
            learn: context.path.startsWith("/learn"),
            invest: context.path.startsWith("/invest"),
            connect: context.path.startsWith("/connect")
        });
    }, [context.path])

    return (
        <div className="menu-container">
            <Menu>
                <Menu.List>
                    <MenuItem name="Home" route="/" />
                    <MenuItem name="Invest" route="/invest" onToggleExpanded={() => setIsExpanded({invest: !isExpanded.invest, learn: false, connect: false})} isExpanded={isExpanded.invest} isParent={true}>
                        <Menu.List className={ isExpanded.invest ? "is-expanded": undefined}>
                            {
                                isFeatureEnabled(FeatureFlag.Equities) &&
                                    <MenuItem name="Funds" route="/invest/funds"></MenuItem>
                            }
                            <MenuItem name="Tools" route="/invest/tools"></MenuItem>
                            {
                                isFeatureEnabled(FeatureFlag.Property) &&
                                    <MenuItem name="Property" route="/invest/property"></MenuItem>
                            }
                        </Menu.List>
                    </MenuItem>
                    <MenuItem name="Learn" route="/learn" onToggleExpanded={() => setIsExpanded({invest: false, learn: !isExpanded.learn, connect: false})} isExpanded={isExpanded.learn} isParent={true}>
                        <Menu.List className={ isExpanded.learn ? "is-expanded": undefined}>
                            <MenuItem name="Blog" route="/learn/blog"></MenuItem>
                            <MenuItem name="Courses" route="/learn/courses"></MenuItem>
                            <MenuItem name="Videos" route="/learn/videos"></MenuItem>
                            <MenuItem name="Guides" route="/learn/guides"></MenuItem>
                        </Menu.List>
                    </MenuItem>
                    {
                        isFeatureEnabled(FeatureFlag.Membership)
                            ? <MenuItem name="Connect" route="/connect" onToggleExpanded={() => setIsExpanded({invest: false, learn: false, connect: !isExpanded.connect})} isExpanded={isExpanded.connect} isParent={true}>
                                <Menu.List className={ isExpanded.connect ? "is-expanded": undefined}>
                                    {/* <MenuItem name="Feed" route="/connect/feed"></MenuItem> */}
                                    <MenuItem name="Events" route="/connect/events"></MenuItem>
                                </Menu.List>
                            </MenuItem>
                            :  <MenuItem name="Connect" route="/connect" />
                    }
                    
                </Menu.List>
            </Menu>
        </div>
    )
}

interface MenuItemProps {
    name: string;
    route: string;
    isParent?: boolean;
    children?: React.ReactElement;
    isExpanded?: boolean;
    onToggleExpanded?: () => void;
}

const MenuItem = (props: MenuItemProps) => {

    const isActive = () => {
        if (props.route === context.path) {
            return true;
        }

        if (props.isParent && context.path.startsWith(props.route)) {
            return true;
        }

        return false;
    }

    const context = useContext(LocationContext);
    const cssClasses = new CssClasses();

    if (isActive()) {
        cssClasses.add("is-active");
    }

    if (props.children) {

        cssClasses.add("has-children");

        return (
            <>
                <span></span>
                <li className={cssClasses.aggregated()}>
                    <p className="is-relative">
                        <span className="is-overlay is-flex is-align-items-center is-justify-content-end" onClick={_ => props.onToggleExpanded?.()}>
                            <Chevron direction={props.isExpanded ? "Up" : "Down"} />
                        </span>
                        {props.name}
                    </p>
                    { props.children }
                </li>
            </>
        );
    }

    return (
        <>
            <li className={cssClasses.aggregated()}>
                <Link className="has-text-navy" to={props.route}>{props.name}</Link>
            </li>
        </>
    );
}