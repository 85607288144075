
import { AuthHelper } from "../../../../components/Auth/AuthHelper";
import { WpTaxonomyRequest, useAuthenticatedCmsClient } from "../../../../components/Auth/AuthenticatedCmsClient";

export const categories = async () => {
    let client = useAuthenticatedCmsClient();
    const isStaff = AuthHelper.isPropelleMember();

    try {
        let categoryResponse = await client!.get(new WpTaxonomyRequest({
            url: "categories",
            hide_empty: false,
            params: {
                per_page: 100
            }
        }));

        return { categories: buildCategoryTree(categoryResponse.data, isStaff) };

    } catch {
        throw new Error("Couldn't fetch categories");
    }
}

export const buildCategoryTree = (categories: any[], isStaff: boolean): any[] => {
    const tree = [];

    // Filter out uncategorised and empty categories - except for staff and "always show" categories
    let filteredCategories = categories.filter(x => x.slug !== 'uncategorised' && (isStaff || x.count > 0 || x.acf?.always_show));

    const categoryDict: { [id: string]: any } = {};
    for (const category of filteredCategories) {
        categoryDict[category.id] = category;
    }

    for (const category of filteredCategories) {
        const categoryId = category.id;

        if (category.parent !== 0) {
            const parentId = category.parent;

            if (categoryDict[parentId]) {
                const parent = categoryDict[parentId];
                parent.children = parent.children || [];
                parent.children.push(categoryDict[categoryId]);
            }
        }
        else {
            tree.push(categoryDict[categoryId]);
        }
    }

    return tree;
}