export const defaultFutureValueCalculatorTimeframe = (inputs: {
    age?: number,
    financialIndependence?: { years?: number },
    financialSecurity?: { years?: number },
    qualityOfLife?: { years?: number },
    specificEvent?: { years?: number },
    retirement?: { }
}) => {
    // Set any defaults for the future value request based on the wealth picture request
    let years: number | undefined;

    if (inputs.financialIndependence) {
        years = inputs.financialIndependence.years;
    }

    if (inputs.financialSecurity) {
        years = inputs.financialSecurity.years;
    }

    if (inputs.qualityOfLife) {
        years = inputs.qualityOfLife.years;
    }

    if (inputs.specificEvent) {
        years = inputs.specificEvent.years;
    }

    if (inputs.retirement) {
        years = 67 - (inputs.age ?? 0);
    }

    return years;
}