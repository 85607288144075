import { useAuth, hasAuthParams } from "react-oidc-context";
import { useEffect } from "react";
import { Loader } from "../Loader/Loader";
import { Heading } from "react-bulma-components";
import "./AuthorizedLayout.scss";
import { dataLayer } from "../../helpers/analytics";
import JwtDecode, { JwtPayload } from "jwt-decode";
import { SigninRedirectArgs } from "oidc-client-ts";
import { AuthHelper } from "./AuthHelper";
import { AccountManagementClientFactory } from "../../areas/invest/services/AccountManagementClientFactory";
import { PortfoliosClientFactory } from "../../areas/invest/services/PorfoliosClientFactory";
import { TradingClientFactory } from "../../areas/invest/services/TradingClientFactory";

export interface AuthorizedLayoutProps {
    children?: React.ReactNode;
}

export const AuthorizedLayout = (props: AuthorizedLayoutProps) => {
    const auth = useAuth();

    useEffect(() => {
        if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
            let state = AuthHelper.generateRedirectState();

            const args = {
                ...auth.settings,
                state: state,
            } as SigninRedirectArgs;

            auth.signinRedirect(args);
        }

        if (auth.isAuthenticated) {
            if (auth.user) {
                const jwtPayload = JwtDecode<JwtPayload>(auth.user?.access_token);
                const userId = (jwtPayload as any).propelle_user_id;
                dataLayer.push({ user_id: userId, event: 'token_refresh' });
            }
        }
    }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

    useEffect(() => {
        return auth.events.addUserLoaded(() => {
            // warm up investments
            let accountManagementClient = AccountManagementClientFactory();
            let portfoliosClient = PortfoliosClientFactory();
            let tradingClient = TradingClientFactory();

            const warmUp = async () => {
                console.log("Warming up clients...");
                await accountManagementClient.warmup();
                await portfoliosClient.warmup();
                await tradingClient.warmup();
            };

            warmUp();
        })
    }, [auth.events]);


    const authorizing = () => {
        return (
            <div className="authorizing is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                <Loader />
                <Heading size={2} className="pt-3">Redirecting</Heading>
            </div>
        )
    }

    if (auth.activeNavigator) {
        return authorizing();
    }

    if (!auth.isAuthenticated) {
        return authorizing();
    }

    return (
        <>{props.children}</>
    );
}