interface IPageTitleProps {
    text: string,
    className?: string
}

export const PageTitle = ({ text, className } : IPageTitleProps) => {
    return (
        <h2 className={`page-title-ubuSG5 title is-size-3 is-size-2-desktop has-text-purple-blue-500 mb-4 mt--2 mb-6-desktop mt-0-desktop ${className}`}>
            {text}
        </h2>
    )
}

export const PageTitleWithinGlass = ({ text, className } : IPageTitleProps) => {
    return (
        <h2 className={`page-title-ubuSG5 title is-size-3 is-size-2-desktop has-text-purple-blue-500 mb-4 mb-4-desktop mt-0 ${className}`}>
            {text}
        </h2>
    )
}
