import { Container } from "react-bulma-components";
import { Helmet } from "react-helmet-async";
import RiskToolIcon from "./Assets/risk-tool-icon.tsx.svg";
import GoalPlanningToolIcon from "./Assets/goal-planning-icon.tsx.svg";
import CrowdfundingToolIcon from "./Assets/crowdfunding-tool.tsx.svg";
import { Glass } from "../../../../components/Layout/Glass/Glass";
import { Main } from "../../../../components/Layout/Main/Main";
import { Section } from "../../../../components/Layout/Section/Section";
import { SectionWithSeparator } from "../../../../components/Layout/Section/SectionWithSeparator";
import { Carousel, CarouselItem } from "../../../../components/Carousel/Carousel";
import { isPropelleMember } from "../../../../helpers/auth";
import { ToolsTile } from "./components/ToolsTile/ToolsTile";
import { FeatureFlag, isFeatureEnabled } from "../../../../helpers/feature-flags";

export const Tools = () => {

    const isMember = isPropelleMember();

    return (
        <>
            <Helmet>
                <title>Tools</title>
            </Helmet>
            <Main>
                <Container>
                    <Section>
                        <Glass>
                            <h2 className="title is-size-2-desktop is-size-3-touch has-text-purple-blue-500 has-text-centered has-text-left-desktop">Tools</h2>
                            <SectionWithSeparator title="General Tools">
                                <Carousel type="tile">
                                    <CarouselItem key={"risk-tool"}>
                                        <ToolsTile navigateTo="/invest/tools/risk">
                                            <ToolsTile.Icon icon={<RiskToolIcon height={30}/>} />
                                            <ToolsTile.Title title="Risk tool" />
                                            <ToolsTile.Description description="Complete our risk tool to see what your attitude to risk is." />
                                        </ToolsTile>
                                    </CarouselItem>
                                    <CarouselItem key={"goal-planning-tool"}>
                                    <ToolsTile navigateTo="/invest/tools/goal-planning">
                                        <ToolsTile.Icon icon={<GoalPlanningToolIcon height={30} />} />
                                        <ToolsTile.Title title="Goal planning" />
                                        <ToolsTile.Description description="View, edit, and create a goal here." />
                                    </ToolsTile>
                                    </CarouselItem>
                                </Carousel>
                            </SectionWithSeparator>
                            {
                                isFeatureEnabled(FeatureFlag.Membership) &&
                                    <SectionWithSeparator title="Property Tools" membership={{ hasAccess: isMember, requiresMembership: true }}>
                                        <Carousel type="tile">
                                            <CarouselItem key={"crowdfunding-tool"}>
                                                <ToolsTile navigateTo="/invest/tools/crowdfunding" background="LightPurpleBlue">
                                                    <ToolsTile.Overlay>
                                                        <ToolsTile.Overlay.Badge>
                                                            <p className="has-text-purple-blue-300 is-size-7">PRO FEATURE</p>
                                                        </ToolsTile.Overlay.Badge>
                                                    </ToolsTile.Overlay>
                                                    <ToolsTile.Icon icon={<CrowdfundingToolIcon height={30} />} />
                                                    <ToolsTile.Title title="Crowdfunding tool" />
                                                    <ToolsTile.Description description="Evaluate the price sensitivity of a crowdfunding deal." />
                                                </ToolsTile>
                                            </CarouselItem>
                                        </Carousel>
                                    </SectionWithSeparator>
                            }
                        </Glass>
                    </Section>
                </Container>
            </Main>
        </>
    );
};
