import { useEffect } from "react";
import { getImageUrl, ImageSize } from "../../helpers/cms";
import { useWpLoader } from "../../hoc/withLoader";
import { WpPostTypeRequest, ArticleParams, OrderBy, Order } from "../Auth/AuthenticatedCmsClient";
import { ArticleCardProps } from "../Cards/ArticleCard";
import { Glass } from "../Layout/Glass/Glass";
import { RelatedContentCarousel } from "./RelatedContentCarousel";
import { Content } from "../Layout/Content/Content";

interface TagContentCarouselProps {
    title: string,
    tag: string;
}

export const TagContentCarousel = ({ title, tag } : TagContentCarouselProps) => {
    const [loaderState, { load, defaultLoaderDisplay }] = useWpLoader();

    useEffect(() => {
        if (loaderState.loadingState === "NotStarted") {
            load(new WpPostTypeRequest({
                url: "posts",
                params: new ArticleParams({
                    per_page: 6,
                    orderby: OrderBy.Date,
                    order: Order.Descending,
                    tag_slug: tag
                })
            }));  
        }
    }, [loaderState.loadingState]);

    let inner = defaultLoaderDisplay();

    if (loaderState.response) {

        let contents : ArticleCardProps[] = loaderState.response.map((x : any) : ArticleCardProps => {
            return {
                id: x.id,
                header: x.title,
                url: `/learn/article/${x.slug}`,
                imageUrl: getImageUrl(x.acf.thumbnail, ImageSize.Medium),
                duration: x.acf.duration,
                membership: x.membership
            }
        });

        inner = <RelatedContentCarousel contents={contents} title={title}/>
    }
    
    return (
        <Glass opacity={100}>
            <Content isMain={true}>
                { inner }
            </Content>
        </Glass>
    );
    
}